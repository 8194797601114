import React, { memo } from "react";
import { ListGroup, ListGroupItem, UncontrolledTooltip } from "reactstrap";
import { capitalize } from "../helper-methods";

const FanListPopver = ({ members = [], target = "" }) => {
  if (!target) return <></>;

  return (
    <>
      <UncontrolledTooltip
        placement="bottom"
        target={target}
        className="tagsListTooltip"
      >
        <ListGroup>
          {members?.length ? (
            members?.map((each) => (
              <ListGroupItem key={`groups_members_list_${each._id}`}>
                {capitalize(each.name?.full || each.name?.first)}
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem>No members</ListGroupItem>
          )}
        </ListGroup>
      </UncontrolledTooltip>
    </>
  );
};

export default memo(FanListPopver);
