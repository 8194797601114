import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

function ImageEditor({ image, _onImageEditing }) {
  // Function to convert Base64 to Blob
  const base64ToBlob = (base64, contentType = "image/png", sliceSize = 512) => {
    const byteCharacters = atob(base64.split(",")[1]); // Decode Base64
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  // Handler for saving the edited image
  const handleSave = (editedImageObject) => {
    if (editedImageObject?.imageBase64) {
      // Extract the Base64 string (remove the prefix if needed)
      const imageBase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABLA"; // shortened for brevity
      const base64String = imageBase64.split(",")[1];

      // Decode the Base64 string
      const binaryString = atob(base64String);
      const binaryLength = binaryString.length;
      const bytes = new Uint8Array(binaryLength);

      // Populate the Uint8Array with binary data
      for (let i = 0; i < binaryLength; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      // Create a Blob
      // const blobBytes = new Blob([bytes], { type: "image/png" });

      // Convert Base64 to Blob
      const blob = base64ToBlob(editedImageObject.imageBase64, "image/png");
      const blobUrl = URL.createObjectURL(blob);
      console.log("blobUrl>>>", blobUrl);
      console.log("blob>>>", blob);

      _onImageEditing("previewBlob", blobUrl, blob);
    }
  };

  return (
    <div>
      <FilerobotImageEditor
        source={image}
        onBeforeSave={() => false} //Save as Modal won't be triggered
        onSave={handleSave}
        annotationsCommon={{
          fill: "#ff0000",
        }}
        Text={{ text: "Enter text" }}
        Rotate={{ angle: 90, componentType: "slider" }}
        Crop={{
          autoResize: true,
          presetsItems: [
            { titleKey: "classicTv", descriptionKey: "4:3", ratio: 4 / 3 },
            { titleKey: "cinemascope", descriptionKey: "21:9", ratio: 21 / 9 },
          ],
          presetsFolders: [
            {
              titleKey: "socialMedia",
              groups: [
                {
                  titleKey: "facebook",
                  items: [
                    {
                      titleKey: "profile",
                      width: 180,
                      height: 180,
                      descriptionKey: "fbProfileSize",
                    },
                    {
                      titleKey: "coverPhoto",
                      width: 820,
                      height: 312,
                      descriptionKey: "fbCoverPhotoSize",
                    },
                  ],
                },
              ],
            },
          ],
        }}
        tabsIds={[TABS.ADJUST, TABS.FINETUNE, TABS.FILTERS, TABS.ANNOTATE]}
        defaultTabId={TABS.ADJUST}
        defaultToolId={TOOLS.TEXT}
        avoidChangesNotSavedAlertOnLeave={true}
        disableSaveIfNoChanges={true}
        // removeSaveButton
      />
    </div>
  );
}

export default ImageEditor;
