import { TextareaAutosize } from "@material-ui/core";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Label,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
} from "reactstrap";
import { autoHeightStyleChat } from "../../assets/styles/js";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  capitalize,
  containsRestrictedWords,
  errorHandler,
  formatCurrencyValue,
  formatDecimalNumber,
  generateCalenderDateForChat,
  getLowResolutionLink,
  handleBrokenImageURL,
  getFullName,
  isFromSafari,
  isPermissionToAccess,
  removeLastWord,
  showToast,
  sleepTime,
  uploadFileOnServer,
} from "../../helper-methods";
import {
  blockSubscriberFromThread,
  markFanAsFavourite,
  markThreadAsRead,
  saveContents,
  updateChatShoutoutStartTime,
} from "../../http-calls";
import {
  addLocalMessage,
  getAndUpdateChatThreads,
  getAndUpdateMessages,
  hideLoader,
  showLoader,
  toggleFanChatFavourite,
  updateThreadFanToTag,
  unreadMessageCountUpdate,
  updateMessageKey,
  updateSelectedThread,
} from "../../redux/actions";
import { newSocket } from "../../socket-io";
import ChatVideoRoomComponent from "./ChatVideoRoomComponent";
import ViewChatMediaModal from "../modals/ViewChatMediaModal";
import MessageItem from "./MessageItem";
import cuid from "cuid";
import MediaLibraryModal from "../modals/MediaLibraryModal";
import moment from "moment";
import FullScreenVideoRecorder from "../FullScreenVideoRecorder";
import SoundRecordUploadModal from "../modals/SoundRecordUploadModal";
import ChatPriceSetterModal from "../modals/ChatPriceSetterModal";
import SkeletonLoading from "../SkeletonLoading";
import ErrorBoundary from "../ErrorBoundary";
import LOAD_ICON from "../../assets/img/load_icon.png";
import TextDetectModal from "../modals/TextDetectModal";
import AddFanToTagPopver from "../AddFanToTagPopver";
import EmojiPickerReact, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import Swal from "sweetalert2";
import colors from "../../assets/styles/scss/style.scss";

const ConversationCard = ({ screenWidth, isShow = true }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const observer = useRef();
  const chatWindowRef = useRef();
  const setTimeOutTimerRef = useRef({ current: {} });
  const prevValues = useRef({ current: {} });
  const prevScrollHeight = useRef();

  const userData = useSelector((state) => state?.userData);
  const { selectedThread, messages, messagesCount, threads, threadsCount } =
    useSelector((state) => state?.chatData || {});
  const { tags } = useSelector((state) => state?.tags || {});

  const [messagesPayload, setMessagesPayload] = useState({
    skip: 0,
    limit: 20,
  });

  const [loadingState, setLoadingState] = useState({
    messages: false,
  });

  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [sendMessage, setSendMessage] = useState({
    text: "",
    images: {},
    videos: {},
    audios: {},
    amount: 0,
  });

  const [mediaLibraryModal, setMediaLibraryModal] = useState({
    isOpen: false,
    data: null,
  });
  const [chatPriceSetterModal, setChatPriceSetterModal] = useState({
    isOpen: false,
  });
  const [viewChatMediaModal, setViewChatMediaModal] = useState({
    isOpen: false,
  });
  const [fullScreenVideoRecorder, setFullScreenVideoRecorder] = useState({
    isOpen: false,
  });
  const [soundRecordUploadModal, setSoundRecordUploadModal] = useState({
    isOpen: false,
  });
  const [textDetectModal, setTextDetectModal] = useState({
    isOpen: false,
  });

  const [chatVideoRoomComponent, setChatVideoRoomComponent] = useState({
    startCall: false,
    message: null,
  });

  const mediaPreviews = useMemo(() => {
    const { images, videos, audios, amount } = sendMessage;
    const preview = [];
    // Iterate through images
    for (let imageId in images) {
      const image = images[imageId];
      preview.push({
        ...image,
        fileId: imageId,
        fileType: "image",
        category: "images",
      });
    }
    // Iterate through videos
    for (let videoId in videos) {
      const video = videos[videoId];
      preview.push({
        ...video,
        fileId: videoId,
        fileType: "video",
        category: "videos",
      });
    }
    // Iterate through audio
    for (let audioId in audios) {
      const audio = audios[audioId];
      preview.push({
        ...audio,
        fileId: audioId,
        fileType: "audio",
        category: "audios",
      });
    }
    if (amount > 0) {
      preview.push({
        amount,
        fileType: "priceTag",
      });
    }
    return preview;
  }, [sendMessage]);

  const fileCount = useMemo(
    () =>
      sendMessage.amount ? mediaPreviews?.length - 1 : mediaPreviews?.length,
    [sendMessage, mediaPreviews]
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _toggleTextDetectModal = (isOpen = false) => {
    setTextDetectModal({ isOpen });
  };

  const _scrollToBottomInChatWindow = (isHalf = false) => {
    if (!chatWindowRef?.current?.scrollHeight) return;

    if (isHalf) {
      setTimeout(() => {
        chatWindowRef.current.scrollTop =
          chatWindowRef.current.scrollHeight - prevScrollHeight.current;

        prevScrollHeight.current = chatWindowRef.current.scrollHeight;
      }, 0);
      return;
    }

    setTimeout(() => {
      prevScrollHeight.current = chatWindowRef.current.scrollHeight;
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }, 0);
  };

  const _sendMessageError = (message) => {
    try {
      if (!message) return;

      dispatch(updateMessageKey({ message, key: "isError", value: true }));
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _saveShoutoutStartCallTime = (message) => {
    try {
      if (!message) return;

      dispatch(
        updateMessageKey({ message, key: "status", value: message.status })
      );
      dispatch(
        updateMessageKey({
          message,
          key: "startCallTime",
          value: message.startCallTime,
        })
      );
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _publishMessageOnChannel = (message) => {
    try {
      newSocket.emit("newmessage", message, (res) => {
        console.log("send>>", res);

        if (res.doSkip) return;

        if (res.error) {
          errorHandler(res.error);
          _sendMessageError(message);
        }
      });

      if (!newSocket.connected) {
        throw new Error();
      }
    } catch (error) {
      _sendMessageError(message);
      errorHandler(error);
    }
  };

  const _isMessageAsPurchased = (message) => {
    try {
      dispatch(updateMessageKey({ message, key: "hasPurchased", value: true }));
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _markMessageAsSent = (message) => {
    try {
      dispatch(updateMessageKey({ message, key: "isUploading", value: false }));
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _appendAndPublishNewMessage = (message) => {
    if (message.hasMedia) {
      const contentsObj = Object.keys(message.media).map((e) => {
        return {
          _id: message.media[e]._id,
          contentType: message.media[e].contentType,
          url: message.media[e].url,
          hlsLink: message.media[e].hls,
          thumbnail: message.media[e].thumbnail,
        };
      });

      message["content"] = contentsObj.map((e) => {
        return {
          _contentId: {
            _id: e._id,
            url: e.url,
            hlsLink: e.hls,
            thumbnail: e.thumbnail,
            contentType: e.contentType,
            aspectRatio: e.aspectRatio,
          },
          _id: e._id,
          mediaType: e.contentType,
          // thumbnail: e.thumbnail,
          isPublic: message.isPublic,
          amount: message.amount,
        };
      });
    }

    const findThread = threads.find((each) => each._id === message._thread);

    if (findThread) {
      dispatch(
        addLocalMessage({
          threadId: message._thread,
          message: {
            ...message,
            time: moment(message.timestamp).format("h:mm A"),
            isMyMessage: true,
            hasMedia: message.content?.length ? true : false,
          },
        })
      );
    } else {
      _getAndUpdateChatThreads();
    }

    _scrollToBottomInChatWindow();

    _publishMessageOnChannel(message);

    _markMessageAsSent(message);
  };

  const _sendNewMessage = async () => {
    try {
      if (loadingState.messages) return;

      const { images, videos, audios, amount, text } = sendMessage;

      _toggleIsShowEmoji();

      let messageText = text?.trim().length ? text.trim() : null;

      const tempMessageId = cuid();

      const messagesToBeSent = {};

      // Prepare outbox queue
      // Iterate through images
      // Iterate through images videos audios
      if (
        Object.keys(images).length ||
        Object.keys(videos).length ||
        Object.keys(audios).length
      ) {
        messagesToBeSent[tempMessageId] = {
          _from: selectedThread?.influencer?._id,
          _to: selectedThread?.fan?._id,
          when: new Date(),
          isPublic: amount && amount > 0 ? false : true,
          amount: amount && amount > 0 ? amount : 0,
          isUploading: true,
          media: {},
          hasMedia: true,
          tempMessageId,
          isLocalMessage: true,
        };

        messagesToBeSent[tempMessageId]["media"] = {
          ...images,
          ...videos,
          ...audios,
        };
        if (messageText) {
          messagesToBeSent[tempMessageId]["text"] = messageText;
          messageText = null;
        }
      }

      if (
        Object.keys(messagesToBeSent)?.length === 0 &&
        messageText &&
        amount &&
        amount > 0
      ) {
        showToast(
          "Text messages cannot be set as paid messages. You must either upload media or remove the payment request.",
          "error"
        );
        return;
      }

      if (Object.keys(messagesToBeSent)?.length === 0 && messageText) {
        messagesToBeSent[tempMessageId] = {
          _from: selectedThread?.influencer?._id,
          _to: selectedThread?.fan?._id,
          when: new Date(),
          isUploading: true,
          hasMedia: false,
          tempMessageId,
          isLocalMessage: true,
        };
        if (messageText) {
          messagesToBeSent[tempMessageId]["text"] = messageText;
          messageText = null;
        }
      }

      if (Object.keys(messagesToBeSent)?.length) {
        messagesToBeSent[tempMessageId]["_thread"] = selectedThread?._id;

        // Append as local messages in thread
        _appendAndPublishNewMessage(messagesToBeSent[tempMessageId]);

        //   Clear new message
        setSendMessage({
          text: "",
          images: {},
          videos: {},
          audios: {},
          amount: 0,
        });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _onMessageTextInputKeyUp = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value.trim().length &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      // inputRef.current.blur();
      _sendNewMessage();
    }
  };

  const _onMessageTextInputChange = (newText, isEmoji) => {
    const newSendMessage = { ...sendMessage };

    if (containsRestrictedWords(newText)) {
      _toggleTextDetectModal(true);
      newText = removeLastWord(newText);
    }

    if (isEmoji) {
      newSendMessage.text = newSendMessage.text + newText;
    } else {
      newSendMessage.text = newText;
    }

    setSendMessage(newSendMessage);
  };

  const _toggleIsShowEmoji = (isShow = false) => {
    setIsShowEmoji(isShow);
  };

  const _onEmojiClick = (event, emojiObject) => {
    _onMessageTextInputChange(emojiObject.emoji, true);
  };

  const _toggleChatVideoRoomComponent = (startCall = false, message = null) => {
    if (!startCall) {
      _startCall(chatVideoRoomComponent.message, "completed");
    }

    // video call
    setChatVideoRoomComponent({
      startCall,
      message,
    });
  };

  // status = accepted or completed
  const _startCall = async (shoutoutMessage, status = "completed") => {
    try {
      if (shoutoutMessage) {
        const messageId =
          shoutoutMessage.tempMessageId || shoutoutMessage.messageId;
        const startCallTime = shoutoutMessage?.startCallTime || new Date();

        const skipMessage = {
          doSkip: true,
          _thread: shoutoutMessage._thread,
          _from: shoutoutMessage._from._id || shoutoutMessage._from,
          _to: shoutoutMessage._to._id || shoutoutMessage._to,
          when: shoutoutMessage.timestamp || shoutoutMessage.when,
          messageId,
          status,
          startCallTime,
          _shoutoutOrder:
            shoutoutMessage._shoutoutOrder?._id ||
            shoutoutMessage._shoutoutOrder,
        };

        if (status === "accepted") {
          dispatch(showLoader("Start calling"));
          await updateChatShoutoutStartTime(messageId, { startCallTime });
          dispatch(hideLoader());

          _saveShoutoutStartCallTime(skipMessage);

          _toggleChatVideoRoomComponent(true, {
            ...shoutoutMessage,
            startCallTime,
          });
        }

        _publishMessageOnChannel(skipMessage);
      }
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _removeMedia = (mediaFile) => {
    const newSendMessage = { ...sendMessage };
    if (mediaFile?.fileType === "priceTag") {
      newSendMessage.amount = 0;
    } else {
      delete newSendMessage[mediaFile.category][mediaFile.fileId];
    }
    setSendMessage(newSendMessage);
  };

  const _renderMediaPreview = useCallback((mediaFile) => {
    const _handleBrokenImageURL = handleBrokenImageURL();

    switch (mediaFile?.fileType) {
      case "image": {
        return (
          <>
            <img
              src={mediaFile?.previewBlob || mediaFile?.url}
              alt="Uploaded Img"
              loading="lazy"
              onError={(e) =>
                _handleBrokenImageURL({
                  event: e,
                  url: mediaFile.url,
                  fallbackImg: LOAD_ICON,
                })
              }
            />
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "video": {
        return (
          <>
            <video controlsList="nodownload" disablePictureInPicture>
              <source src={mediaFile?.previewBlob || mediaFile?.url} />
              Your browser does not support the video tag.
            </video>
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "audio": {
        return (
          <>
            <img
              src="/assets/img/microphone.png"
              alt="Microphone"
              style={{ height: 28, width: "unset" }}
              loading="lazy"
            />
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "priceTag": {
        return (
          <div>
            {mediaFile?.amount ? formatCurrencyValue(mediaFile.amount) : "-"}
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _closeMessagesCard = () => {
    dispatch(updateSelectedThread(null));
  };

  const _toggleChatPriceSetterModal = (isOpen = false) => {
    setChatPriceSetterModal({ isOpen });
  };

  const _onAmountUpdate = (amount) => {
    const newSendMessage = { ...sendMessage };

    newSendMessage.amount = amount ? formatDecimalNumber(+amount) : 0;

    setSendMessage(newSendMessage);

    _scrollToBottomInChatWindow();
  };

  const _toggleMediaLibraryModal = (isOpen = false, data = null) => {
    setMediaLibraryModal({ isOpen, data });
  };

  const _toggleSoundRecordUploadModal = (isOpen = false) => {
    setSoundRecordUploadModal({ isOpen });
  };

  const _toggleFullScreenVideoRecorder = (isOpen = false) => {
    setFullScreenVideoRecorder({ isOpen });
  };

  const _onRecordingComplete = async (blobObject) => {
    try {
      _toggleFullScreenVideoRecorder();

      if (!blobObject) return;

      const res = await uploadFileOnServer([
        {
          uploadData: blobObject,
          previewBlob: blobObject,
          type: "video",
        },
      ]);

      if (!res?.length) {
        return;
      }

      const { contents } = await saveContents({
        contents: [{ contentType: res[0].contentType, url: res[0].url }],
      });

      if (!contents?.length) {
        return;
      }

      const newSendMessage = { ...sendMessage };

      const videoId = cuid();

      newSendMessage.videos[videoId] = {
        blobURL: blobObject.blobURL,
        type: blobObject.type,
        contentType: contents[0].contentType,
        url: contents[0].url,
        hlsLink: contents[0].hls,
        thumbnail: contents[0].thumbnail,
        _id: contents[0]._id,
      };

      setSendMessage(newSendMessage);

      _scrollToBottomInChatWindow();
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onAudioRecordingStop = async (blobObject) => {
    try {
      _toggleSoundRecordUploadModal();

      if (!blobObject?.blob) return;

      const res = await uploadFileOnServer([
        {
          uploadData: blobObject.blob,
          previewBlob: blobObject.blobURL,
          type: "audio",
        },
      ]);

      if (!res?.length) {
        return;
      }

      const { contents } = await saveContents({
        contents: [{ contentType: res[0].contentType, url: res[0].url }],
      });

      if (!contents?.length) {
        return;
      }

      const newSendMessage = { ...sendMessage };

      const audioId = cuid();

      newSendMessage.audios[audioId] = {
        blobURL: blobObject.blobURL,
        type: blobObject.type,
        contentType: contents[0].contentType,
        url: contents[0].url,
        hlsLink: contents[0].hls,
        thumbnail: contents[0].thumbnail,
        _id: contents[0]._id,
      };

      setSendMessage(newSendMessage);

      _scrollToBottomInChatWindow();
    } catch (error) {
      errorHandler(error);
    }
  };

  const _toggleViewChatMediaModal = (isOpen = false) => {
    setViewChatMediaModal({ isOpen });
  };

  const _rediretToCreatorFanList = (username) => {
    if (!username) {
      return;
    }

    history.push(`/my-profile?tabId=4&username=${username}`);
  };

  const _formatMessageContent = async (contents) => {
    if (!contents?.length) {
      return;
    }

    const newSendMessage = { ...sendMessage };
    const currentTimeStamp = +new Date();

    contents.forEach((each, index) => {
      if (each.contentType.indexOf("image") > -1) {
        const imageId = currentTimeStamp + "" + index;
        newSendMessage.images[imageId] = each;
      } else if (each.contentType.indexOf("video") > -1) {
        const videoId = currentTimeStamp + "" + index;
        newSendMessage.videos[videoId] = each;
      } else if (each.contentType.indexOf("audio") > -1) {
        const audioId = currentTimeStamp + "" + index;
        newSendMessage.audios[audioId] = each;
      } else {
        alert("Only Image, Video and Audio are allowed");
      }
    });

    setSendMessage(newSendMessage);

    _scrollToBottomInChatWindow();
  };

  const _updateThreadFanToTag = (thread, groups = []) => {
    dispatch(updateThreadFanToTag({ thread, groups }));
  };

  const _toggleFanChatFavourite = (thread) => {
    if (setTimeOutTimerRef?.current?.favourite)
      clearTimeout(setTimeOutTimerRef.current.favourite);

    if (!prevValues?.current?.hasOwnProperty("isFavourited")) {
      prevValues.current.isFavourited = thread.isFavourited;
    }

    const isFavourited = thread.isFavourited ? false : true;

    dispatch(toggleFanChatFavourite({ thread, isFavourited }));

    setTimeOutTimerRef.current.favourite = setTimeout(async () => {
      try {
        if (prevValues.current.isFavourited === isFavourited) {
          delete prevValues.current.isFavourited;
          return;
        }

        const res = await markFanAsFavourite(thread.fan._id);

        delete prevValues.current.isFavourited;

        dispatch(
          toggleFanChatFavourite({ thread, isFavourited: res.isFavourited })
        );
      } catch (error) {
        dispatch(
          toggleFanChatFavourite({
            thread,
            isFavourited: prevValues.current.isFavourited,
          })
        );
        delete prevValues.current.isFavourited;
        errorHandler(error);
      }
    }, 750);
  };

  const _getAndUpdateMessages = async (payload, isResetScroll = false) => {
    try {
      _manageLoadingState("messages", true);

      if (isResetScroll) {
        _scrollToBottomInChatWindow();
      }

      await getAndUpdateMessages({ threadId: selectedThread?._id, payload })(
        dispatch
      );

      _scrollToBottomInChatWindow(!isResetScroll);

      setTimeout(() => {
        _manageLoadingState("messages", false);
      }, 0);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("messages", false);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.messages) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          messages?.[selectedThread?._id]?.length <
            messagesCount?.[selectedThread?._id]
        ) {
          const newMessagesPayload = { ...messagesPayload };
          newMessagesPayload.skip =
            messages?.[selectedThread?._id]?.length || 0;
          setMessagesPayload(newMessagesPayload);
          _getAndUpdateMessages(newMessagesPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.messages, messages, messagesCount]
  );

  const _getAndUpdateChatThreads = async (isSubscribeAgain = false) => {
    try {
      _manageLoadingState("threads", true);

      await getAndUpdateChatThreads({
        limit: threadsCount > 500 ? 5000 : 500,
        skip: 0,
      })(dispatch);

      if (isSubscribeAgain) {
        _unsubscribeToSingleThreadChannel();

        await sleepTime(100);

        _subscribeToSingleThreadChannel();
      }

      _manageLoadingState("threads", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("threads", false);
    }
  };

  // subscribe to single thread with multiple receive messages
  const _subscribeToSingleThreadChannel = () => {
    try {
      const params = { room: userData.user._id };

      newSocket.emit("subscribe", params, function (res) {
        console.log("subscribed>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });

      newSocket.on("messageNotifiation", (receiveMessage) => {
        console.log("receiveMessageNotifiation>>", receiveMessage);

        if (receiveMessage.doSkip) {
          if (receiveMessage.hasPurchased) {
            _isMessageAsPurchased(receiveMessage);
          }
          return;
        }

        if (receiveMessage.error) {
          errorHandler(receiveMessage);
        } else {
          if (receiveMessage._to === userData?.user?._id) {
            const findThread = threads.find(
              (each) => each._id === receiveMessage._thread
            );
            if (findThread) {
              dispatch(
                addLocalMessage({
                  threadId: receiveMessage._thread,
                  message: {
                    ...receiveMessage,
                    time: moment(
                      receiveMessage.when || receiveMessage.timestamp
                    ).format("h:mm A"),
                    timestamp: receiveMessage.when || receiveMessage.timestamp,
                    isMyMessage: false,
                    isPublic:
                      receiveMessage?.content?.[0]?.amount > 0 ? false : true,
                  },
                })
              );
            } else {
              _getAndUpdateChatThreads(true);
            }

            dispatch(
              unreadMessageCountUpdate({
                threadId: receiveMessage?._thread,
              })
            );
          }
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  // unsubscribe to single thread with multiple receive messages
  const _unsubscribeToSingleThreadChannel = () => {
    try {
      const params = { room: userData.user._id };
      // remove all callback of messageNotifiation event
      newSocket.removeAllListeners("messageNotifiation");
      // unsubscribe event - pause callback
      newSocket.emit("unsubscribe", params, function (res) {
        console.log("unsubscribed>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _subscribeUnreadMessageCount = () => {
    try {
      const params = { room: userData.user._id };

      newSocket.emit("subscribe", params, function (res) {
        console.log("subscribed>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });
      newSocket.on("messageNotifiation", (receiveMessage) => {
        console.log({ receiveMessage });

        if (receiveMessage.doSkip) return;

        if (receiveMessage.error) {
          errorHandler(receiveMessage);
        } else {
          if (receiveMessage._to === userData?.user?._id) {
            dispatch(
              unreadMessageCountUpdate({
                threadId: receiveMessage?._thread,
              })
            );
          }
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _initialiseNewChat = () => {
    _toggleIsShowEmoji();

    setSendMessage({
      text: "",
      images: {},
      videos: {},
      audios: {},
      amount: 0,
    });

    const newMessagesPayload = { ...messagesPayload };
    newMessagesPayload.skip = 0;
    setMessagesPayload(newMessagesPayload);
    _getAndUpdateMessages(newMessagesPayload, true);
  };

  const _onDropFile = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _toggleMediaLibraryModal(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  const _toggleBlockAlert = () => {
    if (!selectedThread?._id || !selectedThread?.fan?.id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${
        selectedThread?.isThreadBlocked ? "unblock" : "block"
      } this susbcriber?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors?.themeColor,
      cancelButtonColor: "#ccc",
      confirmButtonText: "Yes, continue",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          const res = await blockSubscriberFromThread(selectedThread?._id);
          await _getAndUpdateMessages(res?.thread, true);
        } catch (error) {
          errorHandler(error);
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        showToast(
          selectedThread?.isThreadBlocked
            ? "Subscriber has been unblocked and can send messages to you."
            : "Subscriber has been blocked and cannot send messages to you.",
          "success"
        );
      }
    });
  };

  useEffect(() => {
    if (isShow && selectedThread?._id) {
      _initialiseNewChat();
    }

    return () => {
      try {
        if (selectedThread?._id) {
          const isLastMessageFromUser =
            (messages?.[selectedThread?._id]?.slice(-1)?.[0]?._from?.id ||
              messages?.[selectedThread?._id]?.slice(-1)?.[0]?._from) ===
            userData?.user?.id;

          if (!isLastMessageFromUser) {
            markThreadAsRead(selectedThread?._id);
          }
        }
      } catch (error) {
        console.log({ error });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedThread?._id, isShow]);

  useEffect(() => {
    // unsubscribe for message unread count
    _unsubscribeToSingleThreadChannel();

    _subscribeToSingleThreadChannel();

    return () => {
      _unsubscribeToSingleThreadChannel();

      // subscribe message count show
      _subscribeUnreadMessageCount();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`conversationWeb ${
          screenWidth < 768 && !selectedThread ? "d-none" : ""
        } ${isShow === false ? "d-none" : ""}`}
      >
        {selectedThread ? (
          <Fragment>
            <div>
              <div className="addMessageWrap">
                <div className="receiverInfo-Chat">
                  <div className="d-flex align-items-center">
                    <Button
                      className="customBackBtn mr-1"
                      onClick={() => _closeMessagesCard()}
                    >
                      <i className="fa fa-chevron-left" />
                    </Button>

                    <img
                      src={
                        getLowResolutionLink(
                          selectedThread?.fan?.profilePicture
                        ) || DEFAULT_PROFILE_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                      alt="Profile Img"
                      className="profileMsgPg"
                      loading="lazy"
                    />
                    <div className="receiverName-Chat">
                      <div className="d-flex">
                        <h4
                          onClick={() => {
                            _rediretToCreatorFanList(
                              selectedThread?.fan?.username
                            );
                          }}
                        >
                          {getFullName(selectedThread?.fan?.name)}
                        </h4>
                        {selectedThread?.fan?.groups?.length ? (
                          <>
                            <div className="tagsListConversation">
                              <div>
                                {capitalize(
                                  selectedThread?.fan?.groups?.[0]?.name
                                )}
                              </div>
                              {/* if tags is more than 1, then show like this */}
                              {selectedThread?.fan?.groups?.length >= 2 ? (
                                <>
                                  <span id="tagsList">
                                    +{selectedThread?.fan?.groups?.length - 1}
                                  </span>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="tagsList"
                                    className="tagsListTooltip"
                                  >
                                    <ListGroup>
                                      {selectedThread?.fan?.groups
                                        ?.slice(1)
                                        ?.map((each) => (
                                          <ListGroupItem
                                            key={`message_tags_list_${each._id}`}
                                          >
                                            {capitalize(each.name)}
                                          </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                  </UncontrolledTooltip>
                                </>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </div>
                      <span>@{selectedThread?.fan?.username}</span>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-center position-relative"
                    style={{ flexShrink: 0 }}
                  >
                    <Button
                      className="makeFavorite"
                      id={`web_message_view_tag`}
                    >
                      <i
                        className="fa fa-tag"
                        style={{ verticalAlign: -0.5 }}
                      />
                    </Button>

                    <AddFanToTagPopver
                      tags={tags || []}
                      fanId={selectedThread?.fan?._id}
                      groups={selectedThread?.fan?.groups || []}
                      target={`web_message_view_tag`}
                      btnText={"Save"}
                      onSuccess={(groups) =>
                        _updateThreadFanToTag(selectedThread, groups)
                      }
                    />

                    <Button
                      className="makeFavorite"
                      onClick={() => _toggleFanChatFavourite(selectedThread)}
                    >
                      {selectedThread?.isFavourited ? (
                        <i className="fa fa-star themeColor" />
                      ) : (
                        <i className="fa fa-star-o" />
                      )}
                    </Button>

                    <Button
                      className="makeFavorite"
                      onClick={() => _toggleViewChatMediaModal(true)}
                    >
                      <i className="fa fa-image" />
                    </Button>

                    {/* TODO: hide for group chats (what is identifier for group chats?) */}
                    <Button
                      color="link"
                      className="seeMedia"
                      onClick={() => _toggleBlockAlert()}
                    >
                      {selectedThread?.isThreadBlocked ? "Unblock" : "Block"}
                    </Button>
                  </div>
                </div>
                <div className="conversationDetails" ref={chatWindowRef}>
                  {/* chat timeline */}

                  {messages?.[selectedThread?._id]?.length ? (
                    messages[selectedThread?._id].map(
                      (message, messageIndex) => (
                        <Fragment
                          key={`conversation_message_item_${messageIndex}`}
                        >
                          <ErrorBoundary>
                            <MessageItem
                              {...(messageIndex === 0
                                ? { lastElementRef }
                                : {})}
                              messageData={message}
                              loading={loadingState.messages}
                              prevDateHeading={generateCalenderDateForChat(
                                messages?.[selectedThread?._id]?.[
                                  messageIndex - 1
                                ]?.timestamp
                              )}
                              currDateHeading={generateCalenderDateForChat(
                                message?.timestamp
                              )}
                              startCall={_startCall}
                            />
                          </ErrorBoundary>
                        </Fragment>
                      )
                    )
                  ) : loadingState?.messages ? (
                    <SkeletonLoading type={"conversation"} count={2} />
                  ) : (
                    <div />
                  )}
                </div>

                {isPermissionToAccess("messaging", "canSendMessaging") ? (
                  <div
                    id="sendMessageContainer"
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => _onDropFile(e)}
                  >
                    {/* chatHistory */}
                    <div className="typeMsgWrap">
                      { userData.user?.isScheduleForAccountDelete === true ||
                      selectedThread?.isBlocked ||
                      selectedThread?.isCountryBlocked ? (
                        <div className="text-center w-100">
                          <p>
                            {loadingState?.messages
                              ? "Loading..."
                              : "You can not send messages"}
                          </p>
                        </div>
                      ) : (
                        <>
                          {mediaPreviews.length ? (
                            <div className="msgPreviewWrap mb-2 mt-0">
                              {mediaPreviews.map((media, mediaIndex) => (
                                <div className="msgPreview" key={mediaIndex}>
                                  {_renderMediaPreview(media)}
                                </div>
                              ))}
                            </div>
                          ) : null}

                          {isShowEmoji ? (
                            <EmojiPickerReact
                              onEmojiClick={_onEmojiClick}
                              skinTone={SKIN_TONE_NEUTRAL}
                            />
                          ) : null}

                          <div className="position-relative">
                            <TextareaAutosize
                              style={autoHeightStyleChat}
                              placeholder="Type a message..."
                              value={sendMessage.text}
                              onChange={(e) =>
                                _onMessageTextInputChange(e.target.value)
                              }
                              onKeyUp={(e) => _onMessageTextInputKeyUp(e)}
                              onFocus={() => _toggleIsShowEmoji()}
                            />

                            <Button
                              color="link"
                              className="emojiBtnMsg"
                              onClick={() =>
                                _toggleIsShowEmoji(isShowEmoji ? false : true)
                              }
                            >
                              <i className="fa fa-smile-o" />
                            </Button>

                            <Button
                              disabled={
                                fileCount || sendMessage.text?.trim().length
                                  ? false
                                  : true
                              }
                              className="sendMsg"
                              onClick={_sendNewMessage}
                            >
                              {loadingState.messages ? (
                                <i className="fa fa-spinner fa-spin mr-1" />
                              ) : (
                                <img
                                  src="/assets/img/send.png"
                                  alt="Send"
                                  loading="lazy"
                                />
                              )}
                            </Button>
                          </div>

                          <div className="createPostBtnsWrapper mt-0 mb-1">
                            <div className="createPostBtnsWrap">
                              <div className="d-flex">
                                <Label className="btn mediaBtn">
                                  <img
                                    src="/assets/img/img-gallery-active.png"
                                    alt="Img"
                                    onClick={() =>
                                      _toggleMediaLibraryModal(true)
                                    }
                                    loading="lazy"
                                  />
                                </Label>

                                {/* on clicking the below btn, an audio recorder feature should be shown, for this a modal has been added  */}
                                {!isFromSafari() ? (
                                  <Button
                                    className="mediaBtn"
                                    onClick={() =>
                                      _toggleSoundRecordUploadModal(true)
                                    }
                                  >
                                    <img
                                      src="/assets/img/microphone-feed.png"
                                      alt="Microphone"
                                      className="audioIcon"
                                      loading="lazy"
                                    />
                                  </Button>
                                ) : null}

                                {/* on clicking the below btn, an video recorder feature should be shown.  */}
                                <Button
                                  className="mediaBtn"
                                  onClick={() =>
                                    _toggleFullScreenVideoRecorder(true)
                                  }
                                >
                                  <img
                                    src="/assets/img/video-gallery-active.png"
                                    alt="Video"
                                    className="videoIcon"
                                    loading="lazy"
                                  />
                                </Button>
                                {/* the below btn will show 2 modals, "Message Price" & "Enter Tip Amount" */}
                                <Button
                                  className="mediaBtn ml-1"
                                  onClick={() =>
                                    _toggleChatPriceSetterModal(true)
                                  }
                                  disabled={
                                    userData?.user?.hasOwnProperty(
                                      "paymentEnabled"
                                    ) && !userData?.user?.paymentEnabled
                                  }
                                >
                                  {/* hide this img for subscriber/ show this icon for creator. When this icon is there show "Message Price" modal */}
                                  <img
                                    src="/assets/img/price.png"
                                    alt="Price"
                                    className="priceIcon"
                                    style={{ height: 23, marginTop: 0 }}
                                    loading="lazy"
                                  />
                                </Button>
                              </div>

                              {fileCount > 0 ? (
                                <div
                                  className="filesSelected-CreatePost my-0"
                                  style={{ fontWeight: "500" }}
                                >
                                  {fileCount} {fileCount > 1 ? "files" : "file"}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <FullScreenVideoRecorder
              isOpen={fullScreenVideoRecorder.isOpen}
              toggle={() => _toggleFullScreenVideoRecorder()}
              // onStartRecording={_onStartRecording}
              onRecordingComplete={(blobObject) =>
                _onRecordingComplete(blobObject)
              }
            />

            <ChatPriceSetterModal
              isOpen={chatPriceSetterModal.isOpen}
              toggle={() => _toggleChatPriceSetterModal()}
              onSave={(amount) => _onAmountUpdate(amount)}
              amount={sendMessage?.amount || ""}
            />

            <SoundRecordUploadModal
              isOpen={soundRecordUploadModal.isOpen}
              toggle={() => _toggleSoundRecordUploadModal()}
              onSave={(blobObject) => _onAudioRecordingStop(blobObject)}
              isHideUploadAudioFile={true}
            />

            <MediaLibraryModal
              isOpen={mediaLibraryModal.isOpen}
              dropFileData={mediaLibraryModal?.data}
              toggle={() => _toggleMediaLibraryModal()}
              uploadContents={(contents) => _formatMessageContent(contents)}
              isMultipleImages={true}
              isMultipleVideos={true}
              isMultipleAudios={true}
              isMultipleUpload={true}
              isAudioUpload={true}
              isVideoUpload={true}
              isImageUpload={true}
              shouldUploadInModal={true}
            />
          </Fragment>
        ) : (
          <div className="selectChatUserWeb">
            <i className="fa fa-user-o" />

            <p>Select a user</p>
          </div>
        )}
      </div>

      <ViewChatMediaModal
        isOpen={viewChatMediaModal.isOpen}
        toggle={() => _toggleViewChatMediaModal()}
        threadId={selectedThread?._id}
      />

      {chatVideoRoomComponent.startCall && chatVideoRoomComponent.message && (
        <ChatVideoRoomComponent
          startCall={chatVideoRoomComponent.startCall}
          message={chatVideoRoomComponent.message}
          removeVideoRoom={() => _toggleChatVideoRoomComponent()}
        />
      )}

      <TextDetectModal
        isOpen={textDetectModal.isOpen}
        toggle={() => _toggleTextDetectModal()}
      />
    </>
  );
};

export default ConversationCard;
