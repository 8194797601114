import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { AppFooter, AppHeader } from "@coreui/react";
import ProtectedRoute from "../components/ProtectedRoute";
import { errorHandler, showToast } from "../helper-methods";
import {
  fetchNotifications,
  incrementUnreadNotificationCount,
} from "../redux/actions/notificationData";
import { connect } from "react-redux";
import { fetchAndAddNameConfigurable } from "../redux/actions/nameConfigurable";
import SidebarMenu from "../containers/SidebarMenu";
import VaultAddPage from "./VaultAddPage";
import CreatePostPage from "./CreatePostPage";
import MessagesPage from "./MessagesPage";
import ManageStickerPage from "./ManageStickerPage";
import ProtectedFeatureRoute from "../components/ProtectedFeatureRoute";
import { connectToSocket, disconnectToSocket, newSocket } from "../socket-io";
import {
  getUnreadMessageCountAction,
  unreadMessageCountUpdate,
} from "../redux/actions/chatData";
// import ProductList from "./ProductList";
// import ListOwnProduct from "./ListOwnProduct";
// import CustomizeAvailableProducts from "./CustomizeAvailableProducts";
// import CustomizeProduct from "./CustomizeProduct";
// import OrderList from "./OrderList";
// import OrderDetails from "./OrderDetails";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import PromotionLinks from "./PromotionLinks";
import FaqPage from "./FaqPage";
import FeedPage from "./FeedPage";
import VaultsPage from "./VaultsPage";
import MessageInitiatorPage from "./MessageInitiatorPage";
import BroadcastMessageStats from "./BroadcastMessageStats";
import MyProfilePage from "./MyProfilePage";
import SettingsPage from "./SettingsPage";
import NotificationsPage from "./NotificationPage";
import PostDetailsPage from "./PostDetailsPage";
import StatementPage from "./StatementPage";
import TrainingPage from "./TrainingPage";
import InfluencerProfileDetails from "./InfluencerProfileDetails";
import NonSubscriberFeedViewerPage from "./NonSubscriberFeedViewer";
import SubscriberFeedViewerPage from "./SubscriberFeedViewerPage";
import VaultDetailsPage from "./VaultDetailsPage";
import MediaQueueViewer from "../containers/MediaQueueViewer";
import PayPerViewPage from "./PayPerViewPage";
import CreatePayPerView from "./CreatePayPerView";
import CreateLiveEvent from "./CreateLiveEvent";
import PayPerViewDeatilsPage from "./PayPerViewDetailsPage";
import LiveEventPage from "./LiveEventPage";
import LiveEventDetailsPage from "./LiveEventDetailsPage";
import LiveEventStreamingPage from "./LiveEventStreamingPage";
import TeamMembers from "./TeamMembers";
import { createRef } from "react";
import { APP_NAME } from "../config";
import CustomPushNotification from "../components/custom/CustomPushNotification";
import StoryPage from "./StoryPage.jsx";
import FollowersStatistics from "./FollowersStatistics.jsx";

class HomePage extends Component {
  state = {
    isVisibleSidebar: false,
    socket: null,
    notificationData: {
      title: "",
      description: "",
    },
  };

  constructor(props) {
    super(props);

    this._componentCleanup = this._componentCleanup.bind(this);

    this.sideBarWrapperRef = createRef();
  }

  _resetNotificationData = () => {
    const { notificationData } = this.state;
    notificationData.title = "";
    notificationData.description = "";
    this.setState({ notificationData });
  };

  _listenNotificationAlert = () => {
    try {
      newSocket.on(`newNotification`, (notificationAlert) => {
        console.log("notificationAlert>>", notificationAlert);

        if (notificationAlert.error) {
          errorHandler(notificationAlert);
        } else {
          if (
            this.props.history?.location?.pathname?.includes("/notifications")
          ) {
            this._resetNotificationData();
            this.props.fetchNotifications();
            return;
          }

          if (notificationAlert?.byAdmin) {
            const { notificationData } = this.state;
            notificationData.title = `@${APP_NAME} - Admin`;
            notificationData.description = notificationAlert.title || "";
            this.setState({ notificationData });
          }
          this.props.incrementUnreadNotificationCount();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  _listenUnreadMessageCount = () => {
    try {
      if (this.props.history?.location?.pathname?.includes("/messages")) return;

      let { userData } = this.props;

      newSocket.on("messageNotifiation", (receiveMessage) => {
        console.log({ receiveMessage });

        if (receiveMessage.doSkip) return;

        if (receiveMessage.error) {
          errorHandler(receiveMessage);
        } else {
          if (receiveMessage._to === userData?.user?._id) {
            this.props.unreadMessageCountUpdate({
              threadId: receiveMessage?._thread,
            });
          }
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _subscribeToSocketRoom = (params) => {
    newSocket.emit("subscribe", params, function (res) {
      console.log("subscribed>>", { params, res });
      if (res.error) {
        errorHandler(res);
      }
    });
  };

  _handleSocketRoomSubscription = () => {
    let { userData } = this.props;

    const roomsToSubscribe = [
      { room: userData?.user?.id },
      { room: `notification/${userData?.user?.id}` },
    ];

    roomsToSubscribe.forEach((roomParams) =>
      this._subscribeToSocketRoom(roomParams)
    );

    this._listenUnreadMessageCount();
    this._listenNotificationAlert();
  };

  _connectToSocket = () => {
    connectToSocket()
      .then((socket) => {
        if (!this.state.socket?.connected) {
          this.setState({ socket }, () => {
            this._handleSocketRoomSubscription();
          });
        }
      })
      .catch((error) => {
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  _disconnectToSocket = () => {
    disconnectToSocket()
      .then((flag) => {
        console.log("disconnected>>", flag);
      })
      .catch((error) => {
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  _componentCleanup() {
    // this will hold the cleanup code
    // whatever you want to do when the component is unmounted or page refreshes
    this._disconnectToSocket();
  }

  componentDidMount() {
    // fetch and add name configurable - shoutout/vault/ppv/liveEvent
    this.props.fetchAndAddNameConfigurable();

    this.props.fetchNotifications();

    this.props.getUnreadMessageCountAction();

    this._connectToSocket();

    window.addEventListener("beforeunload", this._componentCleanup);
  }

  componentWillUnmount() {
    this._componentCleanup();
    // remove the event handler for normal unmounting
    window.removeEventListener("beforeunload", this._componentCleanup);
  }

  _toggleSideBar = () => {
    if (this.sideBarWrapperRef.current.classList.contains("hideSideMenu")) {
      this.sideBarWrapperRef.current.className = "showSideMenu";
      this.setState({ isVisibleSidebar: true });
    } else {
      this.sideBarWrapperRef.current.className = "hideSideMenu";
      this.setState({ isVisibleSidebar: false });
    }
  };

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <Header
            toggleSideBar={() => this._toggleSideBar()}
            isVisibleSidebar={this.state.isVisibleSidebar}
          />
        </AppHeader>

        <div className="app-body customSidebarWrap">
          <div
            ref={this.sideBarWrapperRef}
            className={`hideSideMenu`} // showSideMenu
          >
            <div
              className="sidebarOverlay"
              onClick={() => this._toggleSideBar()}
            />
            <SidebarMenu
              toggleSideBar={() => this._toggleSideBar()}
              isVisibleSidebar={this.state.isVisibleSidebar}
            />
          </div>

          <main className="main">
            <Container fluid style={{ background: "#fafafa" }}>
              <CustomPushNotification
                title={this.state?.notificationData?.title}
                description={this.state?.notificationData?.description}
                onDismiss={() => this._resetNotificationData()}
              />

              <Switch>
                <ProtectedFeatureRoute
                  exact
                  path="/live-events"
                  component={LiveEventPage}
                  redirectRoute="/feed"
                  featureName="event"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/live-events/:id"
                  component={LiveEventDetailsPage}
                  redirectRoute="/feed"
                  featureName="event"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/live-event-streaming/:id"
                  component={LiveEventStreamingPage}
                  redirectRoute="/feed"
                  featureName="event"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/live-event-form"
                  component={CreateLiveEvent}
                  redirectRoute="/feed"
                  featureName="event"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/manage-sticker"
                  component={ManageStickerPage}
                  redirectRoute="/feed"
                  featureName="event"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/ppv"
                  component={PayPerViewPage}
                  redirectRoute="/feed"
                  featureName="ppv"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/ppv-form"
                  component={CreatePayPerView}
                  redirectRoute="/feed"
                  featureName="ppv"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/ppv/:id"
                  component={PayPerViewDeatilsPage}
                  redirectRoute="/feed"
                  featureName="ppv"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/vault"
                  component={VaultsPage}
                  redirectRoute="/feed"
                  featureName="vault"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/vault/folder/:id"
                  component={VaultDetailsPage}
                  redirectRoute="/feed"
                  featureName="vault"
                />
                <ProtectedFeatureRoute
                  exact
                  path="/vault/add"
                  component={VaultAddPage}
                  redirectRoute="/feed"
                  featureName="vault"
                />
                {/* ============================= */}
                <ProtectedRoute
                  exact
                  path={`/promotion`}
                  component={PromotionLinks}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/faq"
                  component={FaqPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/messages"
                  component={MessagesPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/initate-message"
                  component={MessageInitiatorPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/feed"
                  component={FeedPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/broadcast-stats"
                  component={BroadcastMessageStats}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/my-profile"
                  component={MyProfilePage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/settings"
                  component={SettingsPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/notifications"
                  component={NotificationsPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/post/:id"
                  component={PostDetailsPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/statement"
                  component={StatementPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/training"
                  component={TrainingPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/influencer/:id"
                  component={InfluencerProfileDetails}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/non-subscriber-view"
                  component={NonSubscriberFeedViewerPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/subscriber-view"
                  component={SubscriberFeedViewerPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/CreatePost"
                  component={CreatePostPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/members"
                  component={TeamMembers}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/story"
                  component={StoryPage}
                  redirectRoute="/login"
                />
                <ProtectedRoute
                  exact
                  path="/followers-stats"
                  component={FollowersStatistics}
                  redirectRoute="/login"
                />

                {/* estorePages */}
                {/* created products */}
                {/* <ProtectedRoute
                  exact
                  path="/products"
                  component={ProductList}
                  redirectRoute="/login"
                /> */}
                {/* update new product */}
                {/* <ListOwnProduct
                  exact
                  path="/list-own-product"
                  component={ListOwnProduct}
                  redirectRoute="/login"
                /> */}
                {/* available products for customizable */}
                {/* <CustomizeAvailableProducts
                  exact
                  path="/available-products"
                  component={CustomizeAvailableProducts}
                  redirectRoute="/login"
                />
                <CustomizeProduct
                  exact
                  path="/customize-product"
                  component={CustomizeProduct}
                  redirectRoute="/login"
                /> */}
                {/* e-store orders list */}
                {/* <OrderList
                  exact
                  path="/orders"
                  component={OrderList}
                  redirectRoute="/login"
                /> */}
                {/* e-store orders detail */}
                {/* <OrderDetails
                  exact
                  path="/order-details"
                  component={OrderDetails}
                  redirectRoute="/login"
                /> */}

                <Route exact path="*" render={() => <Redirect to="/feed" />} />
              </Switch>
            </Container>
          </main>
        </div>

        <MediaQueueViewer />

        <AppFooter>
          <Footer
            toggleSideBar={() => this._toggleSideBar()}
            isVisibleSidebar={this.state.isVisibleSidebar}
          />
        </AppFooter>
      </div>

      // </Mobile/Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    unreadMessageCount: state?.chatData?.unreadMessageCount || 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: () => dispatch(fetchNotifications()),
    fetchAndAddNameConfigurable: (configurable) =>
      dispatch(fetchAndAddNameConfigurable(configurable)),
    unreadMessageCountUpdate: ({ threadId }) =>
      dispatch(unreadMessageCountUpdate({ threadId })),
    getUnreadMessageCountAction: () => dispatch(getUnreadMessageCountAction()),
    incrementUnreadNotificationCount: () =>
      dispatch(incrementUnreadNotificationCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
