import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../config";
import {
  formatDate,
  getPostedTimeValue,
  errorHandler,
  isPermissionToAccess,
  CheckPastEvent,
  showToast,
  formatCurrencyValue,
  isSubscriptionPriceExist,
} from "../helper-methods";
import { fetchEvent } from "../http-calls";
import ConfigureString from "../components/ConfigureString";
import { useHistory, useParams } from "react-router-dom";
import CustomLoader from "../components/custom/CustomLoader";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import CustomVideoPlayer from "../components/custom/CustomVideoPlayer";
import SkeletonLoading from "../components/SkeletonLoading";
import { useSelector } from "react-redux";
import LiveEventStatusModal from "../components/modals/LiveEventStatusModal";

const LiveEventDetailsPage = () => {
  const history = useHistory();

  const params = useParams();

  const userData = useSelector((state) => state?.userData);

  const [eventData, setEventData] = useState(null);
  const [isPlayVideoPreview, setIsPlayVideoPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const [liveEventStatusModal, setLiveEventStatusModal] = useState({
    isOpen: false,
    data: null,
  });

  const _startStopEventPreviewVideo = () => {
    if (eventData?.previewVideoUrl || eventData?.previewHls) {
      setIsPlayVideoPreview((prev) => !prev);
    }
  };

  const _toggleLiveEventStatusModal = (isOpen = false, data = null) => {
    setLiveEventStatusModal({
      isOpen,
      data,
    });
  };

  const _renderPrice = (event) => {
    if (!userData?.user?.multiTierSubscription && event?.price) {
      return (
        <div className="eventPrice">
          {event.price ? (
            <div>
              Price: <span>{formatCurrencyValue(event.price)}</span>
            </div>
          ) : null}
        </div>
      );
    }

    if (event?.price && !event?.plusPrice && !event?.premiumPrice) {
      return (
        <div className="eventPrice">
          {event.price ? (
            <div>
              Price: <span>{formatCurrencyValue(event.price)}</span>
            </div>
          ) : null}
        </div>
      );
    }

    if (event?.price || event?.plusPrice || event?.premiumPrice) {
      return (
        <div className="eventPrice">
          {isSubscriptionPriceExist("premium") && (
            <div>
              <i className="fa fa-star" style={{ color: "#FFC107" }} /> Premium:{" "}
              <span>
                {event.premiumPrice
                  ? formatCurrencyValue(event.premiumPrice)
                  : "FREE"}
              </span>
            </div>
          )}

          {isSubscriptionPriceExist("plus") && (
            <div>
              <i className="fa fa-star" style={{ color: "#2196F3" }} /> Plus:{" "}
              <span>
                {event.plusPrice
                  ? formatCurrencyValue(event.plusPrice)
                  : "FREE"}
              </span>
            </div>
          )}

          <div>
            <i className="fa fa-star" style={{ color: "#828282" }} /> Basic:{" "}
            <span>
              {event.price ? formatCurrencyValue(event.price) : "FREE"}
            </span>
          </div>
        </div>
      );
    }

    return "FREE";
  };

  const _fetchEvent = async () => {
    try {
      setLoading(true);

      const res = await fetchEvent(params?.id);

      setEventData(res.event);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isPermissionToAccess("liveEvent", "canViewLiveEvent")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    _fetchEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container className="noPadding">
          <Row className="justify-content-center noMargin mb-2">
            <Col sm={12} md={11} lg={9} className="noPadding">
              <div className="pgTitle justify-content-start customMarginMob">
                <Button
                  className="customBackBtn"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h2>
                  <ConfigureString keyString={"event details"} />
                </h2>{" "}
                {loading ? <CustomLoader className="ml-2" /> : null}
              </div>

              {eventData ? (
                <>
                  <div className="liveEventDetailsWrap">
                    <div className="mediaVideoWrap-Feed">
                      {!isPlayVideoPreview ? (
                        <>
                          <div className="liveStreamEventDate">
                            <div>{formatDate(eventData?.scheduledAt)}</div>
                            <div>
                              {getPostedTimeValue(eventData?.scheduledAt)}
                              {eventData?.hasOwnProperty("scheduledEnd") &&
                                " - " +
                                  getPostedTimeValue(eventData?.scheduledEnd)}
                            </div>
                          </div>
                          <div
                            className="eventAttendingCount"
                            onClick={() =>
                              _toggleLiveEventStatusModal(true, eventData)
                            }
                          >
                            {eventData?.rsvpYes?.length
                              ? eventData?.rsvpYes.length
                              : 0}{" "}
                            Attending
                          </div>
                        </>
                      ) : null}

                      {/* Cancelled Event  */}
                      {eventData?.hasOwnProperty("status") &&
                        eventData?.status === "cancelled" && (
                          <div className="cancelledEventWrap">
                            <div>
                              <ConfigureString keyString={"event Cancelled"} />
                            </div>
                          </div>
                        )}

                      {(eventData?.previewVideoUrl || eventData?.previewHls) &&
                      isPlayVideoPreview ? (
                        <>
                          {/* event preview video */}
                          {/* custom video player support hls */}
                          <CustomVideoPlayer
                            // className={`${getAspectRatioClass(
                            //   eventData?.previewVideoUrlAspectRatio
                            // )}`}
                            onEnded={() => _startStopEventPreviewVideo()}
                            src={eventData?.previewVideoUrl}
                            hlsLink={eventData?.previewHls}
                            duration={eventData?.previewVideoUrlDuration}
                            autoPlay
                            controls
                          />
                          <div className="videoPreviewTag videoPreviewLiveEvent">
                            Video Preview
                          </div>
                        </>
                      ) : (
                        // event cover image
                        <>
                          <div className="shadowOverlayImg" />
                          <img
                            alt="Event Cover Img"
                            className="mediaPhoto-Feed"
                            src={eventData?.coverImage || DEFAULT_COVER_PICTURE}
                            loading="lazy"
                          />
                        </>
                      )}

                      {(eventData?.previewVideoUrl || eventData?.previewHls) &&
                      !isPlayVideoPreview ? (
                        <Button
                          title="Trailer"
                          className="videoPlayBtn"
                          onClick={() => {
                            _startStopEventPreviewVideo();
                          }}
                        >
                          <i className="fa fa-play" />
                        </Button>
                      ) : null}
                    </div>

                    <div className="ppvDetailsWrap">
                      <h5 className="liveStreamEventName">{eventData?.name}</h5>

                      {eventData?.eventCoHosts?.length ? (
                        <div>
                          {eventData?._influencer?.username && (
                            <>
                              <div className="mb-2">
                                <b>Host:</b>{" "}
                                <span>@{eventData?._influencer?.username}</span>
                              </div>
                            </>
                          )}
                          <div className="mb-2">
                            <b>Guest(s):</b>{" "}
                            {eventData?.eventCoHosts?.map(
                              (each) =>
                                ` @${each.username}${
                                  userData?.user?.username === each.username
                                    ? "(You)"
                                    : ""
                                }`
                            )}
                          </div>
                        </div>
                      ) : null}

                      {/* For Event Price */}
                      <div className="eventPriceWrap">
                        {_renderPrice(eventData)}
                      </div>

                      {eventData?.description?.length ? (
                        <ReadMoreReadLess
                          className="readMore"
                          text={eventData.description}
                        />
                      ) : null}
                    </div>

                    {isPermissionToAccess(
                      "liveEvent",
                      "canStartLiveEvent",
                      true
                    )
                      ? eventData?.hasOwnProperty("status") &&
                        eventData?.status !== "cancelled" &&
                        !CheckPastEvent(eventData?.scheduledAt) && (
                          <div className="joinLiveEventWrap">
                            <Button
                              type="button"
                              className="themeBtn joinLiveEvent"
                              onClick={() =>
                                history.push(
                                  `/live-event-streaming/${eventData?.id}`
                                )
                              }
                            >
                              Join
                            </Button>
                          </div>
                        )
                      : null}
                  </div>
                </>
              ) : (
                <SkeletonLoading type="box" count={1} height={400} />
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <LiveEventStatusModal
        isOpen={liveEventStatusModal.isOpen}
        data={liveEventStatusModal.data}
        toggle={() => _toggleLiveEventStatusModal()}
      />
    </>
  );
};

export default LiveEventDetailsPage;
