import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getAllSubscribers } from "../../http-calls";

const clearAllSubscribers = createAction("clearAllSubscribers");

const getAndUpdateSubscribers = createAsyncThunk(
  "subscribers/getAndUpdateSubscribers",
  async (payload, thunkAPI) => {
    const res = await getAllSubscribers();
    return res?.fans;
  }
);

export { clearAllSubscribers, getAndUpdateSubscribers };
