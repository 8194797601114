import React, { useRef, useState, useEffect } from "react";
import { Button } from "reactstrap";

const VideoRangePlayer = ({ videoSrc, getVideoRange }) => {
  //   const [videoSrc, setVideoSrc] = useState('');
  const [range, setRange] = useState([0, 15]);
  const [duration, setDuration] = useState(0);
  const [frames, setFrames] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(document.createElement("canvas"));
  const stripRef = useRef(null);
  const playheadRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", () => {
        setDuration(videoRef.current.duration);
        setRange([0, Math.min(15, videoRef.current.duration)]);
        generateFrames();
      });
    }
  }, [videoSrc]);

  // Generate frames based on video duration
  const generateFrames = async () => {
    const totalFrames = Math.min(20, Math.floor(videoRef.current.duration));
    const interval = videoRef.current.duration / totalFrames;
    const newFrames = [];

    for (let i = 0; i < totalFrames; i++) {
      const time = i * interval;
      const frame = await captureFrame(time);
      newFrames.push(frame);
    }

    setFrames(newFrames);
  };

  // Capture frame from video
  const captureFrame = (time) => {
    return new Promise((resolve) => {
      videoRef.current.currentTime = time;
      videoRef.current.addEventListener(
        "seeked",
        () => {
          const canvas = canvasRef.current;
          canvas.width = 160;
          canvas.height = 90;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoRef?.current, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        },
        { once: true }
      );
    });
  };

  // Update playhead position dynamically
  const updatePlayhead = () => {
    if (videoRef?.current && playheadRef?.current) {
      const percent =
        ((videoRef?.current?.currentTime - range[0]) / (range[1] - range[0])) *
        100;

      playheadRef.current.style.left = `${percent}%`;

      // ✅ Scroll the frame strip smoothly during playback
      if (stripRef?.current) {
        const scrollPosition =
          (videoRef?.current?.currentTime / duration) *
          (stripRef?.current?.scrollWidth - stripRef.current.clientWidth);

        stripRef.current.scrollLeft = scrollPosition;
      }
    }
  };

  useEffect(() => {
    if (videoRef?.current) {
      videoRef?.current?.addEventListener("timeupdate", updatePlayhead);
    }

    return () => {
      videoRef?.current?.removeEventListener("timeupdate", updatePlayhead);
    };
  }, [range]);

  // Handle dragging of the range
  const handleDrag = (e) => {
    const startX = e.clientX;
    const startRange = [...range];

    const onPointerMove = (e) => {
      const delta =
        ((e.clientX - startX) / stripRef.current.clientWidth) * duration;

      let newRange = [
        Math.max(0, startRange[0] + delta),
        Math.min(duration, startRange[1] + delta),
      ];

      if (newRange[1] - newRange[0] > 15) {
        newRange = [newRange[0], newRange[0] + 15];
      }

      setRange(newRange);
      getVideoRange(newRange);
    };

    window.addEventListener("pointermove", onPointerMove);
    window.addEventListener("pointerup", () => {
      window.removeEventListener("pointermove", onPointerMove);
    });
  };

  // Handle resizing left/right handles
  const handleResize = (index, e) => {
    const startX = e.clientX;
    const startRange = [...range];

    const onPointerMove = (e) => {
      const delta =
        ((e.clientX - startX) / stripRef.current.clientWidth) * duration;

      let newRange = [...range];
      if (index === 0) {
        newRange[0] = Math.max(0, startRange[0] + delta);
        if (newRange[1] - newRange[0] > 15) {
          newRange[0] = newRange[1] - 15;
        }
      } else {
        newRange[1] = Math.min(duration, startRange[1] + delta);
        if (newRange[1] - newRange[0] > 15) {
          newRange[1] = newRange[0] + 15;
        }
      }

      setRange(newRange);
      getVideoRange(newRange);
    };

    window.addEventListener("pointermove", onPointerMove);
    window.addEventListener("pointerup", () => {
      window.removeEventListener("pointermove", onPointerMove);
    });
  };

  // Play selected range with smooth progress
  const playSelectedRange = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime = range[0];
      videoRef.current.play();
      setIsPlaying(true);
      intervalRef.current = setInterval(() => {
        updatePlayhead();

        if (videoRef?.current?.currentTime >= range[1]) {
          videoRef?.current?.pause();
          setIsPlaying(false);
          clearInterval(intervalRef.current);
        }
      }, 50);
    }
  };

  const handlePause = () => {
    if (videoRef?.current) {
      videoRef?.current?.pause();
      setIsPlaying(false);
      clearInterval(intervalRef.current);
    }
  };

  // Convert seconds to mm:ss
  const secondsToMinSec = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${min}:${sec < 10 ? "0" : ""}${sec}`;
  };

  console.log("range", range);
  return (
    <>
      {videoSrc && (
        <>
          <video ref={videoRef} src={videoSrc} controls />

          <div className="videoClipControlsWrap">
            {/* Frame Strip */}
            <div ref={stripRef} className="frameStrip">
              {frames.map((frame, index) => (
                <img key={index} src={frame} className="frame" alt="frame" />
              ))}

              <div
                className="range-selector"
                style={{
                  left: `${(range[0] / duration) * 100}%`,
                  width: `${((range[1] - range[0]) / duration) * 100}%`,
                }}
                onPointerDown={handleDrag}
              >
                <div ref={playheadRef} className="playhead" />
              </div>
            </div>

            <div className="videoClipControls">
              <p>
                Selected Range: {secondsToMinSec(range[0])} -{" "}
                {secondsToMinSec(range[1])}
              </p>

              <div>
                <Button onClick={playSelectedRange} disabled={isPlaying}>
                  Play
                </Button>
                <Button onClick={handlePause} disabled={!isPlaying}>
                  Pause
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VideoRangePlayer;
