import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import ChatFanList from "../ChatFanList";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  errorHandler,
  getLowResolutionLink,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import { DEFAULT_GROUP_PICTURE } from "../../config";
import { updateGroup } from "../../http-calls";
import { getAndUpdateSelectedGroupThread } from "../../redux/actions";

const EditGroupModal = ({
  groupId,
  groupName: name,
  isOpen,
  members,
  profilePic,
  toggle,
}) => {
  const [loading, setLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState({
    previewBlob: "",
    uploadData: null,
  });
  const [groupName, setGroupName] = useState(name || "");
  const [groupMembers, setGroupMembers] = useState(() =>
    members?.map((member) => member._id)
  );
  const [errors, setErrors] = useState("");

  const { tags } = useSelector((state) => state?.tags || {});
  const dispatch = useDispatch();

  // Memoized variable to check if the group has been updated
  // Checking if either the name, profile picture or group members have been updated
  const isGroupUpdated = useMemo(() => {
    const isNameUpdated = groupName?.trim() !== name?.trim();
    // If the new profile picture is not null, then it has been updated
    const isProfilePicUpdated = profilePicture?.uploadData;
    const isMembersUpdated =
      groupMembers?.length !== members?.length ||
      // Checking if the ids of the new members are not equal to the ids of the old members
      !groupMembers?.every((id) =>
        members?.some((member) => member?._id === id)
      );
    return isNameUpdated || isProfilePicUpdated || isMembersUpdated;
  }, [groupMembers, groupName, members, name, profilePicture?.uploadData]);

  const _closeThisModal = () => {
    toggle();
  };

  const _validateGroupName = () => {
    if (groupName?.trim()?.length) {
      if (groupName?.trim() === name) {
        setErrors("");
        return true;
      }
      if (
        tags
          ?.map((each) => each?.name?.toLowerCase())
          ?.includes(groupName?.toLowerCase()?.trim())
      ) {
        setErrors("Group name already exists");
        return false;
      } else {
        setErrors("");
        return true;
      }
    } else {
      setErrors("Required");
      return false;
    }
  };

  const _handleChangeProfilePic = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;

      const fileType = file.type.split("/")?.[0];

      if (fileType !== "image")
        return errorHandler({ reason: "Please provide a valid image file." });

      setProfilePicture({
        previewBlob: URL.createObjectURL(file),
        uploadData: file,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _handleUpdateGroupMembers = useCallback((members) => {
    setGroupMembers(members?.map((member) => member?._fan?._id));
  }, []);

  const _handleSubmit = async () => {
    const isValidGroupName = _validateGroupName();
    if (!isValidGroupName) return;

    try {
      setLoading(true);
      let payload = {};
      if (profilePicture?.uploadData) {
        const res = await uploadFileOnServer([{ ...profilePicture }]);
        payload["profilePicture"] = res?.[0]?.url || "";
      }
      payload["groupName"] = groupName?.trim();
      payload["assignedTo"] = groupMembers;

      const response = await updateGroup({ id: groupId, payload });
      if (!response?.error) {
        dispatch(getAndUpdateSelectedGroupThread(groupId));
        showToast("Group updated successfully", "success");
        _closeThisModal();
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="modal-dialog-centered modal-xl modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeThisModal()}>Edit Group</ModalHeader>

      <ModalBody>
        <div className="mb-3 d-flex justify-content-center align-items-center">
          <div className="img100by100Wrap">
            <img
              src={
                profilePicture?.previewBlob ||
                getLowResolutionLink(profilePic) ||
                DEFAULT_GROUP_PICTURE
              }
              alt="Profile Img"
              className="img100by100"
              loading="lazy"
            />

            <Label className="btn editProfileImg" id="editProfile">
              <input
                type="file"
                accept="image/*"
                onChange={_handleChangeProfilePic}
                style={{ display: "none" }}
                value=""
              />
              <i className="fa fa-pencil" />
              <UncontrolledTooltip
                placement="bottom"
                target="editProfile"
                className="createPostTooltip"
              >
                Edit Profile Picture
              </UncontrolledTooltip>
            </Label>
          </div>
        </div>

        <Label htmlFor="groupName">Group Name</Label>
        <FormGroup className="customCreateSelect">
          <Input
            id="groupName"
            type="text"
            placeholder="Update Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            onBlur={() => _validateGroupName()}
          />
          {errors ? <div className="form-error">{errors}</div> : null}
        </FormGroup>

        <ChatFanList
          isEditGroup={true}
          groupMembers={members}
          onUpdateMembers={_handleUpdateGroupMembers}
        />
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <div>
          <Button className="modalBtnCancel" onClick={() => _closeThisModal()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            disabled={loading || !isGroupUpdated}
            onClick={_handleSubmit}
          >
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditGroupModal;
