import { createSlice } from "@reduxjs/toolkit";
import { deleteTagFromRedux, clearAllTags, getAndUpdateTags } from "../actions";

const initialState = {
  tags: [],
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearAllTags, (state, action) => {
        state.tags = [];
      })

      .addCase(deleteTagFromRedux, (state, action) => {
        const tagIndex = state?.tags?.findIndex(
          (each) => each._id === action.payload?._id
        );

        if (tagIndex >= 0) {
          state.tags.splice(tagIndex, 1);
        }
      })

      .addCase(getAndUpdateTags.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateTags.fulfilled, (state, action) => {
        state.tags = action.payload;

        state.loading = false;
      });
  },
});

export const tagsReducer = tagsSlice.reducer;
