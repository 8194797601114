import React, { useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Progress,
} from "reactstrap";
import { useSelector } from "react-redux";
import cuid from "cuid";
import { useHistory } from "react-router-dom";
import {
  errorHandler,
  extractTagUsername,
  formatCurrencyValue,
  formatDecimalNumber,
  getPostedDateValue,
  getPostedTimeValue,
  isPermissionToAccess,
  mentionUserInputParse,
  showToast,
  totalCommentsCount,
  getCommentIconClassName,
  getLikeIconClassName,
  getLowResolutionLink,
  shareableLink,
  getFullName,
  reduceToSevenTwoZeroRes,
} from "../../helper-methods";
import {
  getComments,
  postCommentOnFeed,
  likeComment,
  getTransactions,
  sendNotificationToMentionedInfluencer,
  editPost,
} from "../../http-calls";
import { DEFAULT_PROFILE_PICTURE } from "../../config/index";
import CustomLoader from "../custom/CustomLoader";
import FeedViewerCarousel from "./FeedViewerCarousel";
import PostCommentSection from "../PostCommentSection";
import CustomPrompt from "../modals/CustomPrompt";
import ShowMediaModal from "../modals/ShowMediaModal";
import PostEarningsModal from "../modals/PostEarningsModal";
import CloseGoalModal from "../modals/CloseGoalModal";
import FolderViewer from "./FolderViewer";
import EventViewer from "./EventViewer";
import PayPerViewer from "./PayPerViewer";
import TextViewer from "./TextViewer";
import PollViewer from "./PollViewer";
import VideoViewer from "./VideoViewer";
import ErrorBoundary from "../ErrorBoundary";
import AudioViewer from "./AudioViewer";
import { useEffect } from "react";
import { newSocket } from "../../socket-io";
import { updateAPost } from "../../redux/actions";
import { useDispatch } from "react-redux";
// import SkeletonLoading from "../SkeletonLoading";
import FeedPostProccessing from "../FeedPostProccessing";

const FeedViewer = ({
  feed,
  feedIndex,
  isEditFeed = true,
  pinToProfile,
  deletePost,
  onComment,
  editFeed,
  lastElementRef,
  loadingFeeds,
  updateCommentsCount = () => {},
}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  const [isCommentScetionOpen, setIsCommentScetionOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [isCommentsLikeLoading, setIsCommentsLikeLoading] = useState(null);
  const [isPostEarningsModalOpen, setIsPostEarningsModalOpen] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [tipTransactions, setTipTransactions] = useState([]);
  const [ppvTransactions, setPpvTransactions] = useState([]);
  const [showMediaModal, setShowMediaModal] = useState({
    isOpen: false,
    externalLink: null,
    contentType: null,
  });
  const [closeGoalModal, setCloseGoalModal] = useState({
    isOpen: false,
  });

  const canEditOrDeletePost = useMemo(() => {
    return isPermissionToAccess("feed", "canEditOrDeletePost", true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // const _toggleDropdown = () => {
  //   setDropdownOpen((prev) => !prev);
  // };

  const _getAllComments = async () => {
    try {
      setCommentsLoading(true);

      const res = await getComments(feed._id);

      const newComments = res.comments;

      updateCommentsCount({
        index: feedIndex,
        value: totalCommentsCount(newComments),
      });

      setComments(newComments);
      setCommentsLoading(false);
    } catch (error) {
      updateCommentsCount({
        index: feedIndex,
        value: totalCommentsCount(comments),
      });
      setComments(comments);
      setCommentsLoading(false);
      errorHandler(error);
    }
  };

  const _toggleCommentSection = async () => {
    if (!isCommentScetionOpen) {
      _getAllComments();
    }
    setIsCommentScetionOpen((prev) => !prev);
  };

  const _pinToProfile = (isPinned) => {
    pinToProfile(!isPinned);
  };

  const _postComment = async (comment) => {
    try {
      const newComments = [...comments];

      const tempId = cuid();

      newComments.unshift({
        category: "comment",
        content: comment,
        createdAt: new Date().toISOString(),
        tempId: tempId,
        isSubCommnet: false,
        isLocalComment: true,
        _postId: feed?._id,
        _user: {
          name: userData?.user?.name,
          profilePicture: userData?.user?.profilePicture,
          username: userData?.user?.username,
          userType: userData?.user?.userType,
          _id: userData?.user?._id,
          id: userData?.user?.id,
        },
      });
      setComments(newComments);
      onComment(feedIndex, true);

      const res = await postCommentOnFeed({
        category: "comment",
        content: comment,
        id: feed?._id,
      });

      if (res?.interaction?.content && res?.interaction?._id) {
        const tagUsernameArray = extractTagUsername(res.interaction.content);

        if (tagUsernameArray?.length) {
          const oldTagUsernameArray = feed?._mentionedInfluencer?.length
            ? feed._mentionedInfluencer
            : [];

          const newTagUsernameArray = tagUsernameArray.filter(
            (newEach) => !oldTagUsernameArray.includes(newEach)
          );

          if (newTagUsernameArray?.length) {
            editPost(
              {
                _mentionedInfluencer: [
                  ...oldTagUsernameArray,
                  ...newTagUsernameArray,
                ],
              },
              feed._id
            );
          }

          const payload = {
            username: tagUsernameArray,
            interactionId: res.interaction._id,
          };

          sendNotificationToMentionedInfluencer(payload);
        }
      }

      showToast("Comment has been added", "success");
    } catch (error) {
      errorHandler(error);
    } finally {
      _getAllComments();
    }
  };

  const _onPromptSuccess = () => {
    deletePost();
    setIsPrompt(false);
  };

  const _onPromptDismiss = () => {
    setIsPrompt(false);
  };

  const _deletePost = () => {
    setIsPrompt(true);
  };

  const _closePostEarningsModal = () => {
    setIsPostEarningsModalOpen(false);
  };

  const _getTransactions = async (feed, paymentType = "post") => {
    try {
      const res = await getTransactions(feed.id, { paymentType });

      const newTipTransactions =
        res && res.tips && res.tips.length ? res.tips : [];
      const newPpvTransactions =
        res && res.earnings && res.earnings.length ? res.earnings : [];

      setIsPostEarningsModalOpen(true);
      setTipTransactions(newTipTransactions);
      setPpvTransactions(newPpvTransactions);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _toggleShowMediaModal = (
    isOpen = false,
    externalLink = null,
    contentType = null
  ) => {
    setShowMediaModal({
      isOpen,
      externalLink,
      contentType,
    });
  };

  const _toggleCloseGoalModal = (isOpen = false) => {
    setCloseGoalModal({ isOpen });
  };

  const _toggleLikeComment = async (commentID, isLiked) => {
    const newComments = [...comments];
    const findComment = newComments?.find((each) => each?._id === commentID);
    setIsCommentsLikeLoading(commentID);
    try {
      if (findComment) {
        if (isLiked) {
          findComment._isLiked = 0;
        } else {
          findComment._isLiked = 1;
        }
        setComments(newComments);
      }

      const payload = {};
      if (isLiked) {
        payload.unlike = true;
      } else {
        payload.like = true;
      }
      await likeComment(commentID, payload);

      _getAllComments();
    } catch (error) {
      if (findComment) {
        if (isLiked) {
          findComment._isLiked = 1;
        } else {
          findComment._isLiked = 0;
        }
        setComments(newComments);
      }
      errorHandler(error);
    } finally {
      setIsCommentsLikeLoading(false);
    }
  };

  const _renderPreview = () => {
    if (feed?.isReady === false) {
      // return <SkeletonLoading type={"post"} count={1} />;
      return (
        <FeedPostProccessing
          feed={feed}
          isPermissionToAccess={isPermissionToAccess}
          toggleCommentSection={_toggleCommentSection}
          isEditFeed={isEditFeed}
          editFeed={editFeed}
          pinToProfile={_pinToProfile}
          deletePost={_deletePost}
          getTransactions={_getTransactions}
        />
      );
    }

    let feedEarning = feed?.earning ? feed.earning : 0;

    let goalDiv = null;

    if (
      feed &&
      feed.category === "goal" &&
      (feed.goalAmount || feed.goalAmount === 0) &&
      (feed.goalAmountReached || feed.goalAmountReached === 0)
    ) {
      const percentage = (feed.goalAmountReached / feed.goalAmount) * 100;

      // the below data is for "Set a Goal". It will only show when the creator sets a goal.
      goalDiv = (
        <div className="goalDataWrap">
          <p>
            You have achieved {`${formatDecimalNumber(percentage)}%`} of your
            GOAL
          </p>

          <Progress value={feed.goalAmountReached} max={feed.goalAmount} />
          <div className="goalStatus">
            <span>
              {formatCurrencyValue(feed.goalAmountReached)} out of{" "}
              {formatCurrencyValue(feed.goalAmount)}
            </span>

            {/* once the user clicks the below btn, the goal gets closed & the post gets "unpinned" */}
            {/* also, hide the btn when the creator closes any particular "set goal" post */}
            {feed.isGoalPreClosed ? (
              <Button style={{ cursor: "auto" }}>Goal closed</Button>
            ) : (
              <Button onClick={() => _toggleCloseGoalModal(true)}>Close</Button>
            )}
          </div>
        </div>
      );
    }

    if (feed?.hasOwnProperty("_folder")) {
      return (
        <FolderViewer
          feed={feed}
          feedEarning={feedEarning}
          toggleCommentSection={_toggleCommentSection}
          getTransactions={_getTransactions}
          pinToProfile={_pinToProfile}
          deletePost={_deletePost}
        />
      );
    } else if (feed?.hasOwnProperty("_event")) {
      return (
        <EventViewer
          feed={feed}
          feedEarning={feedEarning}
          toggleCommentSection={_toggleCommentSection}
          getTransactions={_getTransactions}
          pinToProfile={_pinToProfile}
          deletePost={_deletePost}
        />
      );
    } else if (feed?.hasOwnProperty("_payperview")) {
      return (
        <PayPerViewer
          feed={feed}
          feedEarning={feedEarning}
          toggleCommentSection={_toggleCommentSection}
          getTransactions={_getTransactions}
          pinToProfile={_pinToProfile}
          deletePost={_deletePost}
        />
      );
    } else if (feed?.category === "general" || feed?.category === "goal") {
      if (feed?._contents?.length > 1) {
        return (
          <Card className="cardFeed">
            <CardHeader onClick={() => history.push(`/post/${feed?.id}`)}>
              {userData?.user ? (
                <div className="d-flex align-items-center">
                  <img
                    src={
                      getLowResolutionLink(userData?.user?.profilePicture) ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                    alt="Profile Img"
                    className="userImg mr-1"
                    loading="lazy"
                  />
                  <div>
                    <h4>{getFullName(userData?.user?.name)}</h4>
                    <Button color="link" className="profileID">
                      @{userData?.user?.username || "N/A"}
                    </Button>
                  </div>
                </div>
              ) : null}

              {feed?.isPinned ? (
                <svg
                  className="pinnedPost"
                  xmlns="http://www.w3.org/2000/svg"
                  width="253.712"
                  height="256"
                  viewBox="0 0 253.712 256"
                >
                  <path
                    id="office-push-pin_73169"
                    d="M252.92,73.317,181.538,1.935a6.6,6.6,0,0,0-9.338,0l-.3.3A23.157,23.157,0,0,0,168,29.83L93.315,93.93a31.166,31.166,0,0,0-42.782,1.226l-.448.448a6.6,6.6,0,0,0,0,9.338l42.764,42.764L51.2,189.353c-.833.857-20.547,21.172-33.508,37.338C5.349,242.082,2.908,244.9,2.782,245.047a6.6,6.6,0,0,0,9.319,9.319c.11-.1,2.865-2.49,18.356-14.91,16.164-12.961,36.478-32.676,37.4-33.575L109.44,164.3l40.474,40.474a6.6,6.6,0,0,0,9.338,0l.448-.448a31.166,31.166,0,0,0,1.226-42.782l64.1-74.689a23.157,23.157,0,0,0,27.592-3.894l.3-.3A6.6,6.6,0,0,0,252.92,73.317Z"
                    transform="translate(-1.143 0)"
                  />
                </svg>
              ) : null}
            </CardHeader>
            <CardBody>
              {feed?.text?.length ? (
                <div className="customPaddingMob">
                  {mentionUserInputParse(feed?.text)}
                </div>
              ) : null}

              <ErrorBoundary>
                <div className="feedCarousel">
                  {/* multi post carousel */}
                  <FeedViewerCarousel contents={feed?._contents} feed={feed} />
                </div>
              </ErrorBoundary>
            </CardBody>
            {/* goal div add here */}
            {goalDiv ? goalDiv : null}
            <CardFooter className="footerFeed">
              <div className="d-flex">
                <Button className="mr-2 mr-sm-3">
                  <i className={getLikeIconClassName(feed)} />
                </Button>

                <Button
                  className="mr-2 mr-sm-3"
                  onClick={() => _toggleCommentSection()}
                  aria-controls="exampleAccordion1"
                >
                  <i className={getCommentIconClassName(feed)} />
                </Button>

                <Button
                  className="mr-2 mr-sm-3"
                  onClick={() => _getTransactions(feed)}
                >
                  <img
                    src="/assets/img/dollar.png"
                    alt="Dollar"
                    loading="lazy"
                  />
                </Button>

                {canEditOrDeletePost ? (
                  <div className="ml-auto">
                    <Button
                      className="ml-2 ml-sm-3"
                      onClick={() => _pinToProfile(feed?.isPinned || false)}
                      title="Pin"
                    >
                      {/* <img src="../../assets/img/pin-post.png" alt="Pin Post" /> */}
                      <svg
                        id="Capa_1"
                        enable-background="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <g>
                            <path d="m506.143 175.563c-22.316-22.315-150.052-150.05-169.707-169.705-7.811-7.811-20.475-7.811-28.285 0s-7.81 20.474 0 28.284l14.143 14.143-106.288 106.288c-26.524-5.251-54.055-4.492-80.3 2.287-30.98 8.002-59.374 24.25-82.112 46.989-7.811 7.81-7.81 20.473 0 28.284l98.995 98.995-146.732 146.73c-7.81 7.81-7.81 20.474 0 28.284s20.474 7.811 28.285 0l146.73-146.73 98.994 98.994c3.906 3.906 9.023 5.858 14.143 5.858 5.118 0 10.237-1.953 14.143-5.858 22.737-22.738 38.986-51.132 46.988-82.112 6.779-26.245 7.538-53.775 2.287-80.3l106.289-106.289 14.142 14.142c7.808 7.81 20.473 7.811 28.284 0 7.81-7.81 7.81-20.473.001-28.284zm-184.842 99.989c-5.048 5.048-7.022 12.405-5.181 19.302 11.131 41.68 2.252 86.072-23.134 120.102l-97.967-97.967c-.001-.001-.002-.003-.003-.004s-.002-.002-.004-.003l-97.968-97.968c34.031-25.386 78.424-34.264 120.102-23.134 6.897 1.842 14.254-.132 19.303-5.181l114.13-114.131 84.853 84.852z" />
                          </g>
                        </g>
                      </svg>
                    </Button>

                    {isEditFeed ? (
                      <Button
                        className="ml-2 ml-sm-3"
                        onClick={() => editFeed(feed)}
                        title="Edit"
                      >
                        <img
                          src="../../assets/img/edit-post.png"
                          alt="Edit"
                          style={{ height: 18 }}
                        />
                      </Button>
                    ) : null}

                    <Button
                      className="ml-2 ml-sm-3"
                      onClick={() =>
                        shareableLink({ type: "post", id: feed?._id })
                      }
                      title="Share"
                    >
                      <img
                        src="../../assets/img/quick-link.png"
                        alt="Share"
                        style={{ height: 19, verticalAlign: -2 }}
                      />
                    </Button>
                    <Button
                      className="ml-2 ml-sm-3"
                      onClick={() => _deletePost()}
                      title="Delete"
                    >
                      <i className="fa fa-trash-o" />
                    </Button>
                  </div>
                ) : // <Dropdown
                //   className="customDropdown customDropdown-Feed ml-auto"
                //   isOpen={dropdownOpen}
                //   toggle={() => _toggleDropdown()}
                // >
                //   <DropdownToggle>
                //     <img
                //       src="/assets/img/dots-black.png"
                //       alt="Dots"
                //       style={{
                //         verticalAlign: 2,
                //         height: 4,
                //       }}
                //       loading="lazy"
                //     />
                //   </DropdownToggle>
                //   <DropdownMenu right>
                //     <DropdownItem
                //       onClick={() => _pinToProfile(feed?.isPinned || false)}
                //     >
                //       {feed?.isPinned ? "Unpin this post" : "Pin this post"}
                //     </DropdownItem>
                //     {isEditFeed ? (
                //       <DropdownItem onClick={() => editFeed(feed)}>
                //         Edit Post
                //       </DropdownItem>
                //     ) : null}
                //     <DropdownItem
                //       onClick={() =>
                //         shareableLink({ type: "post", id: feed?._id })
                //       }
                //     >
                //       Quick Link
                //     </DropdownItem>
                //     <DropdownItem
                //       onClick={() => _deletePost()}
                //       className="textWarning"
                //     >
                //       Delete Post
                //     </DropdownItem>
                //   </DropdownMenu>
                // </Dropdown>
                null}
              </div>

              {feed?.likes || feed?.comments || feedEarning ? (
                <div className="mt-1">
                  {feed?.likes ? (
                    <span>
                      {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
                    </span>
                  ) : null}
                  {feed?.comments ? (
                    <span>
                      {feed?.comments}{" "}
                      {feed?.comments > 1 ? "Comments" : "Comment"}
                    </span>
                  ) : null}
                  {feedEarning ? (
                    <span>{formatCurrencyValue(feedEarning)} Earned</span>
                  ) : null}
                </div>
              ) : null}

              {/* post time */}
              <div className="postTime-Feed">
                {(feed?.postType === "scheduled" ||
                  feed?.postType === "scheduled-temporary") &&
                new Date() < new Date(feed?.startDate) ? (
                  <div className="d-flex">
                    <div className="mr-1">Scheduled:</div>
                    {getPostedDateValue(feed?.startDate)},{" "}
                    {getPostedTimeValue(feed?.startDate)}
                  </div>
                ) : (
                  getPostedDateValue(feed?.startDate)
                )}
              </div>
            </CardFooter>
          </Card>
        );
      } else if (feed?._contents?.length === 1) {
        switch (feed?._contents?.[0]?._content?.contentType) {
          case "image": {
            // contains only one image
            return (
              <Card className="cardFeed">
                <CardHeader onClick={() => history.push(`/post/${feed?.id}`)}>
                  {userData?.user ? (
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getLowResolutionLink(
                            userData?.user?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="userImg mr-1"
                        loading="lazy"
                      />
                      <div>
                        <h4>{getFullName(userData?.user?.name)}</h4>
                        <Button color="link" className="profileID">
                          @{userData?.user?.username || "N/A"}
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  {feed?.isPinned ? (
                    <svg
                      className="pinnedPost"
                      xmlns="http://www.w3.org/2000/svg"
                      width="253.712"
                      height="256"
                      viewBox="0 0 253.712 256"
                    >
                      <path
                        id="office-push-pin_73169"
                        d="M252.92,73.317,181.538,1.935a6.6,6.6,0,0,0-9.338,0l-.3.3A23.157,23.157,0,0,0,168,29.83L93.315,93.93a31.166,31.166,0,0,0-42.782,1.226l-.448.448a6.6,6.6,0,0,0,0,9.338l42.764,42.764L51.2,189.353c-.833.857-20.547,21.172-33.508,37.338C5.349,242.082,2.908,244.9,2.782,245.047a6.6,6.6,0,0,0,9.319,9.319c.11-.1,2.865-2.49,18.356-14.91,16.164-12.961,36.478-32.676,37.4-33.575L109.44,164.3l40.474,40.474a6.6,6.6,0,0,0,9.338,0l.448-.448a31.166,31.166,0,0,0,1.226-42.782l64.1-74.689a23.157,23.157,0,0,0,27.592-3.894l.3-.3A6.6,6.6,0,0,0,252.92,73.317Z"
                        transform="translate(-1.143 0)"
                      />
                    </svg>
                  ) : null}
                </CardHeader>
                <CardBody>
                  {feed?.text?.length ? (
                    <div className="customPaddingMob">
                      {mentionUserInputParse(feed?.text)}
                    </div>
                  ) : null}

                  <div className="mediaPhotoWrap-Feed">
                    {isLoadingContent ? (
                      <div className="processingContent">
                        <p>We are processing your content</p>
                        <Button onClick={() => setIsLoadingContent(false)}>
                          Reload
                        </Button>
                      </div>
                    ) : (
                      <img
                        className="mediaPhoto-Feed"
                        src={reduceToSevenTwoZeroRes(
                          `${feed?._contents?.[0]?._content?.url}`
                        )}
                        onError={(e) => {
                          setIsLoadingContent(true);
                        }}
                        alt="Feed Img"
                        onClick={() => {
                          _toggleShowMediaModal(
                            true,
                            reduceToSevenTwoZeroRes(
                              feed?._contents?.[0]?._content?.url
                            ),
                            "image"
                          );
                        }}
                        loading="lazy"
                      />
                    )}
                  </div>
                </CardBody>

                {/* goal div add here */}
                {goalDiv ? goalDiv : null}

                <CardFooter className="footerFeed">
                  <div className="d-flex">
                    <Button className="mr-2 mr-sm-3">
                      <i className={getLikeIconClassName(feed)} />
                    </Button>

                    <Button
                      className="mr-2 mr-sm-3"
                      onClick={() => _toggleCommentSection()}
                    >
                      <i className={getCommentIconClassName(feed)} />
                    </Button>

                    <Button
                      className="mr-2 mr-sm-3"
                      onClick={() => _getTransactions(feed)}
                    >
                      <img
                        src="/assets/img/dollar.png"
                        alt="Dollar"
                        loading="lazy"
                      />
                    </Button>

                    {isPermissionToAccess(
                      "feed",
                      "canEditOrDeletePost",
                      true
                    ) ? (
                      <div className="ml-auto">
                        <Button
                          className="ml-2 ml-sm-3"
                          onClick={() => _pinToProfile(feed?.isPinned || false)}
                          title="Pin"
                        >
                          {/* <img
                            src="../../assets/img/pin-post.png"
                            alt="Pin Post"
                          /> */}
                          <svg
                            id="Capa_1"
                            enable-background="new 0 0 512 512"
                            height="512"
                            viewBox="0 0 512 512"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <g>
                                <path d="m506.143 175.563c-22.316-22.315-150.052-150.05-169.707-169.705-7.811-7.811-20.475-7.811-28.285 0s-7.81 20.474 0 28.284l14.143 14.143-106.288 106.288c-26.524-5.251-54.055-4.492-80.3 2.287-30.98 8.002-59.374 24.25-82.112 46.989-7.811 7.81-7.81 20.473 0 28.284l98.995 98.995-146.732 146.73c-7.81 7.81-7.81 20.474 0 28.284s20.474 7.811 28.285 0l146.73-146.73 98.994 98.994c3.906 3.906 9.023 5.858 14.143 5.858 5.118 0 10.237-1.953 14.143-5.858 22.737-22.738 38.986-51.132 46.988-82.112 6.779-26.245 7.538-53.775 2.287-80.3l106.289-106.289 14.142 14.142c7.808 7.81 20.473 7.811 28.284 0 7.81-7.81 7.81-20.473.001-28.284zm-184.842 99.989c-5.048 5.048-7.022 12.405-5.181 19.302 11.131 41.68 2.252 86.072-23.134 120.102l-97.967-97.967c-.001-.001-.002-.003-.003-.004s-.002-.002-.004-.003l-97.968-97.968c34.031-25.386 78.424-34.264 120.102-23.134 6.897 1.842 14.254-.132 19.303-5.181l114.13-114.131 84.853 84.852z" />
                              </g>
                            </g>
                          </svg>
                        </Button>

                        {isEditFeed ? (
                          <Button
                            className="ml-2 ml-sm-3"
                            onClick={() => editFeed(feed)}
                            title="Edit"
                          >
                            <img
                              src="../../assets/img/edit-post.png"
                              alt="Edit"
                              style={{ height: 18 }}
                            />
                          </Button>
                        ) : null}

                        <Button
                          className="ml-2 ml-sm-3"
                          onClick={() =>
                            shareableLink({ type: "post", id: feed?._id })
                          }
                          title="Share"
                        >
                          <img
                            src="../../assets/img/quick-link.png"
                            style={{ height: 19, verticalAlign: -2 }}
                            alt="Share"
                          />
                        </Button>
                        <Button
                          className="ml-2 ml-sm-3"
                          onClick={() => _deletePost()}
                          title="Delete"
                        >
                          <i className="fa fa-trash-o" />
                        </Button>
                      </div>
                    ) : // <Dropdown
                    //   className="customDropdown customDropdown-Feed ml-auto"
                    //   isOpen={dropdownOpen}
                    //   toggle={() => _toggleDropdown()}
                    // >
                    //   <DropdownToggle>
                    //     <img
                    //       src="/assets/img/dots-black.png"
                    //       alt="Dots"
                    //       style={{
                    //         verticalAlign: 2,
                    //         height: 4,
                    //       }}
                    //       loading="lazy"
                    //     />
                    //   </DropdownToggle>
                    //   <DropdownMenu right>
                    //     <DropdownItem
                    //       onClick={() =>
                    //         _pinToProfile(feed?.isPinned || false)
                    //       }
                    //     >
                    //       {feed?.isPinned
                    //         ? "Unpin this post"
                    //         : "Pin this post"}
                    //     </DropdownItem>
                    //     {isEditFeed ? (
                    //       <DropdownItem onClick={() => editFeed(feed)}>
                    //         Edit Post
                    //       </DropdownItem>
                    //     ) : null}
                    //     <DropdownItem
                    //       onClick={() =>
                    //         shareableLink({ type: "post", id: feed?._id })
                    //       }
                    //     >
                    //       Quick Link
                    //     </DropdownItem>
                    //     <DropdownItem
                    //       onClick={() => _deletePost()}
                    //       className="textWarning"
                    //     >
                    //       Delete Post
                    //     </DropdownItem>
                    //   </DropdownMenu>
                    // </Dropdown>
                    null}
                  </div>

                  {feed?.likes || feed?.comments || feedEarning ? (
                    <div className="mt-1">
                      {feed?.likes ? (
                        <span>
                          {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
                        </span>
                      ) : null}
                      {feed?.comments ? (
                        <span>
                          {feed?.comments}{" "}
                          {feed?.comments > 1 ? "Comments" : "Comment"}
                        </span>
                      ) : null}
                      {feedEarning ? (
                        <span>{formatCurrencyValue(feedEarning)} Earned</span>
                      ) : null}
                    </div>
                  ) : null}

                  {/* post time */}
                  <div className="postTime-Feed">
                    {(feed?.postType === "scheduled" ||
                      feed?.postType === "scheduled-temporary") &&
                    new Date() < new Date(feed?.startDate) ? (
                      <div className="d-flex">
                        <div className="mr-1">Scheduled:</div>
                        {getPostedDateValue(feed?.startDate)},{" "}
                        {getPostedTimeValue(feed?.startDate)}
                      </div>
                    ) : (
                      getPostedDateValue(feed?.startDate)
                    )}
                  </div>
                </CardFooter>
              </Card>
            );
          }
          case "audio": {
            // contains only one audio file
            return (
              <AudioViewer
                feed={feed}
                feedEarning={feedEarning}
                getTransactions={_getTransactions}
                toggleCommentSection={_toggleCommentSection}
                pinToProfile={_pinToProfile}
                deletePost={_deletePost}
                isEditFeed={isEditFeed}
                editFeed={editFeed}
                goalDiv={goalDiv}
              />
            );
          }
          case "video": {
            // contains only one video
            return (
              <VideoViewer
                feed={feed}
                feedEarning={feedEarning}
                getTransactions={_getTransactions}
                toggleCommentSection={_toggleCommentSection}
                pinToProfile={_pinToProfile}
                deletePost={_deletePost}
                isEditFeed={isEditFeed}
                editFeed={editFeed}
                goalDiv={goalDiv}
              />
            );
          }
          default:
        }
      } else if (feed?._contents?.length === 0) {
        // text only post
        return (
          <TextViewer
            feed={feed}
            feedEarning={feedEarning}
            getTransactions={_getTransactions}
            toggleCommentSection={_toggleCommentSection}
            pinToProfile={_pinToProfile}
            deletePost={_deletePost}
            isEditFeed={isEditFeed}
            editFeed={editFeed}
          />
        );
      }
    } else if (feed?.category === "poll") {
      return (
        <PollViewer
          feed={feed}
          feedIndex={feedIndex}
          toggleCommentSection={_toggleCommentSection}
          pinToProfile={_pinToProfile}
          deletePost={_deletePost}
        />
      );
    }
    return <div />;
  };

  const _subscribePost = (postId) => {
    try {
      console.log("subscribed call>>", postId);

      const params = { room: postId };

      newSocket.emit("subscribe", params, function (res) {
        console.log("subscribed>>", res);
        if (res.error) {
          errorHandler(res.error);
        }
      });
      newSocket.on(`post/${postId}`, (res) => {
        console.log(`res ==>> post/${postId}`, res);
        // if (receiveMessage.doSkip) return;

        if (res.error) {
          errorHandler(res.error);
          return;
        }

        if (res.hasOwnProperty("isReady")) {
          dispatch(
            updateAPost({
              index: feedIndex,
              attr: "isReady",
              value: res.isReady, // boolean value, get always `true`
            })
          );

          _unsubscribePost(postId);
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _unsubscribePost = (postId) => {
    try {
      console.log("unsubscribed call>>", postId);

      const params = { room: postId };
      // remove all callback of post processing
      newSocket.removeAllListeners(`post/${postId}`);
      // unsubscribe event - pause callback
      newSocket.emit("unsubscribe", params, function (res) {
        console.log("unsubscribed>>", res);
        if (res.error) {
          errorHandler(res.error);
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  useEffect(() => {
    if (feed?.isReady === false) {
      _subscribePost(feed?._id);
    }

    return () => {
      if (feed?.isReady === false) {
        _unsubscribePost(feed?._id);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed?.isReady]);

  return (
    <>
      <div {...(lastElementRef ? { ref: lastElementRef } : {})}>
        {_renderPreview()}

        <PostCommentSection
          feedIndex={feedIndex}
          onComment={(index, isAdded) => onComment(index, isAdded)}
          onLikeComment={(id, isLiked) => _toggleLikeComment(id, isLiked)}
          isCommentsLikeLoading={isCommentsLikeLoading}
          feed={feed}
          comments={comments}
          isOpen={isCommentScetionOpen}
          postComment={(text) => _postComment(text)}
          getComments={() => _getAllComments()}
          isCommentFetching={commentsLoading}
          canAddDeleteComment={isPermissionToAccess(
            "feed",
            "canAddDeleteOrComments",
            true
          )}
        />
      </div>

      {lastElementRef && loadingFeeds && (
        <div className="spinnerWrap text-center">
          <CustomLoader
            style={{
              width: "1.8rem",
              height: "1.8rem",
            }}
          />
        </div>
      )}

      <CustomPrompt
        isOpen={isPrompt}
        message="Are you sure want to delete?"
        successButtonText="Yes"
        closeButtonText="No"
        onSuccess={() => _onPromptSuccess()}
        onDismiss={() => _onPromptDismiss()}
      />
      {showMediaModal?.externalLink?.length ? (
        <ShowMediaModal
          isOpen={showMediaModal.isOpen}
          externalLink={showMediaModal.externalLink}
          contentType={showMediaModal.contentType}
          toggle={() => _toggleShowMediaModal()}
        />
      ) : null}
      <PostEarningsModal
        isVisible={isPostEarningsModalOpen}
        feed={feed}
        close={_closePostEarningsModal}
        tipArr={tipTransactions}
        ppvArr={ppvTransactions}
        userData={userData}
      />
      <CloseGoalModal
        isVisible={closeGoalModal.isOpen}
        post={feed}
        feedIndex={feedIndex}
        toggle={() => _toggleCloseGoalModal()}
      />
    </>
  );
};

export default FeedViewer;
