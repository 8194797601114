import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { APP_LOGO } from "../../config";
import {
  capitalize,
  errorHandler,
  getSrcUrl,
  showToast,
} from "../../helper-methods";
import ReactCropper from "../ReactCropper";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";

const ALLOWED_IMAGE_COUNT = 3;
const ALLOWED_VIDEO_COUNT = 1;

const SignupCoverProfileModal = ({ isOpen, data, toggle, onSuccess }) => {
  const [carousel, setCarousel] = useState([]);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const totalMediaCount = useMemo(() => {
    return {
      image: carousel?.reduce(
        (acc, each) => (each.contentType === "image" ? acc + 1 : acc),
        0
      ),
      video: carousel?.reduce(
        (acc, each) => (each.contentType === "video" ? acc + 1 : acc),
        0
      ),
    };
  }, [carousel]);

  const _resetState = () => {
    setCarousel([]);
    setCurrentCarouselIndex(0);
    setLoading(false);
  };

  const _closeModal = () => {
    _resetState();
    toggle();
  };

  const _makeCover = (index) => {
    const newCarousel = [...carousel];
    const removed = newCarousel.splice(index, 1);
    newCarousel.splice(0, 0, ...removed);
    setCarousel(newCarousel);

    setCurrentCarouselIndex(0);
  };

  const _deleteMedia = (index) => {
    const newCarousel = [...carousel];
    newCarousel.splice(index, 1);
    setCarousel(newCarousel);

    setCurrentCarouselIndex(0);
  };

  const _replaceFile = (e, index) => {
    if (index < 0 || !e) {
      return;
    }

    const file = e.target.files?.[0];
    const fileType = file?.type?.split("/")?.[0];

    if (fileType !== "image" && fileType !== "video") {
      showToast("Only Image and Video files are supported.", "error");
      return;
    }

    let currImageCount = totalMediaCount?.image;
    let currVideoCount = totalMediaCount?.video;

    const newCarousel = [...carousel];

    if (fileType !== newCarousel[index]?.contentType) {
      if (fileType === "image") {
        currImageCount++;
      } else {
        currVideoCount++;
      }

      if (
        currImageCount > ALLOWED_IMAGE_COUNT ||
        currVideoCount > ALLOWED_VIDEO_COUNT
      ) {
        showToast(
          `Only ${ALLOWED_IMAGE_COUNT} Image and ${ALLOWED_VIDEO_COUNT} Video is allowed.`
        );
        return;
      }
    }

    const fileObj = {
      name: file.name,
      contentType: fileType,
      size: file.size,
      previewBlob: URL.createObjectURL(file),
      uploadData: file,
    };

    newCarousel[index] = fileObj;
    setCarousel(newCarousel);
  };

  const _onChangeCoverProfile = (e) => {
    const files = e.target.files;

    let currImageCount = totalMediaCount?.image;
    let currVideoCount = totalMediaCount?.video;

    const contents = [];
    let countFlag = false;
    let typeFlag = false;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = files?.[i]?.type?.split("/")?.[0];

      if (fileType !== "image" && fileType !== "video") {
        if (!typeFlag) {
          showToast("Only Image and Video files are supported.", "error");
          typeFlag = true;
        }
        continue;
      }

      if (fileType.includes("image")) currImageCount++;
      if (fileType.includes("video")) currVideoCount++;

      if (
        currImageCount > ALLOWED_IMAGE_COUNT ||
        currVideoCount > ALLOWED_VIDEO_COUNT
      ) {
        if (!countFlag) {
          showToast(
            `Only ${ALLOWED_IMAGE_COUNT} Image and ${ALLOWED_VIDEO_COUNT} Video is allowed.`
          );

          countFlag = true;
        }
        break;
      } else {
        const fileObj = {
          name: file.name,
          contentType: fileType,
          size: file.size,
          previewBlob: URL.createObjectURL(file),
          uploadData: file,
        };
        contents.push(fileObj);
      }
    }

    setCarousel((prev) => (prev?.length ? prev.concat(contents) : contents));
  };

  const _onCropComplete = ({ index, crop }) => {
    const newCarousel = [...carousel];

    newCarousel[index].crop = crop;

    setCarousel(newCarousel);
  };

  const _onApply = async () => {
    try {
      setLoading(true);

      const filesToUpload = [];

      carousel.forEach((each, index) => {
        each["forKeyName"] = each.crop;
        filesToUpload.push(each);
      });

      onSuccess(filesToUpload);
      setLoading(false);

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  let imageCount = 1;

  useEffect(() => {
    if (isOpen && data?.length) {
      setCarousel(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal-dialog-centered modal-dialog-scrollable modal-lg"
      >
        <ModalHeader toggle={() => _closeModal()}>
          Add Cover Image/Video
        </ModalHeader>
        <ModalBody className="">
          {carousel?.length ? (
            <>
              <Nav tabs className="customTabs px-0">
                {carousel.map((each, index) => (
                  <NavItem key={`carousel_NavLink_${index}`}>
                    <NavLink
                      className={currentCarouselIndex === index ? "active" : ""}
                      onClick={() => setCurrentCarouselIndex(index)}
                    >
                      {capitalize(each?.contentType || each?.type)}{" "}
                      {each?.contentType === "image" || each?.type === "image"
                        ? imageCount++
                        : ""}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent
                activeTab={currentCarouselIndex}
                className="customTabContent"
              >
                {carousel.map((each, index) => (
                  <TabPane key={`carousel_TabPane_${index}`} tabId={index}>
                    {each?.contentType === "image" || each?.type === "image" ? (
                      <div className="my-1">
                        {currentCarouselIndex === index ? (
                          <ReactCropper
                            imageSrc={getSrcUrl(each) || each?.previewBlob}
                            crop={each?.crop}
                            onCropComplete={(crop) =>
                              _onCropComplete({
                                index,
                                crop,
                              })
                            }
                            locked={true}
                            disabled={loading}
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="videoInImageCropModal">
                        <CustomVideoPlayer
                          src={each?.url || each?.previewBlob}
                          hlsLink={each?.hls}
                          thumbnail={each?.thumbnail}
                          controls
                        />
                      </div>
                    )}

                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: 10 }}
                    >
                      {index > 0 ? (
                        <Button
                          className="modalBtnSave"
                          disabled={loading}
                          onClick={() => _makeCover(index)}
                        >
                          Make Cover
                        </Button>
                      ) : null}

                      <div>
                        {totalMediaCount?.image < ALLOWED_IMAGE_COUNT ||
                        totalMediaCount?.video < ALLOWED_VIDEO_COUNT ? (
                          <Label className="themeBtn addBtn d-block mx-2">
                            <Input
                              type="file"
                              className="d-none"
                              onChange={(e) => _onChangeCoverProfile(e)}
                            />
                            Add Media
                          </Label>
                        ) : null}
                      </div>

                      <Label className="modalBtnCancel addBtn d-block mx-2">
                        <Input
                          type="file"
                          className="d-none"
                          onClick={() => {}}
                          onChange={(e) => _replaceFile(e, index)}
                        />
                        Change
                      </Label>

                      <Button
                        className="deleteCoverImg"
                        disabled={loading}
                        onClick={() => _deleteMedia(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  </TabPane>
                ))}
              </TabContent>
            </>
          ) : (
            <>
              <div className="defaultCoverImg" style={{ height: "unset" }}>
                <img src={APP_LOGO} alt="logo" />
              </div>

              <Label className="themeBtn addBtn mt-4 d-block mx-auto">
                <Input
                  type="file"
                  className="d-none"
                  multiple
                  onChange={(e) => _onChangeCoverProfile(e)}
                />
                Add Media
              </Label>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnCancel" onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={() => _onApply()}
            disabled={loading || !carousel?.length}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Apply
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SignupCoverProfileModal;
