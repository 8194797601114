import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getAllTags } from "../../http-calls";

const deleteTagFromRedux = createAction("deleteTagFromRedux");
const clearAllTags = createAction("clearAllTags");

const getAndUpdateTags = createAsyncThunk(
  "tags/getAndUpdateTags",
  async (payload, thunkAPI) => {
    const res = await getAllTags();
    return res?.groups;
  }
);

export { deleteTagFromRedux, clearAllTags, getAndUpdateTags };
