import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
} from "reactstrap";
import { getFolderDetails } from "../http-calls";
import {
  showToast,
  formatCurrencyValue,
  errorHandler,
  isPermissionToAccess,
} from "../helper-methods";
import BroadCastMessage from "../components/messages/BroadCastMessage";
import SharePostModal from "../components/modals/SharePostModal";
import { getConfiguredString } from "../helper-methods/configureString";
import ConfigureString from "../components/ConfigureString";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditVaultDetails from "../components/EditVaultDetails";
import EditVaultCover from "../components/EditVaultCover";
import VaultTransactionModal from "../components/modals/VaultTransactionModal";
import CustomLoader from "../components/custom/CustomLoader";
import VaultContentsComponent from "../components/VaultContentsComponent";
import { useMemo } from "react";
import ErrorBoundary from "../components/ErrorBoundary";

const VaultDetailsPage = () => {
  const history = useHistory();

  const params = useParams();

  const canUpdateProfileSettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateProfileSettings",
        true
      ),
    []
  );

  const [vaultData, setVaultData] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  const [loadingState, setLoadingState] = useState({
    data: false,
    contents: false,
  });
  const [vaultTransactionModal, setVaultTransactionModal] = useState({
    isOpen: false,
    data: null,
  });
  const [sharePostModal, setSharePostModal] = useState({
    isOpen: false,
    data: null,
  });
  const [broadCastMessage, setBroadCastMessage] = useState({
    isOpen: false,
    fans: [],
  });

  const canEdit = useMemo(
    () => isPermissionToAccess("vault", "canEditOrArchiveVault", true),
    []
  );

  const canViewAndSendMessage = useMemo(
    () => isPermissionToAccess("messaging", "canSendMessaging", true),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _toggleVaultTransactionModal = (isOpen = false, data = null) => {
    setVaultTransactionModal({
      isOpen,
      data,
    });
  };

  const _toggleBroadCastMessage = (isOpen = false, fans = []) => {
    setBroadCastMessage({
      isOpen,
      fans,
    });
  };

  const _getFolderDetails = async () => {
    try {
      _manageLoadingState("data", true);

      const res = await getFolderDetails(params?.id);

      setVaultData(res.folder);

      _manageLoadingState("data", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("data", false);
      history.replace("/vault");
    }
  };

  useEffect(() => {
    if (!isPermissionToAccess("vault", "canViewVault")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    _getFolderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _toggleSharePostModal = (isOpen = false, data = null) => {
    if (isOpen && data && !data.isActive) {
      showToast(
        "You can only share active folders, you can share this folder after activating it"
      );
      return;
    }

    setSharePostModal({
      isOpen,
      data,
    });
  };

  if (broadCastMessage.isOpen) {
    return (
      <BroadCastMessage
        goBack={() => _toggleBroadCastMessage()}
        fans={broadCastMessage.fans}
      />
    );
  }

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin mb-2">
          <Col xs="12" xl={10} className="pgPadding">
            <div className="pgTitle">
              <Breadcrumb className="customBreadcrumb">
                <BreadcrumbItem>
                  {/* back btn */}
                  <Button
                    className="customBackBtn"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h2>
                    <ConfigureString keyString={"The vault"} />
                  </h2>
                </BreadcrumbItem>
                {vaultData?.name && (
                  <BreadcrumbItem active>{vaultData?.name}</BreadcrumbItem>
                )}
                {loadingState?.data ? <CustomLoader className="mx-2" /> : null}
              </Breadcrumb>

              <Button
                className="themeBtn addBtn"
                style={{ flexShrink: 0 }}
                onClick={() => _toggleSharePostModal(true, vaultData)}
                disabled={!canUpdateProfileSettings}
              >
                Share
              </Button>
            </div>

            <div className="vaultDetailsWrap">
              <Nav tabs className="customTabs customTabs-Notifications">
                <NavItem>
                  <NavLink
                    className={`${activeTab === "1" ? "active" : ""}`}
                    onClick={() => setActiveTab("1")}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${activeTab === "2" ? "active" : ""}`}
                    onClick={() => setActiveTab("2")}
                  >
                    Preview
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="customTabContent customTabContent-Notifications">
                <TabPane tabId="1">
                  <ErrorBoundary>
                    <EditVaultDetails
                      vaultData={vaultData}
                      setVaultData={setVaultData}
                      canEdit={canEdit}
                    />
                  </ErrorBoundary>
                </TabPane>

                <TabPane tabId="2">
                  <ErrorBoundary>
                    <EditVaultCover
                      vaultData={vaultData}
                      setVaultData={setVaultData}
                      canEdit={canEdit}
                    />
                  </ErrorBoundary>
                </TabPane>
              </TabContent>
            </div>

            <div className="vaultEarningStatsWrap">
              {vaultData?._transactions?.length ? (
                <>
                  <div>
                    <Label>Total Earnings</Label>
                    <p>
                      {vaultData?.earning
                        ? formatCurrencyValue(vaultData.earning)
                        : formatCurrencyValue(0)}{" "}
                    </p>
                  </div>
                  <Button
                    onClick={() =>
                      _toggleVaultTransactionModal(true, vaultData)
                    }
                  >
                    See Subscribers
                  </Button>
                </>
              ) : (
                <p>No earnings yet</p>
              )}
            </div>

            <ErrorBoundary>
              <VaultContentsComponent
                vaultData={vaultData}
                setVaultData={setVaultData}
                canEdit={canEdit}
              />
            </ErrorBoundary>
          </Col>
        </Row>

        {/* Modal for showing "Unlock/Purchase History" */}
      </Container>

      <SharePostModal
        modalHeading={getConfiguredString("Share vault")}
        isOpen={sharePostModal.isOpen}
        data={sharePostModal.data}
        toggle={() => _toggleSharePostModal()}
        shareType="vault"
      />

      <VaultTransactionModal
        isOpen={vaultTransactionModal.isOpen}
        data={vaultTransactionModal.data}
        toggle={() => _toggleVaultTransactionModal()}
        canViewAndSendMessage={canViewAndSendMessage}
        toggleBroadcastMessage={(isOpen, selectedFanForMessages) =>
          _toggleBroadCastMessage(isOpen, selectedFanForMessages)
        }
      />
    </div>
  );
};

export default VaultDetailsPage;
