import { createSlice } from "@reduxjs/toolkit";
import {
  updateSelectedThread,
  updateSelectedGroupThread,
  updateSelectedGroupThreadKey,
  getAndUpdateChatThreads,
  getAndUpdateSelectedGroupThread,
  getAndUpdateMessages,
  updateMessageKey,
  addLocalMessage,
  toggleFanChatFavourite,
  updateThreadFanToTag,
  toggleFanChatArchive,
  updateThreads,
  clearAllMessageData,
  unreadMessageCountUpdate,
  addNewThreadToChatData,
  getUnreadMessageCountAction,
} from "../actions";

const initialState = {
  threads: [],
  threadsCount: 0,
  messages: {},
  messagesCount: {},
  followers: {},
  unreadMessageCount: 0,
  unreadThreadIds: [],
  selectedThread: null,
  selectedGroupThread: null,
};

const chatDataSlice = createSlice({
  name: "chatData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateSelectedThread, (state, action) => {
        const findThread = state.threads.find(
          (each) => each._id === action.payload?._id
        );
        if (findThread) {
          findThread.isRead = true;
          findThread.unreadMessageCount = 0;
          if (findThread.lastMessage) findThread.lastMessage.isRead = true;
        }

        const findUnreadThreadIndex = state.unreadThreadIds?.indexOf(
          action.payload?._id
        );

        if (findUnreadThreadIndex > -1) {
          state.unreadThreadIds.splice(findUnreadThreadIndex, 1);
          if (state.unreadMessageCount) state.unreadMessageCount--;
        }

        state.selectedThread = action.payload;
        // state.selectedGroupThread = null;
      })

      .addCase(updateSelectedGroupThread, (state, action) => {
        // state.selectedThread = null;
        state.selectedGroupThread = action.payload;
      })

      .addCase(getAndUpdateSelectedGroupThread.fulfilled, (state, action) => {
        state.selectedGroupThread = action.payload;
      })

      .addCase(updateSelectedGroupThreadKey, (state, action) => {
        const { thread, key, value } = action.payload || {};
        if (thread?._id === state?.selectedGroupThread?._id) {
          state.selectedGroupThread[key] = value;
        }
      })

      .addCase(updateThreads, (state, action) => {
        state.threads = action.payload;
      })

      .addCase(updateThreadFanToTag, (state, action) => {
        const { thread, groups } = action.payload;

        if (
          state?.selectedThread?._id === thread?._id &&
          state?.selectedThread?.fan
        ) {
          state.selectedThread.fan.groups = groups;
        }

        const findThread = state.threads?.find(
          (each) => each._id === thread?._id
        );
        if (findThread?.fan) {
          findThread.fan.groups = groups;
        }
      })

      .addCase(toggleFanChatFavourite, (state, action) => {
        const { thread, isFavourited } = action.payload;

        if (state.selectedThread?._id === thread?._id) {
          state.selectedThread.isFavourited = isFavourited;
        }

        const findThread = state.threads.find(
          (each) => each._id === thread?._id
        );
        if (findThread) {
          findThread.isFavourited = isFavourited;
        }
      })

      .addCase(toggleFanChatArchive, (state, action) => {
        const { thread, isArchived } = action.payload;

        if (state.selectedThread?._id === thread?._id) {
          state.selectedThread.isArchived = isArchived;
        }
        const findThread = state.threads.find(
          (each) => each._id === thread?._id
        );
        if (findThread) {
          findThread.isArchived = isArchived;
        }
      })

      .addCase(clearAllMessageData, (state, action) => {
        state.threads = [];
        state.threadsCount = 0;
        state.messages = {};
        state.messagesCount = {};
        state.followers = {};
        state.unreadMessageCount = 0;
        state.unreadThreadIds = [];
        state.selectedThread = null;
      })

      .addCase(unreadMessageCountUpdate, (state, action) => {
        const { threadId } = action.payload;

        if (threadId && state.selectedThread?._id !== threadId) {
          if (!state.unreadThreadIds?.includes(threadId)) {
            state.unreadMessageCount = state.unreadThreadIds?.length
              ? state.unreadThreadIds?.length + 1
              : 1;
            state.unreadThreadIds = state.unreadThreadIds?.length
              ? [...state.unreadThreadIds, threadId]
              : [threadId];
          }
        }
      })

      .addCase(updateMessageKey, (state, action) => {
        const { message, key, value } = action.payload;

        const findMessage = state.messages?.[state.selectedThread?._id]?.find(
          (each) =>
            (message.tempMessageId &&
              (each.tempMessageId === message.tempMessageId ||
                each.messageId === message.tempMessageId)) ||
            (message.messageId &&
              (each.tempMessageId === message.messageId ||
                each.messageId === message.messageId))
        );
        if (findMessage) {
          findMessage[key] = value;
        }
      })

      .addCase(addLocalMessage, (state, action) => {
        const { threadId, message } = action.payload;

        if (!state.messages[threadId]) {
          state.messages[threadId] = [];
        }

        state.messages[threadId].push(message);

        const findThreadIndex = state.threads.findIndex(
          (each) => each._id === threadId
        );

        if (findThreadIndex > -1) {
          state.threads[findThreadIndex].lastMessage = message;
          if (threadId === state.selectedThread?._id) {
            state.threads[findThreadIndex].unreadMessageCount = 0;
          } else {
            state.threads[findThreadIndex].unreadMessageCount++;
          }
          const removedThread = state.threads.splice(findThreadIndex, 1);
          state.threads.unshift(removedThread[0]);
        }

        state.messagesCount[threadId]++;
      })

      // get threads success
      .addCase(getAndUpdateChatThreads.fulfilled, (state, action) => {
        const { threads, threadsCount, isConcat } = action.payload;

        if (isConcat) {
          state.threads = state.threads.concat(threads);
        } else {
          state.threads = threads;
        }
        state.threadsCount = threadsCount;
      })

      // get threads messages success
      .addCase(getAndUpdateMessages.fulfilled, (state, action) => {
        const { thread, totalCount, isConcat } = action.payload;

        const findThread = state.threads.find(
          (each) => each._id === thread._id
        );
        if (findThread) {
          findThread.isBlocked = thread.isBlocked;
          findThread.isThreadBlocked = thread?.isThreadBlocked;
          findThread.isCountryBlocked = thread.isCountryBlocked;
          findThread.isFavourited = thread.isFavourited;
          findThread.isArchived = thread.isArchived;
          if (!findThread.influencer.hasOwnProperty("isActive")) {
            findThread.influencer = thread.influencer;
          }
        }

        if (state.selectedThread?._id === thread._id) {
          state.selectedThread.isBlocked = thread.isBlocked;
          state.selectedThread.isThreadBlocked = thread.isThreadBlocked;
          state.selectedThread.isCountryBlocked = thread.isCountryBlocked;
          state.selectedThread.isFavourited = thread.isFavourited;
          state.selectedThread.isArchived = thread.isArchived;
          if (
            state.selectedThread.influencer &&
            !state.selectedThread.influencer.hasOwnProperty("isActive")
          ) {
            state.selectedThread.influencer = thread.influencer;
          }
        }

        if (!state.messages) {
          console.log("Hi");
          state.messages = {};
        }

        if (isConcat) {
          state.messages[thread._id] = thread._messages.concat(
            state.messages[thread._id]
          );
        } else {
          state.messages[thread._id] = thread._messages;
        }

        if (!state.messagesCount) {
          state.messagesCount = {};
        }

        state.messagesCount[thread._id] = totalCount;
      })

      .addCase(addNewThreadToChatData.pending, (state) => {
        state.loading = true;
      })

      .addCase(addNewThreadToChatData.fulfilled, (state, action) => {
        const newThread = {
          [action.payload.id]: {
            ...action.payload,
          },
        };

        state.threads = {
          ...state.threads,
          ...newThread,
        };

        state.totalThreadCount++;

        state.loading = false;
      })

      .addCase(getUnreadMessageCountAction.pending, (state) => {
        state.loading = true;
      })

      .addCase(getUnreadMessageCountAction.fulfilled, (state, action) => {
        state.unreadMessageCount = action.payload.unreadMessageCount;
        state.unreadThreadIds = action.payload.unreadThreadIds;

        state.loading = false;
      });
  },
});

export const chatDataReducer = chatDataSlice.reducer;
