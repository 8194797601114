import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../../config";
import MultiMediaModal from "../modals/MultiMediaModal";
import ErrorBoundary from "../ErrorBoundary";
import AudioPlayer from "../custom/AudioPlayer";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";
import { reduceToSevenTwoZeroRes } from "../../helper-methods";

const FeedViewerCarousel = ({ feed, contents }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [multiMediaModal, setMultiMediaModal] = useState({
    isOpen: false,
    contents: null,
    activeIndex: null,
  });

  const _pauseAllMediaWhenToggleIsOpen = () => {
    try {
      const videos = document.querySelectorAll("video");
      const audios = document.querySelectorAll("audio");

      videos.forEach((each) => {
        if (each?.pause) {
          each.pause();
        }
      });
      audios.forEach((each) => {
        if (each?.pause) {
          each.pause();
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const _next = () => {
    const nextIndex = activeIndex === contents.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const _previous = () => {
    const nextIndex = activeIndex === 0 ? contents.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const _goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  const _onExit = (e, item) => {
    if (item.mediaType === "video" || item.contentType === "video") {
      const video = e.getElementsByTagName("video");
      if (video && video.length) {
        if (!video[0].paused) {
          video[0].pause();
        }
      }
    } else if (item.mediaType === "audio" || item.contentType === "audio") {
      const audio = e.getElementsByTagName("audio");
      if (audio && audio.length) {
        if (!audio[0].paused) {
          audio[0].pause();
        }
      }
    }
  };

  const _getActiveContentItem = (item, index) => {
    if (item.mediaType || item.contentType) {
      switch (item.mediaType || item.contentType) {
        case "image":
          return (
            <img
              src={
                reduceToSevenTwoZeroRes(item?._content?.url) ||
                DEFAULT_COVER_PICTURE
              }
              onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
              alt="Carousel Img"
              loading="lazy"
              className="carouselImg"
            />
          );
        case "video":
          return (
            <ErrorBoundary>
              {/* custom video player support hls */}
              <CustomVideoPlayer
                src={item?._content?.url}
                hlsLink={item?._content?.hls}
                thumbnail={feed?.preview || item?._content?.thumbnail}
                feedId={feed?._id}
                pictureInPictureMode
                duration={item?._content?.duration || item?.duration}
                // fullScreenMode
                controls
              />
            </ErrorBoundary>
          );
        case "audio":
          return (
            <ErrorBoundary>
              <AudioPlayer
                className="audioWrapperFeedCarousel"
                url={item?._content?.url}
              />
            </ErrorBoundary>
          );
        default: {
          return null;
        }
      }
    }
  };

  const _toggleMultiMediaModal = (
    isOpen = false,
    contents = null,
    activeIndex = 0
  ) => {
    if (isOpen) {
      _pauseAllMediaWhenToggleIsOpen();
    }

    setMultiMediaModal({
      isOpen,
      contents,
      activeIndex,
    });
  };

  const slides = contents?.length
    ? contents.map((item, index) => {
        return (
          <CarouselItem
            onExit={(e) => _onExit(e, item)}
            key={`${item?._content?._id || "feed_viewer_carousel"}__${index}`}
          >
            {_getActiveContentItem(item, index)}
          </CarouselItem>
        );
      })
    : null;

  return (
    <div>
      {contents?.length ? (
        <>
          {/* zoom button */}
          <img
            src="/assets/img/expand.png"
            alt="Expand"
            onClick={() => _toggleMultiMediaModal(true, contents, activeIndex)}
            className="expandIcon"
            loading="lazy"
          />

          {/* show active content page number / total content - ( 1 / 8 )*/}
          <div className="mediaCountCarousel">
            {activeIndex + 1}/{contents.length}
          </div>

          <Carousel
            activeIndex={activeIndex}
            next={_next}
            previous={_previous}
            ride="carousel"
            autoPlay={false}
            interval={false}
          >
            <CarouselIndicators
              items={contents.map(
                (item, index) => `${item?._content?._id}_${index}`
              )}
              activeIndex={activeIndex}
              onClickHandler={_goToIndex}
            />
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={_previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={_next}
            />
          </Carousel>
        </>
      ) : (
        "Content Not found"
      )}

      <MultiMediaModal
        isOpen={multiMediaModal.isOpen}
        contents={multiMediaModal.contents}
        activeIndex={multiMediaModal.activeIndex}
        toggle={() => _toggleMultiMediaModal()}
      />
    </div>
  );
};

export default FeedViewerCarousel;
