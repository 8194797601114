import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, FormGroup, Form, Input } from "reactstrap";
import {
  fetchAndModifiedUserDetails,
  storeAssistantData,
} from "../redux/actions/userData";
import { updateUserData } from "../redux/actions/userData";
import {
  login,
  // otplessLogin
} from "../http-calls";
import {
  showToast,
  deepClone,
  extractQueryParams,
  getDeviceDetails,
  errorHandler,
  sleepTime,
  getOneSignalDeviceId,
} from "../helper-methods";
import {
  APP_LOGO,
  APP_VERSION,
  FRONTEND_FAN_BASE_URL,
  // SOCIAL_CREDENTIALS,
  SHOW_SOCIAL_ICON_ONLY,
} from "../config";
import { RegexConfig } from "../config/RegexConfig";
// import { gapi } from "gapi-script";
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
  COMMUNITY_GUIDELINES_URL,
} from "../config";
import { useHistory } from "react-router-dom";
import GoogleLoginComponent from "../components/socialLogin/GoogleLoginComponent";
import FacebookLoginComponent from "../components/socialLogin/FacebookLoginComponent";
import AppleLoginComponent from "../components/socialLogin/AppleLoginComponent";
import { hideLoader, showLoader } from "../redux/actions";
import ErrorBoundary from "../components/ErrorBoundary";

const initialFormfields = {
  email: {
    value: "",
    isValid: false,
    isDirty: false,
  },
  password: {
    value: "",
    isValid: false,
    isDirty: false,
  },
};

// function start() {
//   gapi.auth2.init({
//     client_id: SOCIAL_CREDENTIALS.googleClientId,
//   });
// }

const LoginPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  // REDUX STATE
  const userData = useSelector((state) => state?.userData);

  // LOCAL STATE
  const [formFields, setFormFields] = useState(deepClone(initialFormfields));
  const [showPassword, setShowPassword] = useState(false); //show hide password
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [showInstallableTextOnSafari, setShowInstallableTextOnSafari] =
    useState(false);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [loading, setLoading] = useState(false);

  // const _otplessLogin = async (otplessUser) => {
  //   try {
  //     dispatch(showLoader("Logging you in"));
  //     // always resolve
  //     const oneSignalDeviceId = await getOneSignalDeviceId();
  //     const newDeviceDetails = await getDeviceDetails();
  //     setDeviceDetails(newDeviceDetails);

  //     const loginResponse = await otplessLogin({
  //       email: otplessUser?.email?.email,
  //       phone: otplessUser?.mobile?.number,
  //       deviceDetails: {
  //         ...newDeviceDetails,
  //         allowNotification: oneSignalDeviceId ? true : false,
  //         deviceId: oneSignalDeviceId
  //           ? oneSignalDeviceId
  //           : newDeviceDetails.deviceId,
  //       },
  //       allowNotification: oneSignalDeviceId ? true : false,
  //       ipCountry: newDeviceDetails.ipCountry,
  //     });

  //     if (loginResponse.user.type !== "Influencer") {
  //       dispatch(hideLoader());
  //       showToast("Account doesn't exist", "error");
  //       return;
  //     }

  //     // Login success
  //     dispatch(updateUserData(loginResponse));

  //     dispatch(hideLoader());
  //     dispatch(fetchAndModifiedUserDetails());

  //     // If isAssistant Login
  //     if (loginResponse.user.isAssistant) {
  //       let assistantData = {
  //         ...loginResponse.user,
  //       };
  //       dispatch(storeAssistantData(assistantData));
  //     }

  //     // First check if any redirect required
  //     props.history.replace("/");
  //   } catch (error) {
  //     dispatch(hideLoader());
  //     errorHandler(error);
  //   }
  // };

  // const _otplessInitialize = () => {
  //   try {
  //     const loadScript = function (src) {
  //       const tag = document.createElement("script");
  //       tag.async = false;
  //       tag.src = src;
  //       tag.id = "otpless_script";
  //       const body = document.getElementsByTagName("body")[0];
  //       body.appendChild(tag);
  //     };
  //     loadScript("https://otpless.com/auth.js");
  //     const otplessInit = Reflect?.get(window, "otplessInit");
  //     if (otplessInit) {
  //       otplessInit();
  //     }
  //     window.otpless = (otplessUser) => {
  //       _otplessLogin(otplessUser);
  //     };
  //   } catch (error) {
  //     console.log("error>>", error);
  //   }
  // };

  // const _otplessRemoveScript = () => {
  //   const otpless_script = document.getElementById("otpless_script");
  //   const OTPless_Auth_Script = document.getElementById("OTPless-Auth-Script");
  //   otpless_script?.remove && otpless_script.remove();
  //   OTPless_Auth_Script?.remove && OTPless_Auth_Script.remove();
  // };

  const _getInitialData = async () => {
    try {
      const { token, handle, redirectTo } = extractQueryParams();

      // admin spoof creator account condition
      if (token && handle) {
        setLoading(true);

        // add user details
        dispatch(updateUserData({ handle, token }));
        // fetch and update user details
        dispatch(fetchAndModifiedUserDetails());

        await sleepTime(1000);

        setLoading(false);

        history.replace(redirectTo || "/");
        return;
      }

      // Already logged in
      if (userData?.token) {
        history.replace(redirectTo || "/");
        return;
      }

      // otpless initialise call
      // _otplessInitialize();

      const newDeviceDetails = await getDeviceDetails();
      setDeviceDetails(newDeviceDetails);

      // gapi.load("client:auth2", start);
      _extractEmailIfAvailable();

      // detect app open from pwa installed app
      if (window.matchMedia("(display-mode: standalone)")?.matches) {
        setShowInstallableTextOnSafari(false);
      } else {
        const UA = navigator.userAgent;
        // detect app run on iphone & ipad
        const iPhone_iPad = !!UA.match(/iPad/i) || !!UA.match(/iPhone/i);

        if (
          iPhone_iPad &&
          newDeviceDetails?.name?.toLowerCase()?.includes("safari")
        ) {
          setShowInstallableTextOnSafari(true);
        }
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _extractEmailIfAvailable = () => {
    //   Check if search string is present
    if (history?.location?.search) {
      const queryParamsString = history.location.search;
      // Check if key is available
      if (queryParamsString.includes("?email=")) {
        const newFormFields = { ...formFields };
        newFormFields.email.value = queryParamsString.split("?email=")[1];
        setFormFields({ ...newFormFields, ...formFields });
      }
    }
    return null;
  };

  const _validateForm = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        switch (key) {
          case "email": {
            if (
              RegexConfig[key].test(String(formFields[key].value).toLowerCase())
            ) {
              formFields[key].isValid = true;
            } else {
              formFields[key].isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "password": {
            if (formFields[key].value.length >= 3) {
              formFields[key].isValid = true;
            } else {
              formFields[key].isValid = false;
              isFormValid = false;
            }
            break;
          }
          default:
        }
      });

      setFormFields({ ...newFormFields });
      resolve(isFormValid);
    });
  };

  const _markAsDirty = (key) => {
    const newFormFields = { ...formFields };
    newFormFields[key].isDirty = true;
    setFormFields({ ...newFormFields });
    _validateForm(newFormFields);
  };

  const _updateFieldValue = (key, value) => {
    const newFormFields = { ...formFields };

    newFormFields[key].value = value;

    setFormFields({ ...newFormFields });

    if (newFormFields[key].isDirty) _validateForm(newFormFields);
  };

  const _makeAllFieldDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newFormFields[key].isDirty = true;
      });
      setFormFields({ ...newFormFields });
      resolve(newFormFields);
    });
  };

  const _completedAuthorization = (loginResponse) => {
    try {
      if (loginResponse?.user?.type !== "Influencer") {
        showToast("Account doesn't exist");
        return;
      }

      // Login success
      dispatch(updateUserData(loginResponse));

      dispatch(fetchAndModifiedUserDetails());

      // If isAssistant Login
      if (loginResponse?.user?.isAssistant) {
        let assistantData = {
          ...loginResponse.user,
        };
        dispatch(storeAssistantData(assistantData));
      }

      // registered but not updated cover image and category
      if (
        loginResponse?.user?.lastStep > 0 &&
        loginResponse?.user?.lastStep < 3
      ) {
        history.replace("/update-profile");
        return;
      }

      const { redirectTo } = extractQueryParams();
      // redirect to feed
      history.replace(redirectTo || "/");
    } catch (error) {
      errorHandler(error);
    }
  };

  const _login = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = await _makeAllFieldDirty();

      const isFormValid = await _validateForm(newFormFields);

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      let newDeviceDetails = { ...deviceDetails };
      if (!newDeviceDetails) {
        newDeviceDetails = await getDeviceDetails();
      }

      // always resolve
      const oneSignalDeviceId = await getOneSignalDeviceId();

      console.log({ oneSignalDeviceId });

      const loginResponse = await login({
        handle: formFields.email.value,
        password: formFields.password.value,
        deviceDetails: {
          ...newDeviceDetails,
          allowNotification: oneSignalDeviceId ? true : false,
          deviceId: oneSignalDeviceId
            ? oneSignalDeviceId
            : newDeviceDetails?.deviceId,
        },
        allowNotification: oneSignalDeviceId ? true : false,
        ipCountry: newDeviceDetails?.ipCountry,
        mode: "web",
        userType: "Influencer",
      });

      _completedAuthorization(loginResponse);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _socialLogin = async (platform, accessToken, name = null) => {
    try {
      dispatch(showLoader(`Login through ${platform}...`));

      let newDeviceDetails = { ...deviceDetails };

      if (!newDeviceDetails) {
        newDeviceDetails = await getDeviceDetails();
      }

      // always resolve
      const oneSignalDeviceId = await getOneSignalDeviceId();

      console.log({ oneSignalDeviceId });

      const loginResponse = await login({
        accessToken,
        name,
        socialAccount: platform === "facebook" ? "fb" : platform,
        deviceDetails: {
          ...newDeviceDetails,
          allowNotification: oneSignalDeviceId ? true : false,
          deviceId: oneSignalDeviceId
            ? oneSignalDeviceId
            : newDeviceDetails?.deviceId,
        },
        allowNotification: oneSignalDeviceId ? true : false,
        ipCountry: newDeviceDetails?.ipCountry,
        mode: "web",
        userType: "Influencer",
      });

      _completedAuthorization(loginResponse);

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _navigateToRoute = (route) => {
    history.push(route);
  };

  const _installApp = async () => {
    setIsInstallable(false);
    // Hide the app provided install promotion
    // hideInstallPromotion();
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    setDeferredPrompt(null);
  };

  const _redirectToFanLogin = () => {
    window.open(`${FRONTEND_FAN_BASE_URL}/login`, "_self");
  };

  // Initialize deferredPrompt for use later to show browser install prompt.
  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    setDeferredPrompt(e);
    setIsInstallable(true);
    // alert("event >>")
    // Update UI notify the user they can install the PWA
    // showInstallPromotion();
    // Optionally, send analytics event that PWA install promo was shown.
  });

  window.addEventListener("appinstalled", () => {
    // Hide the app-provided install promotion
    // hideInstallPromotion();
    // Clear the deferredPrompt so it can be garbage collected
    setDeferredPrompt(null);
    // Optionally, send analytics event to indicate successful install
  });

  useEffect(() => {
    _getInitialData();

    // return () => {
    //   _otplessRemoveScript();
    // };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="animated fadeIn authFormWrapper" data-test="page-login">
      <div className="loginWrapper">
        <img
          src={APP_LOGO}
          alt="Project Logo"
          className="projectLogo"
          loading="lazy"
        />

        {isInstallable && (
          <Button className="installPWA" onClick={_installApp}>
            Download App
          </Button>
        )}
        <div className="authPgFormWrap">
          <h4 className="cursorPointer" onClick={() => _redirectToFanLogin()}>
            Creator Login
          </h4>
          <Form onSubmit={(e) => _login(e)} data-test="login-form">
            <FormGroup>
              <Label>Email</Label>
              <Input
                data-test="email-input-box"
                type="email"
                placeholder="Email"
                name="email"
                // autoComplete="Email"
                value={formFields.email.value}
                onChange={(e) => _updateFieldValue("email", e.target.value)}
                onBlur={() => _markAsDirty("email")}
              />
              {formFields.email.isDirty && !formFields.email.isValid ? (
                <div className="form-error" data-test="email-error-div">
                  Invalid Email
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Password</Label>
              <div className="position-relative">
                <Input
                  data-test="password-input-box"
                  type={showPassword ? "text" : "password"}
                  className="passwordInput"
                  placeholder="Password"
                  name="password"
                  autoComplete="current-password"
                  value={formFields.password.value}
                  onChange={(e) =>
                    _updateFieldValue("password", e.target.value)
                  }
                  onBlur={() => _markAsDirty("password")}
                />
                <div className="eyeIcon">
                  {showPassword ? (
                    <i
                      className="fa fa-eye"
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  ) : (
                    <i
                      className="fa fa-eye-slash"
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  )}
                </div>
              </div>
              {formFields.password.isDirty && !formFields.password.isValid ? (
                <div className="form-error" data-test="password-error-div">
                  Invalid password
                </div>
              ) : null}
            </FormGroup>

            <Button
              className="forgotPassword"
              onClick={() => {
                let redirectUrl = "/forgot-password";
                if (formFields.email.value.length) {
                  redirectUrl += "?email=" + formFields.email.value;
                }
                _navigateToRoute(redirectUrl);
              }}
            >
              Forgot Password
            </Button>
            <div className="clearfix"></div>

            <Button
              data-test="login-button"
              className="themeBtn loginBtn"
              type="submit"
              disabled={loading}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
              Login
            </Button>
          </Form>

          <div className="orTxt">
            <span>OR</span>
          </div>

          <div
            className={`${
              SHOW_SOCIAL_ICON_ONLY ? "socialLoginWrap" : "text-center mb-3"
            }`}
          >
            <ErrorBoundary>
              <GoogleLoginComponent
                onSuccess={(res) => _socialLogin("google", res.access_token)}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <FacebookLoginComponent
                onSuccess={(res) => _socialLogin("facebook", res.accessToken)}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <AppleLoginComponent
                onSuccess={(res) =>
                  _socialLogin("apple", res.accessToken, res.name)
                }
              />
            </ErrorBoundary>
            {/* continue with whatsapp / otpless btn */}
            {/* <div id="otpless" /> */}
          </div>

          <Button
            className="registerBtn"
            onClick={() => _navigateToRoute("/request-invite")}
          >
            Want to be a creator? <span>Apply Now</span>
          </Button>

          <Button className="registerBtn" onClick={() => _redirectToFanLogin()}>
            Are you a fan? <span>Sign In</span>
          </Button>
        </div>
      </div>

      {showInstallableTextOnSafari && (
        <div className="addPWATooltipWrap">
          <div className="addPWATooltip">
            <i className="fa fa-plus-square" />
            <div>
              Install this webapp on your device. Tap
              <img
                src={"/assets/img/arrow.png"}
                alt="Add Icon"
                loading="lazy"
              />{" "}
              and then Add to homescreen.
              <Button onClick={() => setShowInstallableTextOnSafari(false)}>
                <i className="fa fa-times" />
              </Button>
              <div className="arrowBottom"></div>
            </div>
          </div>
        </div>
      )}

      <div className="loginFooter">
        <div className="socialLinksFooter-Login">
          <div className="socialLinksFooter">
            <a
              href={TERMS_OF_SERVICE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
            <a
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
            <a
              href={COMMUNITY_GUIDELINES_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Guidelines
            </a>

            <div style={{ fontSize: 12, marginLeft: 10, marginTop: 1 }}>
              v.{APP_VERSION}
            </div>
          </div>
        </div>
        <span className="poweredBy">
          Powered by
          <a
            href="https://www.logic-square.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Logic Square
          </a>
        </span>
      </div>
    </div>
  );
};

export default LoginPage;
