import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  errorHandler,
  extractQueryParams,
  getMessageParticipants,
  getWindowDimensions,
  isPermissionToAccess,
  showToast,
} from "../helper-methods";
import ThreadListGroup from "../components/messages/ThreadListGroup";
import { useSelector } from "react-redux";
import ConversationCard from "../components/messages/ConversationCard";
import { createThread, getAThreadForList } from "../http-calls";
import { useDispatch } from "react-redux";
import {
  getAndUpdateTags,
  hideLoader,
  showLoader,
  updateSelectedThread,
} from "../redux/actions";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import GroupConversationCard from "../components/messages/GroupConversationCard";

const MessagesPage = () => {
  const history = useHistory();

  const { selectedThread, selectedGroupThread } = useSelector(
    (state) => state?.chatData || {}
  );

  const dispatch = useDispatch();

  const { screenWidth } = useMemo(() => {
    return getWindowDimensions();
  }, []);

  const [activeTab, setActiveTab] = useState("1");

  const _initialiseOpenCard = async () => {
    try {
      let { followerId, threadId } = extractQueryParams();

      if (followerId) {
        dispatch(showLoader());
        const res = await createThread(followerId);
        threadId = res.threadId;
      }

      if (threadId) {
        dispatch(showLoader());
        const { threads } = await getAThreadForList(threadId);
        const { fan, influencer } = getMessageParticipants(threads);
        threads.fan = fan;
        threads.influencer = influencer;
        dispatch(updateSelectedThread(threads));
        history.replace("/messages");
      }

      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _getAllTags = async () => {
    try {
      await getAndUpdateTags()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!isPermissionToAccess("messaging", "canViewMessaging")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    _initialiseOpenCard();

    _getAllTags();

    return () => {
      dispatch(updateSelectedThread(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn customPgHeightMsg">
      <Container fluid style={{ padding: "0 15px" }}>
        <Row className="justify-content-center">
          <Col xs="12" lg="11" className="noPadding">
            <div className="msgWrapWeb">
              {/* thread user list */}
              <div
                className={`chatListWeb ${
                  screenWidth < 768 && (selectedThread || selectedGroupThread)
                    ? "d-none"
                    : ""
                }`}
              >
                <ErrorBoundary>
                  <ThreadListGroup
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </ErrorBoundary>
              </div>

              {/* conversation list */}
              <ErrorBoundary>
                <ConversationCard
                  screenWidth={screenWidth}
                  isShow={activeTab === "1" ? true : false}
                />
              </ErrorBoundary>

              {/* group conversation list */}
              <ErrorBoundary>
                <GroupConversationCard
                  screenWidth={screenWidth}
                  isShow={activeTab === "2" ? true : false}
                />
              </ErrorBoundary>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MessagesPage;
