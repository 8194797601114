import React, { useCallback, useEffect, useMemo, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import NewStoryModal from "../components/modals/NewStoryModal";
import ViewStoryModal from "../components/modals/ViewStoryModal";
import StoryPreviewComponent from "../components/StoryPreviewComponent";
import {
  errorHandler,
  isPermissionToAccess,
  showToast,
} from "../helper-methods";
import { deleteStory } from "../http-calls";
import {
  deleteStoryFromRedux,
  getAndUpdateStories,
  updateStories,
} from "../redux/actions";
import SkeletonLoading from "../components/SkeletonLoading";

import { newSocket } from "../socket-io";

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 768 },
    items: 6,
    partialVisibilityGutter: 1,
  },
  miniTablet: {
    breakpoint: { max: 767, min: 576 },
    items: 6,
    partialVisibilityGutter: 1,
  },
  mobile: {
    breakpoint: { max: 575, min: 100 },
    items: 5,
    partialVisibilityGutter: 1,
  },
};

const StoryPage = () => {
  const dispatch = useDispatch();

  const { stories, loading } = useSelector((state) => state?.storiesData || []);

  const canUpdateProfileSettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateProfileSettings",
        true
      ),
    []
  );

  const [addStoryModal, setAddStoryModal] = useState({
    isOpen: false,
    data: null,
  });
  const [viewStoryModal, setViewStoryModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleViewStoryModal = (isOpen = false, data = null) => {
    setViewStoryModal({ isOpen, data });
  };

  const _toggleAddStoryModal = (isOpen = false, data = null) => {
    setAddStoryModal({ isOpen, data });
  };

  const _deleteStory = (story) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(deleteStoryFromRedux(story));
        showToast("Story has been deleted", "success");

        await deleteStory(story.id || story._id);
      } catch (error) {
        errorHandler(error);
        _getAllStories();
      } finally {
        resolve();
        _toggleViewStoryModal();
      }
    });
  };

  const _getAllStories = async () => {
    try {
      await getAndUpdateStories()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getAllStories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _findIndex = useCallback(
    (id) => {
      const index = stories.findIndex((ele) => ele._id === id);
      return index;
    },
    [stories]
  );

  //Socket connection
  useEffect(() => {
    newSocket.emit(
      "subscribe",
      { room: `story/${stories[0]?._createdBy}` },
      (res) => {
        if (res.error) {
          errorHandler(res.error);
        }
      }
    );

    newSocket.on("storyReady", (res) => {
      const index = _findIndex(res?.data?.storyId);
      if (index >= 0) {
        const newAllStory = [...stories]; // Make a copy to keep immutability
        newAllStory.splice(index, 1, { ...stories[index], isReady: true });
        dispatch(updateStories(newAllStory));
        showToast("Story has been posted", "success");
      }
    });
    return () => {
      newSocket.off("storyReady");
    };
  }, [_findIndex, dispatch, stories]);

  return (
    <div className="animated fadeIn">
      {canUpdateProfileSettings ? (
        <>
          <Carousel
            key={stories.length}
            responsive={responsive}
            // centerMode={true}
            partialVisible={true}
            className="storyCarousel"
            draggable={false}
            showDots={false}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            renderButtonGroupOutside={true}
            slidesToSlide={5}
            removeArrowOnDeviceType={["mobile"]}
            arrows={true}
            swipeable={true}
          >
            <div>
              {/* clicking this div should open "new story" modal */}
              <div
                className="storyImgWrap"
                onClick={() => _toggleAddStoryModal(true)}
              >
                <i className="fa fa-plus" />
              </div>

              <p>New Story</p>
            </div>

            {stories?.length ? (
              stories?.map((each) =>
                each?.isReady ? (
                  <StoryPreviewComponent
                    key={each?._id}
                    story={each}
                    toggleViewStoryModal={(data) =>
                      _toggleViewStoryModal(true, data)
                    }
                  />
                ) : (
                  <SkeletonLoading type={"story"} count={1} />
                )
              )
            ) : loading ? (
              <SkeletonLoading type={"story"} count={4} />
            ) : (
              <></>
            )}
          </Carousel>
        </>
      ) : (
        ""
      )}
      <NewStoryModal
        isOpen={addStoryModal.isOpen}
        toggle={_toggleAddStoryModal}
        isForConfirmation={false}
      />

      <ViewStoryModal
        isOpen={viewStoryModal?.isOpen}
        data={[viewStoryModal?.data]}
        toggle={_toggleViewStoryModal}
        deleteStory={_deleteStory}
      />
    </div>
  );
};

export default StoryPage;
