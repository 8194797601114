import React, { useMemo, useState } from "react";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { APP_LOGO } from "../config";
import { countryCodes } from "../config/country-codes";
import { RegexConfig } from "../config/RegexConfig";
import {
  birthYearWithMinimumAge,
  decodeToken,
  errorHandler,
  generateDates,
  getCountryCodeFromDialCode,
  getDeviceDetails,
  getDialCodeFromCountryCode,
  getPhoneCodeFromBrackets,
  getPhoneNumberFromBrackets,
  isValidPhone,
} from "../helper-methods";
import { checkAvailability, firstStepSignup } from "../http-calls";
import {
  fetchAndModifiedUserDetails,
  updateUserData,
} from "../redux/actions/userData";
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
  COMMUNITY_GUIDELINES_URL,
} from "../config";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { MonthsInEngConfig } from "../config/months-config";
import { MIN_SIGNUP_AGE } from "../config/helper-config";

const SignupPublicPage = () => {
  const history = useHistory();

  const params = useParams();

  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    countryCode: "US",
    phone: "",
    gender: "",
    birthDate: "",
    birthMonth: "",
    birthYear: "",
  });
  const [showPassword, setShowPassword] = useState(false); //show hide password
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [dublicateLoading, setDublicateLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const [showInstallableTextOnSafari, setShowInstallableTextOnSafari] =
    useState(false);
  const [deviceDetails, setDeviceDetails] = useState({});

  const passwordValidations = useMemo(() => {
    return {
      length: formFields?.password?.length >= 8,
      letter: /[a-zA-Z]/.test(formFields?.password),
      number: /[0-9]/.test(formFields?.password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(formFields?.password),
    };
  }, [formFields?.password]);

  const _openInApp = () => {
    const userLinkToken = params?.refToken;

    setShowInstallableTextOnSafari(false);

    window.open(
      `https://truefanz.app.link/6TLH1AGGbnb/${userLinkToken}`,
      "_self"
    );
  };

  const _initializeState = async () => {
    try {
      const userLinkToken = params?.refToken;

      let userLinkData = null;

      if (userLinkToken?.length) {
        userLinkData = decodeToken(userLinkToken);
      }

      if (!userLinkData) {
        history.replace("/login");
        return;
      }

      const newFormFields = { ...formFields };

      newFormFields.firstName = userLinkData?.name?.first || "";
      newFormFields.lastName = userLinkData?.name?.last || "";
      newFormFields.email = userLinkData?.email || "";
      newFormFields.phone = userLinkData?.phone
        ? getPhoneNumberFromBrackets(userLinkData.phone)
        : "";
      newFormFields.countryCode = userLinkData?.countryCode || "US";

      if (userLinkData?.phone && !userLinkData?.countryCode) {
        const dial_code = getPhoneCodeFromBrackets(userLinkData.phone);
        const countryCode = dial_code
          ? getCountryCodeFromDialCode(dial_code)
          : "";
        newFormFields.countryCode = countryCode || "US";
      }

      setFormFields(newFormFields);

      const UA = navigator.userAgent;
      // detect app run on iphone
      const iPhone_iPad = !!UA.match(/iPhone/i);

      const newDeviceDetails = await getDeviceDetails();

      if (
        iPhone_iPad &&
        newDeviceDetails?.name?.toLowerCase().includes("safari")
      ) {
        setShowInstallableTextOnSafari(true);
      }

      setDeviceDetails(newDeviceDetails);
    } catch (error) {
      errorHandler(error);
      history.replace("/login");
    }
  };

  useEffect(() => {
    _initializeState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Check for duplicate username, email, phone
   */
  const _checkDuplicateFields = (key, value) => {
    return new Promise(async (resolve) => {
      setDublicateLoading((prev) => ({
        ...prev,
        [key]: true,
      }));

      const payload = {
        [key]: value,
      };

      checkAvailability(payload)
        .then((res) => {
          resolve([res, null]);
        })
        .catch((error) => {
          resolve([null, error]);
        });
    });
  };

  /**
   * validation or Check for duplicate username, email, phone , validate one at a time
   */
  const _formFieldsApiValidation = ({ newFormFields, newIsDirty, key }) => {
    return new Promise(async (resolve) => {
      const newErrors = {};
      let isFieldValid = true;

      if (newIsDirty[key]) {
        if (newFormFields[key]?.trim().length) {
          if (RegexConfig[key].test(String(newFormFields[key]).toLowerCase())) {
            const phoneCountry = getDialCodeFromCountryCode(
              newFormFields?.countryCode
            );

            const checkingValue =
              key !== "phone"
                ? newFormFields[key].trim()
                : `(${phoneCountry})${newFormFields[key].trim()}`;

            // always resolve
            const [res, resError] = await _checkDuplicateFields(
              key,
              checkingValue
            );

            setDublicateLoading((prev) => ({
              ...prev,
              [key]: false,
            }));

            if (res?.isDuplicateFound || res?.error || resError?.error) {
              newErrors[key] = `${newFormFields[key].trim()} already in use`;
              isFieldValid = false;
            } else {
              newErrors[key] = null;
              newIsDirty[key] = false;
            }
          } else {
            newErrors[key] = `*Invalid ${key}`;
            isFieldValid = false;
          }
        } else {
          newErrors[key] = "*Required";
          isFieldValid = false;
        }
      }

      setErrors((prev) => ({
        ...prev,
        [key]: newErrors[key],
      }));

      setIsDirty((prev) => ({
        ...prev,
        [key]: newIsDirty[key],
      }));

      resolve(isFieldValid);
    });
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "firstName":
            case "lastName": {
              if (newFormFields[key]?.trim().length) {
                if (
                  newFormFields[key]?.trim().length > 1 &&
                  newFormFields[key]?.trim().length <= 25 &&
                  RegexConfig.name.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    "*Minimum 2 characters & Maximum 25 characters, no digits/special characters are allowed.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig.password.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    "*Minimum eight characters, at least one letter, one number and one special character";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "gender": {
              if (newFormFields[key]) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "birthDate": {
              if (newFormFields[key]) {
                if (newIsDirty["birthMonth"] && !newFormFields?.birthMonth) {
                  newErrors["birthMonth"] = "*Required";
                  isFormValid = false;
                } else {
                  newErrors["birthMonth"] = null;
                }

                if (newIsDirty["birthYear"] && !newFormFields?.birthYear) {
                  newErrors["birthYear"] = "*Required";
                  isFormValid = false;
                } else {
                  newErrors["birthYear"] = null;
                }
              } else {
                newErrors["birthDate"] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "birthMonth": {
              if (newFormFields[key]) {
                if (newIsDirty["birthDate"] && !newFormFields?.birthDate) {
                  newErrors["birthDate"] = "*Required";
                  isFormValid = false;
                } else {
                  newErrors["birthDate"] = null;
                }

                if (newIsDirty["birthYear"] && !newFormFields?.birthYear) {
                  newErrors["birthYear"] = "*Required";
                  isFormValid = false;
                } else {
                  newErrors["birthYear"] = null;
                }
              } else {
                newErrors["birthMonth"] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "birthYear": {
              if (newFormFields[key]) {
                if (newIsDirty["birthDate"] && !newFormFields?.birthDate) {
                  newErrors["birthDate"] = "*Required";
                  isFormValid = false;
                } else {
                  newErrors["birthDate"] = null;
                }

                if (newIsDirty["birthMonth"] && !newFormFields?.birthMonth) {
                  newErrors["birthMonth"] = "*Required";
                  isFormValid = false;
                } else {
                  newErrors["birthMonth"] = null;
                }
              } else {
                newErrors["birthYear"] = "*Required";
                isFormValid = false;
              }
              break;
            }

            default:
          }
        }
      });

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (key === "phone" && value && !isValidPhone(value)) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };

    if (key === "email" || key === "phone") {
      _formFieldsApiValidation({ newFormFields, newIsDirty, key });
    } else {
      _validateFormFields({ newFormFields, newIsDirty });
    }
  };

  const _prepareRegistrationData = (data) => {
    const phoneCountry = getDialCodeFromCountryCode(data?.countryCode);

    let signupData = {
      inemail: data.email.trim(),
      inphone: `(${phoneCountry})${data.phone}`,
      name: {
        first: data.firstName.trim(),
        last: data.lastName.trim(),
      },
      gender: data?.gender,
      phoneCountry: data.countryCode,
      country: data.countryCode,
      password: data.password.trim(),
      lastStep: 1,
    };

    if (data?.birthDate && data?.birthMonth && data?.birthYear) {
      signupData["dob"] = {
        day: data?.birthDate,
        month: data?.birthMonth,
        year: data?.birthYear,
      };
    }

    return signupData;
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _submitCreatorRegistration = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      setLoading(true);

      const [isFormValid, isEmailFieldValid, isPhoneFieldValid] =
        await Promise.all([
          _validateFormFields({ newFormFields, newIsDirty }),
          _formFieldsApiValidation({
            newFormFields,
            newIsDirty: { email: true },
            key: "email",
          }),
          _formFieldsApiValidation({
            newFormFields,
            newIsDirty: { phone: true },
            key: "phone",
          }),
        ]);

      if (!isFormValid || !isEmailFieldValid || !isPhoneFieldValid) {
        setLoading(false);
        return;
      }

      const registrationData = _prepareRegistrationData(formFields);

      const registrationResponse = await firstStepSignup(
        {
          ...registrationData,
          deviceDetails,
          allowNotification: deviceDetails.allowNotification,
          ipCountry: deviceDetails?.ipCountry,
        },
        params?.refToken
      );
      // step 1 registration success
      delete registrationResponse["error"];

      dispatch(updateUserData(registrationResponse));

      dispatch(fetchAndModifiedUserDetails());

      history.replace("/update-profile");
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  return (
    <div
      className="animated fadeIn authFormWrapper"
      data-test="signup-public-page"
    >
      <div className="loginWrapper" style={{ maxWidth: "unset" }}>
        <img
          src={APP_LOGO}
          alt="Project Logo"
          className="projectLogo"
          onClick={() => history.replace("/login")}
          loading="lazy"
        />

        <div className="authPgFormWrap signUpWrap">
          <h4 className="mb-2">Sign Up</h4>
          <Form
            onSubmit={(e) => _submitCreatorRegistration(e)}
            data-test="signup-public-form"
          >
            <div className="d-flex">
              <FormGroup className="pr-1 w-50">
                <Label>First Name</Label>
                <Input
                  data-test="firstName"
                  type="text"
                  placeholder="Enter your first name"
                  autoComplete="off"
                  name="firstName"
                  value={formFields.firstName}
                  onChange={(e) =>
                    _onChangeFormFields("firstName", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("firstName")}
                />
                {errors?.firstName ? (
                  <div className="form-error">{errors?.firstName}</div>
                ) : null}
              </FormGroup>

              <FormGroup className="pl-1 w-50">
                <Label>Last Name</Label>
                <Input
                  data-test="lastName"
                  type="text"
                  placeholder="Enter your last name"
                  autoComplete="off"
                  name="lastName"
                  value={formFields.lastName}
                  onChange={(e) =>
                    _onChangeFormFields("lastName", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("lastName")}
                />
                {errors?.lastName ? (
                  <div className="form-error">{errors?.lastName}</div>
                ) : null}
              </FormGroup>
            </div>

            <FormGroup className="mt-0">
              <Label>Email</Label>
              <div className="position-relative">
                <Input
                  data-test="email"
                  type="text"
                  placeholder="Enter your email"
                  autoComplete="username"
                  name="email"
                  value={formFields.email}
                  onChange={(e) => _onChangeFormFields("email", e.target.value)}
                  onBlur={() => _onBlurFormFields("email")}
                />
                {dublicateLoading?.email && (
                  <div className="spinnerLogin inputSpinner">
                    <i className="fa fa-spinner fa-spin" />
                  </div>
                )}
              </div>
              {errors?.email ? (
                <div className="form-error">{errors?.email}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Password</Label>
              <div className="position-relative">
                <Input
                  data-test="password"
                  type={`${showPassword ? "text" : "password"}`}
                  placeholder="Enter the password"
                  autoComplete="new-password"
                  name="password"
                  value={formFields.password}
                  onChange={(e) =>
                    _onChangeFormFields("password", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("password")}
                />
                <div
                  className="eyeIcon"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <i
                    className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                  />
                </div>
              </div>
              {errors?.password && (
                <div className="form-error" data-test="password-error-div">
                  {errors?.password}
                </div>
              )}

              <h6 style={{ marginBlock: "12px 5px" }}>
                Password must meet the following criteria:
              </h6>
              <ListGroup className="passwordCriteria">
                <ListGroupItem>
                  <CustomInput
                    type="checkbox"
                    id="minCharacters"
                    checked={passwordValidations.length}
                    label="Minimum 8 characters"
                  />
                </ListGroupItem>
                <ListGroupItem>
                  <CustomInput
                    type="checkbox"
                    id="oneLetter"
                    checked={passwordValidations.letter}
                    label="At least one letter"
                  />
                </ListGroupItem>
                <ListGroupItem>
                  <CustomInput
                    type="checkbox"
                    id="oneNumber"
                    checked={passwordValidations.number}
                    label="At least one number"
                  />
                </ListGroupItem>
                <ListGroupItem>
                  <CustomInput
                    type="checkbox"
                    id="specialCharacter"
                    checked={passwordValidations.specialChar}
                    label="At least one special character"
                  />
                </ListGroupItem>
              </ListGroup>
            </FormGroup>

            <div className="d-flex">
              <FormGroup className="pr-1 w-50 mt-0">
                <Label>Country</Label>
                <Input
                  type="select"
                  name="countryCode"
                  value={formFields.countryCode}
                  onChange={(e) =>
                    _onChangeFormFields("countryCode", e.target.value)
                  }
                >
                  {countryCodes.map((countryCode, countryIndex) => (
                    <option key={countryIndex} value={countryCode.code}>
                      {countryCode.name} ({countryCode.dial_code})
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup className="pl-1 w-50 mt-0">
                <Label>Phone Number</Label>
                <div className="position-relative">
                  <Input
                    data-test="phone"
                    type="text"
                    placeholder="Enter your phone number"
                    autoComplete="off"
                    name="phone"
                    value={formFields.phone}
                    onChange={(e) =>
                      _onChangeFormFields("phone", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("phone")}
                  />
                  {dublicateLoading?.phone && (
                    <div className="spinnerLogin">
                      <i className="fa fa-spinner fa-spin" />
                    </div>
                  )}
                </div>

                {errors?.phone ? (
                  <div className="form-error">{errors?.phone}</div>
                ) : null}
              </FormGroup>
            </div>

            <Label>Birthday</Label>
            <FormGroup className="dobWrap">
              <div>
                <Label>Date</Label>
                <Input
                  data-test="date-dropdown"
                  type="select"
                  value={formFields?.birthDate}
                  onChange={(e) =>
                    _onChangeFormFields("birthDate", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("birthDate")}
                >
                  {!formFields?.dob?.day ? (
                    <option key={"default-birthDate"} value={""}>
                      Select Date
                    </option>
                  ) : null}
                  {generateDates(
                    formFields?.birthMonth,
                    formFields?.birthYear
                  ).map((date) => (
                    <option key={`date_${date}`} value={date}>
                      {date}
                    </option>
                  ))}
                </Input>
                {errors?.birthDate ? (
                  <div className="form-error">{errors?.birthDate}</div>
                ) : null}
              </div>

              <div className="mx-2">
                <Label>Month</Label>
                <Input
                  data-test="month-dropdown"
                  type="select"
                  value={formFields?.birthMonth}
                  onChange={(e) =>
                    _onChangeFormFields("birthMonth", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("birthMonth")}
                >
                  {!formFields?.dob?.month ? (
                    <option key={"default-birthMonth"} value={""}>
                      Select Month
                    </option>
                  ) : null}
                  {MonthsInEngConfig.map((month) => (
                    <option
                      key={`month_${month.short_name}`}
                      value={month?.month}
                    >
                      {month?.short_name}
                    </option>
                  ))}
                </Input>
                {errors?.birthMonth ? (
                  <div className="form-error">{errors?.birthMonth}</div>
                ) : null}
              </div>

              <div>
                <Label>Year</Label>
                <Input
                  data-test="year-dropdown"
                  type="select"
                  value={formFields.birthYear}
                  onChange={(e) =>
                    _onChangeFormFields("birthYear", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("birthYear")}
                >
                  {!formFields?.dob?.year ? (
                    <option key={"default-birthYear"} value={""}>
                      Select Year
                    </option>
                  ) : null}
                  {birthYearWithMinimumAge(MIN_SIGNUP_AGE).map(
                    (year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    )
                  )}
                </Input>
                {errors?.birthYear ? (
                  <div className="form-error">{errors?.birthYear}</div>
                ) : null}
              </div>
            </FormGroup>

            <FormGroup className="mt-0">
              <Label>Gender</Label>

              <div className="d-flex mt-1">
                <CustomInput
                  type="radio"
                  id="male_custom_radio_btn_profile_setting"
                  name="customRadio"
                  value="male"
                  checked={formFields.gender === "male"}
                  onChange={(e) => _onChangeFormFields("gender", "male")}
                  onBlur={() => _onBlurFormFields("gender")}
                  className="mr-4"
                  label="Male"
                />

                <CustomInput
                  type="radio"
                  id="female_custom_radio_btn_profile_setting"
                  name="customRadio"
                  value="female"
                  checked={formFields.gender === "female"}
                  onChange={(e) => _onChangeFormFields("gender", "female")}
                  onBlur={() => _onBlurFormFields("gender")}
                  label="Female"
                />
              </div>

              {errors?.gender ? (
                <p className="form-error" style={{ paddingLeft: 8 }}>
                  {errors.gender}
                </p>
              ) : null}
            </FormGroup>

            <hr />

            <Button
              className="themeBtn loginBtn mt-3"
              type="submit"
              data-test="registration-button"
              disabled={loading}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Sign Up
            </Button>
          </Form>
          <Button
            className="registerBtn mt-3"
            onClick={() => history.replace("/login")}
          >
            Already have an account? <span>Login</span>
          </Button>
        </div>
      </div>

      {showInstallableTextOnSafari && (
        <div className="addPWATooltipWrap openInAppWrap">
          <div className="addPWATooltip">
            <Button className="openInApp" onClick={() => _openInApp()}>
              Open in App
            </Button>
            <Button onClick={() => setShowInstallableTextOnSafari(false)}>
              <i className="fa fa-times" />
            </Button>
          </div>
        </div>
      )}

      <div className="loginFooter">
        <div className="socialLinksFooter-Login">
          <div className="socialLinksFooter">
            <a
              href={TERMS_OF_SERVICE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
            <a
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
            <a
              href={COMMUNITY_GUIDELINES_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Guidelines
            </a>
          </div>
        </div>
        <div className="poweredBy">
          Powered by{" "}
          <a
            href="https://www.logic-square.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Logic Square
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignupPublicPage;
