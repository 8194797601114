import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Input, Label } from "reactstrap";
import {
  checkInstantPaymentValidCountry,
  errorHandler,
  isPermissionToAccess,
  showToast,
} from "../../helper-methods";
import BankInfoUpdateModal from "../modals/BankInfoUpdateModal";
import AddDebitCardModal from "../modals/AddDebitCardModal";
import { PAYMENT_GATEWAY } from "../../config";
import { fetchAndModifiedUserDetails } from "../../redux/actions";

const SettingsBankDetails = () => {
  const user = useSelector((state) => state?.userData?.user);
  const dispatch = useDispatch();

  const [bankInfoModal, setBankInfoModal] = useState({
    isOpen: false,
    data: null,
  });

  const [debitCardModal, setDebitCardModal] = useState({
    isOpen: false,
    data: null,
  });

  const canUpdateBankDetails = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateProfileSettings",
        true
      ),
    []
  );

  const _toggleBankInfoModal = (isOpen = false, data = null) => {
    setBankInfoModal({ isOpen, data });
  };

  const _toggleDebitCardModal = (isOpen = false, data = null) => {
    setDebitCardModal({ isOpen, data });
  };

  const _onAccountUpdate = async (updateType) => {
    try {
      dispatch(fetchAndModifiedUserDetails());
      showToast(`${updateType} Updated`, "success");
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {/* bank details */}
      <React.Fragment>
        {user?.hasBank && user?.bank?.accountNumber ? (
          <>
            <div className="header_Settings" style={{ marginTop: 13 }}>
              <h4>
                Bank Info <small>(ACH Deposit)</small>
              </h4>
            </div>

            <FormGroup>
              <Label>Account Number</Label>
              <Input
                type="text"
                id="text-input-acc-number"
                name="text-input"
                disabled
                placeholder=""
                value={user?.bank?.accountNumber}
              />
            </FormGroup>
            {/* <p>Bank of America</p> */}

            {user?.bank?.routingNumber ? (
              <FormGroup>
                <Label>Routing Number | Sort Code | IFSC | Other </Label>
                <Input
                  type="text"
                  id="text-input-routing-number"
                  name="text-input"
                  disabled
                  placeholder=""
                  value={user?.bank?.routingNumber}
                  readOnly
                />
              </FormGroup>
            ) : null}

            {canUpdateBankDetails ? (
              <Button
                className="themeBtn saveBtn"
                onClick={() => _toggleBankInfoModal(true, user)}
              >
                Change
              </Button>
            ) : null}
          </>
        ) : (
          <>
            <div
              className="header_Settings"
              id="add_bank_info_setting_page"
              style={{ marginTop: 14 }}
            >
              <h4>
                No Bank Account
                <small className="fs-14">(for ACH Transfers)</small>
              </h4>
            </div>

            {canUpdateBankDetails ? (
              <Button
                className="themeBtn saveBtn"
                onClick={() => _toggleBankInfoModal(true, user)}
              >
                Add Account
              </Button>
            ) : null}
          </>
        )}
      </React.Fragment>

      <hr
        style={{
          marginTop: 25,
          borderTopColor: "rgba(204, 204, 204, 0.5)",
        }}
      />

      {/* Debit card */}
      <React.Fragment>
        {PAYMENT_GATEWAY === "stripe" &&
        checkInstantPaymentValidCountry(
          "byDebitCard",
          user?.address?.country
        ) ? (
          <>
            {user?.payoutCard ? (
              <>
                <div className="header_Settings" style={{ marginTop: 27 }}>
                  <h4>
                    Debit Card <small>(Instant Deposit)</small>
                  </h4>
                </div>

                {/* // static card data */}

                <div className="debitCardInfo">
                  <div className="greenBg">
                    <i className="fa fa-credit-card-alt" />
                  </div>
                  <div>
                    <h5>
                      {/* don't remove the below div's. They are here for design purpose */}
                      <div>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>{" "}
                      <div>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>{" "}
                      <div>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>{" "}
                      {PAYMENT_GATEWAY === "stripe"
                        ? user?.payoutCard?.last4
                        : user?.payoutCard?.cardLastFourDigits}
                    </h5>
                    <div className="d-flex justify-content-between align-items-center">
                      {user?.payoutCard?.exp_month &&
                      user?.payoutCard?.exp_year ? (
                        <p>
                          {user?.payoutCard?.exp_month}/
                          {user?.payoutCard?.exp_year.toString().slice(-2)}
                        </p>
                      ) : null}
                      <h5 className="cardBrand">
                        {user?.payoutCard?.brand?.toUpperCase()}
                      </h5>
                    </div>
                  </div>
                </div>

                {canUpdateBankDetails ? (
                  <Button
                    className="themeBtn saveBtn"
                    onClick={() => _toggleDebitCardModal(true, user)}
                  >
                    Change
                  </Button>
                ) : null}
              </>
            ) : (
              <>
                <div
                  className="header_Settings"
                  id="add_debit_card_setting_page"
                  style={{ marginTop: 28 }}
                >
                  <h4>
                    Debit Card{" "}
                    <small className="fs-14">(for Instant Transfers)</small>
                  </h4>
                </div>

                {canUpdateBankDetails ? (
                  <Button
                    className="themeBtn saveBtn"
                    onClick={() => _toggleDebitCardModal(true)}
                  >
                    Add Card
                  </Button>
                ) : null}
              </>
            )}

            <hr
              style={{
                marginTop: 25,
                borderTopColor: "rgba(204, 204, 204, 0.5)",
              }}
            />
          </>
        ) : null}
      </React.Fragment>

      <BankInfoUpdateModal
        isOpen={bankInfoModal.isOpen}
        user={bankInfoModal.data}
        onDismiss={() => _toggleBankInfoModal(false)}
        onAccountUpdate={() => _onAccountUpdate("Bank")}
      />

      <AddDebitCardModal
        isOpen={debitCardModal?.isOpen}
        userData={debitCardModal.data}
        toggle={() => _toggleDebitCardModal()}
        onAccountUpdate={() => _onAccountUpdate("Debit Card")}
      />
    </>
  );
};

export default SettingsBankDetails;
