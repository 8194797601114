import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonStory = ({ count = 2 }) => {
  return (
    <div className="d-flex">
      {[...Array(count)].map((_, index) => (
        <div className="mr-2 storySkeleton">
          <Skeleton
            key={index}
            circle
            height={90}
            width={90}
          />
          <p className="invisible">Story</p>
        </div>
      ))}
    </div>
  );
};

export default SkeletonStory;
