import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselControl,
  Button,
} from "reactstrap";
import { APP_LOGO } from "../config";
import CustomVideoPlayer from "./custom/CustomVideoPlayer";
import ErrorBoundary from "./ErrorBoundary";
import MultiMediaModal from "./modals/MultiMediaModal";
import { getSrcUrl } from "../helper-methods";

const ProfileCarousel = ({
  items,
  activeIndex,
  onChangeActiveIndex,
  screenWidth,
}) => {
  // const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [isLoadingContents, setIsLoadingContents] = useState([]);
  const [multiMediaModal, setMultiMediaModal] = useState({
    isOpen: false,
    contents: null,
    activeIndex: null,
  });

  const _pauseAllMediaWhenToggleIsOpen = () => {
    try {
      const carousel = document.getElementsByClassName("profileCarousel")[0];
      const mediaElements = carousel.getElementsByTagName("video");
      const audioElements = carousel.getElementsByTagName("audio");

      // Pause all videos
      for (let i = 0; i < mediaElements.length; i++) {
        const video = mediaElements[i];
        if (!video.paused) {
          video.pause();
        }
      }

      // Pause all audios
      for (let i = 0; i < audioElements.length; i++) {
        const audio = audioElements[i];
        if (!audio.paused) {
          audio.pause();
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _toggleMultiMediaModal = (
    isOpen = false,
    contents = null,
    activeIndex = 0
  ) => {
    if (isOpen) {
      _pauseAllMediaWhenToggleIsOpen();
    }

    setMultiMediaModal({
      isOpen,
      contents,
      activeIndex,
    });
  };

  // when click on next arrow button
  const _next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items?.length - 1 ? 0 : activeIndex + 1;
    // setActiveIndex(nextIndex);
    onChangeActiveIndex(nextIndex);
  };

  // when click on previous arrow button
  const _previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    // setActiveIndex(nextIndex);
    onChangeActiveIndex(nextIndex);
  };

  // when click on carousel indicator
  const _goToIndex = (newIndex) => {
    if (animating) return;
    // setActiveIndex(newIndex);
    onChangeActiveIndex(newIndex);
  };

  const _setAnimating = (value) => {
    setAnimating(value);
  };

  // when slide enter to viewport
  const _onEnter = (e, item) => {};

  // when slide exits the viewport
  const _onExit = (e, item) => {
    if (item.mediaType === "audio" || item.contentType === "audio") {
      const audio = e.getElementsByTagName("audio");
      if (audio?.length) {
        if (!audio[0].paused) {
          audio[0].pause();
        }
      }
    }
  };

  // when slide content fails to load
  const _onErrorContent = (index, value = false) => {
    const newIsLoadingContents = { ...isLoadingContents };
    newIsLoadingContents[index] = value;
    setIsLoadingContents(newIsLoadingContents);
  };

  // current slide item
  const _getActiveContentItem = (item, index) => {
    if (item.contentType) {
      switch (item.contentType) {
        case "image": {
          return isLoadingContents[index] ? (
            <div className="processingContent">
              <p>We are processing your content</p>
              <Button onClick={() => _onErrorContent(index)}>Reload</Button>
            </div>
          ) : (
            <div className="imgCropWrap">
              <img
                src={getSrcUrl(item)}
                alt="Carousel Img"
                loading="lazy"
                onError={() => _onErrorContent(index, true)}
                className={
                  item?.crop?.height === 100 ? "widthAuto" : "heightAuto"
                }
                style={{
                  transform: `translate(-${
                    (item?.crop?.x || item?.crop?.x === 0 ? item?.crop?.x : 0) +
                    "%"
                  }, -${
                    (item?.crop?.y || item?.crop?.y === 0 ? item?.crop?.y : 0) +
                    "%"
                  })`,
                }}
              />
            </div>
          );
        }
        case "video": {
          return (
            <>
              <ErrorBoundary>
                {/* custom video player support hls */}
                <CustomVideoPlayer
                  src={item.url}
                  hlsLink={item?.hls}
                  thumbnail={item?.thumbnail}
                  duration={item?.duration}
                  controls
                />
              </ErrorBoundary>
            </>
          );
        }
        default: {
          return null;
        }
      }
    }
  };

  const slides = items?.length
    ? items.map((item, index) => {
        return (
          <CarouselItem
            key={`${index}-carousel-item-id`}
            onExiting={() => _setAnimating(true)}
            onExited={() => _setAnimating(false)}
            onExit={(e) => _onExit(e, item)}
            onEnter={(e) => _onEnter(e, item)}
          >
            {_getActiveContentItem(item, index)}
          </CarouselItem>
        );
      })
    : [
        <CarouselItem
          onExiting={() => _setAnimating(true)}
          onExited={() => _setAnimating(false)}
          key={APP_LOGO}
        >
          <div className="defaultCoverImg">
            <img src={APP_LOGO} alt="cover" loading="lazy" />
          </div>
        </CarouselItem>,
      ];

  return (
    <>
      <div
        className="profileCarousel"
        {...(screenWidth <= 575.98
          ? { style: { width: screenWidth, height: screenWidth / 3 } }
          : {})}
      >
        {/* zoom button */}
        {items?.length ? (
          <img
            src="/assets/img/expand2.png"
            alt="Expand"
            onClick={() => _toggleMultiMediaModal(true, items, activeIndex)}
            className="expandIcon"
            style={{ right: 15, left: "unset" }}
            loading="lazy"
          />
        ) : null}

        <Carousel
          activeIndex={activeIndex}
          next={() => _next()}
          previous={() => _previous()}
          autoPlay={false}
          interval={false}
        >
          {items?.length > 1 ? (
            <CarouselIndicators
              items={items?.length ? items : [APP_LOGO]}
              activeIndex={activeIndex}
              onClickHandler={(index) => _goToIndex(index)}
            />
          ) : null}

          {slides}

          {items.length > 1 ? (
            <>
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={() => _previous()}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={() => _next()}
              />
            </>
          ) : null}
        </Carousel>
      </div>

      <MultiMediaModal
        isOpen={multiMediaModal.isOpen}
        contents={multiMediaModal.contents}
        activeIndex={multiMediaModal.activeIndex}
        toggle={() => _toggleMultiMediaModal()}
      />
    </>
  );
};

export default ProfileCarousel;
