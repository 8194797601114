// import React, { useRef, useState, useEffect } from "react";
// import RangeSlider from "react-range-slider-input";
// import "react-range-slider-input/dist/style.css";

// const AudioRangePlayer = ({ audioSrc, onRangeChange }) => {
//   const MAX_RANGE_DURATION = 15; // 15 seconds maximum selection
//   const [range, setRange] = useState([0, MAX_RANGE_DURATION]);
//   const [duration, setDuration] = useState(0);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const audioRef = useRef(null);
//   const intervalRef = useRef(null);

//   // Handle Chrome's duration Infinity bug
//   const fixChromeDurationBug = (audioElement) => {
//     if (audioElement.duration === Infinity) {
//       audioElement.currentTime = Number.MAX_SAFE_INTEGER;
//       const onTimeUpdate = () => {
//         audioElement.removeEventListener('timeupdate', onTimeUpdate);
//         setDuration(audioElement.duration);
//         audioElement.currentTime = 0;
//         // Set initial range based on actual duration
//         setRange([0, Math.min(MAX_RANGE_DURATION, audioElement.duration)]);
//       };
//       audioElement.addEventListener('timeupdate', onTimeUpdate);
//     }
//   };

//   // Update duration when audio metadata is loaded
//   useEffect(() => {
//     const audioElement = audioRef.current;
//     if (!audioElement) return;

//     const handleLoadedMetadata = () => {
//       if (audioElement.duration !== Infinity) {
//         setDuration(audioElement.duration);
//         setRange([0, Math.min(MAX_RANGE_DURATION, audioElement.duration)]);
//       } else {
//         fixChromeDurationBug(audioElement);
//       }
//     };

//     audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);

//     return () => {
//       audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
//       clearInterval(intervalRef.current);
//     };
//   }, [audioSrc]);

//   // Handle range change with 15-second constraint
//   const handleRangeChange = (newRange) => {
//     const [start, end] = newRange;

//     // Determine which thumb is being moved
//     const isMovingStart = Math.abs(start - range[0]) > Math.abs(end - range[1]);

//     if (isMovingStart) {
//       // When moving start thumb, adjust end to maintain max 15s duration
//       const newEnd = Math.min(start + MAX_RANGE_DURATION, duration);
//       const validatedStart = Math.max(0, Math.min(start, duration - 0.1));

//       setRange([validatedStart, newEnd]);
//       if (onRangeChange) onRangeChange([validatedStart, newEnd]);
//     } else {
//       // When moving end thumb, adjust start if needed to maintain max 15s duration
//       const newStart = Math.max(0, end - MAX_RANGE_DURATION);
//       const validatedEnd = Math.min(duration, Math.max(end, newStart + 0.1));

//       // If we needed to adjust the start position
//       if (validatedEnd - newStart > MAX_RANGE_DURATION) {
//         setRange([newStart, newStart + MAX_RANGE_DURATION]);
//         if (onRangeChange) onRangeChange([newStart, newStart + MAX_RANGE_DURATION]);
//       } else {
//         setRange([newStart, validatedEnd]);
//         if (onRangeChange) onRangeChange([newStart, validatedEnd]);
//       }
//     }

//     if (audioRef.current) {
//       audioRef.current.currentTime = isMovingStart ? start : end - MAX_RANGE_DURATION;
//     }
//   };

//   // Play the selected range
//   const handlePlay = () => {
//     if (!audioRef.current) return;

//     audioRef.current.currentTime = range[0];
//     audioRef.current.play()
//       .then(() => {
//         setIsPlaying(true);

//         intervalRef.current = setInterval(() => {
//           if (!audioRef.current) return;

//           if (audioRef.current.currentTime >= range[1] ||
//               audioRef.current.currentTime >= duration) {
//             handlePause();
//           }
//         }, 100);
//       })
//       .catch(error => {
//         console.error("Playback failed:", error);
//       });
//   };

//   // Pause the audio
//   const handlePause = () => {
//     if (audioRef.current) {
//       audioRef.current.pause();
//     }
//     setIsPlaying(false);
//     clearInterval(intervalRef.current);
//   };

//   // Clean up on unmount
//   useEffect(() => {
//     return () => {
//       handlePause();
//     };
//   }, []);

//   const secondsToMinSec = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const remainingSeconds = Math.floor(seconds % 60);
//     return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
//   };

//   return (
//     <div>
//       {audioSrc && (
//         <>

//           <audio
//             ref={audioRef}
//             src={audioSrc}
//             controls
//             onLoadedMetadata={(e) => {
//               if (e.target.duration === Infinity) {
//                 fixChromeDurationBug(e.target);
//               }
//             }}
//           />
//           <div
//             style={{
//               maxWidth: "500px",
//               textAlign: "center",
//               margin: "auto",
//               marginTop: "10px",
//             }}
//           >
//             <RangeSlider
//               min={0}
//               max={duration || MAX_RANGE_DURATION} // Fallback to MAX_RANGE_DURATION if duration not loaded
//               step={0.1}
//               value={range}
//               className="custom-slider"
//               onInput={handleRangeChange}
//               style={{ marginTop: "10px" }}
//               disabled={!duration}
//             />
//             <p style={{ marginTop: "10px" }}>
//               Selected Range: {secondsToMinSec(range[0])} -{" "}
//               {secondsToMinSec(range[1])} (Max {MAX_RANGE_DURATION} seconds)
//               {duration > 0 && ` (Total: ${secondsToMinSec(duration)})`}
//             </p>

//             {/* Slider Styles */}
//             <style>
//               {`
//                 .custom-slider {
//                   --range-slider-track-height: 10px;
//                   --range-slider-thumb-size: 18px;
//                 }

//                 .custom-slider .range-slider__track {
//                   background: linear-gradient(to right, #ff8c00, #ff0000);
//                   height: 10px;
//                   border-radius: 5px;
//                 }

//                 .custom-slider .range-slider__range {
//                   background: linear-gradient(to right, #4caf50, #2196f3);
//                   height: 10px;
//                   border-radius: 5px;
//                 }

//                 .custom-slider .range-slider__thumb {
//                   background: radial-gradient(circle, #ffffff, #ddd);
//                   width: 18px;
//                   height: 18px;
//                   border-radius: 50%;
//                   border: 2px solid #fff;
//                   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
//                   transition: transform 0.2s;
//                 }

//                 .custom-slider .range-slider__thumb[data-active] {
//                   transform: scale(1.2);
//                 }

//                 .custom-slider .range-slider__thumb[data-lower] {
//                   background: radial-gradient(circle, #ff5722, #ff8c00);
//                 }

//                 .custom-slider .range-slider__thumb[data-upper] {
//                   background: radial-gradient(circle, #2196f3, #4caf50);
//                 }
//               `}
//             </style>
//           </div>
//           <div style={{ textAlign: "center" }}>
//             <button
//               onClick={handlePlay}
//               disabled={isPlaying || !duration}
//               className="modalBtnSave"
//             >
//               Play
//             </button>
//             <button
//               onClick={handlePause}
//               disabled={!isPlaying}
//               style={{
//                 marginLeft: "10px",
//                 backgroundColor: "gray",
//                 color: "white",
//                 borderRadius: "30px",
//                 height: "35px",
//               }}
//             >
//               Pause
//             </button>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default AudioRangePlayer;

import React, { useRef, useState, useEffect } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import WaveSurfer from "wavesurfer.js";

const AudioRangePlayer = ({ audioSrc, onRangeChange }) => {
  const MAX_RANGE_DURATION = 15; // 15 seconds maximum selection
  const [range, setRange] = useState([0, MAX_RANGE_DURATION]);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const intervalRef = useRef(null);

  // Initialize WaveSurfer
  useEffect(() => {
    if (!waveformRef.current || !audioSrc) return;

    wavesurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#6d07a8",
      progressColor: "#555",
      cursorColor: "transparent",
      barWidth: 5,
      height: 20,
      responsive: true,
    });

    wavesurferRef.current.load(audioSrc);

    wavesurferRef.current.on("ready", () => {
      const totalDuration = wavesurferRef.current.getDuration();
      setDuration(totalDuration);
      setRange([0, Math.min(MAX_RANGE_DURATION, totalDuration)]);
    });

    wavesurferRef.current.on("finish", () => {
      setIsPlaying(false);
      clearInterval(intervalRef.current);
    });

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
      clearInterval(intervalRef.current);
    };
  }, [audioSrc]);

  // Handle range change with 15-second constraint
  const handleRangeChange = (newRange) => {
    const [start, end] = newRange;

    // Determine which thumb is being moved
    const isMovingStart = Math.abs(start - range[0]) > Math.abs(end - range[1]);

    if (isMovingStart) {
      // When moving start thumb, adjust end to maintain max 15s duration
      const newEnd = Math.min(start + MAX_RANGE_DURATION, duration);
      const validatedStart = Math.max(0, Math.min(start, duration - 0.1));

      setRange([validatedStart, newEnd]);
      if (onRangeChange) onRangeChange([validatedStart, newEnd]);
    } else {
      // When moving end thumb, adjust start if needed to maintain max 15s duration
      const newStart = Math.max(0, end - MAX_RANGE_DURATION);
      const validatedEnd = Math.min(duration, Math.max(end, newStart + 0.1));

      // If we needed to adjust the start position
      if (validatedEnd - newStart > MAX_RANGE_DURATION) {
        setRange([newStart, newStart + MAX_RANGE_DURATION]);
        if (onRangeChange)
          onRangeChange([newStart, newStart + MAX_RANGE_DURATION]);
      } else {
        setRange([newStart, validatedEnd]);
        if (onRangeChange) onRangeChange([newStart, validatedEnd]);
      }
    }
  };

  // Play the selected range
  const handlePlay = () => {
    if (!wavesurferRef.current) return;

    wavesurferRef.current.setTime(range[0]);
    wavesurferRef.current.play();
    setIsPlaying(true);

    intervalRef.current = setInterval(() => {
      if (!wavesurferRef.current) return;

      const currentTime = wavesurferRef.current.getCurrentTime();
      if (currentTime >= range[1] || currentTime >= duration) {
        handlePause();
      }
    }, 100);
  };

  // Pause the audio
  const handlePause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.pause();
    }
    setIsPlaying(false);
    clearInterval(intervalRef.current);
  };

  const secondsToMinSec = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <div>
      {audioSrc && (
        <>
          <div
            ref={waveformRef}
            style={{ maxWidth: "500px", margin: "auto" }}
          />

          <div
            style={{
              maxWidth: "500px",
              textAlign: "center",
              margin: "auto",
              marginTop: "10px",
            }}
          >
            <RangeSlider
              min={0}
              max={duration || MAX_RANGE_DURATION}
              step={0.1}
              value={range}
              className="custom-slider"
              onInput={handleRangeChange}
              style={{ marginTop: "10px" }}
              disabled={!duration}
            />
            <p style={{ marginTop: "10px" }}>
              Selected Range: {secondsToMinSec(range[0])} -{" "}
              {secondsToMinSec(range[1])} (Max {MAX_RANGE_DURATION} seconds)
              {duration > 0 && ` (Total: ${secondsToMinSec(duration)})`}
            </p>

            {/* Slider Styles */}
            <style>
              {`
                .custom-slider {
                  --range-slider-track-height: 10px;
                  --range-slider-thumb-size: 18px;
                }

                .custom-slider .range-slider__track {
                  background: linear-gradient(to right, #ff8c00, #ff0000);
                  height: 10px;
                  border-radius: 5px;
                }

                .custom-slider .range-slider__range {
                  background: linear-gradient(to right, #4caf50, #2196f3);
                  height: 10px;
                  border-radius: 5px;
                }

                .custom-slider .range-slider__thumb {
                  background: radial-gradient(circle, #ffffff, #ddd);
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                  border: 2px solid #fff;
                  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                  transition: transform 0.2s;
                }

                .custom-slider .range-slider__thumb[data-active] {
                  transform: scale(1.2);
                }

                .custom-slider .range-slider__thumb[data-lower] {
                  background: radial-gradient(circle, #ff5722, #ff8c00);
                }

                .custom-slider .range-slider__thumb[data-upper] {
                  background: radial-gradient(circle, #2196f3, #4caf50);
                }
              `}
            </style>
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={handlePlay}
              disabled={isPlaying}
              style={{
                padding: "10px 20px",
                backgroundColor: isPlaying ?  "#bdbdbd":"#6200ea",
                color: "white",
                border: "none",
                borderRadius: "30px",
                cursor: "pointer",
                fontSize: "16px",
                marginRight: "10px",
              }}
            >
              Play
            </button>
            <button
              onClick={handlePause}
              disabled={!isPlaying}
              style={{
                padding: "10px 20px",
                backgroundColor: isPlaying ? "#d32f2f" : "#bdbdbd",
                color: "white",
                border: "none",
                borderRadius: "30px",
                cursor: isPlaying ? "pointer" : "not-allowed",
                fontSize: "16px",
              }}
            >
              Pause
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AudioRangePlayer;
