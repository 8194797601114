import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  errorHandler,
  isPermissionToAccess,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import {
  createShoutOut,
  editShoutOut,
  getAllShoutOut,
} from "../../http-calls/index";
import CreateShoutOut from "../CreateShoutOut";

const SettingsShoutOut = () => {
  const influencer = useSelector((state) => state?.userData?.user);
  // const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    shoutOutDescription: "",
    shoutOutProfilePicture: {
      previewBlob: "",
      uploadData: null,
      uploadUrl: "",
    },
  });
  const [shoutOutPricings, setShoutOutPricings] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  // const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  // const [cropModalContent, setCropModalContent] = useState(false);
  // const [isShoutOutPicUploading, setIsShoutOutPicUploading] = useState(false);
  // const [isDirty, setIsDirty] = useState({});
  // const [errors, setErrors] = useState({});
  // const [isLoading, setIsLoading] = useState(false);

  const canUpdateShoutoutSettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateShoutoutSettings",
        true
      ),
    []
  );

  // toggle image crop modal
  // const _toggleCropModal = (value) => {
  //   setIsCropModalOpen(value);
  // };

  // reset previewBlob and use url if available
  // const _resetPhoto = () => {
  //   const newFormFields = { ...formFields };

  //   newFormFields["shoutOutProfilePicture"] = {
  //     uploadData: null,
  //     previewBlob: null,
  //     uploadUrl: influencer?.shoutOutProfilePicture?.length
  //       ? influencer?.shoutOutProfilePicture
  //       : "",
  //   };

  //   setFormFields(newFormFields);
  // };

  // make api call then udpate latest data in redux
  // const _udpateInfluencerDetails = (payload) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const res = await updateInfluencerDetails(payload);
  //       dispatch(updateOnlyUserData(res.user));
  //       resolve();
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // };

  // upload picture api call, type: profilePicture/coverPicture/carousel
  // const _uploadShoutOutProfilePicture = async (pictureData) => {
  //   try {
  //     setIsShoutOutPicUploading(true);

  //     const serverResponse = await uploadFileOnServer(pictureData);

  //     const payload = {
  //       shoutOutProfilePicture: serverResponse?.[0]?.url,
  //     };

  //     await _udpateInfluencerDetails(payload);

  //     setIsShoutOutPicUploading(false);
  //     showToast(getConfiguredString("shoutout Picture Updated"), "success");
  //   } catch (error) {
  //     setIsShoutOutPicUploading(false);
  //     errorHandler(error);
  //   }
  // };

  // recieve cropped photo and make upload file api call
  // const _saveCroppedPhoto = async (croppedImageUrl, croppedImageBlob) => {
  //   const payload = [
  //     {
  //       previewBlob: croppedImageUrl,
  //       uploadData: croppedImageBlob,
  //     },
  //   ];

  //   try {
  //     const newFormFields = { ...formFields };
  //     newFormFields.shoutOutProfilePicture.previewBlob = croppedImageUrl;
  //     setFormFields(newFormFields);
  //   } catch (error) {
  //     console.log({ error });
  //   }

  //   setIsCropModalOpen(false);
  //   _uploadShoutOutProfilePicture(payload);
  // };

  // function to handle change in file input
  // const _fileChangeHandler = async (e) => {
  //   try {
  //     const file = e.target.files?.[0];
  //     if (!file) return;
  //     const fileType = file.type.split("/")?.[0];

  //     if (fileType !== "image") {
  //       return errorHandler({ reason: "File type must be an image!!" });
  //     }

  //     const newFormFields = { ...formFields };
  //     newFormFields["shoutOutProfilePicture"] = {
  //       previewBlob: URL.createObjectURL(e.target.files[0]),
  //       uploadData: e.target.files[0],
  //     };

  //     setFormFields(newFormFields);
  //     setCropModalContent(file);
  //     setIsCropModalOpen(true);
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };

  // formfields validation
  // const _validateFormFields = ({ newFormFields, newIsDirty }) => {
  //   const newErrors = {};
  //   return new Promise((resolve) => {
  //     let isFormValid = true;
  //     Object.keys(newFormFields).forEach((key) => {
  //       if (!newIsDirty[key]) return;
  //       switch (key) {
  //         case "shoutOutDescription": {
  //           if (newFormFields?.[key]?.length) {
  //             if (
  //               newFormFields?.[key].trim().length >
  //                 DescriptionConfig.minLetter &&
  //               newFormFields?.[key].trim().length < DescriptionConfig.maxLetter
  //             ) {
  //               newIsDirty[key] = false;
  //               newErrors[key] = null;
  //             } else {
  //               newErrors[
  //                 key
  //               ] = `*Description should have minimum ${DescriptionConfig.minLetter} characters & maximum ${DescriptionConfig.maxLetter} characters`;
  //               isFormValid = false;
  //             }
  //           }
  //           break;
  //         }
  //         default:
  //       }
  //     });

  //     setIsDirty(newIsDirty);
  //     setErrors(newErrors);

  //     resolve(isFormValid);
  //   });
  // };

  // on fields blur, update their dirty true then validate
  // const _onBlurHandler = (key) => {
  //   let newFormFields = { ...formFields };
  //   let newIsDirty = { ...isDirty };
  //   newIsDirty[key] = true;
  //   setIsDirty(newIsDirty);

  //   _validateFormFields({ newFormFields, newIsDirty });
  // };

  // update field value onChange
  // const _updateFieldValue = (key, value) => {
  //   let newFormFields = { ...formFields };
  //   newFormFields[key] = value;
  //   setFormFields(newFormFields);
  // };

  // marking all formfields dirty
  // const _markAllIsDirty = () => {
  //   return new Promise((resolve) => {
  //     const newIsDirty = { ...isDirty };
  //     const newFormFields = { ...formFields };
  //     Object.keys(newFormFields).forEach((key) => {
  //       newIsDirty[key] = true;
  //     });
  //     setIsDirty(newIsDirty);
  //     resolve(newIsDirty);
  //   });
  // };

  // handle submission of shoutOut description
  // const _handleSubmit = async () => {
  //   try {
  //     setIsLoading(true);
  //     const newFormFields = { ...formFields };
  //     const newIsDirty = await _markAllIsDirty();
  //     const isFormValid = await _validateFormFields({
  //       newFormFields,
  //       newIsDirty,
  //     });

  //     if (!isFormValid) {
  //       setIsLoading(false);
  //       return errorHandler({ reason: "Please enter the data correctly" });
  //     }

  //     const payload = {
  //       shoutOutDescription: newFormFields.shoutOutDescription,
  //     };

  //     await _udpateInfluencerDetails(payload);
  //     showToast(getConfiguredString("shoutout updated"), "success");
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     errorHandler(error);
  //   }
  // };

  const _createOrUpdateShoutOut = async (data) => {
    try {
      if (data?.id) {
        await editShoutOut(data);
        await _getAllShoutOut();
        showToast("Updated successfully", "success");
      } else {
        await createShoutOut(data);
        await _getAllShoutOut();
        showToast("Created successfully", "success");
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
      errorHandler(error);
    }
  };

  const _makeMediaReady = async (apiData, type) => {
    try {
      const payload = {
        ...apiData,
        contents: [],
      };

      delete payload.containes;

      setSaveLoading(type);

      const uploadFiles = apiData?.containes?.length
        ? apiData.containes
            .filter((each) => each.uploadData)
            .map((each) => ({
              uploadData: each.uploadData,
              previewBlob: each.previewBlob,
              index: each.index,
            }))
        : [];

      const uploadedFiles = apiData?.containes?.length
        ? apiData.containes
            .filter((each) => each.uploadUrl && !each.uploadData)
            .map((each) => ({
              url: each.url,
              id: each?.id,
              orderNo: each?.index + 1,
            }))
        : [];

      uploadedFiles?.forEach((each) => {
        payload.contents.push(each);
      });

      if (uploadFiles?.length) {
        const uploadFilesRes = await uploadFileOnServer(uploadFiles);

        uploadFilesRes?.forEach((each) => {
          payload.contents.push({ url: each.url, orderNo: each?.index + 1 });
        });
      }

      _createOrUpdateShoutOut(payload);
    } catch (error) {
      errorHandler(error);
      setSaveLoading(false);
    }
  };

  const _preFillData = () => {
    const newFormFields = { ...formFields };
    newFormFields["shoutOutDescription"] = influencer?.shoutOutDescription
      ?.length
      ? influencer?.shoutOutDescription
      : "";

    newFormFields["shoutOutProfilePicture"] = {
      previewBlob: "",
      uploadData: null,
      uploadUrl: influencer?.shoutOutProfilePicture?.length
        ? influencer?.shoutOutProfilePicture
        : "",
    };
    setFormFields(newFormFields);
  };

  // get all shoutOut with their prices and description
  const _getAllShoutOut = async () => {
    try {
      const res = await getAllShoutOut();
      setShoutOutPricings(
        res?.shoutOutPricings?.length ? res?.shoutOutPricings : []
      );
    } catch (error) {
      setShoutOutPricings([]);
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getAllShoutOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (influencer) {
      _preFillData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencer]);

  return (
    <>
      <div className="settingOptionsWrap">
        {/* <div className="d-flex my-3">
          <div className="img100by100Wrap">
            <img
              src={
                getLowResolutionLink(
                  formFields?.shoutOutProfilePicture?.uploadUrl
                ) ||
                formFields?.shoutOutProfilePicture?.previewBlob ||
                DEFAULT_PROFILE_PICTURE
              }
              onError={(e) =>
                (e.target.src =
                  getLowResolutionLink(influencer?.shoutOutProfilePicture) ||
                  DEFAULT_PROFILE_PICTURE)
              }
              alt="Profile Img"
              className="img100by100"
              loading="lazy"
            />

            {canUpdateShoutoutSettings ? (
              <Label
                className="btn editProfileImg"
                style={{ bottom: -5, right: 5 }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => _fileChangeHandler(e, "profilePicture")}
                  style={{ display: "none" }}
                  disabled={isShoutOutPicUploading}
                />
                {isShoutOutPicUploading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <i className="fa fa-pencil" />
                )}
              </Label>
            ) : null}
          </div>

          <div className="w-100">
            <Input
              type="textarea"
              name="description"
              rows="4"
              autoFocus={true}
              placeholder="Enter description"
              value={formFields.shoutOutDescription}
              disabled={!canUpdateShoutoutSettings}
              onChange={(e) =>
                _updateFieldValue("shoutOutDescription", e.target.value)
              }
              onBlur={() => _onBlurHandler("shoutOutDescription")}
              // **Note: the Intro text should have minimum 300 characters & maximum 500 characters
            />
            {errors?.shoutOutDescription ? (
              <div className="form-error">{errors?.shoutOutDescription}</div>
            ) : null}
          </div>
        </div> */}

        {/* {canUpdateShoutoutSettings ? (
          <Button
            className="themeBtn addBtn d-block mx-auto"
            onClick={() => _handleSubmit()}
            disabled={isLoading}
          >
            {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Save
          </Button>
        ) : null} */}

        {/* <hr style={{ borderWidth: 2, borderColor: "#E0E0E0" }} /> */}

        <CreateShoutOut
          canUpdateShoutoutSettings={canUpdateShoutoutSettings}
          title="Audio"
          type="audio"
          mediaType="audio"
          durationType="second"
          shoutOutPricings={
            shoutOutPricings &&
            shoutOutPricings.length &&
            shoutOutPricings.filter((shoutOut) => shoutOut.type === "audio")[0]
          }
          onSave={(data, type) => _makeMediaReady(data, type)}
          saveLoading={saveLoading}
        />

        <hr style={{ borderWidth: 2, borderColor: "#E0E0E0" }} />

        <CreateShoutOut
          canUpdateShoutoutSettings={canUpdateShoutoutSettings}
          title="Live Audio Call"
          mediaType="audio"
          type="liveaudio"
          durationType="minute"
          shoutOutPricings={
            shoutOutPricings &&
            shoutOutPricings.length &&
            shoutOutPricings.filter(
              (shoutOut) => shoutOut.type === "liveaudio"
            )[0]
          }
          onSave={(data, type) => _makeMediaReady(data, type)}
          saveLoading={saveLoading}
        />

        <hr style={{ borderWidth: 2, borderColor: "#E0E0E0" }} />

        <CreateShoutOut
          canUpdateShoutoutSettings={canUpdateShoutoutSettings}
          title="Video"
          type="video"
          mediaType="video"
          durationType="second"
          shoutOutPricings={
            shoutOutPricings &&
            shoutOutPricings.length &&
            shoutOutPricings.filter((shoutOut) => shoutOut.type === "video")[0]
          }
          onSave={(data, type) => _makeMediaReady(data, type)}
          saveLoading={saveLoading}
        />

        <hr style={{ borderWidth: 2, borderColor: "#E0E0E0" }} />

        <CreateShoutOut
          canUpdateShoutoutSettings={canUpdateShoutoutSettings}
          title="Live Video Call"
          mediaType="video"
          type="live"
          durationType="minute"
          shoutOutPricings={
            shoutOutPricings &&
            shoutOutPricings.length &&
            shoutOutPricings.filter((shoutOut) => shoutOut.type === "live")[0]
          }
          onSave={(data, type) => _makeMediaReady(data, type)}
          saveLoading={saveLoading}
        />
      </div>

      {/* <ImageCropUploaderModal
        isOpen={isCropModalOpen}
        resetPhoto={() => _resetPhoto()}
        selectedPhoto={cropModalContent}
        onSuccess={_saveCroppedPhoto}
        onDismiss={() => _toggleCropModal(false)}
      /> */}
    </>
  );
};

export default SettingsShoutOut;
