import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import {
  formatCurrencyValue,
  getLowResolutionLink,
  getFullName,
  replaceTipText,
} from "../../helper-methods";
import moment from "moment";
import { DEFAULT_PROFILE_PICTURE } from "../../config/index";
import { useHistory } from "react-router-dom";

const PostEarningsModal = ({ isVisible, tipArr, ppvArr, close }) => {
  const history = useHistory();

  const _calculateTotalTipAmount = () => {
    if (tipArr?.length) {
      let totalTipsAmt = tipArr.reduce((acc, each) => {
        return (acc = acc + each.amount);
      }, 0);
      return totalTipsAmt || totalTipsAmt === 0
        ? formatCurrencyValue(totalTipsAmt)
        : formatCurrencyValue(0);
    } else {
      return formatCurrencyValue(0);
    }
  };

  const _calculateTotalPPVAmount = () => {
    if (ppvArr?.length) {
      let totalAmt = ppvArr.reduce((acc, each) => {
        return (acc = acc + each.amount);
      }, 0);
      return totalAmt || totalAmt === 0
        ? formatCurrencyValue(totalAmt)
        : formatCurrencyValue(0);
    } else {
      return formatCurrencyValue(0);
    }
  };

  const _rediretToCreatorFanList = (earning) => {
    const username = earning?._from?.username;
    history.push(`/my-profile?tabId=4&username=${username}`);
  };

  return (
    <Modal
      isOpen={isVisible}
      toggle={() => close()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => close()}>Post Earnings</ModalHeader>
      <ModalBody>
        {isVisible ? (
          <>
            <h4 className="postEarningHeader">
              {replaceTipText("tip")}:{" "}
              <span className="themeColor">{_calculateTotalTipAmount()}</span>
            </h4>

            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {tipArr?.length ? (
                  tipArr.map((tip, tipIndex) => (
                    <tr key={tipIndex}>
                      <td>
                        <div
                          className="d-flex justify-content-start align-items-center"
                          onClick={() => _rediretToCreatorFanList(tip)}
                        >
                          <div className="fansImgWrap">
                            <img
                              className="fansImg"
                              src={
                                getLowResolutionLink(
                                  tip?._from?.profilePicture
                                ) || DEFAULT_PROFILE_PICTURE
                              }
                              onError={(e) =>
                                (e.target.src = DEFAULT_PROFILE_PICTURE)
                              }
                              alt="Profile Thumbnail"
                              loading="lazy"
                            />
                          </div>
                          {getFullName(tip?._from?.name)}
                        </div>
                      </td>

                      <td>{moment(tip?.time).format("DD MMM, YYYY LT")}</td>
                      <td>
                        {tip?.amount || tip?.amount === 0
                          ? formatCurrencyValue(tip.amount)
                          : "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan="3">{replaceTipText("No tip yet")}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <hr />

            {/* For PPV */}
            <h4 className="postEarningHeader mt-4">
              Earnings: <span>{_calculateTotalPPVAmount()}</span>
            </h4>

            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  {/* <th>Type</th> */}
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {ppvArr?.length ? (
                  ppvArr.map((ppv, ppvIndex) => (
                    <tr key={ppvIndex}>
                      <td>
                        <div
                          className="d-flex justify-content-start align-items-center"
                          onClick={() => _rediretToCreatorFanList(ppv)}
                        >
                          <div className="fansImgWrap">
                            <img
                              className="fansImg"
                              src={
                                getLowResolutionLink(
                                  ppv?._from?.profilePicture
                                ) || DEFAULT_PROFILE_PICTURE
                              }
                              onError={(e) =>
                                (e.target.src = DEFAULT_PROFILE_PICTURE)
                              }
                              alt="Profile Thumbnail"
                              loading="lazy"
                            />
                          </div>
                          {getFullName(ppv?._from?.name)}
                        </div>
                      </td>

                      <td>{moment(ppv.time).format("DD MMM, YYYY LT")}</td>
                      {/* <td>{capitalize(ppv.paymentType)}</td> */}
                      <td>
                        {ppv && (ppv.amount || ppv.amount === 0)
                          ? formatCurrencyValue(ppv.amount)
                          : "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan="3">No Unlock Yet</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button type="button" className="modalBtnSave" onClick={() => close()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PostEarningsModal;
