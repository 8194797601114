import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  FormGroup,
  Label,
  Popover,
  PopoverBody,
  Collapse,
  Input,
} from "reactstrap";
import {
  deepClone,
  formatCurrencyValue,
  errorHandler,
  getWindowDimensions,
  formatDateAndTime,
  isPermissionToAccess,
  showToast,
  getFullName,
} from "../helper-methods";
import { deleteMassMessage, fetchBroadCastStats } from "../http-calls";
import { DEFAULT_COVER_PICTURE } from "../config";
import BroadcastMessageStatMobileView from "../components/BroadcastMessageStatMobileView";
import CustomDateRangePicker from "../components/CustomDateRangePicker";
import { useHistory } from "react-router-dom";
import CustomLoader from "../components/custom/CustomLoader";
import SkeletonLoading from "../components/SkeletonLoading";
import Swal from "sweetalert2";
import colors from "../assets/styles/scss/style.scss";
import MultiMediaModal from "../components/modals/MultiMediaModal";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import moment from "moment";

const BroadcastMessageStats = () => {
  const history = useHistory();

  const [broadcast, setBroadcast] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    mediaType: "",
    startDate: null,
    endDate: null,
  });
  const [isAllUsersPopOverVisible, setIsAllUsersPopOverVisible] =
    useState(false);
  const [isPurchasedUsersPopOverVisible, setIsPurchasedUsersPopOverVisible] =
    useState(false);
  const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);
  const [multiMediaModal, setMultiMediaModal] = useState({
    isOpen: false,
    contents: null,
    activeIndex: null,
  });

  const _toggleMultiMediaModal = (
    isOpen = false,
    contents = null,
    activeIndex = 0
  ) => {
    setMultiMediaModal({
      isOpen,
      contents,
      activeIndex,
    });
  };

  const _fetchBroadCastStats = async () => {
    try {
      setLoading(true);

      const res = await fetchBroadCastStats();

      setBroadcast(res.broadcast?.length ? res.broadcast : []);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const filteredBroadcast = useMemo(() => {
    let newBroadcast = deepClone([...broadcast]);

    newBroadcast = newBroadcast.reverse();

    if (filters.startDate && filters.endDate) {
      const startDate = moment(filters.startDate).startOf("day");
      const endDate = moment(filters.endDate).startOf("day");

      newBroadcast = newBroadcast.filter((each) => {
        const messageDate = moment(each.createdAt).startOf("day");
        return (
          messageDate.isSameOrAfter(startDate) &&
          messageDate.isSameOrBefore(endDate)
        );
      });
    }
    if (filters.mediaType) {
      newBroadcast = newBroadcast.filter(
        (each) => each?._messages?.content?.[0]?.mediaType === filters.mediaType
      );
    }

    return newBroadcast;
  }, [broadcast, filters]);

  // handles change in creator filter or payment type filter change
  const _onChangeFilters = (key, value) => {
    let newFilters = deepClone(filters);
    newFilters[key] = value;
    setFilters(newFilters);
  };

  // handles date change
  const _onDatesChange = ({ startDate, endDate }) => {
    const newFilters = { ...filters };
    newFilters.startDate = startDate?._d ? startDate.toISOString() : null;
    newFilters.endDate = endDate?._d ? endDate.toISOString() : null;
    setFilters(newFilters);
  };

  const _resetFilter = () => {
    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);
  };

  const _getMediaPreview = (stats) => {
    if (stats?._messages?.content?.[0]) {
      switch (stats?._messages?.content?.[0]?.mediaType) {
        case "image": {
          return (
            <div className="mediaPreview">
              <img
                src={
                  stats?._messages?.content?.[0]?._contentId?.url ||
                  DEFAULT_COVER_PICTURE
                }
                alt="Img"
                loading="lazy"
              />
            </div>
          );
        }
        case "audio": {
          return (
            <div className="mediaPreview">
              <img
                style={{ objectFit: "contain", width: 21, height: 30 }}
                src={"/assets/img/microphone.png"}
                alt="Audio Img"
                loading="lazy"
              />
            </div>
          );
        }
        case "video": {
          return (
            <div className="mediaPreview">
              <img
                src={
                  stats?._messages?.content?.[0]?.thumbnail ||
                  DEFAULT_COVER_PICTURE
                }
                alt="Cover Img"
                loading="lazy"
              />
            </div>
          );
        }
        default:
      }
    }
    return;
  };

  const _toggleAllUsersPopOver = (isOpen = false) => {
    if (isOpen !== isAllUsersPopOverVisible) {
      setIsAllUsersPopOverVisible(isOpen);
    } else {
      setIsAllUsersPopOverVisible(false);
    }
  };

  const _togglePurchasedUsersPopOver = (isOpen = false) => {
    if (isOpen !== isPurchasedUsersPopOverVisible) {
      setIsPurchasedUsersPopOverVisible(isOpen);
    } else {
      setIsPurchasedUsersPopOverVisible(false);
    }
  };

  const _deleteMassMessage = async (broadCastId) => {
    try {
      const newBroadcast = deepClone([...broadcast]);

      const findIndex = newBroadcast.findIndex(
        (each) => each.broadCastId === broadCastId
      );

      if (findIndex === -1) {
        showToast("Message not found.", "error");
        return;
      }

      newBroadcast.splice(findIndex, 1);
      setBroadcast(newBroadcast);

      await deleteMassMessage(broadCastId);

      Swal.fire("Deleted!", "Mass Message has been deleted.", "success");
    } catch (error) {
      errorHandler(error);
      _fetchBroadCastStats();
    }
  };

  const _deleteMassMessageAlert = (message) => {
    if (!message?.broadCastId) {
      showToast("Message not found.", "error");
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this mass message. You will not recover it.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors?.themeColor,
      cancelButtonColor: "#ccc",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteMassMessage(message.broadCastId);
      }
    });
  };

  useEffect(() => {
    if (!isPermissionToAccess("messaging", "canViewMessaging")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    _fetchBroadCastStats();

    const { screenWidth } = getWindowDimensions();

    if (screenWidth > 768) {
      setIsOpenFilterCollapse(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container className="noPadding">
          <Row className="noMargin">
            <Col sm={12} className="pgPadding">
              <div className="pgTitle justify-content-start">
                <Button
                  className="customBackBtn"
                  onClick={() => history.replace("/messages")}
                >
                  <i className="fa fa-chevron-left" />
                </Button>

                <h2>Mass Message Statistics</h2>
                {loading ? <CustomLoader className="ml-2" /> : null}

                <Button
                  onClick={() => setIsOpenFilterCollapse((prev) => !prev)}
                  className="toggleBtn ml-auto"
                >
                  <img
                    src="/assets/img/filter-icon.png"
                    alt="Filter Icon"
                    height="24"
                    loading="lazy"
                  />
                </Button>
              </div>

              <Collapse isOpen={isOpenFilterCollapse}>
                <div className="customFilterWrap px-0 px-sm-2">
                  <FormGroup className="dateRange">
                    <div className="d-flex">
                      <Label className="w-50 pl-1">From</Label>
                      <Label className="w-50 pl-3">To</Label>
                    </div>
                    <CustomDateRangePicker
                      startDate={filters?.startDate}
                      endDate={filters?.endDate}
                      startDateId={`startDate_broadcast_message_stats`}
                      endDateId={`endDate_broadcast_message_stats`}
                      onDatesChange={({ startDate, endDate }) =>
                        _onDatesChange({
                          startDate,
                          endDate,
                        })
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Type</Label>
                    <Input
                      type="select"
                      style={{ minWidth: 120 }}
                      name=""
                      value={filters.mediaType}
                      onChange={(e) =>
                        _onChangeFilters("mediaType", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      <option value="image">Image</option>
                      <option value="video">Video</option>
                      <option value="audio">Audio</option>
                    </Input>
                  </FormGroup>

                  <Button
                    className="themeBtn addBtn mt-3 mt-md-0 mx-auto mx-md-1"
                    onClick={() => _resetFilter()}
                  >
                    Reset
                  </Button>
                </div>
              </Collapse>

              {/* 768px & above the below table will be visible */}
              <div className="referralHistoryTable bg-white mb-3 massMsgStatsTable">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Date & Time</th>
                      <th>Text</th>
                      <th>Attachment</th>
                      <th>Price</th>
                      <th>Sent</th>
                      <th>Purchased</th>
                      <th>Earned</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBroadcast?.length ? (
                      filteredBroadcast.map((each) => (
                        <tr key={each._id}>
                          <td>
                            <div style={{ minWidth: 120 }}>
                              {formatDateAndTime(each.createdAt)}
                            </div>
                          </td>

                          <td>
                            {each?._messages?.text?.trim() ? (
                              <ReadMoreReadLess
                                text={each?._messages?.text?.trim()}
                                btnClassName={"py-0 px-1 themeColor"}
                                inlineStyle={{
                                  verticalAlign: 0,
                                  textDecoration: "none",
                                }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>

                          <td>
                            {each?._messages?.content?.length ? (
                              <div
                                onClick={() =>
                                  _toggleMultiMediaModal(
                                    true,
                                    each?._messages?.content
                                  )
                                }
                                style={{ minWidth: 125 }}
                              >
                                <span>
                                  {`${each?._messages?.content?.length} Media ${
                                    each?._messages?.content?.length > 1
                                      ? "Files"
                                      : "File"
                                  }`}
                                </span>
                                {_getMediaPreview(each)}
                              </div>
                            ) : (
                              "No Media"
                            )}
                          </td>

                          <td>
                            {/* get formatted price in currency {CURRENCY_SYMBOL} */}
                            {formatCurrencyValue(
                              each?._messages?.content?.[0]?.amount || 0
                            )}
                          </td>
                          <td>
                            {/* a popover with the list of names is to be shown under "Sent & Purchased" Field */}
                            <span
                              className="cursorPointer themeColor"
                              id={`Popover_isAllUsersPopOverVisible_${each._id}`}
                              onClick={() =>
                                each?._userList?.length
                                  ? _toggleAllUsersPopOver(each._id)
                                  : {}
                              }
                            >
                              {each?._messagesCount || 0}
                            </span>
                            {each?._userList?.length ? (
                              <Popover
                                trigger="legacy"
                                placement="bottom"
                                isOpen={isAllUsersPopOverVisible === each._id}
                                target={`Popover_isAllUsersPopOverVisible_${each._id}`}
                                toggle={() => _toggleAllUsersPopOver()}
                                onClick={() => _toggleAllUsersPopOver()}
                              >
                                <PopoverBody>
                                  <div className="listInPopover">
                                    {each._userList.map((user, userIndex) => (
                                      <p key={userIndex}>
                                        {getFullName(user?._to?.name)}
                                      </p>
                                    ))}
                                  </div>
                                </PopoverBody>
                              </Popover>
                            ) : null}
                          </td>
                          <td>
                            {/* a popover with the list of names is to be shown under "Sent & Purchased" Field */}
                            <span
                              className="cursorPointer themeColor"
                              id={`Popover_isPurchasedUsersPopOverVisible_${each._id}`}
                              onClick={() =>
                                each?._purchased?.length
                                  ? _togglePurchasedUsersPopOver(each._id)
                                  : {}
                              }
                            >
                              {each?._purchased?.length || 0}
                            </span>
                            {each?._purchased?.length ? (
                              <Popover
                                trigger="legacy"
                                placement="bottom"
                                isOpen={
                                  isPurchasedUsersPopOverVisible === each._id
                                }
                                target={`Popover_isPurchasedUsersPopOverVisible_${each._id}`}
                                toggle={() => _togglePurchasedUsersPopOver()}
                                onClick={() => _togglePurchasedUsersPopOver()}
                              >
                                <PopoverBody>
                                  <div className="listInPopover">
                                    {each._purchased.map((user, userIndex) => (
                                      <p key={userIndex}>
                                        {getFullName(user?._to?.name)}
                                      </p>
                                    ))}
                                  </div>
                                </PopoverBody>
                              </Popover>
                            ) : null}
                          </td>
                          <td>
                            {each?._messages?.content?.[0]?.amount &&
                            each._purchased?.length
                              ? formatCurrencyValue(
                                  each?._messages?.content?.[0]?.amount *
                                    each._purchased?.length
                                )
                              : formatCurrencyValue(0)}
                          </td>
                          <td>
                            <Button
                              title="Delete"
                              className="deleteButton"
                              color="link"
                              onClick={() => _deleteMassMessageAlert(each)}
                            >
                              <i className="fa fa-trash-o" />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : loading ? (
                      <SkeletonLoading
                        type={"table"}
                        rows={1}
                        col={8}
                        width={50}
                      />
                    ) : (
                      <tr>
                        <td colSpan="7">
                          <div className="noContentFound">
                            Currently no stats available{" "}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          {/* 767px & below, the below card(s) will be visible */}
          <Row className="msgStatsMob d-md-none">
            {filteredBroadcast?.length ? (
              filteredBroadcast.map((each) => (
                <Fragment key={each._id}>
                  <BroadcastMessageStatMobileView
                    data={each}
                    isAllUsersPopOverVisible={isAllUsersPopOverVisible}
                    isPurchasedUsersPopOverVisible={
                      isPurchasedUsersPopOverVisible
                    }
                    togglePurchasedUsersPopOver={_togglePurchasedUsersPopOver}
                    toggleAllUsersPopOver={_toggleAllUsersPopOver}
                    getMediaPreview={_getMediaPreview}
                    deleteMassMessageAlert={_deleteMassMessageAlert}
                  />
                </Fragment>
              ))
            ) : loading ? (
              <SkeletonLoading type={"vault"} count={4} />
            ) : (
              <Col xs="12">
                <div className="noContentFound">
                  Currently no stats available
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>

      <MultiMediaModal
        isOpen={multiMediaModal?.isOpen}
        contents={multiMediaModal?.contents}
        activeIndex={multiMediaModal.activeIndex}
        toggle={() => _toggleMultiMediaModal()}
      />
    </>
  );
};

export default BroadcastMessageStats;
