import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { APP_LOGO, BIO_MAX_LIMIT, PAYMENT_GATEWAY } from "../config";
import { RegexConfig } from "../config/RegexConfig";
import {
  errorHandler,
  getDialCodeFromCountryCode,
  getWindowDimensions,
  logout,
  showToast,
  uploadFileOnServer,
} from "../helper-methods";
import {
  checkAvailability,
  getSettings,
  getUserKycLink,
  saveContents,
  updateProfile,
} from "../http-calls";
import { fetchAndModifiedUserDetails } from "../redux/actions";
import ImageCropUploaderModal from "../components/modals/ImageCropUploaderModal";
import CustomTooltip from "../components/custom/CustomTooltip";
import SignupCoverProfileModal from "../components/modals/SignupCoverProfileModal";
import SignupCoverMediaCarousel from "../components/SignupCoverMediaCarousel";

const SignupProtectedPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [formFields, setFormFields] = useState({
    username: "", // for tab 1
    category: "",
    introduction: "",
  });
  const [profilePicture, setProfilePicture] = useState({
    previewBlob: null,
    uploadData: null,
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [dublicateLoading, setDublicateLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(() => {
    const currentSignupStep = JSON.parse(localStorage.getItem("signupStep"));
    if (currentSignupStep) return currentSignupStep;
    localStorage.setItem("signupStep", JSON.stringify(1));
    return 1;
  });
  const [isIntroductionFieldFocus, setIsIntroductionFieldFocus] =
    useState(false);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [cropModalContent, setCropModalContent] = useState(false);
  const [coverImageModal, setCoverImageModal] = useState({
    isOpen: false,
    data: null,
  });
  const [coverProfileData, setCoverProfileData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(0);

  // toggle image crop modal
  const _toggleCropModal = (value) => {
    setIsCropModalOpen(value);
  };

  // reset previewBlob and use url if available
  const _resetPhoto = () => {
    setProfilePicture({
      uploadData: null,
      previewBlob: null,
    });
  };

  // recieve cropped photo and make upload file api call
  const _saveCroppedPhoto = async (croppedImageUrl, croppedImageBlob) => {
    setProfilePicture({
      uploadData: croppedImageBlob,
      previewBlob: croppedImageUrl,
    });

    setIsCropModalOpen(false);
  };

  const _getSettings = async () => {
    try {
      const res = await getSettings();

      setCategories(
        res.setting?.categories?.length ? res.setting.categories : []
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  /**
   * Check for duplicate username, email, phone
   */
  const _checkDuplicateFields = (key, value) => {
    return new Promise(async (resolve) => {
      setDublicateLoading((prev) => ({
        ...prev,
        [key]: true,
      }));

      const payload = {
        [key]: value,
      };

      checkAvailability(payload)
        .then((res) => {
          resolve([res, null]);
        })
        .catch((error) => {
          resolve([null, error]);
        });
    });
  };

  /**
   * validation or Check for duplicate username, email, phone , validate one at a time
   */
  const _formFieldsApiValidation = ({ newFormFields, newIsDirty, key }) => {
    return new Promise(async (resolve) => {
      const newErrors = {};
      let isFieldValid = true;

      if (newIsDirty[key]) {
        if (newFormFields[key]?.trim().length) {
          if (RegexConfig[key].test(String(newFormFields[key]).toLowerCase())) {
            const phoneCountry = getDialCodeFromCountryCode(
              newFormFields?.countryCode
            );

            const checkingValue =
              key !== "phone"
                ? newFormFields[key].trim()
                : `(${phoneCountry})${newFormFields[key].trim()}`;

            // always resolve
            const [res, resError] = await _checkDuplicateFields(
              key,
              checkingValue
            );

            setDublicateLoading((prev) => ({
              ...prev,
              [key]: false,
            }));

            if (res?.isDuplicateFound || res?.error || resError?.error) {
              newErrors[key] = `${newFormFields[key].trim()} already in use`;
              isFieldValid = false;
            } else {
              newErrors[key] = null;
              newIsDirty[key] = false;
            }
          } else {
            newErrors[key] = `*Invalid ${key}`;
            isFieldValid = false;
          }
        } else {
          newErrors[key] = "*Required";
          isFieldValid = false;
        }
      }

      setErrors((prev) => ({
        ...prev,
        [key]: newErrors[key],
      }));

      setIsDirty((prev) => ({
        ...prev,
        [key]: newIsDirty[key],
      }));

      resolve(isFieldValid);
    });
  };

  const _onChangeProfilePic = (event) => {
    if (!event?.target?.files?.length) {
      return;
    }

    const file = event.target.files[0];
    const fileType = file.type.split("/")[0];

    if (fileType !== "image") {
      showToast("Only image file is allowed", "error");
      return;
    }

    setProfilePicture({
      previewBlob: URL.createObjectURL(event.target.files[0]),
      uploadData: event.target.files[0],
    });

    setCropModalContent(file);
    setIsCropModalOpen(true);

    setProfilePicture({
      previewBlob: URL.createObjectURL(file),
      uploadData: file,
      type: "image",
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    if (key === "introduction") {
      if (value?.length > BIO_MAX_LIMIT) {
        if (newFormFields[key]?.length >= BIO_MAX_LIMIT) return;
        else {
          value =
            newFormFields[key] +
            value.slice(0, BIO_MAX_LIMIT - newFormFields[key]?.length);
        }
      }
    }

    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };

    setIsDirty(newIsDirty);

    if (key === "username") {
      _formFieldsApiValidation({ newFormFields, newIsDirty, key });
    }

    if (key === "category") {
      _categoryFieldsValidation({ newFormFields });
    }
  };

  const _toggleTab = (tab = 1) => {
    // if (tab === 3) {
    //   if (
    //     userData?.user?.paymentEnabled &&
    //     userData?.user?.payoutEnabled &&
    //     userData?.user?.hasBank
    //   ) {
    //     history.replace("/feed");
    //     return;
    //   }
    // }

    if (activeTab !== tab) {
      setActiveTab(tab);
      localStorage.setItem("signupStep", JSON.stringify(tab));
    }
  };

  const _updateProfile = async (payload, forTab) => {
    try {
      setLoading(true);

      await updateProfile(payload);

      _toggleTab(forTab + 1);

      showToast("Profile updated", "success");
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _categoryFieldsValidation = ({ newFormFields }) => {
    let isFormValid = true;
    const newErrors = { ...errors };

    if (!newFormFields?.category) {
      newErrors["category"] = "*Required";
      isFormValid = false;
    } else {
      newErrors["category"] = null;
    }

    setErrors((prev) => ({ ...prev, ...newErrors }));
    return isFormValid;
  };

  const _createTab1Payload = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true);

        const payload = {
          carousel: [],
          carouselCrop: [],
          lastStep: 2, // => user has uploaded cover image
        };

        if (profilePicture?.uploadData) {
          const res = await uploadFileOnServer([{ ...profilePicture }]);
          payload[`profilePicture`] = res?.[0]?.url || "";
        }

        payload.username = formFields?.username?.trim();

        const res = await uploadFileOnServer(coverProfileData);

        // upload files url to mediaLibraryModal
        const newLibraryFiles = await saveContents({ contents: res });

        // create file's payload
        newLibraryFiles?.contents?.forEach((each, index) => {
          let obj = {
            _id: each._id,
            url: each.url,
            contentType: each.contentType,
            hlsLink: each.hls,
            thumbnail: each.thumbnail,
            crop: res?.[index]?.["forKeyName"],
          };

          payload.carousel.push(obj);
        });

        // create crop data in payload
        payload.carousel.forEach((each, index) => {
          if (!isNaN(each.crop?.x) && !isNaN(each.crop?.y)) {
            payload.carouselCrop.push({
              ...each.crop,
              carouselIndex: index,
            });
          }
        });

        resolve(payload);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _onTabSubmit = async (event, forTab, isValidation = true) => {
    try {
      if (event) event.preventDefault();

      const newFormFields = { ...formFields };

      switch (forTab) {
        case 1: {
          if (isValidation) {
            const newErrors = { ...errors };
            if (!coverProfileData?.length) {
              newErrors["coverProfileData"] = "*Required";
              setErrors(newErrors);
              return;
            } else {
              newErrors["coverProfileData"] = null;
              setErrors(newErrors);
            }

            setLoading(true);

            const isUsernameFieldValid = await _formFieldsApiValidation({
              newFormFields,
              newIsDirty: { username: true },
              key: "username",
            });

            if (!isUsernameFieldValid) {
              setLoading(false);
              return;
            }

            const payload = await _createTab1Payload();

            _updateProfile(payload, forTab);
          } else {
            _toggleTab(2);
            setLoading(false);
          }
          break;
        }

        case 2: {
          if (isValidation) {
            const payload = {
              lastStep: 3, // => user has updated category
            };

            const categoryFieldValid = _categoryFieldsValidation({
              newFormFields,
            });

            if (!categoryFieldValid) return;

            if (newFormFields.category) {
              payload.category = newFormFields.category;
            }

            if (newFormFields.introduction?.trim().length) {
              payload.introduction = newFormFields.introduction.trim();
            }

            if (payload && Object.keys(payload)?.length) {
              _updateProfile(payload, forTab);
            } else {
              _toggleTab(3);
            }
          } else {
            _toggleTab(4);
          }
          break;
        }
        default:
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onAboutFieldFocus = (isFocus = false) => {
    setIsIntroductionFieldFocus(isFocus);
  };

  const _toggleCoverImageModal = (isOpen = false, data = null) => {
    setCoverImageModal({ isOpen, data });
  };

  const _onSuccessCoverProfileModal = (coverFilesToUpload) => {
    if (coverFilesToUpload?.length) {
      setErrors((prev) => ({ ...prev, coverProfileData: null }));
    }
    setCoverProfileData(coverFilesToUpload);
  };

  const _getAndRedirectUserKycLink = async () => {
    try {
      setLoading(true);

      if (PAYMENT_GATEWAY !== "stripe") {
        history.replace("/initialize-account");
        return;
      }

      const res = await getUserKycLink();

      if (res?.kycLink?.url) {
        dispatch(fetchAndModifiedUserDetails());
        localStorage.setItem("hasInitiatedKYC", true);
        window.open(res.kycLink.url, "_self");
      } else {
        errorHandler();
      }

      history.replace("/initialize-account");
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _changeWidth = (event) => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  useEffect(() => {
    // check if user has initiated KYC and signup step is 3 -> then go to initialize account page
    if (
      localStorage.getItem("hasInitiatedKYC") &&
      JSON.parse(localStorage.getItem("signupStep")) === 3
    ) {
      history.replace("/initialize-account");
    }

    // user has uploaded cover image -> then go to categories ->  tab 2
    if (userData?.user?.lastStep === 2) {
      setActiveTab(2);
    }

    _getSettings();

    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`${
          activeTab !== 3 ? "mobile_height" : ""
        } animated fadeIn withtabs authFormWrapper profileTabSignUp`}
        data-test="signup-protected-page"
      >
        <div className="loginWrapper profileSignUp">
          <img
            data-test="app-logo"
            src={APP_LOGO}
            alt="Project Logo"
            className="projectLogo"
            loading="lazy"
            onClick={() => logout()}
          />
          <TabContent
            activeTab={activeTab}
            className="signUpWrap"
            style={{ maxWidth: "unset" }}
          >
            {/* tabId="1" => profile picture and username*/}
            <TabPane tabId={1} className="p-0">
              {/* className="authPgFormWrap" */}

              <Form onSubmit={(event) => _onTabSubmit(event, 1)}>
                <div className="profile_content mt-5 mb-4">
                  <h5>Add Profile Picture & Cover Image</h5>
                  <p>Add a profile picture so your friends know its you</p>
                </div>

                <FormGroup className="profileCarouselSignUp">
                  {/* <div className="d-flex mb-2"> */}
                  {/* <h4>Add Cover Profile</h4> */}

                  {/* <sup
                      id="signup_cover_image"
                      className="infoIcon"
                      style={{ top: 0 }}
                    >
                      <i className="fa fa-info-circle" />
                    </sup>
                    <CustomTooltip
                      text={`Cover image is required to proceed`}
                      target="signup_cover_image"
                    /> */}
                  {/* </div> */}

                  {/* className="coverImgWrap" */}
                  <Label>
                    {/* <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*, .heic, .heif "
                      value=""
                      name="coverImage"
                      onChange={(event) =>
                        _onChangeCover(event, "coverImage")
                      }
                      disabled={loading}
                    /> */}

                    <Button
                      onClick={() => _toggleCoverImageModal(true)}
                      className="d-none"
                      disabled={coverProfileData?.length ? true : false}
                    />

                    {coverProfileData?.length ? (
                      <>
                        <SignupCoverMediaCarousel
                          contents={coverProfileData}
                          screenWidth={screenWidth}
                        />
                        <div
                          className="changeImg"
                          style={{
                            zIndex: 10,
                            pointerEvents: loading ? "none" : "",
                          }}
                          onClick={() =>
                            _toggleCoverImageModal(true, coverProfileData)
                          }
                        >
                          <i className="fa fa-pencil" />
                        </div>
                      </>
                    ) : (
                      <div className="uploadCoverImgSignUp">
                        <img
                          src="/assets/img/upload.png"
                          alt="Upload"
                          className="uploadImg"
                          loading="lazy"
                        />
                        <p className="mb-0 mt-2">Upload Image</p>
                      </div>
                    )}
                  </Label>
                  {errors?.coverProfileData ? (
                    <div className="form-error text-center px-2">
                      {errors?.coverProfileData}
                    </div>
                  ) : null}

                  <div className="profileWrapper">
                    <div className="imagePreview">
                      {profilePicture?.previewBlob ? (
                        <img
                          src={profilePicture.previewBlob}
                          alt="Pic ID"
                          loading="lazy"
                        />
                      ) : (
                        <i className="fa fa-user-o" />
                      )}
                    </div>

                    <Label className="uploadBtn">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={_onChangeProfilePic}
                        disabled={loading}
                        onClick={(e) => (e.target.value = "")}
                        accept="image/*"
                      />
                      <i className="fa fa-camera" />
                    </Label>
                  </div>
                </FormGroup>

                <FormGroup className="px-3 px-sm-0">
                  <div>
                    <Label>Add Username</Label>
                    <sup
                      id="signup_username"
                      className="infoIcon"
                      style={{ top: 0 }}
                    >
                      <i className="fa fa-info-circle" />
                    </sup>
                    <CustomTooltip
                      text={`Subscribers will be able to see and search for this username. You can change this later.`}
                      target="signup_username"
                    />
                  </div>
                  <div className="position-relative">
                    <Input
                      data-test="username-input-box"
                      type="text"
                      placeholder="Enter a username"
                      disabled={loading}
                      autoComplete="true"
                      value={formFields.username}
                      onChange={(e) =>
                        _onChangeFormFields("username", e.target.value)
                      }
                      onBlur={() => _onBlurFormFields("username")}
                    />
                    {dublicateLoading.username && (
                      <div className="spinnerLogin inputSpinner">
                        <i className="fa fa-spinner fa-spin" />
                      </div>
                    )}
                  </div>
                  {errors?.username ? (
                    <div className="form-error">{errors?.username}</div>
                  ) : null}
                </FormGroup>

                <Button
                  data-test="next-button"
                  className="themeBtn loginBtn mt-5"
                  disabled={loading}
                  type="submit"
                >
                  {loading && <i className="fa fa-spinner fa-spin" />} Next
                </Button>
              </Form>

              {/* username & profile-pic validation skip */}
              {/* <Button
                  color="link"
                  className="skipTxt"
                  disabled={loading}
                  onClick={(event) => _onTabSubmit(event, 1, false)}
                >
                  Skip for now
                </Button> */}
            </TabPane>

            {/* tabId="2" => category and bio */}
            <TabPane tabId={2} className="p-0">
              <div className="authPgFormWrap">
                <Form onSubmit={(event) => _onTabSubmit(event, 2)}>
                  <div>
                    <Label>Choose Your Category</Label>
                    <sup
                      id="signup_username"
                      className="infoIcon"
                      style={{ top: 0 }}
                    >
                      <i className="fa fa-info-circle" />
                    </sup>
                    <CustomTooltip
                      text={`Get found! TrueFanz users can search and find your channel based on your category.`}
                      target="signup_username"
                    />
                  </div>
                  {categories?.length ? (
                    <FormGroup>
                      <Input
                        type="select"
                        name="category"
                        value={formFields.category}
                        onChange={(e) =>
                          _onChangeFormFields("category", e.target.value)
                        }
                        onBlur={() => _onBlurFormFields("category")}
                      >
                        <option value="">Select a Category</option>
                        {categories.map((category, index) => (
                          <option key={index} value={category}>
                            {category}
                          </option>
                        ))}
                      </Input>
                      {errors?.category ? (
                        <div className="form-error">{errors?.category}</div>
                      ) : null}
                    </FormGroup>
                  ) : null}

                  <FormGroup>
                    {/* <p
                      style={{
                        fontSize: 18,
                        marginTop: 10,
                        marginBottom: 0,
                        fontWeight: 600,
                      }}
                    >
                      About You
                    </p> */}
                    <div>
                      <Label className="mt-2">About You</Label>
                      <sup
                        id="signup_username"
                        className="infoIcon"
                        style={{ top: 0 }}
                      >
                        <i className="fa fa-info-circle" />
                      </sup>
                      <CustomTooltip
                        text={`Who are you? What do you do? Why is it an awesome idea to subscribe to your channel?`}
                        target="signup_username"
                      />
                    </div>

                    <Input
                      data-test="intro-input-box"
                      type="textarea"
                      rows="4"
                      placeholder="Introduce Yourself"
                      name="introduction"
                      value={formFields.introduction}
                      onChange={(e) =>
                        _onChangeFormFields("introduction", e.target.value)
                      }
                      onFocus={() => _onAboutFieldFocus(true)}
                      onBlur={() => _onAboutFieldFocus()}
                    />
                    {isIntroductionFieldFocus ? (
                      <div className="text-right" style={{ color: "#828282" }}>
                        {BIO_MAX_LIMIT - formFields.introduction?.length} left
                      </div>
                    ) : null}
                  </FormGroup>

                  <Button
                    data-test="finish-button"
                    className="themeBtn loginBtn"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? <i className="fa fa-spinner fa-spin" /> : null}{" "}
                    Finish
                  </Button>
                </Form>

                {/* <Button
                  color="link"
                  className="skipTxt"
                  disabled={loading}
                  onClick={(event) => _onTabSubmit(event, 2, false)}
                >
                  Skip for now
                </Button> */}
              </div>
            </TabPane>

            {/* tabId="3" */}
            <TabPane tabId={3} className="p-0">
              <div className="authPgFormWrap">
                <div className="congContent">
                  <h4>
                    Congratulations{" "}
                    {userData?.user?.name?.full ||
                      `@${userData?.user?.username}`}
                    !
                  </h4>{" "}
                  🥳
                </div>
                <p className="text-center mt-2" style={{ color: "#828282" }}>
                  Now its time to verify your account so you can get paid.
                </p>
                <Button
                  color="link"
                  className="themeBtn loginBtn"
                  disabled={loading}
                  onClick={() => _getAndRedirectUserKycLink()}
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin mr-1" />
                  ) : (
                    <img
                      src={"/assets/img/verify.png"}
                      style={{ width: 16, marginRight: 7, marginTop: -2 }}
                      alt="verify"
                      loading="lazy"
                    />
                  )}
                  Verify
                </Button>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>

      <ImageCropUploaderModal
        isOpen={isCropModalOpen}
        selectedPhoto={cropModalContent}
        resetPhoto={() => _resetPhoto()}
        onSuccess={_saveCroppedPhoto}
        onDismiss={() => _toggleCropModal(false)}
      />

      {coverImageModal.isOpen && (
        <SignupCoverProfileModal
          isOpen={coverImageModal.isOpen}
          data={coverImageModal.data}
          toggle={() => _toggleCoverImageModal()}
          onSuccess={(coverFilesToUpload) =>
            _onSuccessCoverProfileModal(coverFilesToUpload)
          }
        />
      )}
    </>
  );
};

export default SignupProtectedPage;
