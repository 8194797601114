import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  FormGroup,
  PopoverBody,
  UncontrolledPopover,
  CustomInput,
} from "reactstrap";
import { WELCOME_POST_IMAGE, WELCOME_POST_VIDEO } from "../config/index";
import FeedViewer from "./FeedViewer";
import {
  showToast,
  formatNumberInShort,
  errorHandler,
  replaceTipText,
} from "../helper-methods";
import { deletPost, editPost, getPosts } from "../http-calls";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePost, updateAPost } from "../redux/actions";
import {
  contentFilterOptionsConfig,
  contentSortOptionsConfig,
} from "../config/helper-config";
import SkeletonLoading from "./SkeletonLoading";

const MyProfilePosts = ({ tabId, activeTab, summary }) => {
  const dispatch = useDispatch();

  const observer = useRef();

  const cacheFeedData = useSelector((state) => state?.feedData?.feeds || []);

  const [feedData, setFeedData] = useState([]);
  const [totalPostCount, setTotalPostCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
    timePeriod: "allTime", // ['last90days', 'last30days', 'last7Days']
    sortBy: "latestPosts", // ['mostLiked', 'highestTips']
  });
  const [loading, setLoading] = useState(false);
  const [isFilterPopoverOpen, setisFilterPopoverOpen] = useState(false);

  const _scrollToTop = () => {
    try {
      // containerRef.current.scrollTop = 0; // For laptop, desktop
      window.scrollTo(0, 0); // For mobile
    } catch (error) {
      console.log({ error });
    }
  };

  const isFilterDefaultState = useMemo(() => {
    return (
      dataPayload.timePeriod === "allTime" &&
      dataPayload.sortBy === "latestPosts"
    );
  }, [dataPayload]);

  const _fetchPosts = async (payload) => {
    try {
      setLoading(true);

      const res = await getPosts(payload);
      console.log(res);

      setFeedData(payload?.skip ? feedData.concat(res?.posts) : res?.posts);
      setTotalPostCount(res?.total);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _onChangeFilterAndSort = (key = "", value = "") => {
    if (!key || !value) {
      return errorHandler();
    }
    const newDataPayload = { ...dataPayload };
    newDataPayload.skip = 0;
    newDataPayload[key] = value;
    setDataPayload(newDataPayload);

    _fetchPosts(newDataPayload);
  };

  const _resetDataPayload = () => {
    const newDataPayload = { ...dataPayload };
    newDataPayload.skip = 0;
    setDataPayload(newDataPayload);
    _fetchPosts(newDataPayload);
  };

  const _pinToProfile = async (id, isPinned, index) => {
    try {
      dispatch(
        updateAPost({
          index,
          attr: "isPinned",
          value: isPinned,
        })
      );

      await editPost({ isPinned }, id);

      _resetDataPayload();

      _scrollToTop();
    } catch (error) {
      errorHandler(error);

      dispatch(
        updateAPost({
          index,
          attr: "isPinned",
          value: !isPinned,
        })
      );
    }
  };

  const _deletePost = async (id, index) => {
    try {
      await deletPost(id);

      dispatch(deletePost(index));

      showToast("Post has been deleted", "success");
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onCommentPost = (index, isAdded) => {
    try {
      let commentsCount = 0;

      if (isAdded) {
        if (feedData?.feeds?.[index]?.hasOwnProperty("comments")) {
          commentsCount = feedData.feeds[index]["comments"] + 1;
        } else {
          commentsCount = 1;
        }
      } else {
        if (+feedData?.feeds?.[index]?.comments) {
          commentsCount = feedData.feeds[index]["comments"] - 1;
        }
      }

      dispatch(updateAPost({ index, attr: "comments", value: commentsCount }));
    } catch (error) {
      console.log({ error });
    }
  };

  const _updateCommentsCount = ({ index, value }) => {
    dispatch(
      updateAPost({
        index: index,
        attr: "comments",
        value,
      })
    );
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && feedData?.length < totalPostCount) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = feedData?.length || 0;
          setDataPayload(newDataPayload);
          _fetchPosts(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, feedData?.feeds]
  );

  useEffect(() => {
    if (tabId === activeTab) {
      _fetchPosts(dataPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId, activeTab]);

  return (
    <div>
      <div className="filterWrap-Feed mx-auto" style={{ maxWidth: 580 }}>
        <p>
          {formatNumberInShort(totalPostCount) || 0}{" "}
          {totalPostCount > 1 ? "Posts" : "Post"}
        </p>

        {feedData?.length ? (
          <div>
            <Button
              id="Popover1"
              className="customPopoverBtn"
              onClick={() => setisFilterPopoverOpen((prev) => !prev)}
            >
              <img
                src={"/assets/img/filter-icon.png"}
                alt="Filter"
                loading="lazy"
              />
            </Button>
            <UncontrolledPopover
              className="customPopover"
              placement="bottom"
              target="Popover1"
              trigger="legacy"
              isOpen={isFilterPopoverOpen}
              toggle={() => setisFilterPopoverOpen((prev) => !prev)}
            >
              <PopoverBody>
                {contentFilterOptionsConfig.map((each, index) => (
                  <FormGroup check key={each.value} className="radio">
                    <CustomInput
                      className="form-check-input"
                      id={`MyProfilePosts_activeFilterOption_${each.value}`}
                      type="radio"
                      name="activeFilterOption"
                      checked={dataPayload.timePeriod === each.value}
                      value={each.value}
                      onChange={() =>
                        _onChangeFilterAndSort("timePeriod", each.value)
                      }
                      label={each.label}
                    />
                  </FormGroup>
                ))}

                <DropdownItem divider />

                {contentSortOptionsConfig.map((each, index) => (
                  <FormGroup check key={each.value} className="radio">
                    <CustomInput
                      className="form-check-input"
                      id={`MyProfilePosts_activeSortOption_${each.value}`}
                      type="radio"
                      name="activeSortOption"
                      checked={dataPayload.sortBy === each.value}
                      value={each.value}
                      onChange={() =>
                        _onChangeFilterAndSort("sortBy", each.value)
                      }
                      label={replaceTipText(each.label)}
                    />
                  </FormGroup>
                ))}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        ) : null}
      </div>

      {/* multiple posts/feeds */}
      {cacheFeedData?.length || feedData?.length ? (
        (feedData || cacheFeedData).map((feed, feedIndex) => (
          <Fragment key={feed._id || feedIndex}>
            <FeedViewer
              {...(feedIndex === feedData.length - 1 ? { lastElementRef } : {})}
              loadingFeeds={loading}
              feed={feed}
              feedIndex={feedIndex}
              isEditFeed={false}
              pinToProfile={(isPinned) =>
                _pinToProfile(feed._id, isPinned, feedIndex)
              }
              deletePost={() => _deletePost(feed._id, feedIndex)}
              onComment={(index, isAdded) => _onCommentPost(index, isAdded)}
              updateCommentsCount={({ index, value }) =>
                _updateCommentsCount({ index, value })
              }
            />
          </Fragment>
        ))
      ) : loading ? (
        <SkeletonLoading type={"post"} count={3} />
      ) : isFilterDefaultState ? (
        <Card className="cardFeed">
          <CardBody>
            <div className="mediaPhotoWrap-Feed">
              {WELCOME_POST_VIDEO ? (
                <video
                  src={WELCOME_POST_VIDEO}
                  controls
                  controlsList="nodownload"
                  disablePictureInPicture
                />
              ) : (
                <img
                  className="mediaPhoto-Feed"
                  src={WELCOME_POST_IMAGE}
                  alt="Welcome Img"
                  loading="lazy"
                />
              )}
            </div>
          </CardBody>
        </Card>
      ) : (
        <p className="text-center">There is no feed to display.</p>
      )}
    </div>
  );
};

export default MyProfilePosts;
