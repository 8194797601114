import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isUserAuthenticated } from "../guards/auth-guard";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const userData = useSelector((state) => state?.userData);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserAuthenticated()) {
          if (
            +userData?.user?.lastStep > 0 &&
            +userData?.user?.lastStep < 3 &&
            rest?.path !== "/update-profile"
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/update-profile",
                }}
              />
            );
          } else {
            return <Component {...props} />;
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: rest.redirectRoute,
                extras: { ...rest.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
