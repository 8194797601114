import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import CreateTagsModal from "../modals/CreateTagsModal";
import { deleteTagById } from "../../http-calls";
import { capitalize, errorHandler, showToast } from "../../helper-methods";
import ButtonLoading from "../ButtonLoading";
import Swal from "sweetalert2";
import { deleteTagFromRedux, getAndUpdateTags } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../assets/styles/scss/style.scss";

const SettingsManageTags = () => {
  const dispatch = useDispatch();

  const { tags } = useSelector((state) => state?.tags || {});
  const userData = useSelector((state) => state?.userData);
  
  const [loading, setLoading] = useState(false);

  const [createTagsModal, setCreateTagsModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleCreateTagsModal = (isOpen = false, data = null) => {
    setCreateTagsModal({ isOpen, data });
  };

  const _deleteTag = async (data) => {
    try {
      setLoading(true);

      const newTag = [...tags];
      const findTag = newTag.find((each) => each._id === data?._id);
      if (!findTag) {
        showToast("Tag not found.", "error");
        return;
      }
      // newTag.splice(findTagIndex, 1);
      // setTags(newTag);

      dispatch(deleteTagFromRedux(findTag));

      await deleteTagById(data?._id);

      showToast("Tag has been deleted", "success");
    } catch (error) {
      errorHandler(error);
      _getAllTags();
    } finally {
      setLoading(false);
    }
  };

  const _deleteTagAlert = (data) => {
    if (!data?._id) {
      showToast("Tag not found.", "error");
    }

    Swal.fire({
      title: "Are you sure?",
      text: `you want to delete this tag? ${
        data?.membersCount ? data?.membersCount : "No"
      } ${
        data?.membersCount > 1 ? "subscribers are" : "subscriber is"
      } tagged in it.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors?.themeColor,
      cancelButtonColor: "#ccc",
      confirmButtonText: "Yes, Delete it!",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteTag(data);
      }
    });
  };

  const _getAllTags = async () => {
    try {
      setLoading(true);

      await getAndUpdateTags()(dispatch);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    _getAllTags();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="header_Settings"
        style={{ marginTop: 35, marginBottom: 15 }}
      >
        <h4>Manage Tags {loading ? <ButtonLoading /> : null}</h4>
        { !userData.user?.isScheduleForAccountDelete ?
        <Button
          className="themeBtn addBtn"
          onClick={() =>
            _toggleCreateTagsModal(
              true,
              tags?.map((each) => each?.name?.toLowerCase())
            )
          }
        >
          Add
        </Button> : "" }
      </div>

      <div className="creatorCategory">
        {tags?.length ? (
          tags.map((each, index) => (
            <div className="addedTags" key={`tags_${each._id}_${index}`}>
              {capitalize(each.name)} | {each?.membersCount}{" "}
              {each?.membersCount > 1 ? "Members" : "Member"}
              <Button color="link" onClick={() => _deleteTagAlert(each)}>
                <i className="fa fa-times" />
              </Button>
            </div>
          ))
        ) : (
          <div>
            It looks like no tags have been added. Use the 'Add' button to add
            them.
          </div>
        )}
      </div>

      <CreateTagsModal
        isOpen={createTagsModal.isOpen}
        data={createTagsModal.data}
        toggle={() => _toggleCreateTagsModal()}
        onSuccess={() => _getAllTags()}
      />
    </>
  );
};

export default SettingsManageTags;
