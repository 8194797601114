import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
  Label,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  CustomInput,
  Collapse,
} from "reactstrap";
import {
  showToast,
  capitalize,
  formatCurrencyValue,
  extractQueryParams,
  errorHandler,
  formatNumberInShort,
  deepClone,
  getLowResolutionLink,
  getFullName,
} from "../helper-methods";
import CustomPrompt from "./modals/CustomPrompt";
import { DEFAULT_PROFILE_PICTURE } from "../config/index";
import { fanAmountSpentConfig, fanCategoryConfig } from "../config/appConfig";
import {
  getInfluencerFollowers,
  blockFan,
  markFanAsFavourite,
  muteFan,
  updateNotesOfFollower,
  getCategorywiseFanEarning,
  addFanToGroups,
} from "../http-calls";
import FanCostBreakdownModal from "./modals/FanCostBreakdownModal";
import { useSelector } from "react-redux";
import { getAndUpdateTags, saveLocalFilters } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SkeletonLoading from "./SkeletonLoading";
import CustomTooltip from "./custom/CustomTooltip";
import Select from "react-select";
import AddFanToTagPopver from "./AddFanToTagPopver";
import ButtonLoading from "./ButtonLoading";

const FollowerListTab = ({ tabId, activeTab }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const searchIntervalRef = useRef({ current: null });
  const webObserver = useRef();
  const mobileObserver = useRef();

  const { tags } = useSelector((state) => state?.tags || {});

  const localFilters = useSelector((state) => state?.localFilters);

  const [selectedFanIds, setSelectedFanIds] = useState([]);
  const userData = useSelector((state) => state?.userData);
  const [userInputTags, setUserInputTags] = useState([]);

  const [loading, setLoading] = useState(false);

  const [followers, setFollowers] = useState([]);
  const [followersCount, setFollowersCount] = useState({
    total: 0,
    active: 0,
    expired: 0,
  });

  const [filters, setFilters] = useState({
    amountSpent: "",
    groupIds: [],
    category: [],
  });
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    limit: 20,
    search: "",
  });
  const [loadingState, setLoadingState] = useState({
    data: false,
    filter: false,
    search: false,
  });
  const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);

  const [customPrompt, setCustomPrompt] = useState({
    isOpen: false,
    message: "",
    metadata: {
      type: null,
      selectedFan: null,
    },
  });

  const [fanCostBreakdownModal, setFanCostBreakdownModal] = useState({
    isOpen: false,
    category: null,
  });

  const _toggleSelectedFanIds = (id = "", checked = false) => {
    if (id === "all") {
      let newSelectedFanIds = [];

      if (checked) {
        newSelectedFanIds = followers?.map((each) => each?._fan?._id);
      }

      setSelectedFanIds(newSelectedFanIds);
    } else {
      const newSelectedFanIds = [...selectedFanIds];
      const findIndex = newSelectedFanIds.indexOf(id);
      if (findIndex >= 0) {
        newSelectedFanIds.splice(findIndex, 1);
      } else {
        newSelectedFanIds.push(id);
      }
      setSelectedFanIds(newSelectedFanIds);
    }
  };

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _getInfluencerFollowers = async (payload) => {
    try {
      _manageLoadingState("data", true);

      const res = await getInfluencerFollowers(payload);
      setFollowers((prev) =>
        payload?.skip ? prev.concat(res.followers) : res.followers
      );
      setFollowersCount({
        total: res.count,
        expired: res?.expiredCount || 0,
        active: res?.count - res?.expiredCount || 0,
      });

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onFilterChange = (newFilters = {}, username = null) => {
    _manageLoadingState("filter", true);

    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["filters"] = {};

    dispatch(
      saveLocalFilters({ key: "followerListTab", value: deepClone(newFilters) })
    );

    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key]?.length) {
        if (key === "amountSpent") {
          try {
            newDataPayload.filters[key] = JSON.parse(newFilters[key]);
          } catch (error) {}
        } else if (key === "groupIds") {
          newDataPayload.filters[key] = newFilters[key]?.map(
            (each) => each.value
          );
        } else {
          newDataPayload.filters[key] = newFilters[key];
        }
      }
    });

    if (username) {
      newDataPayload.search = username;
    }

    setDataPayload(newDataPayload);

    _getInfluencerFollowers(newDataPayload);
  };

  const _onChangeFilter = (key, value) => {
    const newFilters = { ...filters };
    if (key === "category") {
      const findIndex = newFilters[key].indexOf(value);
      try {
        if (findIndex >= 0) {
          newFilters[key].splice(findIndex, 1);
        } else {
          newFilters[key].push(value);
        }
      } catch (error) {
        newFilters[key] = [];
      }
    } else {
      newFilters[key] = value;
    }
    setFilters(newFilters);

    _onFilterChange(newFilters);
  };

  const _onChangeSearch = (value) => {
    if (searchIntervalRef?.current) clearInterval(searchIntervalRef.current);

    _manageLoadingState("search", true);

    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = value;
    setDataPayload(newDataPayload);

    searchIntervalRef.current = setTimeout(() => {
      newDataPayload["skip"] = 0;
      newDataPayload["search"] = value?.trim() || "";
      setDataPayload(newDataPayload);

      _getInfluencerFollowers(newDataPayload);
    }, 1000);
  };

  const _getAllTags = async () => {
    try {
      await getAndUpdateTags()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const { username } = extractQueryParams();

    if (localFilters?.filters?.followerListTab) {
      const newFilters = { ...filters };
      Object.keys(newFilters).forEach((key) => {
        if (localFilters.filters.followerListTab?.[key]) {
          newFilters[key] = localFilters.filters.followerListTab[key];
        }
      });
      setFilters(newFilters);

      _onFilterChange(newFilters, username);
    } else {
      _getInfluencerFollowers({
        filter: {},
        skip: 0,
        limit: 20,
        search: username || "",
      });
    }

    _getAllTags();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _updateNotes = async (followerId, notes) => {
    try {
      _manageLoadingState("notes", true);
      await updateNotesOfFollower(followerId, notes);
      showToast("Notes updated successfully", "success");
      _manageLoadingState("notes", false);
    } catch (error) {
      _manageLoadingState("notes", false);
      errorHandler(error);
    }
  };

  const _onFavouriteBtnClick = async (data) => {
    try {
      _manageLoadingState("action", true);

      const res = await markFanAsFavourite(data?._fan?._id);

      const newFollowers = [...followers];
      const findFollower = newFollowers.find((e) => e._fan.id === res.id);
      if (findFollower) {
        findFollower.isFavourited = res.isFavourited;
        setFollowers(newFollowers);
      }

      _manageLoadingState("action", false);
    } catch (error) {
      _manageLoadingState("action", false);
      errorHandler(error);
    }
  };

  const _onMuteBtnClick = async (data) => {
    try {
      _manageLoadingState("action", true);

      const res = await muteFan(data?._fan?._id);

      showToast(
        res.subscription.isMuted
          ? "Successfully marked as muted"
          : "Successfully marked as unmuted",
        "success"
      );

      const newFollowers = [...followers];
      const findFollower = newFollowers.find(
        (e) => e._fan.id === res.subscription._fan
      );
      if (findFollower) {
        findFollower.isMuted = res.subscription.isMuted;
        setFollowers(newFollowers);
      }

      _manageLoadingState("action", false);
    } catch (error) {
      _manageLoadingState("action", false);
      errorHandler(error);
    }
  };

  const _onBlockBtnClick = async (data) => {
    try {
      _manageLoadingState("action", true);

      await blockFan(data?._fan?._id);

      showToast(
        !data.isBlocked
          ? `${getFullName(data?._fan?.name)} was blocked`
          : `${getFullName(data?._fan?.name)} was unblocked`,
        "success"
      );

      const newFollowers = [...followers];
      const findFollower = newFollowers.find(
        (e) => e._fan.id === data._fan._id
      );
      if (findFollower) {
        findFollower.isBlocked = !data.isBlocked;
        setFollowers(newFollowers);
      }

      _manageLoadingState("action", false);
    } catch (error) {
      _manageLoadingState("action", false);
      errorHandler(error);
    }
  };

  const _toggleCustomPrompt = (
    isOpen = false,
    message = "",
    metadata = { type: null, selectedFan: null }
  ) => {
    setCustomPrompt({
      isOpen,
      message,
      metadata,
    });
  };

  const _toggleMute = (data) => {
    _toggleCustomPrompt(
      true,
      `Are you sure you want to ${data?.isMuted ? "unmute" : "mute"} ${
        getFullName(data?._fan?.name) || "Fan"
      }?`,
      {
        type: "mute",
        selectedFan: data,
      }
    );
  };

  const _toggleBlock = (data) => {
    _toggleCustomPrompt(
      true,
      `Are you sure you want to ${data?.isBlocked ? "unblock" : "block"} ${
        getFullName(data?._fan?.name) || "Fan"
      }?`,
      {
        type: "block",
        selectedFan: data,
      }
    );
  };

  const _onPromptSuccess = ({ type, selectedFan }) => {
    switch (type) {
      case "favourite": {
        _onFavouriteBtnClick(selectedFan);
        break;
      }
      case "mute": {
        _onMuteBtnClick(selectedFan);
        break;
      }
      case "block": {
        _onBlockBtnClick(selectedFan);
        break;
      }
      default:
        break;
    }
    _toggleCustomPrompt();
  };

  const _updateNote = (value, fan, index) => {
    const newFollowers = [...followers];
    newFollowers[index]["notes"] = value;
    setFollowers(newFollowers);
  };

  const _saveNote = (fanIndex) => {
    const findFollower = followers[fanIndex];
    if (findFollower?._fan?.id && findFollower?.notes?.length) {
      _updateNotes(findFollower._fan.id, findFollower.notes);
    }
  };

  const _resetFilter = () => {
    setFilters({ amountSpent: "", groupIds: [], category: [] });

    dispatch(saveLocalFilters({ key: "followerListTab", value: {} }));

    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["search"] = "";
    newDataPayload["filters"] = {};
    setDataPayload(newDataPayload);

    _getInfluencerFollowers(newDataPayload);
  };

  const _getBillingCycleMonth = (fan) => {
    let cycle = "N/A";

    if (fan?.billingCycle) {
      cycle = Math.floor(fan.billingCycle / 30);
      if (cycle > 1) {
        cycle = `${cycle} Months`;
      } else {
        cycle = `${cycle} Month`;
      }
    }

    return cycle;
  };

  const _onSubmitTag = async () => {
    try {
      setLoading(true);

      const groupIds = userInputTags.map((each) => each.value);

      if (!groupIds?.length) {
        showToast("At least one tag is required.", "error");
        return;
      }

      if (!selectedFanIds?.length) {
        showToast("At least one tag is required.", "error");
        return;
      }

      await addFanToGroups({ fanIds: selectedFanIds, groupIds });

      setUserInputTags([]);
      setSelectedFanIds([]);

      const newFilters = deepClone(filters);
      _onFilterChange(newFilters);

      showToast("Subscriber has been tagged", "success");
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _toggleFanCostBreakdownModal = async (isOpen = false, fanId = null) => {
    try {
      let category = null;

      if (isOpen && fanId) {
        const res = await getCategorywiseFanEarning(fanId);
        category = res?.earnings ? res.earnings : null;
      }

      setFanCostBreakdownModal({
        isOpen,
        category,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const webLastElementRef = useCallback(
    (node) => {
      if (loadingState?.data) return;

      if (webObserver.current) webObserver.current.disconnect();

      webObserver.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          followers?.length < followersCount?.total
        ) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = followers?.length || 0;
          setDataPayload(newDataPayload);
          _getInfluencerFollowers(newDataPayload);
        }
      });

      if (node) webObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.data, followers]
  );

  const mobileLastElementRef = useCallback(
    (node) => {
      if (loadingState?.data) return;

      if (mobileObserver.current) mobileObserver.current.disconnect();

      mobileObserver.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          followers?.length < followersCount?.total
        ) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = followers?.length || 0;
          setDataPayload(newDataPayload);
          _getInfluencerFollowers(newDataPayload);
        }
      });

      if (node) mobileObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.data, followers]
  );

  return (
    <>
      <div className="filterWrap-Feed">
        <p id="fans-count" className="cursorPointer">
          {formatNumberInShort(followersCount?.total) || 0} Fanz
        </p>

        {followersCount?.total ? (
          <CustomTooltip
            target="fans-count"
            placement="right"
            text={
              <>
                {followersCount.active ? (
                  <>
                    <span>
                      {formatNumberInShort(followersCount?.active)}
                      Active{" "}
                      {followersCount?.active > 1
                        ? "Subscribers"
                        : "Subscriber"}
                    </span>
                  </>
                ) : null}

                {followersCount.expired ? (
                  <span>
                    {followersCount?.active ? (
                      <>
                        <br />
                        (+{formatNumberInShort(followersCount?.expired)}
                        Expired{" "}
                        {followersCount?.expired > 1
                          ? "Subscriptions"
                          : "Subscription"}
                        )
                      </>
                    ) : (
                      <>
                        {formatNumberInShort(followersCount?.expired)} Expired{" "}
                        {followersCount?.expired > 1
                          ? "Subscriptions"
                          : "Subscription"}
                      </>
                    )}
                  </span>
                ) : null}
              </>
            }
          />
        ) : null}

        <Button
          onClick={() => setIsOpenFilterCollapse((prev) => !prev)}
          className="customPopoverBtn"
        >
          <img src="/assets/img/filter-icon.png" alt="Filter" loading="lazy" />
        </Button>
      </div>

      <Collapse
        isOpen={isOpenFilterCollapse}
        className="customFilterMob mb-1 mb-sm-0"
      >
        <Row form>
          <Col md="12">
            <div className="d-flex flex-wrap">
              {fanCategoryConfig.map((each, index) => (
                <FormGroup key={index} className="mr-4">
                  <CustomInput
                    type="checkbox"
                    id={`Custom_radio_followerList_${index}_${each.value}`}
                    onChange={() => _onChangeFilter("category", each.value)}
                    checked={filters?.category?.includes(each.value)}
                    label={each.label}
                  />
                </FormGroup>
              ))}
            </div>
          </Col>
          <Col xs="12" sm="6" lg="3" className="mt-1 mt-sm-0">
            <FormGroup>
              <Label style={{ fontSize: 14, marginBottom: 5 }}>
                Amount Spent
              </Label>
              <Input
                type="select"
                name="amountSpent"
                id="amountSpent"
                value={filters.amountSpent}
                onChange={(e) => _onChangeFilter("amountSpent", e.target.value)}
              >
                <option value="">All</option>
                {fanAmountSpentConfig.map((each) => (
                  <option key={each.value} value={JSON.stringify(each.data)}>
                    {each.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <FormGroup className="multiSelectWrap">
              <Label style={{ fontSize: 14, marginBottom: 5 }}>Tags</Label>
              <Select
                options={tags?.map((each) => ({
                  label: capitalize(each.name),
                  value: each._id,
                }))}
                value={filters?.groupIds?.map((each) => each)}
                onChange={(value) => _onChangeFilter("groupIds", value)}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col xs="9" sm="6" lg="3" className="mt-2 mt-lg-0">
            <Label
              className="d-none invisible d-lg-block"
              style={{ fontSize: 14, marginBottom: 5 }}
            >
              Search
            </Label>
            <InputGroup>
              <Input
                type="text"
                placeholder="Search"
                value={dataPayload.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />

              <InputGroupAddon addonType="append">
                <Button
                  className="border-0"
                  style={{
                    background: "#eff0f6",
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                >
                  {dataPayload?.search ? (
                    loadingState.search ? (
                      <i className="fa fa-spinner fa-spin mr-1 clearSearch" />
                    ) : (
                      <i
                        className="fa fa-times clearSearch"
                        onClick={() => _onChangeSearch("")}
                      />
                    )
                  ) : (
                    <i
                      className="fa fa-search"
                      style={{ color: "#828282", verticalAlign: 1 }}
                    />
                  )}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col xs="3" sm="3" lg="2" className="mt-2 mt-lg-0">
            <Label
              className="d-none invisible d-lg-block"
              style={{ fontSize: 14, marginBottom: 5 }}
            >
              Btn
            </Label>
            <Button
              className="themeBtn addBtn mt-1"
              onClick={() => _resetFilter()}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Collapse>

      {selectedFanIds?.length ? (
        <div className="addedSubscriberToTags">
          <p>
            <span>{selectedFanIds?.length}</span>{" "}
            {selectedFanIds?.length > 1 ? "Subscribers" : "Subscriber"} selected
          </p>

          <div
            className="multiSelectWrap ml-3"
            style={{ minWidth: 250, minHeight: "auto", width: "100%" }}
          >
            <Select
              options={tags?.map((each) => ({
                label: capitalize(each.name),
                value: each._id,
              }))}
              value={userInputTags}
              onChange={(value) => setUserInputTags(value)}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <Button
            className="themeBtn addBtn ml-3"
            onClick={() => _onSubmitTag()}
            disabled={loading || !userInputTags?.length ? true : false}
          >
            {loading ? <ButtonLoading /> : null}
            Add Tag
          </Button>
        </div>
      ) : null}

      <div className="referralHistoryTable my-3 d-none d-lg-block">
        <Table responsive>
          <thead>
            <tr>
              <th>
                <CustomInput
                  type="checkbox"
                  id={`tag_checkbox_all`}
                  label=""
                  checked={
                    selectedFanIds?.length === followers?.length ? true : false
                  }
                  onChange={(e) =>
                    _toggleSelectedFanIds("all", e.target.checked)
                  }
                />
              </th>
              <th>Fan Name</th>
              <th>Notes</th>
              <th>Subscription Rate</th>
              <th>Duration</th>
              <th>Subscribed Tier</th>
              <th>Subscription Paid</th>
              <th>Total Amount</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {followers?.length ? (
              followers.map((each, index) => (
                <tr
                  key={index}
                  className={
                    dataPayload?.search === each._fan?.username
                      ? "highlightRow"
                      : null
                  }
                  {...(index === followers.length - 1
                    ? { ref: webLastElementRef }
                    : {})}
                >
                  <td>
                    <CustomInput
                      type="checkbox"
                      id={`tag_checkbox_${each._id}`}
                      label=""
                      checked={
                        selectedFanIds?.includes(each?._fan?._id) ? true : false
                      }
                      onChange={(e) =>
                        _toggleSelectedFanIds(each?._fan?._id, e.target.checked)
                      }
                    />
                  </td>
                  <td>
                    <div className="fanInfo">
                      <div className="position-relative mr-1">
                        <img
                          className="userImg"
                          src={
                            getLowResolutionLink(each._fan?.profilePicture) ||
                            DEFAULT_PROFILE_PICTURE
                          }
                          onError={(e) =>
                            (e.target.src = DEFAULT_PROFILE_PICTURE)
                          }
                          alt="Profile"
                          loading="lazy"
                        />

                        {/* <img src={"https://res.cloudinary.com/www-logic-square-com/image/upload/v1734594400/TF/diamond-supporter.gif"} alt="Diamond Supporter" className="fanBadgeFanPg" />
                        <img src={"https://res.cloudinary.com/www-logic-square-com/image/upload/v1734594400/TF/gold-supporter.gif"} alt="Gold Supporter" className="fanBadgeFanPg" />
                        <img src={"https://res.cloudinary.com/www-logic-square-com/image/upload/v1734594431/TF/silver-supporter.gif"} alt="Silver Supporter" className="fanBadgeFanPg" /> */}
                      </div>

                      <div>
                        <p>{capitalize(getFullName(each?._fan?.name))}</p>
                        <Button
                          color="link"
                          className="profileID otherPgsProfileID"
                        >
                          @{each?._fan?.username}
                        </Button>
                        {/* Note:  show the below text when the subscribers are expired */}
                        {each?.isExpired && (
                          <span className="expiredSubscriberProfile">
                            Expired
                          </span>
                        )}
                      </div>
                    </div>
                  </td>

                  <td>
                    <Input
                      name="text-input"
                      type="text"
                      className="notesFan"
                      value={each.notes ? each.notes : ""}
                      disabled={
                        loadingState.notes ||
                        userData?.user?.isScheduleForAccountDelete === true
                      }
                      onChange={(e) => _updateNote(e.target.value, each, index)}
                      onBlur={() => _saveNote(index)}
                    />
                  </td>
                  <td>
                    {each?.amount ? formatCurrencyValue(each.amount) : "-"}
                  </td>
                  <td>{_getBillingCycleMonth(each)}</td>
                  <td>{each.tier ? capitalize(each.tier) : "-"}</td>
                  <td>
                    {formatCurrencyValue(each.categorySpent?.subscription || 0)}
                  </td>
                  <td>
                    <span
                      className="cursorPointer themeColor"
                      onClick={() =>
                        _toggleFanCostBreakdownModal(true, each._fan?._id)
                      }
                    >
                      {formatCurrencyValue(each.lifetimeSpent || 0)}
                    </span>
                  </td>
                  <td>
                    {/* Add a tooltip for each of the btn inside the btn-group, The text will be: "Favourite, Mute, Block & Message" respectively */}
                    <ButtonGroup className="actionBtnSubscriber">
                      <Button
                        title={
                          each?.isFavourited ? "Remove Favorite" : "Favorite"
                        }
                        onClick={() => _onFavouriteBtnClick(each)}
                        disabled={loadingState.action}
                      >
                        <i
                          className={
                            each.isFavourited
                              ? "fa fa-star themeColor"
                              : "fa fa-star-o"
                          }
                        />
                      </Button>
                      <Button
                        title={each?.isMuted ? "Unmute" : "Mute"}
                        onClick={() => _toggleMute(each)}
                        disabled={loadingState.action}
                      >
                        <i
                          className={
                            each.isMuted
                              ? "fa fa-volume-off text-danger"
                              : "fa fa-volume-up"
                          }
                        />
                      </Button>
                      <Button
                        title={each?.isBlocked ? "Unblock" : "Block"}
                        onClick={() => _toggleBlock(each)}
                        disabled={loadingState.action}
                      >
                        <i
                          className={`fa fa-ban ${
                            each.isBlocked ? "text-danger" : ""
                          }`}
                        />
                      </Button>
                      <Button
                        title="Message"
                        onClick={() =>
                          history.push(`/messages?followerId=${each._fan?._id}`)
                        }
                      >
                        <i
                          className="fa fa-comment-o"
                          style={{ verticalAlign: 1 }}
                        />
                      </Button>
                      <Button title="Add Tag" id={`web_view_tag_${each._id}`}>
                        <i className="fa fa-tag" />
                      </Button>
                    </ButtonGroup>

                    <AddFanToTagPopver
                      tags={tags || []}
                      fanId={each?._fan?._id}
                      groups={each?._fan?.groups || []}
                      target={`web_view_tag_${each._id}`}
                      btnText="Save"
                    />
                  </td>
                </tr>
              ))
            ) : loadingState?.data ? (
              <SkeletonLoading type={"table"} rows={1} col={9} />
            ) : (
              <tr>
                <td colSpan={9} className="text-center">
                  No Subscribers Yet
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <Row className="noMargin d-lg-none mt-3">
        {followers?.length ? (
          followers.map((each, index) => (
            <Col sm="6" key={index} className="px-2 px-sm-3">
              <Card
                className={`fansInfoCard ${
                  dataPayload?.search === each._fan?.username
                    ? "highlightRow"
                    : ""
                }`}
                key={index}
              >
                <CardHeader>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    {...(index === followers.length - 1
                      ? { ref: mobileLastElementRef }
                      : {})}
                  >
                    <CustomInput
                      type="checkbox"
                      id={`tag_checkbox_${each._id}`}
                      label=""
                      checked={
                        selectedFanIds?.includes(each?._fan?._id) ? true : false
                      }
                      onChange={(e) =>
                        _toggleSelectedFanIds(each?._fan?._id, e.target.checked)
                      }
                    />

                    <div className="fanInfo mr-auto">
                      <img
                        src={
                          getLowResolutionLink(each._fan?.profilePicture) ||
                          DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile"
                        className="userImg mr-1"
                        loading="lazy"
                      />
                      <div>
                        <p>{capitalize(getFullName(each._fan?.name))}</p>
                        <Button
                          color="link"
                          className="profileID otherPgsProfileID"
                        >
                          @{each._fan?.username}
                        </Button>
                      </div>
                    </div>
                    {/* Note:  show the below text when the subscribers are expired */}
                    {each?.isExpired && (
                      <span className="expiredSubscriberProfile ml-1">
                        Expired
                      </span>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <ListGroup>
                    <ListGroupItem>
                      <div>Subscription Rate</div>
                      <div>
                        {each?.amount ? formatCurrencyValue(each.amount) : "-"}
                      </div>
                    </ListGroupItem>
                    <ListGroupItem>
                      <div>Duration</div>
                      <div>{_getBillingCycleMonth(each)}</div>
                    </ListGroupItem>
                    <ListGroupItem>
                      <div>Subscribed Tier</div>
                      <div>{each.tier ? capitalize(each.tier) : "-"}</div>
                    </ListGroupItem>
                    <ListGroupItem>
                      <div>Subscription Paid</div>
                      <div>
                        {formatCurrencyValue(each?.categorySpent?.subscription)}
                      </div>
                    </ListGroupItem>
                    <ListGroupItem>
                      <div>Notes</div>
                      <Input
                        name="text-input"
                        type="text"
                        className="notesFan"
                        value={each.notes ? each.notes : ""}
                        disabled={loadingState.notes}
                        onChange={(e) =>
                          _updateNote(e.target.value, each, index)
                        }
                        onBlur={() => _saveNote(index)}
                      />
                    </ListGroupItem>
                    <ListGroupItem>
                      <div>Total</div>
                      <span
                        className="cursorPointer themeColor"
                        onClick={() =>
                          _toggleFanCostBreakdownModal(true, each._fan?._id)
                        }
                      >
                        {formatCurrencyValue(each?.lifetimeSpent)}
                      </span>
                      {/* <div>{formatCurrencyValue(each?.lifetimeSpent)}</div> */}
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
                <CardFooter>
                  {/* Add a tooltip for each of the btns below, The text will be: "Favourite, Mute, Block & Message" respectively */}
                  <Button
                    title={each?.isFavourited ? "Remove Favorite" : "Favorite"}
                    onClick={() => _onFavouriteBtnClick(each)}
                    disabled={loadingState.action}
                  >
                    <i
                      className={
                        each.isFavourited
                          ? "fa fa-star themeColor"
                          : "fa fa-star-o"
                      }
                    />
                  </Button>
                  <Button
                    title={each?.isMuted ? "Unmute" : "Mute"}
                    onClick={() => _toggleMute(each)}
                    disabled={loadingState.action}
                  >
                    <i
                      className={
                        each.isMuted
                          ? "fa fa-volume-off text-danger"
                          : "fa fa-volume-up"
                      }
                    />
                  </Button>
                  <Button
                    title={each?.isBlocked ? "Unblock" : "Block"}
                    onClick={() => _toggleBlock(each)}
                    disabled={loadingState.action}
                  >
                    <i
                      className={`fa fa-ban ${
                        each.isBlocked ? "text-danger" : ""
                      }`}
                    />
                  </Button>
                  <Button
                    title="Message"
                    onClick={() =>
                      history.push(`/messages?followerId=${each._fan?._id}`)
                    }
                  >
                    <i
                      className="fa fa-comment-o"
                      style={{ verticalAlign: 1 }}
                    />
                  </Button>
                  <Button title="Add Tag" id={`mobile_view_tag_${each._id}`}>
                    <i className="fa fa-tag" />
                  </Button>

                  <AddFanToTagPopver
                    tags={tags || []}
                    fanId={each?._fan?._id}
                    groups={each?._fan?.groups || []}
                    target={`mobile_view_tag_${each._id}`}
                    btnText="Save"
                  />
                </CardFooter>
              </Card>
            </Col>
          ))
        ) : loadingState?.data ? (
          <SkeletonLoading type={"vault"} count={4} />
        ) : (
          <Col xs="12">
            <div className="noContentFound mt-0">No Subscribers Yet</div>
          </Col>
        )}
      </Row>

      {followers?.length < followersCount && loadingState?.data && (
        <div className="spinnerWrap text-center">
          <SkeletonLoading type="box" count={1} height={100} />
        </div>
      )}

      <FanCostBreakdownModal
        isOpen={fanCostBreakdownModal.isOpen}
        category={fanCostBreakdownModal.category}
        toggle={() => _toggleFanCostBreakdownModal()}
      />

      <CustomPrompt
        isOpen={customPrompt.isOpen}
        message={customPrompt.message}
        metadata={customPrompt.metadata}
        successButtonText="Yes"
        closeButtonText="No"
        onSuccess={(metadata) => _onPromptSuccess(metadata)}
        onDismiss={() => _toggleCustomPrompt()}
      />
    </>
  );
};

export default FollowerListTab;
