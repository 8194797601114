import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Progress, Collapse } from "reactstrap";

const ProgressTracking = () => {
  const userData = useSelector((state) => state?.userData?.user);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const overAllProgressValue = useMemo(() => {
    const total = 17;
    let count = 0;

    // # 1 - Pay-in KYC
    if (userData?.paymentEnabled) {
      count++;
    }
    // # 2 - Pay-out KYC
    if (userData?.payoutEnabled) {
      count++;
    }

    // # 3 - Profile Picture
    if (userData?.profilePicture) {
      count++;
    }
    // # 4 - Cover Pic
    if (userData?.carousel?.length) {
      count++;
    }
    // # 5 - Username
    if (userData?.username) {
      count++;
    }
    // # 6 - Category
    if (userData?.category) {
      count++;
    }
    // # 7 - About
    if (userData?.introduction) {
      count++;
    }

    // # 8 - Upload 1st Post
    if (userData?.progressTracker?.uploadFirstPost) {
      count++;
    }
    // # 9 - Add A Vault
    if (userData?.progressTracker?.addAVault) {
      count++;
    }
    // # 10 - Host Live Event
    if (userData?.progressTracker?.hostLiveEvent) {
      count++;
    }
    // # 11 - Add A Story
    if (userData?.progressTracker?.addStory) {
      count++;
    }
    // # 12 - Add A PPV
    if (userData?.progressTracker?.addPPV) {
      count++;
    }
    // # 13 - Send A Message
    if (userData?.progressTracker?.sendAMessage) {
      count++;
    }

    // # 14 - Subscription Setup
    if (userData?.subscriptionFees?.amount) {
      count++;
    }
    // # 15 - Welcome Message Setup
    if (userData?.welcomeMessage?.isActive) {
      count++;
    }
    // # 16 - 1st Sale
    if (userData?.progressTracker?.firstSale) {
      count++;
    }
    // # 17 - 1st Payout
    if (userData?.progressTracker?.firstPayout) {
      count++;
    }

    return Math.ceil((count * 100) / total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <div className="progressTrackingWrapper">
        <div onClick={toggle} className="progressTrackingTxt">
          <div className="d-flex align-items-center">
            Progress Tracking <Progress value={overAllProgressValue} />
          </div>
          <i className={`fa fa-arrow-${isOpen ? "down" : "right"}`} />
        </div>

        <Collapse isOpen={isOpen} className="progressTrackingWrap">
          <h6>Account Creation</h6>
          <div className="d-flex flex-wrap flex-lg-nowrap">
            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Pay-in KYC</p>

                {userData?.paymentEnabled ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.paymentEnabled ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Pay-out KYC</p>

                {userData?.payoutEnabled ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.payoutEnabled ? 100 : 0} />
            </div>
          </div>

          <h6>Profile Setup</h6>

          <div className="d-flex flex-wrap flex-lg-nowrap">
            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Profile Picture</p>

                {userData?.profilePicture ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.profilePicture ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Cover Pic</p>

                {userData?.carousel?.length ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.carousel?.length ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Username</p>

                {userData?.username ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.username ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Category</p>

                {userData?.category ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.category ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>About</p>

                {userData?.introduction ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.introduction ? 100 : 0} />
            </div>
          </div>

          <h6>Content Management</h6>

          <div className="d-flex flex-wrap flex-lg-nowrap">
            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Upload 1st Post</p>

                {userData?.progressTracker?.uploadFirstPost ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress
                value={userData?.progressTracker?.uploadFirstPost ? 100 : 0}
              />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Add A Vault</p>

                {userData?.progressTracker?.addAVault ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress
                value={userData?.progressTracker?.addAVault ? 100 : 0}
              />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Host Live Event</p>

                {userData?.progressTracker?.hostLiveEvent ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress
                value={userData?.progressTracker?.hostLiveEvent ? 100 : 0}
              />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Add A Story</p>

                {userData?.progressTracker?.addStory ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.progressTracker?.addStory ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Add A PPV</p>

                {userData?.progressTracker?.addPPV ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.progressTracker?.addPPV ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Send A Message</p>

                {userData?.progressTracker?.sendAMessage ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress
                value={userData?.progressTracker?.sendAMessage ? 100 : 0}
              />
            </div>
          </div>

          <h6>Monetization</h6>

          <div className="d-flex flex-wrap flex-lg-nowrap">
            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Subscription Setup</p>

                {userData?.subscriptionFees?.amount ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.subscriptionFees?.amount ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>Welcome Message Setup</p>

                {userData?.welcomeMessage?.isActive ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress value={userData?.welcomeMessage?.isActive ? 100 : 0} />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>1st Sale</p>

                {userData?.progressTracker?.firstSale ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress
                value={userData?.progressTracker?.firstSale ? 100 : 0}
              />
            </div>

            <div className="progressTracking">
              <div className="d-flex justify-content-between align-items-end">
                <p>1st Payout</p>

                {userData?.progressTracker?.firstPayout ? (
                  <i className="fa fa-check text-success" />
                ) : null}
              </div>

              <Progress
                value={userData?.progressTracker?.firstPayout ? 100 : 0}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default ProgressTracking;
