import React, { useEffect, useRef, useState } from "react";
import { CircleStencil, Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FlipHorizontal from "../../assets/img/flip-image.png";
import FlipVertical from "../../assets/img/flip-vertical.png";
import RotateLeft from "../../assets/img/rotate-left.png";
import RotateRight from "../../assets/img/rotate-right.png";

const ImageCropUploaderModal = ({
  isOpen,
  selectedPhoto,
  onSuccess,
  onDismiss,
  resetPhoto,
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const imageRef = useRef(null);

  const _onSuccess = () => {
    const canvas = imageRef.current?.getCanvas();
    const image = canvas?.toDataURL();
    console.log(image);
    canvas?.toBlob((blob) => {
      onSuccess(image, blob);
    });
  };

  const _onClose = () => {
    resetPhoto();
    onDismiss();
  };

  const _onFileChange = (selectedPhoto) => {
    if (selectedPhoto?.type?.includes("image")) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageSrc(reader.result));
      reader.readAsDataURL(selectedPhoto);
    }
  };

  const flip = (horizontal, vertical) => {
    if (imageRef.current) {
      imageRef.current.flipImage(horizontal, vertical);
    }
  };

  const onRotate = (angle) => {
    if (imageRef.current) {
      imageRef.current.rotateImage(angle);
    }
  };

  useEffect(() => {
    _onFileChange(selectedPhoto);
  }, [selectedPhoto]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => _onClose()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      size="lg" // Use large size for the modal
    >
      <ModalHeader id="alert-dialog-slide-title">Edit Image</ModalHeader>
      <ModalBody className="customImageCropModal">
        {imageSrc?.length ? (
          <>
            <div
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "800px",
                margin: "0 auto",
              }}
            >
              <Cropper
                stencilComponent={CircleStencil}
                src={imageSrc}
                ref={imageRef}
                stencilProps={{
                  overlayClassName: "overlay", // Add this property to specify the overlay class
                }}
                style={{
                  margin: "0 auto",
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "90vh",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              className="buttons-container"
              style={{ textAlign: "center", marginTop: "5px" }}
            >
              <Button className="icon-btn" onClick={() => flip(true, false)}>
                <img
                  src={FlipHorizontal}
                  alt="Flip Horizontal"
                  loading="lazy"
                  width="30px"
                />
              </Button>
              <Button className="icon-btn" onClick={() => flip(false, true)}>
                <img
                  src={FlipVertical}
                  alt="Flip Vertical"
                  loading="lazy"
                  width="30px"
                />
              </Button>
              <Button className="icon-btn" onClick={() => onRotate(90)}>
                <img
                  src={RotateLeft}
                  alt="Rotate Left"
                  loading="lazy"
                  width="30px"
                />
              </Button>
              <Button className="icon-btn" onClick={() => onRotate(-90)}>
                <img
                  src={RotateRight}
                  alt="Rotate Right"
                  loading="lazy"
                  style={{
                    width: "30px",
                  }}
                />
              </Button>
            </div>
          </>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _onClose()}>
          Cancel
        </Button>
        <Button className="modalBtnSave" onClick={() => _onSuccess()}>
          Save
        </Button>
      </ModalFooter>

      <style>
        {`
          .icon-btn {
            background: linear-gradient(90deg, rgba(41,10,179,1) 0%, rgba(134,0,173,1) 100%);
            border: none;
            padding: 10px 15px;
            margin: 0 10px;
            border-radius: 10%;
            transition: all 0.3s ease;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
          .icon-btn img {
            transition: transform 0.3s ease;
          }
          .icon-btn:hover {
            background: linear-gradient(90deg, rgba(85,114,255,1) 0%, rgba(242,148,254,1) 100%);
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
            transform: translateY(-4px);
          }
          .icon-btn:hover img {
            transform: scale(1.1);
          }
          .overlay {
            color: #601f55eb;
            opacity: 0.5;
          }
        `}
      </style>
    </Modal>
  );
};

export default ImageCropUploaderModal;
