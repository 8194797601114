import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  getCommentIconClassName,
  getLikeIconClassName,
  getLowResolutionLink,
  getPostedDateValue,
  getPostedTimeValue,
  getFullName,
  isPermissionToAccess,
  mentionUserInputParse,
  reactHtmlParser,
  shareableLink,
} from "../../helper-methods";
import ReadMoreReadLess from "../custom/ReadMoreReadLess";
import PollOptionComponent from "../PollOptionComponent";
import PollVotesDetailsModal from "../modals/PollVotesDetailsModal";

const PollViewer = ({
  feed,
  feedIndex,
  toggleCommentSection,
  pinToProfile,
  deletePost,
}) => {
  const history = useHistory();

  const userData = useSelector((state) => state?.userData);

  const [pollVotesDetailsModal, setPollVotesDetailsModal] = useState({
    isOpen: false,
    data: null,
  });

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  const canEditOrDeletePost = useMemo(() => {
    return isPermissionToAccess("feed", "canEditOrDeletePost", true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // const _toggleDropdown = () => {
  //   setDropdownOpen((prev) => !prev);
  // };

  const _togglePollVotesDetailsModal = (isOpen = false, data = null) => {
    console.log({ isOpen, data });
    setPollVotesDetailsModal({ isOpen, data });
  };

  return (
    <>
      <Card className="cardFeed">
        <CardHeader onClick={() => history.push(`/post/${feed?.id}`)}>
          {userData?.user ? (
            <div className="d-flex align-items-center">
              <img
                src={
                  getLowResolutionLink(userData?.user?.profilePicture) ||
                  DEFAULT_PROFILE_PICTURE
                }
                onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                alt="Profile Img"
                className="userImg mr-1"
                loading="lazy"
              />
              <div>
                <h4>{getFullName(userData?.user?.name)}</h4>
                <Button color="link" className="profileID">
                  @{userData?.user?.username || "N/A"}
                </Button>
              </div>
            </div>
          ) : null}

          {feed?.isPinned ? (
            <svg
              className="pinnedPost"
              xmlns="http://www.w3.org/2000/svg"
              width="253.712"
              height="256"
              viewBox="0 0 253.712 256"
            >
              <path
                id="office-push-pin_73169"
                d="M252.92,73.317,181.538,1.935a6.6,6.6,0,0,0-9.338,0l-.3.3A23.157,23.157,0,0,0,168,29.83L93.315,93.93a31.166,31.166,0,0,0-42.782,1.226l-.448.448a6.6,6.6,0,0,0,0,9.338l42.764,42.764L51.2,189.353c-.833.857-20.547,21.172-33.508,37.338C5.349,242.082,2.908,244.9,2.782,245.047a6.6,6.6,0,0,0,9.319,9.319c.11-.1,2.865-2.49,18.356-14.91,16.164-12.961,36.478-32.676,37.4-33.575L109.44,164.3l40.474,40.474a6.6,6.6,0,0,0,9.338,0l.448-.448a31.166,31.166,0,0,0,1.226-42.782l64.1-74.689a23.157,23.157,0,0,0,27.592-3.894l.3-.3A6.6,6.6,0,0,0,252.92,73.317Z"
                transform="translate(-1.143 0)"
              />
            </svg>
          ) : null}
        </CardHeader>
        <CardBody>
          {feed?.description?.length ? (
            <div className="customPaddingMob">
              <ReadMoreReadLess
                className="readMore"
                text={reactHtmlParser(feed.description)}
              />
            </div>
          ) : null}

          {feed?.text?.length ? (
            <div className="customPaddingMob">
              {mentionUserInputParse(feed?.text)}
            </div>
          ) : null}

          <>
            {/* poll options should always be wrapped inside the below div */}
            <div className="pollOptionWrap">
              {feed?.options?.length
                ? feed.options.map((option, optionIndex) => (
                    <PollOptionComponent
                      key={`polloption_${optionIndex}`}
                      post={feed}
                      option={option}
                      feed={feed}
                      feedIndex={feedIndex}
                      optionIndex={optionIndex}
                    />
                  ))
                : null}
            </div>
            {/* total vote count */}
            {feed?._opinions?.length ? (
              <Button
                color="link"
                className="totalVotes"
                onClick={() =>
                  _togglePollVotesDetailsModal(true, feed?._opinions)
                }
                style={{ textDecoration: "underline" }}
              >
                {`${feed._opinions.length} ${
                  feed._opinions.length > 1 ? "Votes" : "Vote"
                }`}
              </Button>
            ) : (
              <div className="totalVotes">0 Votes</div>
            )}
          </>
        </CardBody>

        <CardFooter className="footerFeed">
          <div className="d-flex">
            <Button className="mr-2 mr-sm-3">
              <i className={getLikeIconClassName(feed)} />
            </Button>

            <Button
              className="mr-2 mr-sm-3"
              onClick={() => toggleCommentSection()}
            >
              <i className={getCommentIconClassName(feed)} />
            </Button>

            {canEditOrDeletePost ? (
              <div className="ml-auto">
                <Button
                  className="ml-2 ml-sm-3"
                  onClick={() => pinToProfile(feed?.isPinned || false)}
                  title="Pin"
                >
                  {/* <img src="../../assets/img/pin-post.png" alt="Pin Post" /> */}
                  <svg
                    id="Capa_1"
                    enable-background="new 0 0 512 512"
                    height="512"
                    viewBox="0 0 512 512"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <g>
                        <path d="m506.143 175.563c-22.316-22.315-150.052-150.05-169.707-169.705-7.811-7.811-20.475-7.811-28.285 0s-7.81 20.474 0 28.284l14.143 14.143-106.288 106.288c-26.524-5.251-54.055-4.492-80.3 2.287-30.98 8.002-59.374 24.25-82.112 46.989-7.811 7.81-7.81 20.473 0 28.284l98.995 98.995-146.732 146.73c-7.81 7.81-7.81 20.474 0 28.284s20.474 7.811 28.285 0l146.73-146.73 98.994 98.994c3.906 3.906 9.023 5.858 14.143 5.858 5.118 0 10.237-1.953 14.143-5.858 22.737-22.738 38.986-51.132 46.988-82.112 6.779-26.245 7.538-53.775 2.287-80.3l106.289-106.289 14.142 14.142c7.808 7.81 20.473 7.811 28.284 0 7.81-7.81 7.81-20.473.001-28.284zm-184.842 99.989c-5.048 5.048-7.022 12.405-5.181 19.302 11.131 41.68 2.252 86.072-23.134 120.102l-97.967-97.967c-.001-.001-.002-.003-.003-.004s-.002-.002-.004-.003l-97.968-97.968c34.031-25.386 78.424-34.264 120.102-23.134 6.897 1.842 14.254-.132 19.303-5.181l114.13-114.131 84.853 84.852z" />
                      </g>
                    </g>
                  </svg>
                </Button>
                <Button
                  className="ml-2 ml-sm-3"
                  onClick={() => shareableLink({ type: "post", id: feed?._id })}
                  title="Share"
                >
                  <img
                    src="../../assets/img/quick-link.png"
                    alt="Share"
                    style={{ height: 19, verticalAlign: -2 }}
                  />
                </Button>
                <Button
                  className="ml-2 ml-sm-3"
                  onClick={() => deletePost()}
                  title="Delete"
                >
                  <i className="fa fa-trash-o" />
                </Button>
              </div>
            ) : // <Dropdown
            //   className="customDropdown customDropdown-Feed ml-auto"
            //   isOpen={dropdownOpen}
            //   toggle={() => _toggleDropdown()}
            // >
            //   <DropdownToggle>
            //     <img
            //       src="/assets/img/dots-black.png"
            //       alt="Dots"
            //       style={{
            //         verticalAlign: 2,
            //         height: 4,
            //       }}
            //       loading="lazy"
            //     />
            //   </DropdownToggle>
            //   <DropdownMenu right>
            //     <DropdownItem
            //       onClick={() => pinToProfile(feed?.isPinned || false)}
            //     >
            //       {feed?.isPinned ? "Unpin this post" : "Pin this post"}
            //     </DropdownItem>
            //     <DropdownItem
            //       onClick={() => shareableLink({ type: "post", id: feed?._id })}
            //     >
            //       Quick Link
            //     </DropdownItem>
            //     <DropdownItem
            //       onClick={() => deletePost()}
            //       className="textWarning"
            //     >
            //       Delete Post
            //     </DropdownItem>
            //   </DropdownMenu>
            // </DropdownToggle>
            null}
          </div>

          {feed?.likes || feed?.comments ? (
            <div className="mt-1">
              {feed?.likes ? (
                <span>
                  {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
                </span>
              ) : null}
              {feed?.comments ? (
                <span>
                  {feed?.comments} {feed?.comments > 1 ? "Comments" : "Comment"}
                </span>
              ) : null}
            </div>
          ) : null}

          {/* post time */}
          <div className="postTime-Feed">
            {(feed?.postType === "scheduled" ||
              feed?.postType === "scheduled-temporary") &&
            new Date() < new Date(feed?.startDate) ? (
              <div className="d-flex">
                <div className="mr-1">Scheduled:</div>
                {getPostedDateValue(feed?.startDate)},{" "}
                {getPostedTimeValue(feed?.startDate)}
              </div>
            ) : (
              getPostedDateValue(feed?.startDate)
            )}
          </div>
        </CardFooter>
      </Card>

      <PollVotesDetailsModal
        isOpen={pollVotesDetailsModal?.isOpen}
        data={pollVotesDetailsModal?.data}
        optionsList={feed?.options || []}
        toggle={_togglePollVotesDetailsModal}
      />
    </>
  );
};

export default PollViewer;
