import React, { Fragment, useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  CustomInput,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
} from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../config";
import {
  errorHandler,
  getPostedDateValue,
  showToast,
  uploadFileOnServer,
} from "../helper-methods";
import {
  deleteVaultFolderContent,
  getFolderContentsDetails,
  postFolderContents,
  saveContents,
} from "../http-calls";
import { hideLoader, showLoader } from "../redux/actions";
import CustomPrompt from "./modals/CustomPrompt";
import EditVaultContentNameModal from "./modals/EditVaultContentNameModal";
import MediaLibraryModal from "./modals/MediaLibraryModal";
import MultiMediaModal from "./modals/MultiMediaModal";
import SkeletonLoading from "./SkeletonLoading";
import SvgIcons from "./SvgIcons";

const VaultContentsComponent = ({ vaultData, setVaultData, canEdit }) => {
  const observer = useRef();

  const dispatch = useDispatch();

  const [contents, setContents] = useState([]);
  const [contentsCount, setContentsCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
  });
  // const [contentsTypeCount, setContentsTypeCount] = useState({
  //   imageCount: 0,
  //   videoCount: 0,
  //   audioCount: 0,
  // });
  const [selectedVauldContent, setSelectedVauldContent] = useState({
    ids: [],
    deleteAll: false,
  });
  const [mediaLibraryModal, setMediaLibraryModal] = useState({
    isOpen: false,
    data: null,
  });
  const [customPrompt, setCustomPrompt] = useState({
    isOpen: false,
    message: "",
  });

  const [multiMediaModal, setMultiMediaModal] = useState({
    isOpen: false,
    contents: null,
    activeIndex: null,
  });

  const [loading, setLoading] = useState(false);
  const [editVaultContentNameModal, setEditVaultContentNameModal] = useState({
    isOpen: false,
    data: null,
  });
  const [dropdownOpen, setDropdownOpen] = useState({
    id: "",
    isOpen: false,
  });

  const _toggleDropdownOpen = (id = "") => {
    setDropdownOpen((prev) => ({
      isOpen: !prev.isOpen,
      id,
    }));
  };

  const _toggleEditVaultContentNameModal = (isOpen = false, data = null) => {
    setEditVaultContentNameModal({
      isOpen,
      data,
    });
  };

  const _onSaveContentName = (content, name) => {
    const newContents = [...contents];
    const findContent = newContents.find((each) => each._id === content?._id);
    if (findContent) {
      findContent.name = name;
      setContents(newContents);
    }
  };

  const _isVauldContentSelected = (id) => {
    if (
      selectedVauldContent.deleteAll ||
      selectedVauldContent.ids?.includes(id)
    ) {
      return true;
    }

    return false;
  };

  const _resetDataPayload = () => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    setDataPayload(newDataPayload);
    _getFolderContentsDetails(newDataPayload);
  };

  const _postFolderContents = async (filesList) => {
    try {
      const libraryFiles = [];
      const filesToUpload = [];

      filesList.forEach((each) => {
        if (each?.url) {
          libraryFiles.push({
            id: each?._id, //for avoiding repeatition of content via BE
            _id: each?._id,
            isNew: true,
            contentType: each.contentType,
            url: each.url,
            duration: each?.duration ? each?.duration : null,
          });
        } else {
          filesToUpload.push(each);
        }
      });

      let uploadedFiles = [];
      if (filesToUpload?.length) {
        let filesUploadedToServer = await uploadFileOnServer(filesToUpload);
        // upload files to server

        uploadedFiles = await saveContents({ contents: filesUploadedToServer });
        // upload to "/contents"
        uploadedFiles = uploadedFiles?.contents?.map((each) => ({
          _id: each?._id,
          isNew: true,
          contentType: each.contentType,
          url: each.url,
          duration: each?.duration ? each?.duration : null,
        }));
      }

      let finalList = [];
      if (uploadedFiles?.length) {
        finalList = uploadedFiles.concat(libraryFiles);
      } else {
        finalList = libraryFiles;
      }

      const res = await postFolderContents({
        contents: finalList,
        folderId: vaultData?._id,
      });

      showToast(
        finalList.length === 1
          ? "Content added successfully"
          : "Contents added successfully",
        "success"
      );

      if (finalList?.length) {
        _resetDataPayload();
      }

      setVaultData(res.folder);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _toggleMultiMediaModal = (
    isOpen = false,
    contents = null,
    activeIndex = 0
  ) => {
    setMultiMediaModal({
      isOpen,
      contents,
      activeIndex,
    });
  };

  const _toggleMediaLibraryModal = (isOpen = false, data) => {
    setMediaLibraryModal({ isOpen, data });
  };

  const _toggleCustomPrompt = ({ isOpen = false, message = "" }) => {
    setCustomPrompt({
      isOpen,
      message,
    });
  };

  const _onPromptSuccess = async () => {
    try {
      dispatch(showLoader("Deleting content..."));

      const payload = {
        folderId: vaultData?._id,
      };

      if (selectedVauldContent.deleteAll) {
        payload["deleteAll"] = selectedVauldContent.deleteAll;
      } else {
        payload["ids"] = selectedVauldContent.ids;
      }

      await deleteVaultFolderContent(payload);

      showToast("Deleted successfully", "success");

      let newContents = [...contents];
      let newContentsCount = contentsCount;

      if (payload.deleteAll) {
        newContents = [];
        newContentsCount = 0;
      } else {
        newContents = newContents.filter(
          (each) => !payload.ids?.includes(each._id)
        );
        newContentsCount = newContentsCount - payload.ids?.length;
      }

      setContents(newContents);
      setContentsCount(newContentsCount);

      setSelectedVauldContent({
        ids: [],
        deleteAll: false,
      });
      _toggleCustomPrompt({});

      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _deleteFolderContent = (contentId, isSingleDelete = false) => {
    if (isSingleDelete && contentId) {
      _toggleCustomPrompt({
        isOpen: true,
        message: "Do you want to delete this content?",
      });
      setSelectedVauldContent({
        ids: [contentId],
        deleteAll: false,
      });
    } else {
      if (
        selectedVauldContent.ids?.length ||
        (selectedVauldContent.deleteAll && contents)
      ) {
        _toggleCustomPrompt({
          isOpen: true,
          message: `Do you want to delete ${
            selectedVauldContent.deleteAll
              ? contentsCount
              : selectedVauldContent.ids?.length
          } ${
            selectedVauldContent.ids?.length > 1 ||
            (selectedVauldContent.deleteAll && contentsCount > 1)
              ? "contents"
              : "content"
          }?`,
        });
      }
    }
  };

  const _onChangeVauldContent = (id, value, isDeleteAll = false) => {
    const newSelectedVauldContent = { ...selectedVauldContent };

    if (isDeleteAll) {
      newSelectedVauldContent.deleteAll = value;
      newSelectedVauldContent.ids = [];
    } else {
      if (newSelectedVauldContent.deleteAll) {
        newSelectedVauldContent.deleteAll = false;
        const filterShowingContent = contents.filter((each) => each.id !== id);
        newSelectedVauldContent.ids = filterShowingContent.length
          ? filterShowingContent.map((each) => each.id)
          : [];
      } else {
        if (value) {
          newSelectedVauldContent.ids.push(id);
        } else {
          const idIndex = newSelectedVauldContent.ids.findIndex(
            (each) => each === id
          );
          newSelectedVauldContent.ids.splice(idIndex, 1);
        }
      }
    }

    setSelectedVauldContent(newSelectedVauldContent);
  };

  const _getFolderContentsDetails = async (payload) => {
    try {
      setLoading(true);

      const res = await getFolderContentsDetails(vaultData?._id, payload);

      setContents((prev) =>
        payload?.skip ? prev.concat(res.contents) : res.contents
      );
      setContentsCount(res.count);

      // setContentsTypeCount({
      //   imageCount: res.imageCount,
      //   videoCount: res.videoCount,
      //   audioCount: res.audioCount,
      // });

      // setLoading(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const _onDropFile = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _toggleMediaLibraryModal(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && contents?.length < contentsCount) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = contents?.length;
          setDataPayload(newDataPayload);
          _getFolderContentsDetails(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (vaultData) {
      _getFolderContentsDetails(dataPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultData]);

  return (
    <>
      <div className="vaultFolderDetailsWrap">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h4 className="filesTxt">Files</h4>

          {/* {canEdit ? (
            <>
              <SvgIcons type="uploadPlusIcon" />
              <Button
                className="themeBtn addBtn"
                onClick={() => _toggleMediaLibraryModal(true)}
              >
                Upload
              </Button>
            </>
          ) : null} */}
        </div>

        <div className="d-flex justify-content-between align-items-center">
          {selectedVauldContent.ids.length &&
          selectedVauldContent.ids.length !== contentsCount ? (
            <div>
              <Button
                color="link"
                className="themeColor py-1 px-0"
                onClick={() => _onChangeVauldContent(null, true, true)}
              >
                Select All {contentsCount}
              </Button>
            </div>
          ) : null}

          {(selectedVauldContent.ids.length &&
            selectedVauldContent.ids.length === contentsCount) ||
          (selectedVauldContent.deleteAll && contentsCount) ? (
            <div>
              <Button
                color="link"
                className="textWarning py-1 px-0"
                onClick={() => _onChangeVauldContent(null, false, true)}
              >
                Clear All {contentsCount}
              </Button>
            </div>
          ) : null}

          {selectedVauldContent.ids.length ||
          (selectedVauldContent.deleteAll && contentsCount) ? (
            <div className="d-flex align-items-center">
              <span style={{ color: "#4f4f4f" }}>
                {selectedVauldContent.deleteAll
                  ? contentsCount
                  : selectedVauldContent.ids.length}{" "}
                {selectedVauldContent.ids.length > 1 ||
                (selectedVauldContent.deleteAll && contentsCount > 1)
                  ? "Files"
                  : "File"}{" "}
                Selected
              </span>

              <Button
                title="Delete"
                color="link"
                className="p-1 textWarning ml-2"
                onClick={() => _deleteFolderContent()}
              >
                <i className="fa fa-trash-o" />
              </Button>
            </div>
          ) : null}
        </div>

        {canEdit ? (
          <div
            className="vaultMediaWrap"
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              _onDropFile(e);
            }}
          >
            <Label className="vaultMedia cursorPointer">
              <Button
                className="d-none"
                onClick={() => _toggleMediaLibraryModal(true)}
                disabled={loading}
              />

              {loading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <SvgIcons type="uploadPlusIcon" />
              )}
            </Label>
          </div>
        ) : null}

        {contents?.length ? (
          <Row className="vaultFolderRow mt-2">
            {contents.map((each, index) => (
              <Fragment key={`vault_content_${each?._id}_${index}`}>
                <Col
                  xs="6"
                  md="4"
                  xl="3"
                  key={index}
                  className="customColPadding"
                >
                  <div
                    className="vaultMediaThumbWrap"
                    {...(index === contents.length - 1
                      ? { ref: lastElementRef }
                      : {})}
                  >
                    {canEdit ? (
                      <div className="selectFile">
                        <CustomInput
                          type="checkbox"
                          id={`selectVaultContentFile__${each._id}`}
                          checked={_isVauldContentSelected(each._id)}
                          onChange={(e) =>
                            _onChangeVauldContent(each._id, e.target.checked)
                          }
                        />

                        {!_isVauldContentSelected(each._id) ? (
                          <Dropdown
                            className="customDropdown customDropdown-Feed"
                            isOpen={
                              each._id === dropdownOpen.id &&
                              dropdownOpen.isOpen
                            }
                            toggle={() => {
                              _toggleDropdownOpen(each._id);
                            }}
                          >
                            <DropdownToggle>
                              <img
                                src="/assets/img/dots.png"
                                alt="Dots"
                                style={{ width: 18 }}
                                loading="lazy"
                              />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem
                                onClick={() =>
                                  _toggleEditVaultContentNameModal(true, each)
                                }
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  _deleteFolderContent(each._id, true)
                                }
                                className="textWarning"
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        ) : null}
                      </div>
                    ) : null}
                    <div
                      onClick={() =>
                        _toggleMultiMediaModal(true, contents, index)
                      }
                    >
                      <div className="shadowOverlayImg" />

                      <img
                        className="vaultMediaThumb"
                        src={
                          each.contentType === "image"
                            ? each.thumbnail ||
                              require("../assets/img/image_icon.svg")
                            : each.contentType === "doc"
                            ? "/assets/img/pdf_icon.png"
                            : each.contentType === "audio"
                            ? "/assets/img/mic.png"
                            : each.contentType === "video"
                            ? each.thumbnail || "/assets/img/video-file.png"
                            : DEFAULT_COVER_PICTURE
                        }
                        onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                        alt="Vault Media"
                        loading="lazy"
                      />

                      <div className="vaultMediaInfo">
                        {each.name ? <p>{each.name}</p> : null}

                        <div className="d-flex justify-content-between">
                          {/* <span>{formatFileSize(each.size)}</span> */}

                          <span>
                            {each.createdAt
                              ? getPostedDateValue(each.createdAt)
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                {index === contents.length - 1 && loading && (
                  <SkeletonLoading type={"vault"} count={12} />
                )}
              </Fragment>
            ))}
          </Row>
        ) : loading ? (
          <Row>
            <SkeletonLoading type={"vault"} count={12} />
          </Row>
        ) : (
          <div className="noContentFound">There is no content to display</div>
        )}
      </div>

      <MediaLibraryModal
        isOpen={mediaLibraryModal.isOpen}
        toggle={() => _toggleMediaLibraryModal()}
        dropFileData={mediaLibraryModal.data}
        uploadContents={(contents) => _postFolderContents(contents)}
        isMultipleImages={true}
        isMultipleVideos={true}
        isMultipleAudios={true}
        isMultipleUpload={true}
        isPdfUpload={true}
        isAudioUpload={true}
        isVideoUpload={true}
        isImageUpload={true}
      />

      <MultiMediaModal
        isOpen={multiMediaModal.isOpen}
        contents={multiMediaModal.contents}
        activeIndex={multiMediaModal.activeIndex}
        toggle={() => _toggleMultiMediaModal()}
        isHideTotalLength={true}
      />

      <CustomPrompt
        isOpen={customPrompt.isOpen}
        message={customPrompt.message}
        successButtonText="Yes"
        closeButtonText="No"
        onSuccess={() => _onPromptSuccess()}
        onDismiss={() => _toggleCustomPrompt({})}
      />

      <EditVaultContentNameModal
        isOpen={editVaultContentNameModal.isOpen}
        data={editVaultContentNameModal.data}
        toggle={() => _toggleEditVaultContentNameModal()}
        onSave={(content, name) => _onSaveContentName(content, name)}
      />
    </>
  );
};

export default VaultContentsComponent;
