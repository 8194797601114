import React, { useState, useEffect, useRef } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import Pagination from "react-js-pagination";
import { showToast, uploadFileOnServer } from "../../helper-methods";
import ButtonSpinner from "../ButtonSpinner";
import { getMediaContents } from "../../http-calls";
import AudioRangePlayer from "../AudioRangePlayer";
import SkeletonLoading from "../SkeletonLoading";

function AddAudioStoryModal({ saveAudio, getRange }) {
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [recordedFile, setRecordedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allAudio, setAllAudio] = useState([]);
  const [duration, setDuration] = useState(null);
//   const [range, setRange] = useState([0, 15]);
  const [totalAudios, setTotalAudios] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    contentType: "audio",
    skip: 0,
    limit: 12,
    pageNumber: 1,
  });

  // Refs for audio elements
  const audioRefs = useRef({});
  const currentAudioRef = useRef(null);
  const isMounted = useRef(true);

  const MediaLibraryLoading = (
    <>
      {[...Array(12)].map((_, index) => (
        <Col
          xs={6}
          md={4}
          lg={3}
          className="px-1"
          key={`media_video_loading_${index}`}
          style={{ marginBottom: 4 }}
        >
          <SkeletonLoading type="box" count={1} width={"100%"} height={152} />
        </Col>
      ))}
    </>
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
      // Clean up audio elements
      if (recordedFile?.blobURL) {
        URL.revokeObjectURL(recordedFile.blobURL);
      }
      if (uploadedFile?.blobURL) {
        URL.revokeObjectURL(uploadedFile.blobURL);
      }
    };
  }, []);

  const toggle = () => {
    if (isMounted.current) {
      setModal(!modal);
      if (!modal) {
        resetModalState();
      }
    }
  };

  const resetModalState = () => {
    setRecordedFile(null);
    setSelectedAudio(null);
    setUploadedFile(null);
    setDuration(null);
  };

  const closeModal = () => {
    resetModalState();
    toggle();
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab && isMounted.current) {
      setActiveTab(tab);
    }
  };

  const addAudioElement = (blob) => {
    try {
      const url = URL.createObjectURL(blob);
      if (isMounted.current) {
        setRecordedFile({ blob, blobURL: url });
        setUploadedFile(null);
        setSelectedAudio(null);
        setDuration(null);
      }
    } catch (error) {
      showToast("Error processing recorded audio", "error");
      console.error("Error processing blob: ", error);
    }
  };

  const handleFileUpload = (files) => {
    if (files && files.length > 0) {
      const uploadedFile = files[0];
      const fileURL = URL.createObjectURL(uploadedFile);
      if (isMounted.current) {
        setUploadedFile({ blob: uploadedFile, blobURL: fileURL });
        setRecordedFile(null);
        setSelectedAudio(null);
        setDuration(null);
      }
    }
  };

  const handleSaveAudio = async () => {
    if (!isMounted.current) return;

    if (recordedFile?.blob || uploadedFile?.blob) {
      setIsLoading(true);
      try {
        const uploadResponse = await uploadFileOnServer(
          [{ uploadData: recordedFile?.blob || uploadedFile?.blob }],
          true
        );
        await saveAudio(uploadResponse?.[0]?.url || "");
        closeModal();
      } catch (error) {
        showToast("Failed to upload audio", "error");
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    } else if (selectedAudio) {
      await saveAudio(selectedAudio.url);
      closeModal();
    } else {
      showToast(
        "Please record, upload, or select an audio file first",
        "error"
      );
    }
  };

  const fetchAudioContents = async () => {
    try {
      setLoading(true);
      const response = await getMediaContents(filter);
      if (isMounted.current) {
        setAllAudio(response.contents || []);
        setTotalAudios(response.audioCount || 0);
      }
    } catch (error) {
      showToast("Failed to fetch audio contents", "error");
      console.error("Fetch audio error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNum) => {
    const newSkip = (pageNum - 1) * filter.limit;
    if (isMounted.current) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        pageNumber: pageNum,
        skip: newSkip,
      }));
    }
  };

  const handleAudioLoad = (e, audioId) => {
    if (!isMounted.current) return;

    // Only process if this is the currently selected audio or the current source
    const isCurrentAudio =
      (selectedAudio && selectedAudio._id === audioId) ||
      (!selectedAudio && (audioId === "recorded" || audioId === "uploaded"));

    if (!isCurrentAudio) return;

    // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
    if (e.target.duration === Infinity) {
      e.target.currentTime = Number.MAX_SAFE_INTEGER;
      e.target.ontimeupdate = () => {
        if (!isMounted.current) return;
        e.target.ontimeupdate = null;
        setDuration(e.target.duration);
        e.target.currentTime = 0;
      };
    } else {
      setDuration(e.target.duration);
    }
  };

  useEffect(() => {
    if (modal && activeTab === "2" && isMounted.current) {
      fetchAudioContents();
    }
  }, [filter, modal, activeTab]);

  // Reset duration when selecting a new audio
  useEffect(() => {
    if (!isMounted.current) return;

    if (selectedAudio) {
      setDuration(null);
      // Force reload the audio element
      const audioElement = audioRefs.current[selectedAudio._id];
      if (audioElement) {
        audioElement.load();
      }
    }
  }, [selectedAudio]);

//   const getRangeStartEnd = (data) => {
//     if (isMounted.current) {
//       setRange(data);
//       getRange(data);
//     }
//   };

  return (
    <div>
      <Button onClick={toggle} className="createStoryActionBtn">
        <i className="fa fa-music" />
      </Button>

      <Modal
        isOpen={modal}
        toggle={closeModal}
        className="modal-dialog-centered modal-xl modal-dialog-scrollable"
        onClosed={() => {
          // Pause all audio elements when modal closes
          Object.values(audioRefs.current).forEach((audio) => {
            if (audio) audio.pause();
          });
          if (currentAudioRef.current) currentAudioRef.current.pause();
        }}
      >
        <ModalHeader toggle={closeModal}>Audio Story</ModalHeader>
        <ModalBody>
          <Nav tabs className="customTabs mediaLibraryTab">
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => toggleTab("1")}
              >
                Record
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => toggleTab("2")}
              >
                Audio
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => toggleTab("3")}
              >
                Upload New
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="tabContentMediaLibrary">
            {/* Record Tab */}
            <TabPane tabId="1">
              <div className="customAudioRecorder">
                <AudioRecorder
                  onRecordingComplete={addAudioElement}
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                  }}
                  showVisualizer
                />
              </div>

              {recordedFile?.blobURL && (
                <>
                  <audio
                    controls
                    className="audioRecorded mt-4"
                    controlsList="nodownload"
                    src={recordedFile.blobURL}
                    ref={(el) => (currentAudioRef.current = el)}
                    onLoadedMetadata={(e) => handleAudioLoad(e, "recorded")}
                  />
                  {/* {duration > 15 && (
                    <AudioRangePlayer
                      key={`recorded-${recordedFile.blobURL}`} // Force re-render when source changes
                      audioSrc={recordedFile.blobURL}
                      onRangeChange={getRangeStartEnd}
                      audioRef={currentAudioRef}
                    />
                  )} */}
                </>
              )}
            </TabPane>

            {/* Audio Library Tab */}
            <TabPane tabId="2">
              {allAudio.length > 0 ? (
                <Row className="mx-0">
                  {allAudio.map((audio, index) => (
                    <Col xs={6} md={4} lg={3} key={index} className="px-1">
                      <div
                        className={`mediaLibraryFilesWrap ${
                          selectedAudio?._id === audio._id ? "selected" : ""
                        }`}
                        onClick={() => {
                          if (selectedAudio?._id === audio._id) {
                            setSelectedAudio(null);
                            setDuration(null);
                          } else {
                            setSelectedAudio(audio);
                            setRecordedFile(null);
                            setUploadedFile(null);
                          }
                        }}
                      >
                        <div className="mediaLibraryFiles d-flex align-items-center justify-content-center">
                          <audio
                            controls
                            src={audio.url}
                            ref={(el) => (audioRefs.current[audio._id] = el)}
                            onLoadedMetadata={(e) =>
                              handleAudioLoad(e, audio._id)
                            }
                          />
                          {selectedAudio?._id === audio._id && (
                            <div className="mediaFileSelected">
                              <i className="fa fa-check-circle" />
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : loading ? (
                <Row className="mx-0">{MediaLibraryLoading}</Row>
              ) : (
                <Col xs={12}>
                  <div className="noContentFound">Audio not found</div>
                </Col>
              )}

              {/* {selectedAudio?.url && duration > 15 && (
                <AudioRangePlayer
                  key={`selected-${selectedAudio._id}`} // Force re-render when source changes
                  audioSrc={selectedAudio.url}
                  onRangeChange={getRangeStartEnd}
                  audioRef={audioRefs.current[selectedAudio._id]}
                />
              )} */}
            </TabPane>

            {/* Upload New Tab */}
            <TabPane tabId="3">
              <div className="mediaFileUploadWrap">
                <Label className="btn themeBtn">
                  <input
                    type="file"
                    accept="audio/*"
                    onChange={(e) => handleFileUpload(e.target.files)}
                    style={{ display: "none" }}
                  />
                  <span>Upload</span>
                </Label>
              </div>

              {uploadedFile?.blobURL && (
                <>
                  <audio
                    controls
                    className="audioRecorded mt-3"
                    controlsList="nodownload"
                    src={uploadedFile.blobURL}
                    ref={(el) => (currentAudioRef.current = el)}
                    onLoadedMetadata={(e) => handleAudioLoad(e, "uploaded")}
                  />
                  {/* {duration > 15 && (
                    <AudioRangePlayer
                      key={`uploaded-${uploadedFile.blobURL}`} // Force re-render when source changes
                      audioSrc={uploadedFile.blobURL}
                      onRangeChange={getRangeStartEnd}
                      audioRef={currentAudioRef}
                    />
                  )} */}
                </>
              )}
            </TabPane>
          </TabContent>

          {activeTab === "2" && totalAudios > 12 && (
            <div className="mediaLibraryPagination">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                prevPageText="Prev"
                nextPageText="Next"
                activePage={filter.pageNumber}
                itemsCountPerPage={filter.limit}
                totalItemsCount={totalAudios}
                pageRangeDisplayed={3}
                onChange={handlePageChange}
              />
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={closeModal} className="modalBtnCancel">
            Cancel
          </Button>
          <Button
            onClick={handleSaveAudio}
            className="modalBtnSave"
            disabled={isLoading}
          >
            {isLoading ? <ButtonSpinner /> : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddAudioStoryModal;
