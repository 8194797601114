import React, { useState } from "react";
import {
  Button,
  CustomInput,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from "reactstrap";
import { RegexConfig } from "../config/RegexConfig";
import { checkAvailability, requestInvitation } from "../http-calls/index";
import {
  errorHandler,
  getDialCodeFromCountryCode,
  isValidPhone,
  showToast,
  splitFullName,
} from "../helper-methods";
import { countryCodes } from "../config/country-codes";
import {
  APP_LOGO,
  APP_NAME,
  COMMUNITY_GUIDELINES_URL,
  PRIVACY_POLICY_URL,
  REQUEST_INVITE_THUMBNAIL,
  REQUEST_INVITE_VIDEO,
  TERMS_OF_SERVICE_URL,
} from "../config";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import colors from "../assets/styles/scss/style.scss";
import { socialAccountKeys } from "../config/helper-config";
import CustomTooltip from "../components/custom/CustomTooltip";

const RequestInvitePage = () => {
  const history = useHistory();

  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    countryCode: "US",
    phone: "",
    twitter: "",
    youtube: "",
    facebook: "",
    snapchat: "",
    tiktok: "",
    instagram: "",
    description: "",
  });
  const [termsAndConditions, setTermsAndConditions] = useState(false); //show hide password
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [dublicateLoading, setDublicateLoading] = useState({});
  const [loading, setLoading] = useState(false);

  /**
   * Check for duplicate username, email, phone
   */
  const _checkDuplicateFields = (key, value) => {
    return new Promise(async (resolve) => {
      setDublicateLoading((prev) => ({
        ...prev,
        [key]: true,
      }));

      const payload = {
        [key]: value,
      };

      checkAvailability(payload)
        .then((res) => {
          resolve([res, null]);
        })
        .catch((error) => {
          resolve([null, error]);
        });
    });
  };

  /**
   * validation or Check for duplicate username, email, phone , validate one at a time
   */
  const _formFieldsApiValidation = ({ newFormFields, newIsDirty, key }) => {
    return new Promise(async (resolve) => {
      const newErrors = {};
      let isFieldValid = true;

      if (newIsDirty[key]) {
        if (newFormFields[key]?.trim().length) {
          if (RegexConfig[key].test(String(newFormFields[key]).toLowerCase())) {
            const phoneCountry = getDialCodeFromCountryCode(
              newFormFields?.countryCode
            );

            const checkingValue =
              key !== "phone"
                ? newFormFields[key].trim()
                : `(${phoneCountry})${newFormFields[key].trim()}`;

            // always resolve
            const [res, resError] = await _checkDuplicateFields(
              key,
              checkingValue
            );

            setDublicateLoading((prev) => ({
              ...prev,
              [key]: false,
            }));

            if (res?.isDuplicateFound || res?.error || resError?.error) {
              newErrors[key] = `${newFormFields[key].trim()} already in use`;
              isFieldValid = false;
            } else {
              newErrors[key] = null;
              newIsDirty[key] = false;
            }
          } else {
            newErrors[key] = `*Invalid ${key}`;
            isFieldValid = false;
          }
        } else {
          newErrors[key] = "*Required";
          isFieldValid = false;
        }
      }

      setErrors((prev) => ({
        ...prev,
        [key]: newErrors[key],
      }));

      setIsDirty((prev) => ({
        ...prev,
        [key]: newIsDirty[key],
      }));

      resolve(isFieldValid);
    });
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.trim().length) {
                if (
                  newFormFields[key]?.trim().length > 1 &&
                  newFormFields[key]?.trim().length <= 25 &&
                  RegexConfig?.name?.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    "*Minimum 2 characters & Maximum 25 characters, no digits/special characters are allowed.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "description": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "twitter":
            case "youtube":
            case "facebook":
            case "tiktok":
            case "snapchat":
            case "instagram": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig.url.test(String(newFormFields[key]).toLowerCase())
                ) {
                  if (newFormFields[key].includes(key)) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = `*Make sure your link has "${key}.com"`;
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Invalid Link";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = null;
                newIsDirty[key] = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (key === "phone" && value && !isValidPhone(value)) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };

    if (key === "email" || key === "phone") {
      _formFieldsApiValidation({ newFormFields, newIsDirty, key });
    } else {
      _validateFormFields({ newFormFields, newIsDirty });
    }
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      if (!termsAndConditions) {
        showToast("Please accept Terms & Conditions to proceed", "error");
        return;
      }

      const social = [];

      socialAccountKeys.forEach((key) => {
        if (newFormFields?.[key]?.trim()) {
          social.push({
            name: key,
            accountUrl: newFormFields?.[key]?.trim(),
          });
        }
      });

      if (!social?.length) {
        showToast("Please add atleast one social media profile link", "error");
        return;
      }

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const [isEmailFieldValid, isPhoneFieldValid] = await Promise.all([
        _formFieldsApiValidation({
          newFormFields,
          newIsDirty: { email: true },
          key: "email",
        }),
        _formFieldsApiValidation({
          newFormFields,
          newIsDirty: { phone: true },
          key: "phone",
        }),
      ]);

      if (!isFormValid || !isEmailFieldValid || !isPhoneFieldValid) {
        setLoading(false);
        return;
      }

      const { firstName, lastName } = splitFullName(formFields.name);

      const phoneCountry = getDialCodeFromCountryCode(
        newFormFields?.countryCode
      );

      const payload = {
        name: {
          first: firstName,
          last: lastName,
        },
        phone: `(${phoneCountry})${newFormFields.phone}`,
        email: newFormFields.email?.trim(),
        description: newFormFields.description?.trim(),
        termsAndConditions,
        social,
      };

      await requestInvitation(payload);

      showToast("Invitation has been sent to the admin", "success");

      Swal.fire({
        title: "Request Sent Successfully!",
        text: `A ${APP_NAME} account executive will review your submission and contact you shortly to help you get started and answer any additional question you may have.`,
        icon: "success",
        confirmButtonColor: colors?.themeColor,
        confirmButtonText: "Okay",
      });

      history.replace("/login");
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  return (
    <div className="animated fadeIn authFormWrapper">
      <div className="loginWrapper requestInviteWrapper">
        <img
          src={APP_LOGO}
          alt="Project Logo"
          className="projectLogo"
          onClick={() => history.replace("/")}
          loading="lazy"
        />
        <div className="authPgFormWrap">
          <h4>Apply For A Creator Account</h4>
          <p>We are currently an Invite only plateform.</p>

          <hr />

          {REQUEST_INVITE_VIDEO ? (
            <>
              <div className="mediaPhotoWrap-Feed mt-0">
                <video
                  poster={REQUEST_INVITE_THUMBNAIL}
                  src={REQUEST_INVITE_VIDEO}
                  controls
                  controlsList="nodownload"
                  disablePictureInPicture
                />
              </div>

              <hr />
            </>
          ) : null}

          <Form onSubmit={(e) => _onSubmit(e)}>
            <FormGroup>
              <Label>Full Name</Label>
              <>
                <sup id="full-name" className="infoIcon">
                  <i className="fa fa-info-circle" />
                </sup>
                <CustomTooltip
                  text={`Please enter your full legal name for quick verification.`}
                  target="full-name"
                  placement="right"
                />
              </>
              <Input
                type="text"
                placeholder="Enter your full name"
                autoComplete="off"
                name="name"
                value={formFields.name}
                onChange={(e) => _onChangeFormFields("name", e.target.value)}
                onBlur={() => _onBlurFormFields("name")}
              />

              {errors?.name ? (
                <div className="form-error">{errors?.name}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Email</Label>
              <div className="position-relative">
                <Input
                  type="text"
                  placeholder="Enter your email"
                  autoComplete="off"
                  name="email"
                  value={formFields.email}
                  onChange={(e) => _onChangeFormFields("email", e.target.value)}
                  onBlur={() => _onBlurFormFields("email")}
                />
                {dublicateLoading?.email && (
                  <div className="spinnerLogin inputSpinner">
                    <i className="fa fa-spinner fa-spin" />
                  </div>
                )}
              </div>
              {errors?.email ? (
                <div className="form-error">{errors?.email}</div>
              ) : null}
            </FormGroup>

            <div className="d-flex">
              <FormGroup className="mr-1 w-50">
                <Label>Country</Label>
                <Input
                  type="select"
                  name="countryCode"
                  value={formFields.countryCode}
                  onChange={(e) =>
                    _onChangeFormFields("countryCode", e.target.value)
                  }
                >
                  {countryCodes.map((countryCode, countryIndex) => (
                    <option key={countryIndex} value={countryCode.code}>
                      {countryCode.name} ({countryCode.dial_code})
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup className="ml-1 w-50">
                <Label>Phone Number</Label>

                <div className="position-relative">
                  <Input
                    type="text"
                    placeholder="Enter your phone number"
                    autoComplete="off"
                    name="phone"
                    value={formFields.phone}
                    onChange={(e) =>
                      _onChangeFormFields("phone", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("phone")}
                  />
                  {dublicateLoading?.phone && (
                    <div className="spinnerLogin">
                      <i className="fa fa-spinner fa-spin" />
                    </div>
                  )}
                </div>

                {errors?.phone ? (
                  <div className="form-error">{errors?.phone}</div>
                ) : null}
              </FormGroup>
            </div>

            <Label>Enter links to your social media</Label>
            <FormGroup className="mb-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      style={{ height: 30 }}
                      src="/assets/img/social/twitter-icon.png"
                      alt="tiktok-icon"
                      loading="lazy"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={formFields.twitter}
                  placeholder="twitter.com/username"
                  name="twitter"
                  onChange={(e) =>
                    _onChangeFormFields("twitter", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("twitter")}
                />
              </InputGroup>
              {errors?.twitter ? (
                <div className="form-error">{errors?.twitter}</div>
              ) : null}
            </FormGroup>

            <FormGroup className="mb-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      style={{ height: 30 }}
                      src="/assets/img/social/youtube-icon.png"
                      alt="youtube"
                      loading="lazy"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={formFields.youtube}
                  placeholder="youtube.com/username"
                  name="youtube"
                  onChange={(e) =>
                    _onChangeFormFields("youtube", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("youtube")}
                />
              </InputGroup>
              {errors?.youtube ? (
                <div className="form-error">{errors?.youtube}</div>
              ) : null}
            </FormGroup>

            <FormGroup className="mb-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      style={{ height: 30 }}
                      src="/assets/img/social/fb-icon.png"
                      alt="Facebook"
                      loading="lazy"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={formFields.facebook}
                  placeholder="facebook.com/username"
                  name="facebook"
                  onChange={(e) =>
                    _onChangeFormFields("facebook", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("facebook")}
                />
              </InputGroup>
              {errors?.facebook ? (
                <div className="form-error">{errors?.facebook}</div>
              ) : null}
            </FormGroup>

            <FormGroup className="mb-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      style={{ height: 30 }}
                      src="/assets/img/social/tiktok-icon.png"
                      alt="tiktok-icon"
                      loading="lazy"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={formFields.tiktok}
                  placeholder="tiktok.com/username"
                  name="tiktok"
                  onChange={(e) =>
                    _onChangeFormFields("tiktok", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("tiktok")}
                />
              </InputGroup>
              {errors?.tiktok ? (
                <div className="form-error">{errors?.tiktok}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      style={{ height: 30 }}
                      src="/assets/img/social/instagram-icon.png"
                      alt="Instagram"
                      loading="lazy"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={formFields.instagram}
                  placeholder="instagram.com/username"
                  name="instagram"
                  onChange={(e) =>
                    _onChangeFormFields("instagram", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("instagram")}
                />
              </InputGroup>
              {errors?.instagram ? (
                <div className="form-error">{errors?.instagram}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      style={{ height: 30 }}
                      src="/assets/img/social/snapchat.png"
                      alt="Snapchat"
                      loading="lazy"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={formFields.snapchat}
                  placeholder="snapchat.com/username"
                  name="snapchat"
                  onChange={(e) =>
                    _onChangeFormFields("snapchat", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("snapchat")}
                />
              </InputGroup>
              {errors?.instagram ? (
                <div className="form-error">{errors?.snapchat}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Why should we let you in? Tell us about yourself.</Label>
              <Input
                type="textarea"
                name="text"
                rows="3"
                placeholder="Enter the description"
                value={formFields.description}
                onChange={(e) =>
                  _onChangeFormFields("description", e.target.value)
                }
                onBlur={() => _onBlurFormFields("description")}
              />
              {errors?.description ? (
                <div className="form-error">{errors?.description}</div>
              ) : null}
            </FormGroup>

            <div className="d-flex">
              <CustomInput
                type="checkbox"
                id="termsAndConditions"
                name="termsAndConditions"
                checked={termsAndConditions}
                onChange={(e) => setTermsAndConditions(e.target.checked)}
              />
              <Label
                check
                htmlFor="termsAndConditions"
                style={{ fontWeight: 400, cursor: "pointer" }}
              >
                I accept the{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={TERMS_OF_SERVICE_URL}
                >
                  terms & guideLines
                </a>
              </Label>
            </div>

            <Button
              type="submit"
              className="themeBtn loginBtn"
              disabled={loading}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Request an Invite
            </Button>
          </Form>

          <Button
            className="registerBtn mt-2"
            onClick={() => history.push("/login")}
          >
            Have an account? <span>Login</span>
          </Button>
        </div>
      </div>
      <div className="loginFooter">
        <div className="socialLinksFooter-Login">
          <div className="socialLinksFooter">
            <a
              href={TERMS_OF_SERVICE_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms
            </a>
            <a
              href={PRIVACY_POLICY_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy
            </a>
            <a
              href={COMMUNITY_GUIDELINES_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Guidelines
            </a>
          </div>
        </div>
        <span className="poweredBy">
          Powered by{" "}
          <a
            href="https://www.logic-square.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Logic Square
          </a>
        </span>
      </div>
    </div>
  );
};

export default RequestInvitePage;
