import React, { useState } from "react";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";
import ErrorBoundary from "./ErrorBoundary";
import CustomVideoPlayer from "./custom/CustomVideoPlayer";
import { APP_LOGO, DEFAULT_COVER_PICTURE } from "../config";

const SignupCoverMediaCarousel = ({ contents, screenWidth }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const _onChangeActiveIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  // when click on carousel indicator
  const _goToIndex = (newIndex) => {
    if (animating) return;
    // setActiveIndex(newIndex);
    _onChangeActiveIndex(newIndex);
  };

  const _setAnimating = (value) => {
    setAnimating(value);
  };

  // when slide enter to viewport
  const _onEnter = (e, item) => {};

  const _onExit = (e, item) => {
    if (item.mediaType === "video" || item.contentType === "video") {
      const video = e.getElementsByTagName("video");
      if (video && video.length) {
        if (!video[0].paused) {
          video[0].pause();
        }
      }
    } else if (item.mediaType === "audio" || item.contentType === "audio") {
      const audio = e.getElementsByTagName("audio");
      if (audio && audio.length) {
        if (!audio[0].paused) {
          audio[0].pause();
        }
      }
    }
  };

  const _next = () => {
    const nextIndex = activeIndex === contents.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const _previous = () => {
    const nextIndex = activeIndex === 0 ? contents.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const _getActiveContentItem = (item, index) => {
    if (item.mediaType || item.contentType) {
      switch (item.mediaType || item.contentType) {
        case "image": {
          return (
            <div className="imgCropWrap">
              <img
                src={item?.previewBlob || DEFAULT_COVER_PICTURE}
                onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                alt="Carousel Img"
                loading="lazy"
                className={
                  item?.crop?.height === 100 ? "widthAuto" : "heightAuto"
                }
                style={{
                  transform: `translate(-${
                    (item?.crop?.x || item?.crop?.x === 0 ? item?.crop?.x : 0) +
                    "%"
                  }, -${
                    (item?.crop?.y || item?.crop?.y === 0 ? item?.crop?.y : 0) +
                    "%"
                  })`,
                }}
              />
            </div>
          );
        }

        case "video": {
          return (
            <ErrorBoundary>
              {/* custom video player support hls */}
              <CustomVideoPlayer
                src={item?._content?.url || item?.previewBlob}
                controls
                showMinimalControl
              />
            </ErrorBoundary>
          );
        }

        // case "audio": {
        //   return (
        //     <ErrorBoundary>
        //       <AudioPlayer
        //         className="audioWrapperFeedCarousel"
        //         url={item?._content?.url}
        //       />
        //     </ErrorBoundary>
        //   );
        // }
        default: {
          return null;
        }
      }
    }
  };

  const slides = contents?.length
    ? contents.map((item, index) => {
        return (
          <CarouselItem
            key={`${index}-carousel-item-id`}
            onExiting={() => _setAnimating(true)}
            onExited={() => _setAnimating(false)}
            onExit={(e) => _onExit(e, item)}
            onEnter={(e) => _onEnter(e, item)}
          >
            {_getActiveContentItem(item, index)}
          </CarouselItem>
        );
      })
    : null;

  return (
    <div
      className="profileCarousel"
      {...(screenWidth <= 575.98
        ? { style: { width: screenWidth, height: screenWidth / 3 } }
        : {})}
    >
      <Carousel
        activeIndex={activeIndex}
        next={() => _next()}
        previous={() => _previous()}
        autoPlay={false}
        interval={false}
      >
        {contents?.length > 1 ? (
          <CarouselIndicators
            items={contents?.length ? contents : [APP_LOGO]}
            activeIndex={activeIndex}
            onClickHandler={(index) => _goToIndex(index)}
          />
        ) : null}

        {slides}

        {contents.length > 1 ? (
          <>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={() => _previous()}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={() => _next()}
            />
          </>
        ) : null}
      </Carousel>
    </div>
  );
};

export default SignupCoverMediaCarousel;
