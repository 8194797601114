import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import ButtonLoading from "../ButtonLoading";
import { errorHandler, showToast } from "../../helper-methods";
import { addTags } from "../../http-calls";

const CreateTagsModal = ({ isOpen, data, toggle, onSuccess }) => {
  const [tags, setTags] = useState([]);

  const [loading, setLoading] = useState(false);

  const _resetState = () => {
    setTags([]);
    setLoading(false);
  };

  const _closeModal = () => {
    _resetState();
    toggle();
  };

  const _onSubmit = async () => {
    try {
      setLoading(true);

      const groupNames = tags.map((each) => each.value.toLowerCase());

      if (!groupNames?.length) {
        showToast(
          "At least one tag is required, and it must be unique.",
          "error"
        );
        return;
      }

      if (data?.some((each) => groupNames.includes(each?.toLowerCase()))) {
        showToast(
          "Duplicate tag detected. Please select a different tag.",
          "error"
        );
        return;
      }

      await addTags({ groupNames });

      onSuccess();

      showToast("Tags have been added", "success");

      _closeModal();
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal-dialog-centered modal-dialog-scrollable"
        toggle={() => _closeModal()}
      >
        <ModalHeader toggle={() => _closeModal()}>Create Tag(s)</ModalHeader>
        <ModalBody>
          <FormGroup className="customCreateSelect">
            <CreatableSelect
              // isClearable
              placeholder="Enter"
              name=""
              noOptionsMessage={() => null}
              value={tags}
              onChange={(value) => setTags(value)}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              components={{ DropdownIndicator: () => {} }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnCancel" onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={() => _onSubmit()}
            disabled={loading || !tags?.length ? true : false}
          >
            {loading ? <ButtonLoading /> : null}
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateTagsModal;
