import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  FormGroup,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  DEFAULT_COVER_PICTURE,
  DEFAULT_PROFILE_PICTURE,
} from "../config/index";
import {
  deepClone,
  getLowResolutionLink,
  getFullName,
  showToast,
} from "../helper-methods";
import { getProfileDetailsAsFan } from "../http-calls/index";
import { showLoader, hideLoader } from "../redux/actions/loaderData";
import SubscribeFanFeedViewer from "../components/SubscribeFanFeedViewer";
import InfiniteScroll from "react-infinite-scroller";
import { APP_NAME } from "../config/index";
import ConfigureString from "../components/ConfigureString";

class SubscriberFeedViewerPage extends Component {
  state = {
    influencer: null,
    posts: [],
    activeTab: new Array(5).fill("1"),
    popovers: [
      {
        placement: "bottom",
        text: "Bottom",
      },
    ],
    activeIndex: 0,
    custom: [false],
    dropdownOpen: new Array(6).fill(false),
    isFilterPopoverOpen: false,
    activeFilterOption: "allTime", // Other options:  ['last90days', 'last30days', 'last7Days']
    activeSortOption: "latestPosts", // Other options:  ['mostLiked', 'highestTips'],
    filterOptions: [
      {
        key: "allTime",
        label: "All Time",
      },
      {
        key: "last90days",
        label: "Last 90 Days",
      },
      {
        key: "last30days",
        label: "Last 30 Days",
      },
      {
        key: "last7Days",
        label: "Last 7 Days",
      },
    ],
    sortOptions: [
      {
        key: "latestPosts",
        label: "Latest Posts",
      },
      {
        key: "mostLiked",
        label: "Most Liked",
      },
      {
        key: "highestTips",
        label: "Highest Tips",
      },
    ],
    filteredAndSortedPosts: [],
    formattedPosts: [],
    subscriptionData: {
      isSubscribed: false,
      isSubscriptionExpired: false,
    },
    viewAsValue: "",
    vaults: [],
    feedPagination: {
      skip: 0,
      limit: 20,
    },
    loadMore: false,
  };

  componentDidMount() {
    // this.props.history.replace("/feed");
    this.props.showLoader("Loading subscriber view");
    this._getInfluencerDetails();
  }

  _getInfluencerDetails = () => {
    const { posts, feedPagination } = deepClone(this.state);
    feedPagination["skip"] = posts && posts.length ? posts.length : 0;

    getProfileDetailsAsFan(this.props.userData.user.username, feedPagination)
      .then((res) => {
        if (!res.error) {
          if (res.influencer) {
            this.setState(
              {
                influencer: res.influencer,
                posts:
                  posts && posts.length ? posts.concat(res.posts) : res.posts,
                subscriptionData: {
                  isSubscribed: res.isSubscribed,
                  isSubscriptionExpired: res.isSubscriptionExpired,
                },
                totalFeed: res.total,
                loadMore: false,
              },
              () => {
                if (res.posts && res.posts.length) {
                  const vaults = res.posts.filter((post) =>
                    post.hasOwnProperty("_folder")
                  );
                  this.setState({ vaults }, () => {
                    this.props.hideLoader();
                  });
                } else {
                  this.props.hideLoader();
                }
              }
            );
          }
        }
      })
      .catch((err) => {
        // this.props.history.replace("/feed");
        showToast(
          err.reason && err.reason.length
            ? err.reason.indexOf("Cast to ObjectId failed for value") > -1
              ? "No influencer found"
              : err.reason
            : "Server Error. Try again after sometime.",
          "error"
        );
        this.props.hideLoader();
      });
  };

  _selectPollOption = (feed, option) => {
    return;
  };

  _scrollToTop = () => {
    this.props.scrollToTop();
  };

  toggle(tabPane, tab) {
    const newArray = this.state.activeTab.slice();
    newArray[tabPane] = tab;
    this.setState({
      activeTab: newArray,
      popoverOpen: !this.state.popoverOpen,
    });
  }

  toggleCustom(tab) {
    const prevState = this.state.custom;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    this.setState({
      custom: state,
    });
  }

  _editProfile = () => {
    this.props.history.push("./settings");
  };

  _toggleFilterPopover = () => {
    this.setState({
      isFilterPopoverOpen: !this.state.isFilterPopoverOpen,
    });
  };

  _changeSortBy = (newSortOption) => {
    this._toggleFilterPopover();
    this.setState({ activeSortOption: newSortOption }, () => {
      this._filterAndSort();
    });
  };

  _changeFilterBy = (newFilterSortOption) => {
    this._toggleFilterPopover();
    this.setState({ activeFilterOption: newFilterSortOption }, () => {
      this._filterAndSort();
    });
  };

  _filterAndSort = () => {
    return new Promise((resolve, reject) => {
      const { formattedPosts, activeFilterOption, activeSortOption } =
        deepClone(this.state);
      if (formattedPosts.length) {
        // Filter
        let filterdPosts = formattedPosts;
        switch (activeFilterOption) {
          case "last90days":
            filterdPosts = formattedPosts.filter((formattedPost) => {
              return this._isPostNotOlderThan(90, formattedPost.createdAt);
            });
            break;
          case "last30days":
            filterdPosts = formattedPosts.filter((formattedPost) => {
              return this._isPostNotOlderThan(30, formattedPost.createdAt);
            });
            break;
          case "last7Days":
            filterdPosts = formattedPosts.filter((formattedPost) => {
              return this._isPostNotOlderThan(7, formattedPost.createdAt);
            });
            break;
          default:
            filterdPosts = formattedPosts;
            break;
        }
        // Sort
        let sortedPosts = filterdPosts;
        switch (activeSortOption) {
          case "latestPosts":
            sortedPosts = filterdPosts;
            break;
          case "mostLiked":
            sortedPosts = filterdPosts.sort(
              (post1, post2) => post2.likes - post1.likes
            );
            break;
          case "highestTips":
            sortedPosts = filterdPosts.sort(
              (post1, post2) => post2.totalTipAmount - post1.totalTipAmount
            );
            break;
          default:
            break;
        }
        this.setState({ filteredAndSortedPosts: sortedPosts }, () => {
          resolve();
        });
      }
    });
  };

  _isPostNotOlderThan = (allowedDiffDays, postDate) => {
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - new Date(postDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= allowedDiffDays) {
      return true;
    } else {
      return false;
    }
  };

  _formatPosts = () => {
    return new Promise((resolve, reject) => {
      const { feeds: posts } = this.props;
      let formattedPosts = [];
      if (posts) {
        formattedPosts = posts.map((post) => {
          // Generate total tip amount
          let totalTipAmount = 0;
          if (post._transactions && post._transactions.length) {
            post._transactions.forEach((transaction) => {
              if (transaction.paymentType === "tips") {
                totalTipAmount += transaction.amount;
              }
            });
          }
          return { ...post, totalTipAmount };
        });
      }

      this.setState({ formattedPosts }, () => {
        resolve();
      });
    });
  };

  _subscribe = () => {
    const redirectTo = `/subscribe?influencerId=${this.props.match.params.id}`;
    this.props.history.push(redirectTo);
    // routerRef.push(`/subscribe?influencerId=${influencer.username}`);
  };

  _redirectToCreatorView = () => {
    this.props.showLoader("Redirecting to your view");
    this.props.history.push("my-profile");
  };

  _changeViewAs = (viewAsValue) => {
    this.props.showLoader("Redirecting to your view");
    this.setState({ viewAsValue }, () => {
      switch (viewAsValue) {
        case "creator": {
          this.props.history.push("/feed");
          break;
        }
        case "nonSubscriber": {
          this.props.history.push("/non-subscriber-view");
          break;
        }
        default:
          break;
      }
    });
  };

  _loadMoreFeeds = () => {
    if (!this.state.loadMore) {
      this.setState(
        {
          loadMore: true,
        },
        () => {
          this._getInfluencerDetails();
        }
      );
    }
    return;
  };

  render() {
    const {
      posts,
      influencer,
      subscriptionData,
      viewAsValue,
      vaults,
      activeTab,
      feedPagination,
      totalFeed,
      loadMore,
    } = this.state;

    return (
      <div className="app scPgBg animated fadeIn">
        <InfiniteScroll
          pageStart={feedPagination.skip}
          loadMore={this._loadMoreFeeds}
          hasMore={
            this.state.activeTab[0] === "1" &&
            (posts && posts.length) < totalFeed
              ? true || false
              : false
          }
          loader={
            loadMore && posts && posts.length < totalFeed ? (
              <div className="spinnerWrap " key={0}>
                <Spinner style={{ width: "1.8rem", height: "1.8rem" }} />{" "}
              </div>
            ) : null
          }
          useWindow={false}
        >
          <Container className="noPadding">
            <Row className="mb-2 justify-content-center mt-3 noMargin">
              <Col sm={12} md={11} lg={9} xl={8} className="noPadding">
                <div className="subscriberViewTxt">Subscriber View</div>

                <div className="d-flex justify-content-center justify-content-sm-end">
                  <FormGroup className="mb-3 mb-sm-0">
                    <Input
                      type="select"
                      name="view"
                      id="view"
                      value={viewAsValue}
                      onChange={(e) => this._changeViewAs(e.target.value)}
                    >
                      <option value="">View As</option>
                      <option value="creator">Creator</option>
                      <option value="nonSubscriber">Non-Subscriber</option>
                    </Input>
                  </FormGroup>
                </div>
                {/* user profile card  */}

                <Card className="cardFeed profileCard">
                  <CardHeader>
                    <div className="coverImgWrap">
                      <img
                        className="coverImg"
                        src={influencer?.coverPicture || DEFAULT_COVER_PICTURE}
                        alt="Cover Img"
                        loading="lazy"
                      />
                    </div>

                    <div className="influencerDetailsWrap">
                      <div className="d-flex justify-content-start align-items-start">
                        <div className="img100by100Wrap">
                          <img
                            src={
                              getLowResolutionLink(
                                influencer?.profilePicture
                              ) || DEFAULT_PROFILE_PICTURE
                            }
                            onError={(e) =>
                              (e.target.src = DEFAULT_PROFILE_PICTURE)
                            }
                            alt="Profile Img"
                            className="img100by100"
                          />
                        </div>
                        <div className="influencerName">
                          <div className="d-flex justify-content-start align-items-center">
                            <h4>{getFullName(influencer?.name)}</h4>
                            {influencer?.username?.length ? (
                              <Button color="link" className="profileID ml-2">
                                @{influencer.username}
                              </Button>
                            ) : null}
                          </div>

                          <p>
                            <i className="icon-location-pin" />
                            {influencer?.address?.country || "N/A"}
                          </p>

                          {influencer?.settings?.showFanCountOnProfile &&
                          influencer?._subCount > 0 ? (
                            <p>
                              <i className="fa fa-users" />
                              Subscribers : {influencer._subCount}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="profileDescWrap">
                    <p className="profileDesc">
                      {influencer?.introduction?.length
                        ? influencer.introduction
                        : `Welcome to my ${APP_NAME} page! Subscribe to unlock all of my exclusive content!`}
                    </p>
                  </CardBody>
                </Card>

                <h4>Post</h4>

                <TabContent
                  activeTab={activeTab[0]}
                  className="customTabContent no-feed feedPgTabContent"
                >
                  <TabPane tabId="1">
                    {/* multiple posts/feeds */}
                    {posts && posts.length ? (
                      posts.map((feed, index) => (
                        <SubscribeFanFeedViewer
                          key={index}
                          feed={feed}
                          subscriptionData={subscriptionData}
                          getAllPosts={this._getInfluencerDetails}
                          selectPollOption={(feed, option) =>
                            this._selectPollOption(feed, option)
                          }
                          requestUpdate={this._getInfluencerDetails}
                          routerRef={this.props.history}
                          influencer={influencer}
                          {...this.props}
                        />
                      ))
                    ) : (
                      <label>No feeds yet</label>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="pgTitle justify-content-start">
                      <img
                        src="/assets/img/vault-icon.png"
                        alt="Vault Icon"
                        className="iconVault"
                        loading="lazy"
                      />

                      <h2>
                        <ConfigureString keyString="vault" />
                      </h2>
                    </div>

                    {vaults && vaults.length ? (
                      vaults.map((vault, index) => (
                        <SubscribeFanFeedViewer
                          key={index}
                          {...this.props}
                          feed={vault}
                          subscriptionData={subscriptionData}
                          getAllPosts={this._getInfluencerDetails}
                          selectPollOption={(vault, option) =>
                            this._selectPollOption(vault, option)
                          }
                          requestUpdate={this._getInfluencerDetails}
                          routerRef={this.props.history}
                          influencer={influencer}
                          showVaultPostTextInHeader={false}
                          showInteractionPanel={false}
                        />
                      ))
                    ) : (
                      <div className="noContentFound">
                        <ConfigureString keyString="No vault found" />
                      </div>
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </InfiniteScroll>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (loaderText) => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriberFeedViewerPage);
