import React from "react";
import { Badge, Button, ListGroup, ListGroupItem } from "reactstrap";
import {
  capitalize,
  formatPhoneNumber,
  getLowResolutionLink,
  getFullName,
  joinName,
} from "../helper-methods";
import SkeletonLoading from "./SkeletonLoading";

const ReferralHistoryList = ({
  confirmationPrompt,
  isTimeMoreThan24h,
  loadingState,
  referralHistoryList,
  isUserAccountScheduledForDelete,
}) => {
  return (
    <>
      <ListGroup className="referralHistoryList">
        {referralHistoryList?.length ? (
          referralHistoryList?.map((history, index) => (
            <ListGroupItem key={index}>
              <div className="d-flex">
                <div className="referralProfile">
                  {history?.acceptedBy?.profilePicture ? (
                    <img
                      src={getLowResolutionLink(
                        history.acceptedBy.profilePicture
                      )}
                      alt="Avatar"
                      loading="lazy"
                    />
                  ) : (
                    <i className="fa fa-user-o" />
                  )}
                </div>

                <div className="referralInfo">
                  <h5>
                    {history?.acceptedBy?.name?.full
                      ? capitalize(getFullName(history?.acceptedBy?.name))
                      : joinName(history.name)}
                  </h5>
                  {history?.acceptedBy?.username ? (
                    <p>@{history.acceptedBy.username}</p>
                  ) : (
                    <>
                      <p>
                        {history &&
                        history.hasOwnProperty("email") &&
                        history.email.length
                          ? history.email
                          : "N/A"}
                      </p>
                      <p>
                        {history?.phone?.length
                          ? formatPhoneNumber(history.phone)
                          : "N/A"}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-end flex-column">
                <Badge
                  color={history.status !== "accepted" ? "warning" : "success"}
                >
                  {capitalize(history.status)}
                </Badge>

                {!isUserAccountScheduledForDelete &&
                history &&
                history.status !== "accepted" &&
                isTimeMoreThan24h(history) ? (
                  <Button
                    className="sendReminder"
                    onClick={() => confirmationPrompt(history)}
                    disabled={loadingState?.reminder === history.id}
                  >
                    {loadingState?.reminder === history.id ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : null}{" "}
                    Send Reminder
                  </Button>
                ) : null}
              </div>
            </ListGroupItem>
          ))
        ) : loadingState.referralHistory ? (
          <SkeletonLoading type={"chatFanList"} count={2} />
        ) : (
          <p className="noContentFound">No requests yet</p>
        )}
      </ListGroup>
    </>
  );
};

export default ReferralHistoryList;
