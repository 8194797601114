import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  showToast,
  formatTimeFromNow,
  parseForTextField,
  mentionUserInputParse,
  extractTagUsername,
  errorHandler,
  getLowResolutionLink,
  getFullName,
  deepClone,
  isPermissionToAccess,
} from "../helper-methods";
import CustomPrompt from "../components/modals/CustomPrompt";
import {
  updateComment,
  replyOnComment,
  deleteComment,
  postCommentOnEvent,
  postCommentOnPPV,
  sendNotificationToMentionedInfluencer,
  editPost,
  getAllSubComments,
} from "../http-calls/index";
import { DEFAULT_PROFILE_PICTURE } from "../config/index";
// import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import "moment/min/locales";
import MentionUserInput from "../components/MentionUserInput";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../redux/actions";
import { useHistory } from "react-router-dom";
import ButtonLoading from "../components/ButtonLoading";

const Comments = ({
  comment: commentObj,
  isSubComment,
  toggleReplySection,
  getComments,
  likeComment,
  feedIndex,
  onComment,
  feed,
  type,
  canAddDeleteComment,
  getSubComments,
  isCommentsLikeLoading,
}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [subComments, setSubComments] = useState({});
  const [subCommentsPagination, setSubCommentsPagination] = useState(10);
  const [subCommentsLoading, setSubCommentsLoading] = useState(false);

  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isEditFieldOpened, setIsEditFieldOpened] = useState(false);
  const [comment, setComment] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [isReplySectionShown, setIsReplySectionShown] = useState(false);

  const canUpdateProfileSettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateProfileSettings",
        true
      ),
    []
  );

  useEffect(() => {
    if (commentObj?.content) {
      setComment(commentObj.content);
    }
  }, [commentObj?.content]);

  // toggle dropdown to show option of edit/share ppv
  const _toggleDropdown = () => {
    setIsDropDownOpen((prev) => !prev);
  };

  // toggle custom prompt => for deletion of comment
  const _togglePromptOpen = (isOpen = false) => {
    setIsPromptOpen(isOpen);
  };

  const _getAllSubComments = async (id, isNewCreated = false) => {
    try {
      if (!isNewCreated) {
        setSubCommentsLoading(id);
      }
      const res = await getAllSubComments(id, type, {
        limit: isNewCreated ? 1 : 1000,
      });
      setSubComments((prev) => {
        if (isNewCreated) {
          return {
            [id]: prev[id] ? prev[id].concat(res.comments) : res.comments,
          };
        }
        return {
          [id]: res?.comments || [],
        };
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setSubCommentsLoading(false);
    }
  };

  // on prompt success => delete comment api call
  const _onPromptSuccess = () => {
    onComment(feedIndex, false);

    _togglePromptOpen();

    deleteComment(commentObj?._id, type)
      .then((resp) => {
        getComments();
        if (commentObj?.parentComment && getSubComments) {
          getSubComments();
        }
        showToast("Deleted Successfully", "success");
      })
      .catch((error) => {
        onComment(feedIndex, true);
        errorHandler(error);
      });
  };

  const _toggleCommentField = () => {
    setIsEditFieldOpened((prev) => !prev);
  };

  const _toggleReplySection = () => {
    if (isSubComment) {
      toggleReplySection();
    } else {
      setIsReplySectionShown((prev) => !prev);
    }
  };

  const _rediretToCreatorFanList = () => {
    if (comment?._user?.userType === "Fan") {
      dispatch(showLoader("Fetching summary content"));
      const username = comment?._user?.username;
      history.push(`/my-profile?tabId=4&username=${username}`);
    } else {
      dispatch(showLoader("Fetching summary content"));
      history.push("/my-profile");
    }
  };

  const _updateComment = (value, isEmoji = false) => {
    if (isEmoji) {
      setComment((prev) => prev + value);
    } else {
      setComment(value);
    }
  };

  const _replyComment = (value) => {
    setReplyComment(value);
  };

  const _clearReplyComment = () => {
    _replyComment("");
  };

  const _onEnterPressed = (event, isReply) => {
    const code = event.keyCode || event.which;

    if (code === 13 && !event.shiftKey && !event.ctrlKey) {
      if (event) event.preventDefault();

      let data = {};

      if (replyComment?.trim().length) {
        // const newReplyComment = replyComment.replace(/\n/g, "<br/>");
        data = {
          content: replyComment?.trim(),
          isSubCommnet: true,
          parentComment: commentObj?._id,
          category: "comment",
        };
        setIsReplySectionShown(false);
        _clearReplyComment();
      }

      if (!comment?.trim().length) {
        return;
      }

      if (isReply) {
        if (data?.content?.trim().length) {
          onComment(feedIndex, true);
          if (type === "ppv") {
            postCommentOnPPV(data, commentObj?._postId)
              .then((response) => {
                getComments();
                _clearReplyComment();
                setIsReplySectionShown(false);
                showToast("Replied successfully", "success");
              })
              .catch((error) => {
                onComment(feedIndex, false);
                errorHandler(error);
              });
          } else if (type === "event") {
            postCommentOnEvent(data, commentObj?._postId)
              .then((response) => {
                getComments();
                _clearReplyComment();
                setIsReplySectionShown(false);
                showToast("Replied successfully", "success");
              })
              .catch((error) => {
                onComment(feedIndex, false);
                errorHandler(error);
              });
          } else {
            replyOnComment(data, commentObj?._postId, type)
              .then((response) => {
                getComments();
                _clearReplyComment();
                setIsReplySectionShown(false);
                showToast("Replied Successfully", "success");
              })
              .catch((error) => {
                onComment(feedIndex, false);
                errorHandler(error);
              });
          }
        } else {
          setIsEditFieldOpened(false);
          setIsReplySectionShown(false);
        }
      } else {
        // for comment editing
        // const newComment = comment.replace(/\n/g, "<br/>");
        updateComment({ content: comment?.trim() }, commentObj?._id)
          .then((resp) => {
            showToast("Updated Successfully", "success");
            getComments();
          })
          .catch((error) => {
            errorHandler(error);
          });

        setIsEditFieldOpened(false);
        setIsReplySectionShown(false);
      }
    }
  };

  const _likeComment = (id, isLiked) => {
    likeComment(id, isLiked);

    const newSubComments = deepClone(subComments);
    if (newSubComments?.[commentObj?._id]?.length) {
      const findComment = newSubComments[commentObj?._id].find(
        (each) => each._id === id
      );
      if (findComment) {
        findComment._isLiked = !findComment._isLiked;
        setSubComments(newSubComments);

        setTimeout(() => {
          _getAllSubComments(commentObj?._id);
        }, 2000);
      }
    }
  };

  const _postComment = async (isReply = false) => {
    if (!comment?.trim().length) {
      return;
    }

    if (isReply) {
      onComment(feedIndex, true);

      if (replyComment?.trim().length) {
        try {
          // const newReplyComment = replyComment.replace(/\n/g, "<br/>");

          const data = {
            content: replyComment?.trim(),
            isSubCommnet: true,
            parentComment: commentObj?._id,
            category: "comment",
          };

          setIsReplySectionShown(false);
          _clearReplyComment();

          const res = await replyOnComment(data, commentObj?._postId, type);
          if (
            type === "post" &&
            res?.interaction?.content &&
            res?.interaction?._id
          ) {
            const tagUsernameArray = extractTagUsername(
              res.interaction.content
            );
            if (tagUsernameArray?.length) {
              const oldTagUsernameArray = feed?._mentionedInfluencer?.length
                ? feed._mentionedInfluencer
                : [];

              const newTagUsernameArray = tagUsernameArray.filter(
                (newEach) => !oldTagUsernameArray.includes(newEach)
              );

              if (newTagUsernameArray?.length) {
                editPost(
                  {
                    _mentionedInfluencer: [
                      ...oldTagUsernameArray,
                      ...newTagUsernameArray,
                    ],
                  },
                  feed._id
                );
              }

              const payload = {
                username: tagUsernameArray,
                interactionId: res.interaction._id,
              };
              sendNotificationToMentionedInfluencer(payload);
            }
          }
          showToast("Replied successfully", "success");

          getComments();
          _getAllSubComments(commentObj?._id, true);
        } catch (error) {
          onComment(feedIndex, false);
          errorHandler(error);
        }
      } else {
        setIsEditFieldOpened(false);
        // setIsReplySectionShown(false);
      }
    } else {
      // for comment editing
      // const newComment = comment.replace(/\n/g, "<br/>");
      updateComment({ content: comment?.trim() }, commentObj?._id)
        .then((res) => {
          if (
            type === "post" &&
            res?.interaction?.content &&
            res?.interaction?._id
          ) {
            const tagUsernameArray = extractTagUsername(
              res.interaction.content
            );

            if (tagUsernameArray?.length) {
              const oldTagUsernameArray = feed?._mentionedInfluencer?.length
                ? feed._mentionedInfluencer
                : [];

              const newTagUsernameArray = tagUsernameArray.filter(
                (newEach) => !oldTagUsernameArray.includes(newEach)
              );

              if (newTagUsernameArray?.length) {
                editPost(
                  {
                    _mentionedInfluencer: [
                      ...oldTagUsernameArray,
                      ...newTagUsernameArray,
                    ],
                  },
                  feed._id
                );

                const payload = {
                  username: newTagUsernameArray,
                  interactionId: res.interaction._id,
                };
                sendNotificationToMentionedInfluencer(payload);
              }
            }
          }

          showToast("Updated Successfully", "success");
          getComments();
        })
        .catch((error) => {
          errorHandler(error);
        });

      setIsEditFieldOpened(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <img
          src={
            getLowResolutionLink(commentObj?._user?.profilePicture) ||
            DEFAULT_PROFILE_PICTURE
          }
          onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
          alt="avatar"
          className="userImg-Comment"
          onClick={() => _rediretToCreatorFanList()}
        />

        <div className="w-100">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: 2 }}
          >
            <div className="d-flex">
              <p
                onClick={() => _rediretToCreatorFanList()}
                className="userName-Comment"
              >
                {getFullName(commentObj?._user?.name)}
              </p>
              {commentObj?.createdAt ? (
                <div className="commentTime">
                  {formatTimeFromNow(commentObj.createdAt)}
                </div>
              ) : null}
            </div>

            {/* comment edit/delete options */}
            {canAddDeleteComment && !commentObj?.isLocalComment ? (
              <Dropdown
                nav
                className="customDropdown commentOptions"
                isOpen={isDropDownOpen}
                toggle={() => _toggleDropdown()}
              >
                <DropdownToggle nav>
                  <i className="fa fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  {userData?.user?._id === commentObj?._user?.id ? (
                    <DropdownItem onClick={() => _toggleCommentField()}>
                      Edit
                    </DropdownItem>
                  ) : null}
                  <DropdownItem
                    onClick={() => _togglePromptOpen(true)}
                    className="textWarning"
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : null}
          </div>

          <div>
            <div className="comments-Post">
              {isEditFieldOpened ? (
                <>
                  <MentionUserInput
                    isShowEmoji={false}
                    placeholder="Add comment..."
                    maxTextLength={200}
                    rows={1}
                    onChange={(value) => _updateComment(value)}
                    onKeyPress={(ev) => _onEnterPressed(ev, false)}
                    value={parseForTextField(comment)}
                    className="customPostCommentWrap"
                  />
                  {/* <Button
                      color="link"
                      className="emojiBtn"
                      style={{ bottom: "8px" }}
                      onClick={._editEmojiSectionToggle}
                    >
                      <i className="fa fa-smile-o" />
                    </Button> */}

                  <Button
                    className="sendMsg"
                    style={{ bottom: 20, right: 16 }}
                    disabled={!comment.trim().length}
                    onClick={() => _postComment()}
                  >
                    <img src="/assets/img/send.png" alt="Send" loading="lazy" />
                  </Button>

                  {/* {isEditEmojiSectionShown ? (
                      <Picker
                        onEmojiClick={onEditEmojiClick}
                        skinTone={SKIN_TONE_NEUTRAL}
                      />
                    ) : null} */}
                </>
              ) : (
                <pre>{mentionUserInputParse(comment)}</pre>
              )}
            </div>

            <div className="d-flex align-items-center" style={{ marginTop: 2 }}>
              {canAddDeleteComment ? (
                <Button
                  className="replyBtn"
                  onClick={() => _toggleReplySection()}
                  disabled={commentObj?.isLocalComment}
                >
                  Reply
                </Button>
              ) : null}
              <Button
                className="commentLikeBtn"
                onClick={() => likeComment(commentObj._id, commentObj._isLiked)}
                disabled={
                  commentObj?.isLocalComment ||
                  !canUpdateProfileSettings ||
                  isCommentsLikeLoading === commentObj._id
                }
              >
                {commentObj._isLiked ? (
                  <i className="fa fa-heart" />
                ) : (
                  <i className="fa fa-heart-o" />
                )}

                <span>{commentObj._likes ? commentObj._likes.length : 0}</span>
              </Button>
            </div>

            {/* Comment Thread */}
            {subComments?.[commentObj?._id]?.length ? (
              <>
                {subComments?.[commentObj?._id]
                  ?.slice(0, subCommentsPagination)
                  ?.map((subComment, subIndex) => (
                    <Fragment key={`subcomment_${subIndex}`}>
                      <Comments
                        comment={subComment}
                        isSubComment={true}
                        toggleReplySection={() => _toggleReplySection()}
                        getComments={() => getComments()}
                        getSubComments={() =>
                          _getAllSubComments(commentObj?._id)
                        }
                        likeComment={() =>
                          _likeComment(subComment._id, subComment._isLiked)
                        }
                        isCommentsLikeLoading={isCommentsLikeLoading}
                        feedIndex={feedIndex}
                        onComment={(index, isAdded) =>
                          onComment(index, isAdded)
                        }
                        feed={feed}
                        canAddDeleteComment={canAddDeleteComment}
                        type={type}
                      />
                    </Fragment>
                  ))}
              </>
            ) : null}

            {commentObj?._subComments &&
            (!subComments?.[commentObj?._id] ||
              commentObj?._subComments >
                subComments?.[commentObj?._id]?.length) &&
            commentObj?._subComments -
              (subComments?.[commentObj?._id]?.length || 0) ? (
              <Button
                className="replyBtn mb-2"
                disabled={subCommentsLoading === commentObj?._id ? true : false}
                onClick={() => _getAllSubComments(commentObj?._id)}
              >
                {subCommentsLoading === commentObj?._id ? (
                  <ButtonLoading />
                ) : (
                  <>
                    View{" "}
                    {commentObj._subComments -
                      (subComments?.[commentObj?._id]?.length || 0)}{" "}
                    more{" "}
                    {commentObj._subComments -
                      (subComments?.[commentObj?._id]?.length || 0) >
                    1
                      ? "replies"
                      : "reply"}
                  </>
                )}
              </Button>
            ) : subComments?.[commentObj?._id]?.length ? (
              <>
                {subComments?.[commentObj?._id]?.length -
                  subCommentsPagination >
                0 ? (
                  <Button
                    className="replyBtn mb-2"
                    onClick={() =>
                      setSubCommentsPagination((prev) => prev + 10)
                    }
                  >
                    View{" "}
                    {subComments?.[commentObj?._id]?.length -
                      subCommentsPagination}{" "}
                    more{" "}
                    {subComments?.[commentObj?._id]?.length -
                      subCommentsPagination >
                    1
                      ? "replies"
                      : "reply"}
                  </Button>
                ) : null}
              </>
            ) : null}

            {/* comment area inside thread */}
            {isReplySectionShown ? (
              <>
                <div
                  className="d-flex position-relative"
                  style={{ marginBottom: 10 }}
                >
                  <img
                    src={
                      getLowResolutionLink(userData?.user?.profilePicture) ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                    alt="Profile"
                    className="userImg-Comment"
                    loading="lazy"
                  />

                  <MentionUserInput
                    isShowEmoji={false}
                    placeholder="Add comment..."
                    maxTextLength={200}
                    rows={1}
                    value={parseForTextField(replyComment)}
                    onChange={(value) => _replyComment(value)}
                    onKeyPress={(ev) => _onEnterPressed(ev, true)}
                    className="customPostCommentWrap"
                  />

                  {/* <Button
                      color="link"
                      className="emojiBtn"
                      onClick={._emojiSectionToggle}
                    >
                      <i className="fa fa-smile-o" />
                    </Button> */}

                  <Button
                    className="sendMsg"
                    disabled={!replyComment.trim().length}
                    onClick={() => _postComment(true)}
                  >
                    <img src="/assets/img/send.png" alt="Send" loading="lazy" />
                  </Button>
                </div>
                {/* {isEmojiSectionShown ? (
                    <Picker
                      onEmojiClick={onEmojiClick}
                      skinTone={SKIN_TONE_NEUTRAL}
                    />
                  ) : null} */}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <CustomPrompt
        isOpen={isPromptOpen}
        message="Are you sure want to delete?"
        onSuccess={() => _onPromptSuccess()}
        onDismiss={() => _togglePromptOpen()}
      />
    </>
  );
};

export default Comments;
