import React, { useEffect, useState, memo } from "react";
import ReactSelect from "react-select";
import {
  Button,
  FormGroup,
  Label,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { capitalize, errorHandler, showToast } from "../helper-methods";
import ButtonLoading from "./ButtonLoading";
import { addFanToGroups } from "../http-calls";

const AddFanToTagPopver = ({
  tags = [],
  fanId,
  fanIdList = [],
  groups = [],
  target = "",
  btnText = "",
  onSuccess = () => {},
}) => {
  const [userInputTags, setUserInputTags] = useState([]);

  const [loading, setLoading] = useState(false);

  const _onSubmit = async () => {
    try {
      setLoading(true);

      const groupIds = userInputTags.map((each) => each.value);

      // if (!groupIds?.length) {
      //   showToast("At least one tag is required.", "error");
      //   return;
      // }

      // const res =
      await addFanToGroups({
        fanIds: fanIdList?.length ? fanIdList : [fanId],
        groupIds,
      });

      showToast("Subscriber's Tag has been updated", "success");

      onSuccess(
        userInputTags?.map((each) => ({
          _id: each.value,
          id: each.value,
          name: each.label,
        }))
      );

      // onSuccess(res?.groups || []);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserInputTags(
      groups?.length
        ? groups.map((each) => ({
            label: capitalize(each.name),
            value: each._id,
          }))
        : []
    );
  }, [groups]);

  if (!target) return <></>;

  return (
    <>
      <UncontrolledPopover
        placement="bottom-end"
        target={target}
        trigger="legacy"
        className="customPopover customPopoverTag"
      >
        <PopoverBody>
          <FormGroup className="multiSelectWrap mb-3">
            <Label style={{ fontSize: 14, marginBottom: 5 }}>Tags</Label>
            <div style={{ minWidth: 200 }}>
              <ReactSelect
                options={tags?.map((each) => ({
                  label: capitalize(each.name),
                  value: each._id,
                }))}
                value={userInputTags}
                onChange={(value) => setUserInputTags(value)}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </FormGroup>
          <Button
            className="themeBtn addBtn"
            onClick={() => _onSubmit()}
            disabled={loading ? true : false}
          >
            {loading ? <ButtonLoading /> : null}
            {btnText || "Add"}
          </Button>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default memo(AddFanToTagPopver);
