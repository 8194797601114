import React from "react";

const CustomSvgIcons = ({ type = "" }) => {
  const _getSvgIcon = (type) => {
    switch (type) {
      case "pause": {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Outline"
            viewBox="0 0 24 24"
            width="512"
            height="512"
          >
            <path d="M6.5,0A3.5,3.5,0,0,0,3,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,6.5,0ZM8,20.5a1.5,1.5,0,0,1-3,0V3.5a1.5,1.5,0,0,1,3,0Z" />
            <path d="M17.5,0A3.5,3.5,0,0,0,14,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,17.5,0ZM19,20.5a1.5,1.5,0,0,1-3,0V3.5a1.5,1.5,0,0,1,3,0Z" />
          </svg>
        );
      }

      case "edit": {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="255.999"
            height="256"
            viewBox="0 0 255.999 256"
          >
            <g id="edit_2985043" transform="translate(-29.627 -29.709)">
              <g id="Group_48" data-name="Group 48">
                <path
                  id="Path_66"
                  data-name="Path 66"
                  d="M54.7,214.759a9.852,9.852,0,0,1-6.965-16.817L201.7,43.984a9.851,9.851,0,0,1,13.931,13.931L61.669,211.873A9.825,9.825,0,0,1,54.7,214.759Z"
                />
              </g>
              <g id="Group_49" data-name="Group 49">
                <path
                  id="Path_67"
                  data-name="Path 67"
                  d="M39.492,285.709a9.966,9.966,0,0,1-2.074-.222,9.852,9.852,0,0,1-7.566-11.7l15.221-70.949a9.852,9.852,0,0,1,19.266,4.133L49.117,277.921a9.854,9.854,0,0,1-9.625,7.788Z"
                />
              </g>
              <g id="Group_50" data-name="Group 50">
                <path
                  id="Path_68"
                  data-name="Path 68"
                  d="M110.431,270.487a9.852,9.852,0,0,1-6.965-16.817L257.424,99.717a9.851,9.851,0,1,1,13.931,13.931L117.4,267.6a9.824,9.824,0,0,1-6.97,2.887Z"
                />
              </g>
              <g id="Group_51" data-name="Group 51">
                <path
                  id="Path_69"
                  data-name="Path 69"
                  d="M39.472,285.709a9.853,9.853,0,0,1-2.059-19.487L108.362,251a9.852,9.852,0,1,1,4.133,19.266L41.546,285.487a9.643,9.643,0,0,1-2.074.222Z"
                />
              </g>
              <g id="Group_52" data-name="Group 52">
                <path
                  id="Path_70"
                  data-name="Path 70"
                  d="M236.523,144.4a9.8,9.8,0,0,1-6.965-2.887L173.829,85.782A9.851,9.851,0,0,1,187.76,71.851l55.728,55.728a9.852,9.852,0,0,1-6.965,16.817Z"
                />
              </g>
              <g id="Group_53" data-name="Group 53">
                <path
                  id="Path_71"
                  data-name="Path 71"
                  d="M264.389,116.535a9.854,9.854,0,0,1-6.97-16.822,29.554,29.554,0,0,0-41.8-41.792,9.854,9.854,0,0,1-13.936-13.936,49.26,49.26,0,0,1,69.664,69.664,9.805,9.805,0,0,1-6.96,2.887Z"
                />
              </g>
            </g>
          </svg>
        );
      }

      case "trash": {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="225"
            height="256"
            viewBox="0 0 225 256"
          >
            <g id="bin_484662" transform="translate(-15.5)">
              <g id="Group_35" data-name="Group 35">
                <g id="Group_34" data-name="Group 34">
                  <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M218,30H180.5V22.5A22.526,22.526,0,0,0,158,0H98A22.526,22.526,0,0,0,75.5,22.5V30H38a22.5,22.5,0,0,0-7,43.881L44.38,235.368A22.632,22.632,0,0,0,66.8,256H189.2a22.633,22.633,0,0,0,22.423-20.635L225,73.88A22.5,22.5,0,0,0,218,30ZM90.5,22.5A7.509,7.509,0,0,1,98,15h60a7.509,7.509,0,0,1,7.5,7.5V30h-75ZM196.672,234.123A7.544,7.544,0,0,1,189.2,241H66.8a7.543,7.543,0,0,1-7.473-6.874L46.147,75H209.853ZM218,60H38a7.5,7.5,0,0,1,0-15H218a7.5,7.5,0,0,1,0,15Z"
                  />
                </g>
              </g>
              <g id="Group_37" data-name="Group 37">
                <g id="Group_36" data-name="Group 36">
                  <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M97.985,218.036l-7.5-121a7.5,7.5,0,0,0-14.972.928l7.5,121a7.5,7.5,0,1,0,14.972-.929Z"
                  />
                </g>
              </g>
              <g id="Group_39" data-name="Group 39">
                <g id="Group_38" data-name="Group 38">
                  <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M128,90a7.5,7.5,0,0,0-7.5,7.5v121a7.5,7.5,0,0,0,15,0V97.5A7.5,7.5,0,0,0,128,90Z"
                  />
                </g>
              </g>
              <g id="Group_41" data-name="Group 41">
                <g id="Group_40" data-name="Group 40">
                  <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M173.464,90.015a7.5,7.5,0,0,0-7.949,7.021l-7.5,121a7.5,7.5,0,0,0,14.971.928l7.5-121A7.5,7.5,0,0,0,173.464,90.015Z"
                  />
                </g>
              </g>
            </g>
          </svg>
        );
      }
      default:
    }
  };

  return <>{_getSvgIcon(type)}</>;
};

export default CustomSvgIcons;
