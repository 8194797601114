import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, ListGroup, ListGroupItem } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  capitalize,
  capitalizeEveryFirstLetter,
  errorHandler,
  formatCurrencyValue,
  formatDecimalNumber,
  getFeatureStatus,
  getLowResolutionLink,
  getFullName,
} from "../../helper-methods";
import CustomDateRangePicker from "../CustomDateRangePicker";
import ReactApexChart from "react-apexcharts";
import colors from "../../assets/styles/scss/style.scss";
import { getEarningSummaryStatistics } from "../../http-calls";
import moment from "moment";
import { MonthsInEngConfig } from "../../config/months-config";
import { getConfiguredString } from "../../helper-methods/configureString";
import { useSelector } from "react-redux";
import CustomLoader from "../custom/CustomLoader";
import SkeletonLoading from "../SkeletonLoading";

const EarningsSummary = ({ activeTab, tabId }) => {
  const history = useHistory();

  const userData = useSelector((state) => state?.userData);

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });
  
  const [lineGraphConfig, setLineGraphConfig] = useState({
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: colors?.themeColor,
          },
          formatter: function (value) {
            return value ? formatCurrencyValue(value) : formatCurrencyValue(0);
          },
        },
      },
      xaxis: {
        categories: [],
      },
      colors: [colors?.themeColor],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "Total Earning",
        data: [],
      },
    ],
  });

  const [donutGraphConfig, setDonutGraphConfig] = useState({
    options: {
      plotOptions: {
        pie: {
          expandOnClick: false,
          // donut: {
          //   labels: {
          //     show: true,
          //     name: {
          //       show: true,
          //     },
          //     value: {
          //       show: true,
          //       formatter: function (value) {
          //         return value
          //           ? formatCurrencyValue(value)
          //           : formatCurrencyValue(0);
          //       },
          //     },
          //   },
          // },
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: [],
      tooltip: {
        y: {
          formatter: function (value) {
            return value ? formatCurrencyValue(value) : formatCurrencyValue(0);
          },
        },
      },
      colors: [
        "#008ffb",
        "#00e396",
        "#feb019",
        "#E389B9",
        "#96FF07",
        "#F1512E",
        "#255aee",
        "#9C27B0",
        "#9A8665",
        "#4B5154",
      ],
    },
    series: [],
  });
  const [topFans, setTopFans] = useState([]);
  const [loading, setLoading] = useState(false);

  const isLiveEventActive = useMemo(() => getFeatureStatus("event"), []);
  const isPayPerViewActive = useMemo(() => getFeatureStatus("ppv"), []);
  const isVaultActive = useMemo(() => getFeatureStatus("vault"), []);

  const _setLineGraphData = (earningsGraph) => {
    const newLineGraphConfig = { ...lineGraphConfig };

    newLineGraphConfig.options.xaxis.categories = [];
    newLineGraphConfig.series[0].data = [];

    earningsGraph.forEach((each) => {
      let xaxisValue = MonthsInEngConfig[each.month - 1].short_name;

      if (new Date().getFullYear() !== each.year) {
        xaxisValue += ` ${each.year}`;
      }

      newLineGraphConfig.options.xaxis.categories.push(xaxisValue);
      newLineGraphConfig.series[0].data.push(each.amount);
    });

    setLineGraphConfig(newLineGraphConfig);
  };

  const _formatStatisticsDonutLabels = (label, percentage) => {
    switch (label) {
      case "liveEventEarning":
        return `${getConfiguredString("event")} ${percentage}%`;
      case "payperviewEarning":
        return `${getConfiguredString("ppv")} ${percentage}%`;
      case "vaultEarning":
        return `${getConfiguredString("vault")} ${percentage}%`;
      case "postEarning":
        return `Post ${percentage}%`;
      case "messageEarning":
        return `Message ${percentage}%`;
      case "refferalEarning":
        return `Referral ${percentage}%`;
      case "subscriptionEarning":
        return `Subscription ${percentage}%`;
      case "tipsEarning":
        const text = userData?.user?.settings?.tipText?.length
          ? capitalizeEveryFirstLetter(userData.user.settings.tipText)
          : "Tip";
        return `${text} ${percentage}%`;
      case "courseEarning":
        return `Course ${percentage}%`;
      case "customOrder":
        return `Order ${percentage}%`;
      case "goalEarning":
        return `Goals ${percentage}%`;
      case "shoutoutEarning":
        return `Shoutouts ${percentage}%`;
      default:
        return "N/A";
    }
  };

  const _setDonutGraphData = (statistics) => {
    const newDonutGraphConfig = { ...donutGraphConfig };

    newDonutGraphConfig.series = [];
    newDonutGraphConfig.options.labels = [];

    const totalAmount = Object.keys(statistics).reduce((acc, key) => {
      if (
        (!isLiveEventActive && key === "liveEventEarning") ||
        (!isPayPerViewActive && key === "payperviewEarning") ||
        (!isVaultActive && key === "vaultEarning")
      )
        return acc;

      acc = acc + statistics[key];
      return acc;
    }, 0);

    if (totalAmount) {
      Object.keys(statistics).forEach((key) => {
        if (
          (!isLiveEventActive && key === "liveEventEarning") ||
          (!isPayPerViewActive && key === "payperviewEarning") ||
          (!isVaultActive && key === "vaultEarning")
        )
          return;
        else {
          const percentage = formatDecimalNumber(
            (statistics[key] / totalAmount) * 100 || 0
          );

          newDonutGraphConfig.options.labels.push(
            _formatStatisticsDonutLabels(key, percentage)
          );
          newDonutGraphConfig.series.push(statistics[key]);
        }
      });
    }

    setDonutGraphConfig(newDonutGraphConfig);
  };

  const _getEarningSummaryStatistics = async (newFilters = {}) => {    
    try {
      setLoading(true);

      const payload = {};

      if (newFilters?.startDate && newFilters?.endDate) {
        payload.startDate = newFilters.startDate;
        payload.endDate = newFilters.endDate;
      } else {
        // newFilters.startDate = moment()
        //   .subtract(11, "months")
        //   .startOf("month")._d;
        // newFilters.endDate = moment()._d;

        // payload.startDate = newFilters.startDate;
        // payload.endDate = newFilters.endDate;

        setFilters(newFilters);
      }

      const res = await getEarningSummaryStatistics(payload);

      _setLineGraphData(res.earningsGraph);
      _setDonutGraphData(res.statistics);

      setTopFans(res.topFans?.length ? res.topFans : []);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _onDatesChange = ({ startDate, endDate }) => {
    const newFilters = { ...filters };
    newFilters.startDate = startDate?._d
      ? moment(startDate._d)
          .subtract(6, "hours")
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS+0000")
      : null;
    newFilters.endDate = endDate?._d
      ? moment(endDate._d)
          .add(12, "hours")
          .minutes(-1)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS+0000")
      : null;
    setFilters(newFilters);

    if (newFilters?.startDate && newFilters?.endDate) {
      _getEarningSummaryStatistics(newFilters);
      return;
    }

    if (!newFilters?.startDate && !newFilters?.endDate) {
      _getEarningSummaryStatistics(newFilters);
      return;
    }
  };

  const _onClickOneTopFan = (fan) => {
    if (fan?.userType === "Fan") {
      const username = fan?.username;
      history.push(`/my-profile?tabId=4&username=${username}`);
    }
  };

  useEffect(() => {
    if (activeTab === tabId) {
      _getEarningSummaryStatistics();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabId]);

  return (
    <Row className="my-3 noMargin">
      <Col md="12" className="noPadding">
        {/* #1 line graph for earnings summary */}

        <div className="earningSummaryDateRange">
          <CustomDateRangePicker
            startDate={filters?.startDate}
            endDate={filters?.endDate}
            startDateId={`startDate_earning_statistics`}
            endDateId={`endDate_earning_statistics`}
            onDatesChange={({ startDate, endDate }) =>
              _onDatesChange({
                startDate,
                endDate,
              })
            }
          />

          {loading ? <CustomLoader className="mx-2" /> : null}
        </div>

        {lineGraphConfig?.series?.[0]?.data?.length ? (
          <ReactApexChart
            options={lineGraphConfig.options}
            series={lineGraphConfig.series}
            type="area"
            width="100%"
            height="400"
            className="chartWrap"
          />
        ) : null}
      </Col>

      <Col lg="7" className="noPadding mb-4 mb-lg-0">
        {/* #2 donut graph for earnings summary*/}
        <h4 className="mb-2 mt-2">Statistics</h4>
        {donutGraphConfig?.series?.length ? (
          <ReactApexChart
            options={donutGraphConfig.options}
            series={donutGraphConfig.series}
            type="donut"
            width="100%"
            height="325"
            className="donutChart"
          />
        ) : loading ? (
          <SkeletonLoading type={"box"} count={1} />
        ) : (
          <p className="mt-3">There is no data to display.</p>
        )}
      </Col>

      <Col lg="5" className="noPadding">
        {/* Your Top Fanz `upto 5` list */}
        <div className="topFansWrap">
          <h5 className="mb-3">Your Top Fanz</h5>
          <ListGroup>
            {topFans?.length ? (
              topFans.map((each) => (
                <ListGroupItem
                  key={each.id}
                  onClick={() => _onClickOneTopFan(each._fan)}
                >
                  <div className="userChatList justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="mr-2 position-relative">
                        {/* show the below icon only when the user is Favourite */}
                        {!each.isExpired && each.isFavourited ? (
                          <i title="Favorite" className="fa fa-star topFan" />
                        ) : null}

                        <img
                          className="fanImg"
                          src={
                            getLowResolutionLink(each._fan?.profilePicture) ||
                            DEFAULT_PROFILE_PICTURE
                          }
                          onError={(e) =>
                            (e.target.src = DEFAULT_PROFILE_PICTURE)
                          }
                          alt="Profile Img"
                          loading="lazy"
                        />

                        {/* show the below icon only when the user subscription has expired */}
                        {each.isExpired ? (
                          <i
                            title="Expired"
                            className="fa fa-times-circle expiredSubscriber"
                          />
                        ) : null}
                      </div>
                      <div>
                        <h5>
                          {each._fan?.name?.full || each?._fan?.name?.first
                            ? capitalize(getFullName(each._fan?.name))
                            : "N/A"}
                        </h5>
                        <span>
                          {each._fan?.username
                            ? `@${each._fan.username}`
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="sumSpent">
                      {formatCurrencyValue(each.lifetimeSpent || 0)}
                    </div>
                  </div>
                </ListGroupItem>
              ))
            ) : loading ? (
              <SkeletonLoading type={"threadList"} count={5} />
            ) : (
              <ListGroupItem>There is no data to display.</ListGroupItem>
            )}
          </ListGroup>

          {topFans?.length ? (
            <Button
              className="themeBtn saveBtn mt-3 mb-2"
              onClick={() => history.push("/my-profile?tabId=4")}
            >
              View All Fanz
            </Button>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export default EarningsSummary;
