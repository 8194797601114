import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import React, { useEffect } from "react";
import { getFullName, getLowResolutionLink } from "../../helper-methods";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedThread } from "../../redux/actions";
import { useState } from "react";

function ViewLikePreviewModal({ modal, viewToggle, stories, currentIndex, likedBy }) {

  const [ selectedFan, setSelectedFan ] = useState(null)

  const history = useHistory();
  const dispatch = useDispatch();

  const chatData = useSelector((state) => state?.chatData);
    // console.log("chatData",chatData)
    // console.log("threadId",threadId)   

  const _openMessagesCard = (threadId) => { 
    const findThread = chatData?.threads.find((item) => item._id === threadId)
    // console.log("threadFound",threadFound)
    if(findThread){
      history.push(`/messages`)
      dispatch(updateSelectedThread(findThread));
    }
  };

  useEffect(() => {
    _openMessagesCard(selectedFan?.threadId)
  },[selectedFan])

  return (
    <>
      {stories[currentIndex]?.like?.length > 0 ? (
        <>
          <Button onClick={viewToggle} color="link" className="storyLikeStats">
            <i className="fa fa-heart" />
          </Button>
          <h6 className="storyLikesCount">{stories[currentIndex]?.like?.length}</h6>
        </>
      ) : (
        <Button onClick={viewToggle} color="link" className="storyLikeStats">
          <i className="fa fa-heart-o" />
        </Button>
      )}
      <Modal
        isOpen={modal}
        toggle={() => viewToggle()}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => viewToggle()}>Story Likes</ModalHeader>
        <ModalBody>
        <p className="fs-16" style={{ marginBottom: 10 }}>
            Total Likes:{" "}
            <span style={{ fontWeight: "600" }} className="themeColor">
              {stories[currentIndex]?.like?.length}
            </span>
          </p>
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {stories[currentIndex]?.like?.length ? (
                stories[currentIndex]?.like?.map((likedPerson, index) => (
                  <tr key={stories[currentIndex]?.like}>
                    <td>
                      <div className="d-flex align-items-center" onClick={() => setSelectedFan(likedBy[index])}>
                        <div className="fansImgWrap">
                          <img
                            className="fansImg"
                            src={
                              getLowResolutionLink(
                                likedPerson.profilePicture
                              ) || DEFAULT_PROFILE_PICTURE
                            }
                            onError={(e) =>
                              (e.target.src = DEFAULT_PROFILE_PICTURE)
                            }
                            alt="Profile Thumbnail"
                            loading="lazy"
                          />
                        </div>
                        <span
                          style={{ cursor: 'pointer' }}

                        >
                          {getFullName(likedPerson?.name)}
                        </span>



                      </div>
                    </td>

                    <td>{moment(likedPerson?.likedAt).calendar()}</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="3">{"No one has liked yet"}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            className="modalBtnSave"
            onClick={() => viewToggle()}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* <Modal
              isOpen={modal}
              toggle={viewToggle}
              // style={customModalStyles}
              shouldCloseOnOverlayClick={false} // Prevent closing when clicking outside
              ariaHideApp={false}
            >
              <ModalHeader
                toggle={viewToggle}
                style={{ backgroundColor: "#f1b0ff" }}
              >
                Liked by {stories[currentIndex]?.like?.length}
              </ModalHeader>
              <ModalBody style={{ backgroundColor: "white", color: "black" }}>
                {stories[currentIndex]?.like?.map((likedPerson) => {
                  return (
                    <div style={{ display: "flex",margin:"auto auto 12px auto" }}>
                      <img
                        src={
                          likedPerson.profilePicture ||
                          "/assets/img/default_user_icon.svg"
                        }
                        alt="likedPerson"
                        style={{
                          borderRadius: "50%",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                      <div style={{ marginLeft: "5px" }}>
                        <h6>
                          {likedPerson?.name?.first} {likedPerson?.name?.last}
                        </h6>
                        {moment(likedPerson?.likedAt).calendar()}
                      </div>
                    </div>
                  );
                })}
              </ModalBody>
            </Modal> */}
    </>
  );
}

export default ViewLikePreviewModal;
