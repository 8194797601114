import React, { useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  showToast,
  TierAmountValidation,
  isSubscriptionPriceExist,
  uploadFileOnServer,
  errorHandler,
  isPermissionToAccess,
  extractQueryParams,
  getYesterdayDate,
  isValidPrice,
  formatDateAndTime,
  getRecentHourDate,
  capitalize,
  getFullName,
} from "../helper-methods";
import { showLoader, hideLoader } from "../redux/actions/loaderData";
import {
  createLiveEvent,
  getEventDetails,
  editLiveEvent,
  getInfluencersList,
} from "../http-calls";
import { CURRENCY_SYMBOL } from "../config";
import { getConfiguredString } from "../helper-methods/configureString";
import ConfigureString from "../components/ConfigureString";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AsyncReactSelect from "react-select/async";
import { useRef } from "react";
import { AmountConfig } from "../config/appConfig";
import MediaLibraryModal from "../components/modals/MediaLibraryModal";
import Select from "react-select";
import { getAndUpdateSubscribers, getAndUpdateTags } from "../redux/actions";
import NewStoryModal from "../components/modals/NewStoryModal";
import CustomVideoPlayer from "../components/custom/CustomVideoPlayer";

const CreateLiveEvent = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);
  const { tags } = useSelector((state) => state?.tags || {});
  const { subscribers } = useSelector((state) => state?.subscribers || {});

  const searchInfluencersTimerRef = useRef({ current: null });

  const [formFields, setFormFields] = useState({
    name: "",
    description: "",
    price: "",
    plusPrice: "",
    premiumPrice: "",
    scheduledAt: getRecentHourDate(),
    eventCoHosts: [],
    isExclusive: false,
    groupIds: [],
    fanIds: [],
  });
  const [preview, setPreview] = useState({
    coverImage: {
      uploadData: null,
      previewBlob: null,
      type: "image",
      uploadUrl: null,
    },
    previewVideo: {
      uploadData: null,
      previewBlob: null,
      type: "video",
      uploadUrl: null,
      hlsLink: null,
    },
  });
  // console.log(preview);
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [eventDetails, setEventDetails] = useState(null);
  const [loadingState, setLoadingState] = useState({
    coverImage: false,
    eventPreviewVideo: false,
  });

  const [mediaLibraryModalCoverImage, setMediaLibraryModalCoverImage] =
    useState({
      isOpen: false,
      data: null,
    });
  const [mediaLibraryModalPreviewVideo, setMediaLibraryModalPreviewVideo] =
    useState({
      isOpen: false,
      data: null,
    });

  const [postAsStoryConfirmationModal, setPostAsStoryConfirmationModal] =
    useState({
      isOpen: false,
      data: null,
    });

  const _togglePostAsStoryConfirmationModal = (isOpen = false, data = null) => {
    setPostAsStoryConfirmationModal({ isOpen, data });
  };
  const _togglePreviewVideoModal = (isOpen = false, data = null) => {
    setMediaLibraryModalPreviewVideo({ isOpen, data });
  };
  const _toggleCoverImageModal = (isOpen = false, data = null) => {
    setMediaLibraryModalCoverImage({ isOpen, data });
  };

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _setFormFields = (data) => {
    setFormFields({
      name: data?.name || "",
      description: data?.description || "",
      price: data?.price ? data.price.toString() : "",
      plusPrice: data?.plusPrice ? data.plusPrice.toString() : "",
      premiumPrice: data?.premiumPrice ? data.premiumPrice.toString() : "",
      scheduledAt: data?.scheduledAt ? data.scheduledAt : "",
      eventCoHosts: data?.eventCoHosts?.length
        ? data.eventCoHosts.map((each) => ({
            label: each.username,
            value: each._id,
            ...each,
          }))
        : [],
      isExclusive: data?.isExclusive || false,
      fanIds:
        data?._fans?.map((each) => ({
          label: capitalize(getFullName(each.name)),
          value: each._id || each?.id,
        })) || [],
      groupIds:
        data?.groups?.map((each) => ({
          label: capitalize(each.name),
          value: each._id || each?.id,
        })) || [],
    });
    setPreview({
      coverImage: {
        uploadData: null,
        previewBlob: null,
        type: "image",
        uploadUrl: data?.coverImage || null,
      },
      previewVideo: {
        uploadData: null,
        previewBlob: null,
        type: "video",
        uploadUrl: data?.previewVideoUrl || null,
        hlsLink: data?.hlsLink || null,
      },
    });
    setIsDirty({});
    setErrors({});
  };

  const _getAllTags = async () => {
    try {
      await getAndUpdateTags()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAllSubscribers = async () => {
    try {
      await getAndUpdateSubscribers()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getEventDetails = async () => {
    try {
      const { eventId } = extractQueryParams();

      dispatch(showLoader("Loading..."));

      const res = await getEventDetails(eventId);

      setEventDetails(res.event);

      _setFormFields(res.event);

      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
      history.goBack();
    }
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      // Check for existence of subscription prices and remove fields if not available
      if (!isSubscriptionPriceExist("basic")) {
        delete newFormFields.price;
        delete newIsDirty.price;
      }

      if (!isSubscriptionPriceExist("plus")) {
        delete newFormFields.plusPrice;
        delete newIsDirty.plusPrice;
      }

      if (!isSubscriptionPriceExist("premium")) {
        delete newFormFields.premiumPrice;
        delete newIsDirty.premiumPrice;
      }

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "name": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "description": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "fanIds":
            case "groupIds": {
              if (!newFormFields?.isExclusive) {
                break;
              }

              if (
                newFormFields?.fanIds?.length ||
                newFormFields?.groupIds?.length
              ) {
                newErrors["fanIds"] = null;
                newErrors["groupIds"] = null;
              } else {
                newErrors["fanIds"] = "*Required";
                newErrors["groupIds"] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "price":
            case "plusPrice":
            case "premiumPrice": {
              if (
                !String(newFormFields?.[key]).length ||
                Number(newFormFields?.[key]) === 0
              ) {
                newIsDirty[key] = false;
                newErrors[key] = null;
                break;
              }

              if (
                +newFormFields?.[key] >= +AmountConfig.minEventPrice &&
                +newFormFields?.[key] <= +AmountConfig.maximumLimit
              ) {
                if (
                  TierAmountValidation(
                    newFormFields?.price,
                    newFormFields?.plusPrice,
                    newFormFields?.premiumPrice
                  )
                ) {
                  newIsDirty[key] = false;
                  newErrors[key] = null;
                } else {
                  newErrors[key] =
                    key === "price"
                      ? "Basic price should be greater than or equal to plus and premium price"
                      : key === "plusPrice"
                      ? "Plus price should be greater than or equal to premium price. But less than basic price"
                      : "Premium price should be less than or equal to plus and basic price.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = `${
                  newFormFields?.[key] > AmountConfig.maximumLimit
                    ? `Maximum ${CURRENCY_SYMBOL}${AmountConfig.maximumLimit}`
                    : `Minimum ${CURRENCY_SYMBOL}${AmountConfig.minEventPrice} or free`
                }`;
                isFormValid = false;
              }
              break;
            }

            case "scheduledAt": {
              if (newFormFields[key]) {
                if (new Date() < new Date(newFormFields[key])) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Should be future date & time";
                  isFormValid = false;
                }
              } else {
                if (eventDetails) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _onChangeFormfield = (key, value) => {
    if (
      (key === "price" || key === "plusPrice" || key === "premiumPrice") &&
      value &&
      !isValidPrice(value)
    ) {
      return;
    }
    const newFormFields = { ...formFields };

    if (key === "isExclusive") {
      newFormFields.price = 0;
      newFormFields.plusPrice = 0;
      newFormFields.premiumPrice = 0;
      newFormFields.tags = [];

      const newErrors = { ...errors };
      newErrors.price = null;
      newErrors.plusPrice = null;
      newErrors.premiumPrice = null;
      setErrors(newErrors);
    }

    if (key === "scheduledAt") {
      newFormFields[key] = value?._d ? new Date(value).toISOString() : "";

      const newIsDirty = {
        [key]: true,
      };
      _validateFormfields({ newFormFields, newIsDirty });
    } else {
      newFormFields[key] = value;
    }

    setFormFields(newFormFields);
  };

  const _onBlurFormfield = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _updateCoverImageAndPreview = (key, file) => {
    let fileType = file?.[0]?.contentType || file?.[0]?.type;

    if (key === "coverImage" && fileType !== "image") {
      showToast("Only image file is allowed", "error");
      _toggleCoverImageModal();
      return;
    } else if (key === "previewVideo" && fileType !== "video") {
      showToast("Only video file is allowed", "error");
      _togglePreviewVideoModal();
      return;
    }

    _manageLoadingState(key, true);

    const newPreview = { ...preview };

    if (key) {
      if (file?.[0]?.url) {
        newPreview[key]["previewBlob"] = "";
        newPreview[key]["uploadData"] = null;
        newPreview[key]["type"] = fileType;
        newPreview[key]["uploadUrl"] = file?.[0]?.url;
        newPreview[key]["hlsLink"] = file?.[0].hlsLink;
      } else {
        newPreview[key] = file?.[0];
      }
    }

    setPreview(newPreview);

    if (key === "coverImage") {
      _toggleCoverImageModal();
    } else {
      _togglePreviewVideoModal();
    }

    _manageLoadingState(key, false);
  };

  // const _onChangeFile = async (event, key) => {
  //   if (!event?.target?.files?.length) {
  //     return;
  //   }

  //   const newPreview = { ...preview };

  //   const file = event.target.files[0];
  //   const fileType = file.type.split("/")[0];

  //   if (key === "coverImage" && fileType !== "image") {
  //     showToast("Only image file is allowed", "error");
  //     return;
  //   } else if (key === "previewVideo" && fileType !== "video") {
  //     showToast("Only video file is allowed", "error");
  //     return;
  //   }

  //   setIsPreviewLoading(true);

  //   const res = await mediaFilesHandler({ newFiles: [file] });

  //   if (res.error) {
  //     errorHandler();
  //     setIsPreviewLoading(false);
  //     return;
  //   }

  //   newPreview[key] = {
  //     uploadData: res?.mediaFiles?.[0]?.uploadData,
  //     previewBlob: res?.mediaFiles?.[0]?.previewBlob,
  //     type: res?.mediaFiles?.[0]?.type,
  //     uploadUrl: null,
  //   };

  //   setPreview(newPreview);
  //   setIsPreviewLoading(false);
  // };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _createLiveEvent = (uploadData) => {
    createLiveEvent(uploadData)
      .then((res) => {
        showToast(getConfiguredString("event has been created."), "success");
        dispatch(hideLoader());
        _togglePostAsStoryConfirmationModal(true, res?.event);
        // history.replace("/live-events");
      })
      .catch((err) => {
        dispatch(hideLoader());
        errorHandler(err);
      });
  };

  const _editLiveEvent = (id, updateData) => {
    editLiveEvent(updateData, id)
      .then((res) => {
        showToast(getConfiguredString("event has been updated."), "success");
        dispatch(hideLoader());

        history.replace("/live-events");
      })
      .catch((err) => {
        dispatch(hideLoader());
        errorHandler(err);
      });
  };

  const _callPostContentAPI = (apiData) => {
    if (eventDetails) {
      _editLiveEvent(eventDetails.id, apiData);
    } else {
      _createLiveEvent(apiData);
    }
  };

  const _submitLiveEventCreationForm = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      if (eventDetails) {
        dispatch(showLoader("Updating event"));
      } else {
        dispatch(showLoader("Creating event"));
      }

      const payload = {
        name: newFormFields.name.trim(),
        description: newFormFields.description.trim(),
        eventCoHosts: newFormFields.eventCoHosts?.length
          ? newFormFields.eventCoHosts.map((each) => each.value)
          : [],
        isExclusive: newFormFields?.isExclusive,
      };

      if (newFormFields?.isExclusive) {
        payload.exclusivePrice = newFormFields?.price;
        payload["groupIds"] = newFormFields?.groupIds?.map(
          (each) => each?.value
        );
        payload["fanIds"] = newFormFields?.fanIds?.map((each) => each?.value);
      }

      if (newFormFields.scheduledAt) {
        payload.scheduledAt = newFormFields.scheduledAt;
      }

      if (
        (Number(formFields.price) === 0 || !String(formFields.price).length) &&
        (Number(formFields.premiumPrice) === 0 ||
          !String(formFields.premiumPrice).length) &&
        (Number(formFields.plusPrice) === 0 ||
          !String(formFields.plusPrice).length)
      ) {
        payload["isPublic"] = true;
      } else {
        payload["price"] =
          Number(formFields.price) === 0 || !String(formFields.price).length
            ? 0
            : Number(formFields.price);

        payload["plusPrice"] =
          Number(formFields.plusPrice) === 0 ||
          !String(formFields.plusPrice).length
            ? 0
            : Number(formFields.plusPrice);

        payload["premiumPrice"] =
          Number(formFields.premiumPrice) === 0 ||
          !String(formFields.premiumPrice).length
            ? 0
            : Number(formFields.premiumPrice);
        payload["isPublic"] = false;
      }

      // handle preview media
      if (preview?.coverImage?.uploadUrl || preview?.previewVideo?.uploadUrl) {
        if (preview?.coverImage?.uploadUrl) {
          payload["coverImage"] = preview?.coverImage?.uploadUrl;
        }

        if (preview?.previewVideo?.uploadUrl) {
          // console.log(
          //   " preview?.previewVideo?.uploadUrl",
          //   preview?.previewVideo?.uploadUrl
          // );
          payload["previewVideoUrl"] = preview?.previewVideo?.uploadUrl;
        }
      }

      if (preview.coverImage?.uploadData || preview.previewVideo?.uploadData) {
        const uploadMedia = [];

        if (preview.coverImage?.uploadData) {
          preview.coverImage["forKeyName"] = "coverImage";
          uploadMedia.push({ ...preview.coverImage });
        }
        if (preview.previewVideo?.uploadData) {
          preview.previewVideo["forKeyName"] = "previewVideoUrl";
          uploadMedia.push({ ...preview.previewVideo });
        }

        if (uploadMedia.length) {
          const uploadMediaRes = await uploadFileOnServer([...uploadMedia]);
          uploadMediaRes.forEach((res) => {
            payload[res.forKeyName] = res.url;
          });
        }
      }

      _callPostContentAPI(payload);
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _getInfluencersList = (searchText = "") =>
    new Promise((resolve) => {
      if (searchInfluencersTimerRef?.current)
        clearTimeout(searchInfluencersTimerRef?.current);

      if (!searchText) return resolve([]);

      searchInfluencersTimerRef.current = setTimeout(async () => {
        try {
          const res = await getInfluencersList({ searchText });

          if (!res?.influencers?.length) return resolve([]);

          resolve(
            res.influencers
              ?.filter((each) => each.username !== userData?.user?.username)
              .map((each) => ({
                label: each.username,
                value: each._id,
                ...each,
              }))
          );
        } catch (error) {
          errorHandler(error);
          resolve([]);
        }
      }, 1000);
    });

  const _onDropFileCoverImage = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _toggleCoverImageModal(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  const _onDropFilePreviewVideo = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _togglePreviewVideoModal(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    const { eventId } = extractQueryParams();

    if (eventId) {
      // edit event
      if (!isPermissionToAccess("liveEvent", "canEditOrDeleteLiveEvent")) {
        showToast("Unauthorized", "error");
        history.push("/my-profile");
        return;
      }

      _getEventDetails();
    } else {
      // create event
      if (!isPermissionToAccess("liveEvent", "canCreateLiveEvent")) {
        showToast("Unauthorized", "error");
        history.push("/my-profile");
        return;
      }
    }

    _getAllTags();
    _getAllSubscribers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col
            sm={12}
            md={10}
            lg={9}
            xl={8}
            className="pgPadding bg-white createEvent"
          >
            <div className="pgTitle justify-content-start">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              <h2>
                {eventDetails ? (
                  <ConfigureString keyString={"Edit event"} />
                ) : (
                  <ConfigureString keyString={"Create event"} />
                )}
              </h2>
            </div>

            {/* <Form> */}
              <FormGroup>
                <Label>
                  <ConfigureString keyString={"event Name"} />
                </Label>
                <Input
                  type="text"
                  value={formFields.name}
                  onChange={(e) => _onChangeFormfield("name", e.target.value)}
                  onBlur={() => _onBlurFormfield("name")}
                  placeholder="Enter"
                />
                {errors?.name ? (
                  <div className="form-error">{errors.name}</div>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>
                  <ConfigureString keyString="event Description" />
                </Label>
                <Input
                  type="textarea"
                  value={formFields.description}
                  onChange={(e) =>
                    _onChangeFormfield("description", e.target.value)
                  }
                  onBlur={() => _onBlurFormfield("description")}
                  rows="3"
                  placeholder="Enter"
                />
                <div className="form-error">
                  {errors?.description ? (
                    <div className="form-error">{errors.description}</div>
                  ) : null}
                </div>
              </FormGroup>

              {/* live event cover Image */}
              <FormGroup
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDrop={(e) => _onDropFileCoverImage(e)}
              >
                <Label>Add Cover Image</Label>
                <Label className="coverImgWrap">
                  {/* <Input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*, .heic, .heif"
                    value=""
                    name="coverImage"
                    onChange={(e) => _onChangeFile(e, "coverImage")}
                    disabled={isPreviewLoading}
                  /> */}

                  <Button
                    style={{ display: "none" }}
                    onClick={() => _toggleCoverImageModal(true)}
                    disabled={loadingState?.coverImage}
                  />

                  {preview?.coverImage?.previewBlob ||
                  preview?.coverImage?.uploadUrl ? (
                    <>
                      <img
                        src={
                          preview?.coverImage?.previewBlob ||
                          preview?.coverImage?.uploadUrl
                        }
                        alt="Cover Img"
                        loading="lazy"
                      />
                      <div className="changeImg">
                        {loadingState?.coverImage ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : (
                          <i className="fa fa-pencil" />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <img
                        src="/assets/img/upload.png"
                        alt="Upload"
                        className="uploadImg"
                        loading="lazy"
                      />
                      <p className="mb-0">Upload Image</p>
                    </div>
                  )}
                </Label>
              </FormGroup>

              {/* live event preview video */}
              <FormGroup
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDrop={(e) => _onDropFilePreviewVideo(e)}
              >
                <Label>Add Preview Video</Label>
                <Label className="coverImgWrap">
                  {/* <Input
                    type="file"
                    style={{ display: "none" }}
                    accept="video/*"
                    value=""
                    name="eventPreviewVideo"
                    onChange={(e) => _onChangeFile(e, "previewVideo")}
                  /> */}
                  <Button
                    style={{ display: "none" }}
                    onClick={() => _togglePreviewVideoModal(true)}
                    disabled={
                      loadingState?.eventPreviewVideo ||
                      preview.previewVideo?.hlsLink ||
                      preview.previewVideo?.previewBlob
                    }
                  />

                  {preview.previewVideo?.previewBlob ||
                  preview.previewVideo?.uploadUrl ||
                  preview.previewVideo?.hlsLink ? (
                    <>
                      {/* <video
                        controls
                        disablePictureInPicture
                        src={
                          preview.previewVideo?.previewBlob ||
                          preview.previewVideo?.uploadUrl
                        }
                      /> */}
                      <CustomVideoPlayer
                        key={preview?.previewVideo?.previewBlob}
                        src={
                          preview?.previewVideo?.uploadUrl ||
                          preview?.previewVideo?.previewBlob
                        }
                        hlsLink={preview?.previewVideo?.hlsLink || {}}
                        duration={preview?.previewVideo?.duration}
                        controls
                        disablePictureInPicture
                      />

                      <div
                        className="changeImg"
                        onClick={() => _togglePreviewVideoModal(true)}
                      >
                        {loadingState?.ppvVideo ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : (
                          <i className="fa fa-pencil" />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <img
                        src="/assets/img/upload.png"
                        alt="Upload"
                        className="uploadImg"
                        loading="lazy"
                      />
                      <p className="mb-0">Upload Video</p>
                    </div>
                  )}
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>Invite Influencers (Co-Host)</Label>
                <AsyncReactSelect
                  cacheOptions
                  defaultOptions
                  isMulti
                  value={formFields.eventCoHosts}
                  isClearable={false}
                  onChange={(value) =>
                    _onChangeFormfield("eventCoHosts", value)
                  }
                  loadOptions={_getInfluencersList}
                />
                {errors?.eventCoHosts && (
                  <div className="form-error">{errors?.eventCoHosts}</div>
                )}
              </FormGroup>

              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="exclusiveEvent"
                  checked={formFields?.isExclusive}
                  onChange={(e) =>
                    _onChangeFormfield("isExclusive", e.target.checked)
                  }
                  label="Exclusive Event"
                />
              </FormGroup>

              {formFields?.isExclusive ? (
                <Row className="noMargin">
                  <Col md="6" className="noPadding">
                    <FormGroup>
                      <Label>Subscribers</Label>
                      <div className="multiSelectWrap">
                        <Select
                          isMulti
                          name=""
                          value={formFields?.fanIds}
                          options={subscribers?.map((each) => ({
                            label: capitalize(getFullName(each.name)),
                            value: each._id,
                          }))}
                          onChange={(value) =>
                            _onChangeFormfield("fanIds", value)
                          }
                          onBlur={() => _onBlurFormfield("fanIds")}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </FormGroup>
                    {errors?.fanIds && (
                      <div className="form-error">{errors?.fanIds}</div>
                    )}
                  </Col>
                  <Col md="6" className="noPadding">
                    <FormGroup>
                      <Label>Tag</Label>
                      <div className="multiSelectWrap">
                        <Select
                          isMulti
                          options={tags?.map((each) => ({
                            label: capitalize(each.name),
                            value: each._id,
                          }))}
                          value={formFields?.groupIds}
                          onChange={(value) =>
                            _onChangeFormfield("groupIds", value)
                          }
                          onBlur={() => _onBlurFormfield("groupIds")}
                          name=""
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={tags.loading}
                        />
                      </div>
                    </FormGroup>
                    {errors?.groupIds && (
                      <div className="form-error">{errors?.groupIds}</div>
                    )}
                  </Col>
                </Row>
              ) : null}

              {formFields?.isExclusive ||
              !userData?.user?.multiTierSubscription ? (
                <>
                  <FormGroup>
                    <Label>Price</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="dollarWrap">
                          <i className="fa fa-dollar" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        value={formFields.price}
                        onChange={(e) =>
                          _onChangeFormfield("price", e.target.value)
                        }
                        onBlur={() => _onBlurFormfield("price")}
                        className="dollarWrapInput"
                      />
                    </InputGroup>
                    {errors?.price ? (
                      <div className="form-error">{errors.price}</div>
                    ) : null}
                  </FormGroup>
                </>
              ) : userData?.user?.multiTierSubscription ? (
                <>
                  <Label>Pricing</Label>
                  <Row className="noMargin PPVCustomPadding">
                    {/* For Support Multi tier */}
                    {/* basicMemberPrice */}
                    {isSubscriptionPriceExist("basic") ? (
                      <Col xs={6} sm={4}>
                        <FormGroup>
                          <Label>Basic</Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="dollarWrap">
                                <i className="fa fa-dollar" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              value={formFields.price}
                              onChange={(e) =>
                                _onChangeFormfield("price", e.target.value)
                              }
                              onBlur={() => _onBlurFormfield("price")}
                              className="dollarWrapInput"
                            />
                          </InputGroup>
                          {errors?.price ? (
                            <div className="form-error">{errors.price}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    ) : null}

                    {/* For Support Multi tier */}
                    {/* plusMemberPrice */}
                    {userData?.user?.multiTierSubscription &&
                    isSubscriptionPriceExist("plus") ? (
                      <Col xs={6} sm={4}>
                        <FormGroup>
                          <Label>Plus</Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="dollarWrap">
                                <i className="fa fa-dollar" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              value={formFields.plusPrice}
                              onChange={(e) =>
                                _onChangeFormfield("plusPrice", e.target.value)
                              }
                              onBlur={() => _onBlurFormfield("plusPrice")}
                              className="dollarWrapInput"
                            />
                          </InputGroup>
                          {errors?.plusPrice ? (
                            <div className="form-error">{errors.plusPrice}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    ) : null}

                    {/* For Support Multi tier */}
                    {/* premiumMemberPrice */}
                    {userData?.user?.multiTierSubscription &&
                    isSubscriptionPriceExist("premium") ? (
                      <Col xs={6} sm={4}>
                        <FormGroup>
                          <Label>Premium</Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="dollarWrap">
                                <i className="fa fa-dollar" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              value={formFields.premiumPrice}
                              onChange={(e) =>
                                _onChangeFormfield(
                                  "premiumPrice",
                                  e.target.value
                                )
                              }
                              onBlur={() => _onBlurFormfield("premiumPrice")}
                              className="dollarWrapInput"
                            />
                          </InputGroup>
                          {errors?.premiumPrice ? (
                            <div className="form-error">
                              {errors.premiumPrice}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ) : (
                <></>
              )}

              <FormGroup>
                <Label>
                  <ConfigureString keyString="event Start" />
                </Label>

                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select start date & time",
                    value: formatDateAndTime(formFields.scheduledAt),
                  }}
                  value={
                    formFields.scheduledAt
                      ? new Date(formFields.scheduledAt)
                      : ""
                  }
                  onChange={(e) => _onChangeFormfield("scheduledAt", e)}
                  isValidDate={(current) => current.isAfter(getYesterdayDate())}
                  timeConstraints={{
                    minutes: {
                      step: 15,
                    },
                  }}
                  dateFormat={true}
                  timeFormat={true}
                />
                {errors?.scheduledAt ? (
                  <div className="form-error">{errors.scheduledAt}</div>
                ) : null}
              </FormGroup>

              <div>
                <small
                  className="font-italic d-block mb-2"
                  style={{ color: "#4f4f4f" }}
                >
                  *Please give an estimated end time so that we can schedule our
                  resources accordingly.
                </small>
                {userData?.user?.multiTierSubscription ? (
                  <small className="font-italic" style={{ color: "#4f4f4f" }}>
                    *Basic price should be greater than Plus and Premium price.
                    Plus price should be greater than Premium price.
                  </small>
                ) : null}
              </div>
              <div className="d-flex justify-content-center mb-3">
                <Button
                  type="button"
                  className="cancelBtn mx-2"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
                <Button className="themeBtn saveBtn mx-2" type="button" onClick={(e) => _submitLiveEventCreationForm(e)}>
                  {eventDetails ? "Update" : "Create"}
                </Button>
              </div>
            {/* </Form> */}
          </Col>
        </Row>
      </Container>

      <NewStoryModal
        isOpen={postAsStoryConfirmationModal.isOpen}
        data={postAsStoryConfirmationModal.data}
        toggle={() => _togglePostAsStoryConfirmationModal()}
        category="live event"
        onSuccess={() => history.push("/live-events")}
        onDismiss={() => history.push("/live-events")}
      />

      <MediaLibraryModal
        isOpen={mediaLibraryModalCoverImage?.isOpen}
        dropFileData={mediaLibraryModalCoverImage.data}
        toggle={() => _toggleCoverImageModal()}
        uploadContents={(contents) =>
          _updateCoverImageAndPreview("coverImage", contents)
        }
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isMultipleUpload={false}
        isImageUpload={true}
        isUploadLimit={true}
        imageLimit={1}
        uploadedFiles={[formFields.coverImage]}
        isWelcomeMessage={true}
        shouldUploadInModal={false}
      />

      <MediaLibraryModal
        isOpen={mediaLibraryModalPreviewVideo.isOpen}
        dropFileData={mediaLibraryModalPreviewVideo.data}
        toggle={() => _togglePreviewVideoModal()}
        uploadContents={(contents) =>
          _updateCoverImageAndPreview("previewVideo", contents)
        }
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isMultipleUpload={false}
        isVideoUpload={true}
        isUploadLimit={true}
        videoLimit={1}
        uploadedFiles={[formFields.ppvPreviewVideo]}
        isWelcomeMessage={true}
        shouldUploadInModal={false}
      />
    </div>
  );
};

export default CreateLiveEvent;
