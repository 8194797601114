import React, { useRef, useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { b64toBlob } from "../../helper-methods";

function CameraModal({ onSave }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  const [modal, setModal] = useState(false);
  const [stream, setStream] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [mediaType, setMediaType] = useState(null); // "image" or "video"
  const [recording, setRecording] = useState(false);
  const [progress, setProgress] = useState(0);
  const [facingMode, setFacingMode] = useState("user"); // "user" for front camera, "environment" for rear camera

  const progressIntervalRef = useRef(null); // New ref to track progress timer

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: facingMode }, // Use the current facingMode
        audio: true,
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const flipCamera = () => {
    // Toggle between front and rear cameras
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const photoUrl = canvas.toDataURL("image/png");

    setBlobUrl(photoUrl);
    setMediaType("image"); // Set media type as image
  };

 

const startRecording = () => {
    // Check for supported MIME types
    const supportedMimeTypes = [
        // "video/webm",
        "video/mp4",
        // "video/ogg",
        // "video/x-matroska",
    ].filter((mimeType) => MediaRecorder.isTypeSupported(mimeType));

    if (supportedMimeTypes.length === 0) {
        console.error("No supported MIME types found for recording.");
        return;
    }

    const mimeType = supportedMimeTypes[0];

    const mediaRecorder = new MediaRecorder(stream, { mimeType });
    mediaRecorderRef.current = mediaRecorder;
    const chunks = [];

    mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
            chunks.push(e.data);
        }
    };

    mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: mimeType });
        const videoUrl = URL.createObjectURL(blob);
        setBlobUrl(videoUrl);
        setMediaType("video");
        setProgress(0); // Reset progress when video is done
    };

    mediaRecorder.start();
    setRecording(true);

    let elapsed = 0;

    // Clear existing interval (in case it's leftover from a previous recording)
    if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
    }

    progressIntervalRef.current = setInterval(() => {
        elapsed += 1;
        const newProgress = (elapsed / 16) * 100;
        setProgress(newProgress);

        if (elapsed >= 16) {
            clearInterval(progressIntervalRef.current); // Stop progress updates
        }
    }, 1000);

    recordingTimeoutRef.current = setTimeout(() => {
        mediaRecorder.stop();
        stopProgressTimer();
        setRecording(false);
    }, 16000); // Stop after 16 seconds
};

const stopProgressTimer = () => {
    if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
        progressIntervalRef.current = null;
    }
};

const stopRecording = () => {
    if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
    }
    if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current); // Clear auto-stop timeout
    }
    stopProgressTimer(); // ✅ Stop progress timer immediately
    setRecording(false);
    setProgress(0); // ✅ Reset progress
};

  

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const handleSave = async () => {
    if (blobUrl && mediaType) {
      let finalBlobUrl = blobUrl;
      let blob = null;

      if (mediaType === "image") {
        // Handle image
        const base64Data = blobUrl.split(",")[1];
        if (!base64Data) {
          console.error("Invalid base64 image data");
          return;
        }

        // Convert base64 image data to Blob
        blob = b64toBlob(base64Data, "image/png");
        console.log("blob", blob);
        finalBlobUrl = URL.createObjectURL(blob);
        console.log("finalBlobUrl", finalBlobUrl);
      } else if (mediaType === "video") {
        // Handle video
        try {
          // Fetch the Blob object from the Blob URL
          const response = await fetch(blobUrl);
          blob = await response.blob(); // Get the Blob object
          console.log("video blob", blob);

          // Create a Blob URL for the video (optional, if needed)
          finalBlobUrl = URL.createObjectURL(blob);
          console.log("finalBlobUrl", finalBlobUrl);
        } catch (error) {
          console.error("Error fetching video Blob:", error);
          return;
        }
      }

      // Pass the final Blob URL, Blob object, and mediaType to the parent component
      onSave(finalBlobUrl, blob, mediaType);
    }

    stopCamera();
    setModal(false); // Close the modal after saving
  };

  const toggleModal = () => {
    if (!modal) {
      // Reset states when opening the modal
      setBlobUrl(null);
      setMediaType(null);
      setProgress(0);
    }
    setModal(!modal);
  };

  useEffect(() => {
    if (modal) {
      startCamera();
    } else {
      stopCamera();
    }
    return () => stopCamera();
  }, [modal]);

  useEffect(() => {
    if (modal) {
      stopCamera();
      startCamera();
    }
  }, [facingMode]); // Restart camera when facingMode changes
  console.log("videoRef", videoRef);
  return (
    <>
      <div className="openCameraWrap" onClick={toggleModal}>
        <img
          src="/assets/img/camera.png"
          alt="Camera"
          className="uploadImg"
          loading="lazy"
        />
        Open Camera
      </div>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleModal}>Capture Photo/Video</ModalHeader>
        <ModalBody className="p-0 position-relative">
          <div className="cameraView">
            {blobUrl && mediaType === "image" ? (
              <img src={blobUrl} alt="Captured" />
            ) : blobUrl && mediaType === "video" ? (
              <video
                src={blobUrl}
                controls
                autoPlay
                onLoadedMetadata={(e) => {
                  // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
                  if (e.target.duration === Infinity) {
                    e.target.currentTime = Number.MAX_SAFE_INTEGER;
                    e.target.ontimeupdate = () => {
                      e.target.ontimeupdate = null;
                      e.target.currentTime = 0;
                    };
                  }
                }}
              />
            ) : (
              <>
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  muted
                  className="camera-video"
                ></video>
              </>
            )}
            <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
          </div>

          <div className="cameraControls">
            <Button
              onClick={() => {
                setBlobUrl(null); // Clear the captured photo/video
                setMediaType(null); // Reset media type
                setProgress(0); // Reset progress for recording
                startCamera();
              }}
            >
              <i className="fa fa-undo" />
            </Button>

            <Button onClick={capturePhoto} disabled={blobUrl}>
              <i class="fa fa-camera" />
            </Button>
            <Button
              onClick={recording ? stopRecording : startRecording}
              disabled={blobUrl}
            >
              <i class="fa fa-video-camera" />
            </Button>
            <Button onClick={flipCamera}>
              <i className="fa fa-refresh" />
            </Button>
            <svg
              width="38"
              height="38"
              viewBox="0 0 50 50"
              className={`progressCircleStyles progress-icon ${
                recording ? "recording" : ""
              }`}
            >
              <defs>
                <linearGradient
                  id="gradient1"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop
                    offset="0%"
                    style={{ stopColor: "#b300ff", stopOpacity: 1 }}
                  />
                  <stop
                    offset="100%"
                    style={{ stopColor: "#5f42aa", stopOpacity: 1 }}
                  />
                </linearGradient>
              </defs>

              <circle
                fill="transparent"
                stroke="url(#gradient1)"
                strokeWidth="4"
                r="22"
                cx="25"
                cy="25"
                strokeDasharray="138"
                strokeDashoffset={(138 * (100 - progress)) / 100}
              />
            </svg>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnCancel" onClick={toggleModal}>
            Cancel
          </Button>
          <Button className="modalBtnSave" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CameraModal;
