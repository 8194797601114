import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../config";
import { errorHandler, showToast, uploadFileOnServer } from "../helper-methods";
import { updateVaultFolder } from "../http-calls";
import CustomVideoPlayer from "./custom/CustomVideoPlayer";
import ErrorBoundary from "./ErrorBoundary";
import ImageCropUploaderModal from "./modals/ImageCropUploaderModal";
import MediaLibraryModal from "./modals/MediaLibraryModal";

const EditVaultCover = ({ vaultData, setVaultData, canEdit }) => {
  const [preview, setPreview] = useState({
    coverImage: {
      uploadData: null,
      previewBlob: null,
      type: "image",
      uploadUrl: null,
    },
    previewVideo: {
      uploadData: null,
      previewBlob: null,
      type: "video",
      uploadUrl: null,
      hlsLink: {},
    },
  });

  const [loading, setLoading] = useState(false);
  const [isCropDialogVisible, setIsCropDialogVisible] = useState(false);

  const [contentLoading, setContentLoading] = useState({});
  const [mediaLibraryModalCoverImage, setMediaLibraryModalCoverImage] =
    useState({
      isOpen: false,
      data: null,
    });
  const [mediaLibraryModalPreviewVideo, setMediaLibraryModalPreviewVideo] =
    useState({
      isOpen: false,
      data: null,
    });

  const _toggleModalForPreviewVideo = (isOpen = false, data = null) => {
    setMediaLibraryModalPreviewVideo({ isOpen, data });
  };
  const _toggleModalForCoverImage = (isOpen = false, data = null) => {
    setMediaLibraryModalCoverImage({ isOpen, data });
  };

  const _toggleContentLoading = (key, value) => {
    const newContentLoading = { ...contentLoading };
    newContentLoading[key] = value;
    setContentLoading(newContentLoading);
  };

  const _onChangeFile = (key, file) => {
    let fileType = file?.[0]?.contentType || file?.[0]?.type;

    if (key === "coverImage" && fileType !== "image") {
      showToast("Only image file is allowed", "error");
      _toggleModalForCoverImage();
      return;
    } else if (key === "previewVideo" && fileType !== "video") {
      showToast("Only video file is allowed", "error");
      _toggleModalForPreviewVideo();
      return;
    }

    _toggleContentLoading(key, true);

    const newPreview = { ...preview };

    if (key) {
      if (file?.[0]?.url) {
        newPreview[key]["previewBlob"] = "";
        newPreview[key]["uploadData"] = null;
        newPreview[key]["type"] = fileType;
        newPreview[key]["uploadUrl"] = file?.[0]?.url;

        if (key === "previewVideo" && Object.keys(file?.[0]?.hlsLink)?.length) {
          newPreview[key]["hlsLink"] = file?.[0]?.hlsLink;
        } else {
          delete newPreview[key]["hlsLink"];
        }
      } else {
        newPreview[key] = file?.[0];
      }
    }

    setPreview(newPreview);

    if (key === "coverImage") {
      _toggleModalForCoverImage();
    } else {
      _toggleModalForPreviewVideo();
    }

    _toggleContentLoading(key, false);
  };

  const _onSaveVault = async () => {
    try {
      setLoading(true);

      const payload = {};

      const uploadMedia = [];

      if (preview.coverImage?.uploadData) {
        preview.coverImage["forKeyName"] = "preview";
        uploadMedia.push({ ...preview.coverImage });
      }

      if (preview.previewVideo?.uploadData) {
        preview.previewVideo["forKeyName"] = "previewVideoUrl";
        uploadMedia.push({ ...preview.previewVideo });
      }

      if (uploadMedia.length) {
        const uploadMediaRes = await uploadFileOnServer(uploadMedia);
        uploadMediaRes.forEach((res) => {
          payload[res.forKeyName] = res.url;
        });
      } else {
        if (preview.coverImage.uploadUrl) {
          payload["preview"] = preview.coverImage.uploadUrl;
        }

        if (preview.previewVideo.uploadUrl) {
          payload["previewVideoUrl"] = preview.previewVideo.uploadUrl;
        }
      }

      if (Object.keys(payload)?.length) {
        const res = await updateVaultFolder(payload, vaultData?._id);

        setVaultData(res.folder);

        showToast("Details has been updated", "success");
      }

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _saveCroppedPreviewPhoto = (previewBlob, uploadData) => {
    const newPreview = { ...preview };

    newPreview.coverImage = {
      uploadData,
      previewBlob,
      type: "image",
      uploadUrl: null,
    };

    setPreview(newPreview);
    setIsCropDialogVisible(false);
  };

  const _resetPhoto = () => {
    const newPreview = { ...preview };

    newPreview.coverImage = {
      uploadData: null,
      previewBlob: null,
      type: "image",
      uploadUrl: vaultData?.preview,
    };

    setPreview(newPreview);
    setIsCropDialogVisible(false);
  };

  const _onDropFileCoverImage = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _toggleModalForCoverImage(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  const _onDropFilePreviewVideo = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _toggleModalForPreviewVideo(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  const _setFormFields = (data = null) => {
    setPreview({
      coverImage: {
        uploadData: null,
        previewBlob: null,
        type: "image",
        uploadUrl: data?.preview,
      },
      previewVideo: {
        uploadData: null,
        previewBlob: null,
        type: "video",
        uploadUrl: data?.previewVideoUrl,
        duration: data?.previewVideoUrlDuration,
        hlsLink: data?.previewHls,
      },
    });
    setLoading(false);
    setIsCropDialogVisible(false);
  };

  useEffect(() => {
    if (vaultData) {
      _setFormFields(vaultData);
    }
  }, [vaultData]);

  return (
    <>
      <div className="vaultFolderDetails">
        <FormGroup
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={(e) => (canEdit ? _onDropFileCoverImage(e) : {})}
        >
          <Label>Album Cover Image</Label>

          {contentLoading?.coverImage ? (
            <div className="coverImgWrap">
              <div className="processingContent">
                <p>We are processing your content</p>
                <Button
                  onClick={() => _toggleContentLoading("coverImage", false)}
                >
                  Reload
                </Button>
              </div>
            </div>
          ) : (
            <Label className="coverImgWrap">
              {canEdit ? (
                <>
                  {/* <Input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    value=""
                    name="coverImage"
                    onChange={(event) => _onChangeFile(event, "coverImage")}
                    disabled={loading}
                  /> */}

                  <Button
                    onClick={() => _toggleModalForCoverImage(true)}
                    style={{ display: "none" }}
                    disabled={loading}
                  />
                </>
              ) : null}

              {preview?.coverImage?.previewBlob ||
              preview?.coverImage?.uploadUrl ? (
                <>
                  <img
                    src={
                      preview?.coverImage?.previewBlob ||
                      preview?.coverImage?.uploadUrl ||
                      DEFAULT_COVER_PICTURE
                    }
                    onError={(e) => _toggleContentLoading("coverImage", true)}
                    alt="Cover Img"
                    loading="lazy"
                  />

                  {canEdit ? (
                    <Label className="changeImg">
                      <div
                        onClick={() => _toggleModalForCoverImage(true)}
                        disabled={loading}
                      >
                        <i className="fa fa-pencil" />
                      </div>
                    </Label>
                  ) : null}
                </>
              ) : (
                <div className="text-center">
                  <img
                    src="/assets/img/upload.png"
                    alt="Upload"
                    className="uploadImg"
                    loading="lazy"
                  />
                  <p className="mb-0">
                    {canEdit ? "Upload Image" : "Cover image is not uploaded"}
                  </p>
                </div>
              )}
            </Label>
          )}
        </FormGroup>

        <FormGroup
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={(e) => (canEdit ? _onDropFilePreviewVideo(e) : {})}
        >
          <Label>Album Preview Video</Label>

          {contentLoading?.previewVideo ? (
            <div className="coverImgWrap">
              <div className="processingContent">
                <p>We are processing your content</p>
                <Button
                  onClick={() => _toggleContentLoading("previewVideo", false)}
                >
                  Reload
                </Button>
              </div>
            </div>
          ) : (
            <>
              {preview?.previewVideo?.previewBlob ||
              preview?.previewVideo?.uploadUrl ? (
                <div className="coverImgWrap">
                  <ErrorBoundary>
                    {/* custom video player support hls */}
                    <CustomVideoPlayer
                      // className={`${getAspectRatioClass(
                      //   vaultData?.previewVideoUrlAspectRatio
                      // )}`}
                      key={preview?.previewVideo}
                      src={
                        preview?.previewVideo?.uploadUrl ||
                        preview?.previewVideo?.previewBlob
                      }
                      hlsLink={preview?.previewVideo?.hlsLink || {}}
                      duration={preview?.previewVideo?.duration}
                      thumbnail={vaultData?.previewVideoUrlThumbnail}
                      controls
                      inlineStyle={{ display: "block" }}
                    />
                  </ErrorBoundary>

                  {canEdit ? (
                    <Label className="changeImg">
                      {/* <Input
                        type="file"
                        style={{ display: "none" }}
                        accept="video/*"
                        value=""
                        name="previewVideoUrl"
                        onChange={(event) =>
                          _onChangeFile(event, "previewVideo")
                        }
                        disabled={loading}
                      /> */}
                      <div
                        onClick={() => _toggleModalForPreviewVideo(true)}
                        disabled={loading}
                      >
                        <i className="fa fa-pencil" />
                      </div>
                    </Label>
                  ) : null}
                </div>
              ) : (
                <Label className="coverImgWrap">
                  {canEdit ? (
                    <>
                      {/* <Input
                        type="file"
                        style={{ display: "none" }}
                        accept="video/*"
                        value=""
                        name="previewVideoUrl"
                        onChange={(event) =>
                          _onChangeFile(event, "previewVideo")
                        }
                        disabled={loading}
                      /> */}
                      <Button
                        style={{ display: "none" }}
                        onClick={() => _toggleModalForPreviewVideo(true)}
                        disabled={loading}
                      />
                    </>
                  ) : null}

                  <div className="text-center">
                    <img
                      src="/assets/img/upload.png"
                      alt="Upload"
                      className="uploadImg"
                      loading="lazy"
                    />
                    <p className="mb-0">
                      {canEdit
                        ? "Upload Video"
                        : "Preview video is not uploaded"}
                    </p>
                  </div>
                </Label>
              )}
            </>
          )}
        </FormGroup>

        {canEdit ? (
          <div className="d-flex justify-content-center">
            <Button
              className="cancelBtn m-2"
              onClick={() => _setFormFields(vaultData)}
              disabled={loading}
            >
              Reset
            </Button>

            <Button
              className="themeBtn saveBtn m-2"
              onClick={() => _onSaveVault()}
              disabled={loading}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Save
            </Button>
          </div>
        ) : null}
      </div>

      <ImageCropUploaderModal
        isOpen={isCropDialogVisible}
        resetPhoto={() => _resetPhoto()}
        selectedPhoto={preview.coverImage?.uploadData}
        onSuccess={_saveCroppedPreviewPhoto}
        onDismiss={() => setIsCropDialogVisible(false)}
      />

      <MediaLibraryModal
        isOpen={mediaLibraryModalCoverImage?.isOpen}
        dropFileData={mediaLibraryModalCoverImage.data}
        toggle={() => _toggleModalForCoverImage()}
        uploadContents={(contents) => _onChangeFile("coverImage", contents)}
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isMultipleUpload={false}
        isAudioUpload={false}
        isImageUpload={true}
        isUploadLimit={true}
        imageLimit={1}
        // videoLimit={}
        // audioLimit={}
        uploadedFiles={[]}
        shouldUploadInModal={false}
      />

      <MediaLibraryModal
        isOpen={mediaLibraryModalPreviewVideo.isOpen}
        dropFileData={mediaLibraryModalPreviewVideo.data}
        toggle={() => _toggleModalForPreviewVideo()}
        uploadContents={(contents) => _onChangeFile("previewVideo", contents)}
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isMultipleUpload={false}
        isAudioUpload={false}
        isUploadLimit={true}
        isVideoUpload={true}
        // imageLimit={}
        videoLimit={1}
        // audioLimit={}
        uploadedFiles={[]}
        shouldUploadInModal={false}
      />
    </>
  );
};

export default EditVaultCover;
