import React, { useMemo, useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  CustomInput,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAndModifiedUserDetails } from "../../redux/actions/userData";
import {
  errorHandler,
  isPermissionToAccess,
  logout,
  showToast,
  splitPhoneNumber,
  getDialCode,
  uploadFileOnServer,
  getDialCodeFromCountryCode,
  getLowResolutionLink,
  handleBrokenImageURL,
  getWindowDimensions,
  birthYearWithMinimumAge,
  generateDates,
} from "../../helper-methods";
import { RegexConfig } from "../../config/RegexConfig";
import { countryCodes } from "../../config/country-codes";
import {
  DEFAULT_PROFILE_PICTURE,
  DEFAULT_COVER_PICTURE,
  APP_NAME,
  BIO_MAX_LIMIT,
} from "../../config/index";
import {
  checkAvailability,
  accountDelete,
  updateInfluencerDetails,
} from "../../http-calls";
import ProfileCarousel from "../ProfileCarousel";
import CustomPrompt from "../modals/CustomPrompt";
import ImageCropUploaderModal from "../modals/ImageCropUploaderModal";
import {
  deleteAccountWarning,
  MIN_SIGNUP_AGE,
} from "../../config/helper-config";
import CustomTooltip from "../custom/CustomTooltip";
import ErrorBoundary from "../ErrorBoundary";
import { useHistory } from "react-router-dom";
import CoverImageModal from "../modals/CoverImageModal";
import { MonthsInEngConfig } from "../../config/months-config";

const SettingsProfileSection = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const influencer = useSelector((state) => state?.userData?.user || {});

  const _handleBrokenImageURL = handleBrokenImageURL();

  const [formFields, setFormFields] = useState({
    coverPicture: "",
    profilePicture: {
      previewBlob: "",
      uploadData: null,
      uploadUrl: "",
    },
    username: "",
    introduction: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneCountry: "",
    phone: "",
    website: "",
    gender: "",
    birthDate: "",
    birthMonth: "",
    birthYear: "",
  });

  const [coverImageModal, setCoverImageModal] = useState({
    isOpen: false,
    data: null,
  });
  const [screenWidth, setScreenWidth] = useState(0);

  const _toggleCoverImageModal = (isOpen = false, data = null) => {
    setCoverImageModal({ isOpen, data });
  };

  const _setFormData = () => {
    const newFormFields = { ...formFields };

    newFormFields["coverPicture"] =
      influencer?.coverPicture || DEFAULT_COVER_PICTURE;
    newFormFields["profilePicture"] = {
      previewBlob: "",
      uploadData: null,
      uploadUrl: influencer?.profilePicture?.length
        ? influencer?.profilePicture
        : "",
    };
    newFormFields["username"] = influencer?.username || "";
    newFormFields["introduction"] = influencer?.introduction?.length
      ? influencer?.introduction
      : `Welcome to my ${APP_NAME} page! Subscribe to unlock all of my exclusive content!`;
    newFormFields["firstName"] = influencer?.name?.first || "";
    newFormFields["lastName"] = influencer?.name?.last || "";
    newFormFields["email"] = influencer?.email || "";
    newFormFields["phone"] = influencer?.phone
      ? splitPhoneNumber(influencer.phone).phoneNumber
      : "";
    newFormFields["phoneCountry"] = influencer?.phoneCountry || "US";
    newFormFields["website"] = influencer?.website?.length
      ? influencer?.website
      : "";

    newFormFields["gender"] = influencer?.gender || "";

    // add birthday
    if (influencer?.dob?.day) {
      newFormFields["birthDate"] = influencer?.dob?.day || "";
      newFormFields["birthMonth"] = influencer?.dob?.month || "";
      newFormFields["birthYear"] = influencer?.dob?.year || "";
    }

    setFormFields(newFormFields);
  };

  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({
    profilePic: false,
    deleteAccount: false,
    uplaodCover: false,
    deleteCover: false,
    makeCover: false,
    formSubmit: false,
  });
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [cropModalContent, setCropModalContent] = useState(false);
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [markFocused, setMarkFocused] = useState({});
  const [dublicateLoading, setDublicateLoading] = useState({});
  const [isValid, setIsValid] = useState({});

  const canUpdateProfileSettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateProfileSettings",
        true
      ),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _udpateInfluencerDetails = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateInfluencerDetails(payload);
        dispatch(fetchAndModifiedUserDetails());
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  // toggle custom prompt modal
  const _togglePromptModal = (value) => {
    setIsPromptModalOpen(value);
  };

  // toggle image crop modal
  const _toggleCropModal = (value) => {
    setIsCropModalOpen(value);
  };

  // reset previewBlob and use url if available
  const _resetPhoto = () => {
    const newFormFields = { ...formFields };

    newFormFields["profilePicture"] = {
      uploadData: null,
      previewBlob: null,
      uploadUrl: influencer?.profilePicture?.length
        ? influencer?.profilePicture
        : "",
    };

    setFormFields(newFormFields);
  };

  // recieve cropped photo and make upload file api call
  const _saveCroppedPhoto = async (croppedImageUrl, croppedImageBlob) => {
    const payload = [
      {
        previewBlob: croppedImageUrl,
        uploadData: croppedImageBlob,
      },
    ];

    try {
      const newFormFields = { ...formFields };
      newFormFields.profilePicture.previewBlob = croppedImageUrl;
      setFormFields(newFormFields);
    } catch (error) {
      console.log({ error });
    }

    setIsCropModalOpen(false);
    _uploadProfilePicture(payload);
  };

  // function to handle change in file input
  const _fileChangeHandler = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;
      const fileType = file.type.split("/")?.[0];

      if (fileType !== "image") {
        return errorHandler({ reason: "Please provide a valid image file." });
      }

      const newFormFields = { ...formFields };

      newFormFields["profilePicture"] = {
        previewBlob: URL.createObjectURL(e.target.files[0]),
        uploadData: e.target.files[0],
        uploadUrl: "",
      };

      setFormFields(newFormFields);
      setCropModalContent(file);
      setIsCropModalOpen(true);
    } catch (error) {
      errorHandler(error);
    }
  };

  // upload picture api call
  const _uploadProfilePicture = async (pictureData) => {
    try {
      _manageLoadingState("profilePic", true);

      const serverResponse = await uploadFileOnServer(pictureData);

      const payload = {
        profilePicture: serverResponse?.[0]?.url || "",
      };

      await _udpateInfluencerDetails(payload);

      showToast("Profile Picture Updated", "success");
      _manageLoadingState("profilePic", false);
    } catch (error) {
      _manageLoadingState("profilePic", false);
      errorHandler(error);
    }
  };

  // delete user account
  const _deleteAccount = async () => {
    try {
      _togglePromptModal(false);
      _manageLoadingState("deleteCover", false);

      await accountDelete();
      _manageLoadingState("deleteAccont", false);

      logout(history, true);
      showToast("Your Account has been Deleted", "success");
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("deleteAccont", false);
    }
  };

  const _markAsFocused = (key) => {
    const newMarkFocused = { ...markFocused };
    newMarkFocused[key] = true;
    setMarkFocused(newMarkFocused);
  };

  /**
   * Check for duplicate username, email, phone
   */
  const _checkDuplicateFields = (key, value) => {
    return new Promise(async (resolve) => {
      setDublicateLoading((prev) => ({
        ...prev,
        [key]: true,
      }));

      const payload = {
        [key]: value,
      };

      checkAvailability(payload)
        .then((res) => {
          resolve([res, null]);
        })
        .catch((error) => {
          resolve([null, error]);
        });
    });
  };

  /**
   * validation or Check for duplicate username, email, phone , validate one at a time
   */
  const _formFieldsApiValidation = ({ newFormFields, newIsDirty, key }) => {
    return new Promise(async (resolve) => {
      const newErrors = {};
      let isFieldValid = true;

      if (newIsDirty[key]) {
        if (newFormFields[key]?.trim().length) {
          if (RegexConfig[key].test(String(newFormFields[key]).toLowerCase())) {
            const phoneCountry = getDialCodeFromCountryCode(
              newFormFields?.phoneCountry
            );

            const checkingValue =
              key !== "phone"
                ? newFormFields[key].trim()
                : `(${phoneCountry})${newFormFields[key].trim()}`;

            if (influencer?.[key] === checkingValue) {
              newErrors[key] = null;
              newIsDirty[key] = false;
              setIsValid((prev) => ({
                ...prev,
                [key]: true,
              }));
            } else {
              // always resolve
              const [res, resError] = await _checkDuplicateFields(
                key,
                checkingValue
              );

              setDublicateLoading((prev) => ({
                ...prev,
                [key]: false,
              }));

              if (res?.isDuplicateFound || res?.error || resError?.error) {
                newErrors[key] = `${newFormFields[key].trim()} already in use`;
                isFieldValid = false;
              } else {
                newErrors[key] = null;
                newIsDirty[key] = false;
                setIsValid((prev) => ({
                  ...prev,
                  [key]: true,
                }));
              }
            }
          } else {
            newErrors[key] = `*Invalid ${key}`;
            isFieldValid = false;
          }
        } else {
          newErrors[key] = "*Required";
          isFieldValid = false;
        }
      }

      setErrors((prev) => ({
        ...prev,
        [key]: newErrors[key],
      }));

      setIsDirty((prev) => ({
        ...prev,
        [key]: newIsDirty[key],
      }));

      resolve(isFieldValid);
    });
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    const newErrors = {};

    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (!newIsDirty[key]) return;

        switch (key) {
          case "firstName":
          case "lastName":
            if (newFormFields?.[key]?.trim()?.length) {
              if (
                RegexConfig?.name?.test(
                  String(newFormFields[key]).toLowerCase()
                ) &&
                newFormFields[key].length >= 2 &&
                newFormFields[key].length <= 25
              ) {
                newIsDirty[key] = false;
                newErrors[key] = null;
              } else {
                newErrors[key] =
                  "*Minimum 2 characters & Maximum 25 characters, no digits/special characters are allowed.";
                isFormValid = false;
              }
            } else {
              newErrors[key] = "*Required";
              isFormValid = false;
            }
            break;

          case "website": {
            if (newFormFields?.[key]?.length) {
              if (
                RegexConfig?.url?.test(String(formFields.website).toLowerCase())
              ) {
                newIsDirty[key] = false;
                newErrors[key] = null;
              } else {
                newErrors[key] = "Invalid URL";
                isFormValid = false;
              }
            }
            break;
          }

          case "birthDate": {
            if (newFormFields[key]) {
              if (newIsDirty["birthMonth"] && !newFormFields?.birthMonth) {
                newErrors["birthMonth"] = "*Required";
                isFormValid = false;
              } else {
                newErrors["birthMonth"] = null;
              }

              if (newIsDirty["birthYear"] && !newFormFields?.birthYear) {
                newErrors["birthYear"] = "*Required";
                isFormValid = false;
              } else {
                newErrors["birthYear"] = null;
              }
            } else {
              if (newFormFields?.birthMonth || newFormFields?.birthYear) {
                newErrors["birthDate"] = "*Required";
                isFormValid = false;
              } else {
                newIsDirty["birthDate"] = false;
                newErrors["birthDate"] = null;
              }
            }
            break;
          }

          case "birthMonth": {
            if (newFormFields[key]) {
              if (newIsDirty["birthDate"] && !newFormFields?.birthDate) {
                newErrors["birthDate"] = "*Required";
                isFormValid = false;
              } else {
                newErrors["birthDate"] = null;
              }

              if (newIsDirty["birthYear"] && !newFormFields?.birthYear) {
                newErrors["birthYear"] = "*Required";
                isFormValid = false;
              } else {
                newErrors["birthYear"] = null;
              }
            } else {
              if (newFormFields?.birthDate || newFormFields?.birthYear) {
                newErrors["birthMonth"] = "*Required";
                isFormValid = false;
              } else {
                newIsDirty["birthMonth"] = false;
                newErrors["birthMonth"] = null;
              }
            }
            break;
          }

          case "birthYear": {
            if (newFormFields[key]) {
              if (newIsDirty["birthDate"] && !newFormFields?.birthDate) {
                newErrors["birthDate"] = "*Required";
                isFormValid = false;
              } else {
                newErrors["birthDate"] = null;
              }

              if (newIsDirty["birthMonth"] && !newFormFields?.birthMonth) {
                newErrors["birthMonth"] = "*Required";
                isFormValid = false;
              } else {
                newErrors["birthMonth"] = null;
              }
            } else {
              if (newFormFields?.birthDate || newFormFields?.birthMonth) {
                newErrors["birthYear"] = "*Required";
                isFormValid = false;
              } else {
                newIsDirty["birthYear"] = false;
                newErrors["birthYear"] = null;
              }
            }
            break;
          }

          default:
        }
      });

      setIsDirty(newIsDirty);
      setErrors(newErrors);

      resolve(isFormValid);
    });
  };

  // on fields blur, update their dirty true then validate
  const _onBlurHandler = (key) => {
    let newFormFields = { ...formFields };
    let newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    if (key === "introduction") {
      setMarkFocused((prev) => ({ ...prev, introduction: false }));
    }

    if (key === "phone" || key === "email" || key === "username") {
      setIsValid((prev) => ({
        ...prev,
        [key]: false,
      }));
      _formFieldsApiValidation({ newFormFields, newIsDirty, key });
    } else {
      _validateFormFields({ newFormFields, newIsDirty });
    }
  };

  // update field value onChange
  const _updateFieldValue = (key, value) => {
    if (
      key === "phone" &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    ) {
      return;
    }

    let newFormFields = { ...formFields };
    if (key === "day" || key === "month" || key === "year") {
      newFormFields.dob[key] = value;
    } else {
      newFormFields[key] = value;
    }
    setFormFields(newFormFields);
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };

      Object.keys(newFormFields).forEach((key) => {
        if (key === "username" || key === "email" || key === "phone") {
          newIsDirty[key] = isDirty[key] || false;
        } else {
          newIsDirty[key] = true;
        }
      });

      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _createPayload = (formFields) => {
    return new Promise(async (resolve) => {
      const payload = {};

      payload["email"] = formFields?.email?.trim() || "";
      if (formFields?.phone?.trim().length) {
        payload["phone"] = formFields?.phone?.length
          ? `(${getDialCode(formFields?.phoneCountry).dial_code})` +
            formFields?.phone
          : "";
      }
      payload["phoneCountry"] = formFields?.phoneCountry || "US";

      payload["website"] = formFields?.website || "";

      payload["name"] = {
        first: formFields?.firstName?.trim() || "",
        last: formFields?.lastName?.trim() || "",
      };
      payload["username"] = formFields.username;
      payload["introduction"] = formFields.introduction.trim().length
        ? formFields.introduction.trim()
        : "";

      if (
        formFields?.birthDate &&
        formFields?.birthMonth &&
        formFields?.birthYear
      ) {
        payload["dob"] = {
          day: formFields?.birthDate,
          month: formFields?.birthMonth,
          year: formFields?.birthYear,
        };
      }

      if (formFields?.gender) {
        payload["gender"] = formFields?.gender;
      }

      resolve(payload);
    });
  };

  const _handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      _manageLoadingState("formSubmit", true);
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();

      const [
        isFormValid,
        isEmailFieldValid,
        isPhoneFieldValid,
        isUsernameValid,
      ] = await Promise.all([
        _validateFormFields({ newFormFields, newIsDirty }),
        _formFieldsApiValidation({
          newFormFields,
          newIsDirty: { email: newIsDirty.email },
          key: "email",
        }),
        _formFieldsApiValidation({
          newFormFields,
          newIsDirty: { phone: newIsDirty.phone },
          key: "phone",
        }),
        _formFieldsApiValidation({
          newFormFields,
          newIsDirty: { username: newIsDirty.user },
          key: "username",
        }),
      ]);

      if (
        !isFormValid ||
        !isEmailFieldValid ||
        !isPhoneFieldValid ||
        !isUsernameValid
      ) {
        _manageLoadingState("formSubmit", false);
        return;
      }

      const payload = await _createPayload(newFormFields);
      await _udpateInfluencerDetails(payload);

      _manageLoadingState("formSubmit", false);
      showToast("Profile Updated", "success");
    } catch (error) {
      _manageLoadingState("formSubmit", false);
      errorHandler(error);
    }
  };

  const _onChangeActiveIndex = (index = 0) => {
    setActiveCarouselIndex(index);
  };

  useEffect(() => {
    _setFormData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencer]);

  const _changeWidth = (event) => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  useEffect(() => {
    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
  }, []);

  const profileCarouselData = useMemo(() => {
    if (!influencer?.carousel?.length) return [];

    const carouselCrop = influencer?.carouselCrop?.length
      ? influencer?.carouselCrop
      : [];

    const newCarousel = [];

    influencer?.carousel?.forEach((each, index) => {
      const carouselObj = {
        ...each,
        id: index,
      };

      if (each.contentType === "image") {
        const findCropObj = carouselCrop?.find(
          (each) => each.carouselIndex === index
        );

        if (findCropObj) {
          carouselObj.crop = findCropObj;
        }
      }

      newCarousel.push(carouselObj);
    });

    return newCarousel;
  }, [influencer?.carousel, influencer?.carouselCrop]);

  return (
    <>
      <Card className="profileCard mb-2 profileCardSettings">
        <CardHeader>
          {/* Profile Carousel */}
          <ErrorBoundary>
            <ProfileCarousel
              items={profileCarouselData}
              activeIndex={activeCarouselIndex}
              onChangeActiveIndex={(newIndex) => _onChangeActiveIndex(newIndex)}
              screenWidth={screenWidth - 20}
            />
          </ErrorBoundary>

          <div className="d-flex justify-content-between align-items-center">
            <div
              className="img100by100Wrap"
              style={{ marginTop: -40, marginLeft: 3 }}
            >
              <img
                src={
                  getLowResolutionLink(formFields?.profilePicture?.uploadUrl) ||
                  formFields?.profilePicture?.previewBlob ||
                  DEFAULT_PROFILE_PICTURE
                }
                onError={(e) =>
                  _handleBrokenImageURL({
                    event: e,
                    url: getLowResolutionLink(influencer?.profilePicture),
                    fallbackImg: DEFAULT_PROFILE_PICTURE,
                  })
                }
                alt="Profile Img"
                className="img100by100"
                loading="lazy"
              />

              {canUpdateProfileSettings ? (
                <Label className="btn editProfileImg" id="editProfile">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => _fileChangeHandler(e, "profilePicture")}
                    style={{ display: "none" }}
                    value=""
                    disabled={loadingState?.profilePic}
                  />
                  {loadingState?.profilePic ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    <i className="fa fa-pencil" />
                  )}
                  <UncontrolledTooltip
                    placement="bottom"
                    target="editProfile"
                    className="createPostTooltip"
                  >
                    Edit Profile Picture
                  </UncontrolledTooltip>
                </Label>
              ) : null}
            </div>

            {canUpdateProfileSettings ? (
              <>
                <div className="editProfileBanner">
                  <Button
                    id="upload_cover_setting"
                    className="editProfileImg"
                    disabled={
                      loadingState?.uplaodCover ||
                      loadingState?.deleteCover ||
                      loadingState?.makeCover
                    }
                    onClick={() => _toggleCoverImageModal(true, influencer)}
                  >
                    <i className="fa fa-pencil" />
                    <CustomTooltip
                      text={`Edit Cover Image or Video`}
                      target={`upload_cover_setting`}
                    />
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </CardHeader>

        <CardBody className="p-0">
          <FormGroup>
            <Label>Username</Label>
            <div className="position-relative">
              <Input
                type="text"
                id="text-input-username"
                value={formFields?.username}
                disabled={!canUpdateProfileSettings}
                onChange={(e) => _updateFieldValue("username", e.target.value)}
                onBlur={() => _onBlurHandler("username")}
                onFocus={() => _markAsFocused("username")}
              />
              {dublicateLoading?.username ? (
                <div className="spinnerLogin">
                  <i className="fa fa-spinner fa-spin mr-1" />
                </div>
              ) : null}
            </div>

            {errors?.username ? (
              <div className="form-error">{errors?.username}</div>
            ) : null}

            {isValid?.username ? (
              <div className="themeColor">
                <i className="fa fa-check" />
                Valid Username
              </div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>About / Bio</Label>
            <Input
              type="textarea"
              name="textarea-input"
              id="textarea-input"
              rows="4"
              disabled={!canUpdateProfileSettings}
              value={formFields?.introduction}
              maxLength={BIO_MAX_LIMIT}
              onChange={(e) =>
                _updateFieldValue("introduction", e.target.value)
              }
              onBlur={() => _onBlurHandler("introduction")}
              onFocus={() => _markAsFocused("introduction")}
            />

            {markFocused?.introduction ? (
              <div className="text-right" style={{ color: "#828282" }}>
                {BIO_MAX_LIMIT - formFields.introduction?.length} left
              </div>
            ) : null}

            {errors?.introduction ? (
              <div className="form-error">{errors?.introduction}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>Website URL</Label>
            <Input
              type="text"
              id="text-input-url"
              value={formFields?.website}
              disabled={!canUpdateProfileSettings}
              onChange={(e) => _updateFieldValue("website", e.target.value)}
              onBlur={() => _onBlurHandler("website")}
            />
            {errors?.website ? (
              <div className="form-error">{errors?.website}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>
              First Name
              <sup id="settingprofile_TooltipFirstName" className="infoIcon">
                <i className="fa fa-info-circle" />
              </sup>
              <CustomTooltip
                text={`Your First-name and Last-Name are not visible to your fanz. This info is used to verify your account to receive and withdraw your earnings.`}
                target={`settingprofile_TooltipFirstName`}
              />
            </Label>
            <Input
              type="text"
              id="text-input-fName"
              disabled={!canUpdateProfileSettings}
              value={formFields.firstName}
              onChange={(e) => _updateFieldValue("firstName", e.target.value)}
              onBlur={() => _onBlurHandler("firstName")}
            />
            {errors?.firstName ? (
              <div className="form-error">{errors?.firstName}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>
              Last Name
              <sup id="settingprofile_TooltipLastName" className="infoIcon">
                <i className="fa fa-info-circle" />
              </sup>
              <CustomTooltip
                text={`Your First-name and Last-Name are not visible to your fanz. This info is used to verify your account to receive and withdraw your earnings.`}
                target={`settingprofile_TooltipLastName`}
              />
            </Label>
            <Input
              type="text"
              id="text-input-lName"
              disabled={!canUpdateProfileSettings}
              value={formFields.lastName}
              onChange={(e) => _updateFieldValue("lastName", e.target.value)}
              onBlur={() => _onBlurHandler("lastName")}
            />
            {errors?.lastName ? (
              <div className="form-error">{errors?.lastName}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>Email</Label>
            <div className="position-relative">
              <Input
                type="email"
                id="text-input-email"
                value={formFields.email}
                disabled={!canUpdateProfileSettings}
                onChange={(e) => _updateFieldValue("email", e.target.value)}
                onBlur={() => _onBlurHandler("email")}
                onFocus={() => _markAsFocused("email")}
              />
              {dublicateLoading?.email ? (
                <div className="spinnerLogin">
                  <i className="fa fa-spinner fa-spin mr-1" />
                </div>
              ) : null}
            </div>

            {errors?.email ? (
              <div className="form-error">{errors?.email}</div>
            ) : null}

            {isValid?.email ? (
              <div className="themeColor">
                <i className="fa fa-check" />
                Valid email
              </div>
            ) : null}
          </FormGroup>

          <div className="d-flex flex-wrap">
            <FormGroup className="mr-lg-4 mr-md-4">
              <Label>Phone</Label>
              <div className="d-flex">
                <Input
                  type="select"
                  name="selectCountryCode"
                  id="selectCountryCode"
                  value={formFields.phoneCountry}
                  disabled={!canUpdateProfileSettings}
                  onChange={(e) =>
                    _updateFieldValue("phoneCountry", e.target.value)
                  }
                  onBlur={() => _onBlurHandler("phoneCountry")}
                  style={{
                    maxWidth: 165,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  {countryCodes.map((countryCode, countryIndex) => (
                    <option key={countryIndex} value={countryCode.code}>
                      {countryCode.name} ({countryCode.dial_code})
                    </option>
                  ))}
                </Input>
                <div className="position-relative phoneSettings">
                  <Input
                    type="text"
                    id="text-input-phone"
                    value={formFields.phone}
                    disabled={!canUpdateProfileSettings}
                    onChange={(e) => _updateFieldValue("phone", e.target.value)}
                    onBlur={() => _onBlurHandler("phone")}
                    onFocus={() => _markAsFocused("phone")}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  />
                  {dublicateLoading?.phone ? (
                    <div className="spinnerLogin">
                      <i className="fa fa-spinner fa-spin mr-1" />
                    </div>
                  ) : null}
                </div>
              </div>

              {errors?.phone ? (
                <div className="form-error">{errors?.phone}</div>
              ) : null}

              {isValid?.phone ? (
                <div className="form-error themeColor">
                  <i className="fa fa-check mr-1" />
                  Valid Phone
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Gender</Label>

              <div className="d-flex mt-1">
                <CustomInput
                  type="radio"
                  id="male_custom_radio_btn_profile_setting"
                  name="customRadio"
                  value="male"
                  checked={formFields.gender === "male"}
                  disabled={!canUpdateProfileSettings}
                  onChange={(e) => _updateFieldValue("gender", "male")}
                  onBlur={() => _onBlurHandler("gender")}
                  className="mr-4"
                  label="Male"
                />

                <CustomInput
                  type="radio"
                  id="female_custom_radio_btn_profile_setting"
                  name="customRadio"
                  value="female"
                  checked={formFields.gender === "female"}
                  disabled={!canUpdateProfileSettings}
                  onChange={(e) => _updateFieldValue("gender", "female")}
                  onBlur={() => _onBlurHandler("gender")}
                  label="Female"
                />
              </div>

              {errors?.gender ? (
                <p className="form-error" style={{ paddingLeft: 8 }}>
                  {errors.gender}
                </p>
              ) : null}
            </FormGroup>
          </div>

          <Label>Birthday</Label>
          <FormGroup className="dobWrap">
            <div>
              <Label>Date</Label>
              <Input
                data-test="date-dropdown"
                type="select"
                value={formFields?.birthDate}
                disabled={!canUpdateProfileSettings}
                onChange={(e) => _updateFieldValue("birthDate", e.target.value)}
                onBlur={() => _onBlurHandler("birthDate")}
              >
                {!influencer?.dob?.day ? (
                  <option key={"default-birthDate"} value={""}>
                    Select Date
                  </option>
                ) : null}
                {generateDates(
                  formFields?.birthMonth,
                  formFields?.birthYear
                ).map((date) => (
                  <option key={`date_${date}`} value={date}>
                    {date}
                  </option>
                ))}
              </Input>
              {errors?.birthDate ? (
                <div className="form-error">{errors?.birthDate}</div>
              ) : null}
            </div>

            <div className="mx-2">
              <Label>Month</Label>
              <Input
                data-test="month-dropdown"
                type="select"
                disabled={!canUpdateProfileSettings}
                value={formFields?.birthMonth}
                onChange={(e) =>
                  _updateFieldValue("birthMonth", e.target.value)
                }
                onBlur={() => _onBlurHandler("birthMonth")}
              >
                {!influencer?.dob?.month ? (
                  <option key={"default-birthMonth"} value={""}>
                    Select Month
                  </option>
                ) : null}
                {MonthsInEngConfig.map((month) => (
                  <option
                    key={`month_${month.short_name}`}
                    value={month?.month}
                  >
                    {month?.short_name}
                  </option>
                ))}
              </Input>
              {errors?.birthMonth ? (
                <div className="form-error">{errors?.birthMonth}</div>
              ) : null}
            </div>

            <div>
              <Label>Year</Label>
              <Input
                data-test="year-dropdown"
                type="select"
                value={formFields.birthYear}
                disabled={!canUpdateProfileSettings}
                onChange={(e) => _updateFieldValue("birthYear", e.target.value)}
                onBlur={() => _onBlurHandler("birthYear")}
              >
                {!influencer?.dob?.year ? (
                  <option key={"default-birthYear"} value={""}>
                    Select Year
                  </option>
                ) : null}
                {birthYearWithMinimumAge(MIN_SIGNUP_AGE).map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </Input>
              {errors?.birthYear ? (
                <div className="form-error">{errors?.birthYear}</div>
              ) : null}
            </div>
          </FormGroup>

          {canUpdateProfileSettings ? (
            <>
              <Button
                onClick={(e) => _handleSubmit(e)}
                className="themeBtn saveBtn"
                disabled={loadingState?.formSubmit}
              >
                {loadingState?.formSubmit ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}
                Save
              </Button>

              <Button
                color="link"
                className="deleteAccountBtn"
                onClick={() => _togglePromptModal(true)}
                disabled={
                  loadingState?.formSubmit || loadingState?.deleteAccount
                }
              >
                <i className="fa fa-trash-o mr-2" />
                Delete My Account
              </Button>
            </>
          ) : null}

          <hr
            style={{
              marginTop: 25,
              borderTopColor: "rgba(204, 204, 204, 0.5)",
            }}
          />
        </CardBody>
      </Card>

      <ImageCropUploaderModal
        isOpen={isCropModalOpen}
        selectedPhoto={cropModalContent}
        resetPhoto={() => _resetPhoto()}
        onSuccess={_saveCroppedPhoto}
        onDismiss={() => _toggleCropModal(false)}
      />

      <CustomPrompt
        isOpen={isPromptModalOpen}
        message={deleteAccountWarning}
        className="deleteProfile"
        successButtonText="Yes, Delete My Account"
        isEmphasisNo={true}
        onSuccess={() => _deleteAccount()}
        onDismiss={() => _togglePromptModal(false)}
      />

      {coverImageModal.isOpen && (
        <CoverImageModal
          isOpen={coverImageModal.isOpen}
          data={coverImageModal.data}
          toggle={() => _toggleCoverImageModal()}
        />
      )}
    </>
  );
};

export default SettingsProfileSection;
