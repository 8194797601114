import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../components/custom/CustomLoader";
import { errorHandler } from "../../helper-methods";
import {
  getAllBundles,
  getAllOffers,
  updateInfluencerDetails,
} from "../../http-calls/index";
import {
  fetchAndModifiedUserDetails,
  updateOnlyUserData,
} from "../../redux/actions";
import TierComponent from "../TierComponent";
import SettingsPromoOffers from "./SettingsPromoOffers";
import { CustomInput } from "reactstrap";
import Swal from "sweetalert2";
import colors from "../../assets/styles/scss/style.scss";

const SettingsSubscription = () => {
  const influencer = useSelector((state) => state?.userData?.user);
  const dispatch = useDispatch();

  const [bundles, setBundles] = useState([]);
  const [offers, setOffers] = useState([]);
  const [loadingState, setLoadingState] = useState({
    bundleData: false,
    offerData: false,
  });
  const [isFreeSubAllowUpdating, setIsFreeSubAllowUpdating] = useState(false);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _udpateInfluencerDetails = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await updateInfluencerDetails(payload);
        dispatch(updateOnlyUserData(res.user));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const _toggleFreeSubscription = async (checked = false) => {
    try {
      if (checked === true) {
        const { isConfirmed } = await Swal.fire({
          title: `Free Subscription Policy & Sales Requirements`,
          text: `Offering free subscriptions may be subject to fees based on the number of free subscribers and monthly sales performance. Free subscriptions for up to 99 fans come with no fees. However, if your free subscriber count exceeds 99 and your monthly sales fall below the required threshold, a fee may be applied. Fees are assessed at the end of each month based on your sales. By proceeding, you agree to these terms.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: colors?.themeColor,
          cancelButtonColor: "#ccc",
          confirmButtonText: "I agree",
        });

        if (isConfirmed !== true) {
          return;
        }
      }

      setIsFreeSubAllowUpdating(true);
      await _udpateInfluencerDetails({ isFreeSubscription: checked });
      setIsFreeSubAllowUpdating(false);
    } catch (error) {
      setIsFreeSubAllowUpdating(false);
      errorHandler(error);
    }
  };

  const _getAllbundles = async () => {
    try {
      _manageLoadingState("bundleData", true);

      const res = await getAllBundles();
      setBundles(res?.bundles?.length ? res.bundles : []);
      dispatch(fetchAndModifiedUserDetails());
    } catch (error) {
      errorHandler(error);
    } finally {
      _manageLoadingState("bundleData", false);
    }
  };

  const _getAllOffers = async () => {
    try {
      _manageLoadingState("offerData", true);

      const res = await getAllOffers();
      setOffers(res?.offers?.length ? res.offers : []);
    } catch (error) {
      errorHandler(error);
    } finally {
      _manageLoadingState("offerData", false);
    }
  };

  useEffect(() => {
    _getAllbundles();
    _getAllOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="settingOptionsWrap">
        {!influencer?.isScheduleForAccountDelete &&
        influencer?.isFreeSubscriptionsAllow ? (
          <div className="header_Settings mb-4">
            <h4>Allow Free Subscription</h4>

            <CustomInput
              type="switch"
              className="customSwitch freeSubscriptionSwitch"
              id="freeSubscriptionSwitch"
              disabled={isFreeSubAllowUpdating}
              checked={influencer?.isFreeSubscription || false}
              onChange={(e) => _toggleFreeSubscription(e.target.checked)}
            />
          </div>
        ) : null}

        <div className="header_Settings">
          <h4>
            {influencer?.multiTierSubscription ? "Tiers" : "Subscription"}
          </h4>
        </div>

        {influencer?.multiTierSubscription ? (
          bundles?.length ? (
            bundles.map((bundle, index) => (
              <TierComponent
                key={index}
                bundles={bundles}
                bundle={bundle}
                offers={offers}
                onSave={() => {
                  _getAllbundles();
                  _getAllOffers();
                }}
              />
            ))
          ) : loadingState?.offerData ? (
            <div className="d-flex justify-content-center">
              <CustomLoader />
            </div>
          ) : null
        ) : bundles?.length ? (
          bundles.map((bundle, index) => {
            if (bundle?.tier === "basic") {
              return (
                <TierComponent
                  key={index}
                  bundles={bundles}
                  bundle={bundle}
                  offers={offers}
                  onSave={() => _getAllbundles()}
                />
              );
            }
            return null;
          })
        ) : loadingState?.offerData ? (
          <div className="d-flex justify-content-center">
            <CustomLoader />
          </div>
        ) : null}

        <SettingsPromoOffers
          bundles={bundles}
          offers={offers}
          setOffers={setOffers}
          loading={loadingState?.offerData}
          getAllOffers={_getAllOffers}
        />
      </div>
    </>
  );
};

export default SettingsSubscription;
