import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { Table, Badge } from "reactstrap";
import {
  errorHandler,
  formatCurrencyValue,
  formatDateAndTime,
  formatPayoutStatus,
  getPayoutStatusColor,
} from "../../helper-methods";
import { getAllPayoutRequests } from "../../http-calls";
import CustomLoader from "../custom/CustomLoader";
import SkeletonLoading from "../SkeletonLoading";

const PayoutRequests = ({ activeTab, tabId }) => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const payload = {
            startingAfter: withdrawals?.[withdrawals?.length - 1]?._id,
          };

          _getAllPayoutRequests(payload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, withdrawals]
  );

  const _getAllPayoutRequests = async (payload) => {
    try {
      setLoading(true);

      const res = await getAllPayoutRequests(payload);

      setWithdrawals((prev) =>
        payload?.startingAfter
          ? prev?.concat(res?.withdrawals)
          : res?.withdrawals
      );
      setHasMore(res?.hasMore);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === tabId) {
      _getAllPayoutRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabId]);

  return (
    <>
      <div className="paymentTime d-flex align-items-center">
        Payment may take 3-5 days to reflect in your account
        {loading ? <CustomLoader className="mx-2" /> : null}
      </div>

      <div className="referralHistoryTable statsTable">
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th className="customTableColWidth">Instant Payout Charges</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {withdrawals?.length ? (
              React.Children.toArray(
                withdrawals.map((each, index) => (
                  <tr
                    {...(index === withdrawals?.length - 1
                      ? { ref: lastElementRef }
                      : {})}
                  >
                    <td>{formatDateAndTime(each.time)}</td>
                    <td>
                      <span style={{ fontWeight: 500 }}>
                        {formatCurrencyValue(each.amount || 0)}
                      </span>
                    </td>
                    <td className="customTableColWidth">
                      {each.isInstant
                        ? formatCurrencyValue(each.instantPayoutCharge || 0)
                        : "-"}
                    </td>
                    <td>
                      <Badge
                        color={getPayoutStatusColor(each.status)}
                        className="tableDataStatus"
                        style={{ width: 125 }}
                      >
                        {formatPayoutStatus({
                          id: each._id,
                          status: each.status,
                          reason: each.reason,
                        })}
                      </Badge>
                    </td>
                  </tr>
                ))
              )
            ) : loading ? (
              <SkeletonLoading type={"table"} rows={1} col={4} />
            ) : (
              <tr className="text-center">
                <td colSpan="4">There is no request to display</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default PayoutRequests;
