import React, {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CustomInput,
  Card,
  CardBody,
} from "reactstrap";
import {
  showToast,
  errorHandler,
  isPermissionToAccess,
} from "../helper-methods";
import ConfigureString from "../components/ConfigureString";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAndUpdateVaults } from "../redux/actions";
import VaultDescriptionCard from "../components/VaultDescriptionCard";
import CustomLoader from "../components/custom/CustomLoader";
import { useMemo } from "react";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";

// code splitting
const VaultCardComponent = React.lazy(async () =>
  import("../components/VaultCardComponent")
);

const VaultsPage = () => {
  const history = useHistory();

  const observer = useRef();

  const dispatch = useDispatch();

  const feedData = useSelector((state) => state?.feedData);
  const userData = useSelector((state) => state?.userData);

  const [dataPayload, setDataPayload] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [showInactiveFolder, setShowInactiveFolder] = useState(false);

  const [loadingState, setLoadingState] = useState({
    data: false,
    filter: false,
    search: false,
  });

  const filteredVaults = useMemo(() => {
    if (feedData?.vaults?.length) {
      return feedData?.vaults.filter(
        (each) => each.isActive !== showInactiveFolder
      );
    }
    return [];
  }, [feedData?.vaults, showInactiveFolder]);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _getAndUpdateVaults = async (payload) => {
    try {
      _manageLoadingState("data", true);

      await getAndUpdateVaults(payload)(dispatch);

      _manageLoadingState("data", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("data", false);
    }
  };

  const _toggleShowInactiveFolder = (isShow = false) => {
    console.log(localStorage);
    localStorage.showInactiveFolder = isShow;

    setShowInactiveFolder(isShow);
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.data) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          feedData?.vaults?.length < feedData?.vaultsCount
        ) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["pageNumber"] = feedData?.vaults?.length
            ? newDataPayload["pageNumber"] + 1
            : 1;
          setDataPayload(newDataPayload);
          _getAndUpdateVaults(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.data, feedData?.vaults]
  );

  useEffect(() => {
    if (!isPermissionToAccess("messaging", "canViewVault")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    _toggleShowInactiveFolder(
      localStorage?.showInactiveFolder === "true" ? true : false
    );

    _getAndUpdateVaults(dataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin mb-2">
          <Col sm={12} lg={11} className="pgPadding">
            <div className="pgTitle hasCreateBtn">
              <div className="d-flex">
                <h2>
                  <ConfigureString keyString={"THE vault"} />
                </h2>
                {loadingState?.data ? <CustomLoader className="mx-2" /> : null}
              </div>

              {!userData?.user?.isScheduleForAccountDelete &&
              isPermissionToAccess("vault", "canCreateVault") ? (
                <Button
                  className="themeBtn addBtn"
                  onClick={() => history.push("/vault/add")}
                >
                  <span className="d-none d-md-inline-block">
                    New Folder
                  </span>
                  <img
                    src="/assets/img/new-vault.png"
                    alt="New Vault"
                    loading="lazy"
                    className="d-md-none createIcon" 
                  />
                </Button>
              ) : null}
            </div>

            <ErrorBoundary>
              <VaultDescriptionCard />
            </ErrorBoundary>

            <div className="text-right mb-3 pr-1">
              <CustomInput
                id="exampleCustomCheckbox__showInactiveFolderFormGroup"
                type="checkbox"
                checked={showInactiveFolder}
                onChange={(e) => _toggleShowInactiveFolder(e.target.checked)}
                label="Show Archived Folders"
              />
            </div>

            <Row className="noMargin">
              {filteredVaults?.length ? (
                filteredVaults.map((each, index) => (
                  <Fragment key={each._id}>
                    <Col xs="6" sm="6" lg="4" className="vaultCustomCol">
                      {/* on clicking the card, the influencer will be taken to the detail page of that particular folder */}
                      <ErrorBoundary>
                        <Suspense
                          fallback={
                            <Card className="vaultFolderWrap">
                              <CardBody>
                                <SkeletonLoading
                                  type={"box"}
                                  count={1}
                                  height={"100%"}
                                  borderRadius={16}
                                  style={{ verticalAlign: 3 }}
                                />
                              </CardBody>
                            </Card>
                          }
                        >
                          <VaultCardComponent
                            {...(index === filteredVaults?.length - 1
                              ? { lastElementRef }
                              : {})}
                            folder={each}
                            canEdit={
                              !userData?.user?.isScheduleForAccountDelete &&
                              isPermissionToAccess(
                                "vault",
                                "canEditOrArchiveVault"
                              )
                            }
                            getAndUpdateVaults={() =>
                              _getAndUpdateVaults(dataPayload)
                            }
                          />
                        </Suspense>
                      </ErrorBoundary>
                    </Col>

                    {index === filteredVaults?.length - 1 &&
                      loadingState.data && (
                        <SkeletonLoading type={"vault"} count={6} />
                      )}
                  </Fragment>
                ))
              ) : loadingState?.data ? (
                <SkeletonLoading type={"vault"} count={6} />
              ) : (
                <Col xs={12}>
                  <div className="noContentFound">
                    There is no vault to display.
                  </div>
                </Col>
              )}
            </Row>

            {/* create btn */}
            {!userData?.user?.isScheduleForAccountDelete &&
            isPermissionToAccess("vault", "canCreateVault") ? (
              <Button
                className="createBtn"
                onClick={() => history.push("/vault/add")}
              >
                <img
                  src="/assets/img/new-vault.png"
                  alt="Calendar"
                  loading="lazy"
                />
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VaultsPage;
