import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CustomInput,
  Label,
} from "reactstrap";
import { AudioRecorder } from "react-audio-voice-recorder";
import colors from "../../assets/styles/scss/style.scss";
import { showToast } from "../../helper-methods";

const SoundRecordUploadModal = ({
  isOpen,
  toggle,
  modalHeading,
  onSave,
  uploadFile,
  isMultipleUpload = false,
  isUploadFile = true,
  isHideUploadAudioFile = false,
}) => {
  const [isUploadAudio, setIsUploadAudio] = useState(false);
  const [recordedFile, setRecordedFile] = useState({
    recordedAudio: null,
    isAudioRecording: false,
  });

  const _resetModalState = () => {
    setIsUploadAudio(false);
    setRecordedFile({
      recordedAudio: null,
      isAudioRecording: false,
    });
  };

  const _closeModal = () => {
    _resetModalState();
    toggle();
  };

  const _readFile = (e) => {
    try {
      if (!e?.target?.files?.length) return;

      for (let file of e.target.files) {
        if (!file.type.includes("audio")) {
          showToast("Only audio files are allowed", "error");
          return;
        }
      }

      uploadFile(e);
      _closeModal();
    } catch (error) {
      showToast("An error occurred while uploading the file", "error");
      console.error("Error uploading file: ", error);
    }
  };

  const addAudioElement = (blob) => {
    try {
      const url = URL.createObjectURL(blob);
      setRecordedFile({ recordedAudio: { blob, blobURL: url } });
    } catch (error) {
      showToast("Error processing recorded audio", "error");
      console.error("Error processing blob: ", error);
    }
  };

  const _saveSoundRecord = () => {
    if (recordedFile.recordedAudio) {
      if (isUploadFile) {
        _readFile({
          target: {
            files: [
              {
                ...recordedFile.recordedAudio,
                type: "audio/*",
                isRecordedAudio: true,
              },
            ],
          },
        });
      } else {
        onSave(recordedFile.recordedAudio);
        _resetModalState();
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={_closeModal}
      className="modal-dialog-centered modal-dialog-scrollable"
      aria-labelledby="audio-modal-heading"
    >
      <ModalHeader toggle={_closeModal}>
        {modalHeading || "Record Audio"}
      </ModalHeader>
      <ModalBody>
        {!isHideUploadAudioFile ? (
          <div className="d-flex mb-4 justify-content-center">
            <CustomInput
              type="radio"
              id="isUploadAudio_upload_CustomRadio"
              name="isUploadAudio"
              label="Upload Audio"
              checked={isUploadAudio}
              onChange={() => setIsUploadAudio(true)}
              className="mr-5"
            />
            <CustomInput
              type="radio"
              id="isUploadAudio_record_CustomRadio"
              name="isUploadAudio"
              label="Record Audio"
              checked={!isUploadAudio}
              onChange={() => setIsUploadAudio(false)}
            />
          </div>
        ) : null}

        {isUploadAudio && !isHideUploadAudioFile ? (
          <Label className="themeBtn addBtn d-block mx-auto mb-1">
            <input
              accept="audio/*"
              type="file"
              multiple={isMultipleUpload}
              onChange={_readFile}
              style={{ display: "none" }}
            />
            <i className="fa fa-cloud-upload upload-icon" /> Upload Audio
          </Label>
        ) : (
          <div>
             <div className="customAudioRecorder mt-0">
              <AudioRecorder
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                showVisualizer={true}
                strokeColor={colors?.themeColor}
              />
            </div>

            {recordedFile?.recordedAudio?.blobURL && (
              <audio
                controls
                className="audioRecorded mt-4"
                controlsList="nodownload"
                src={recordedFile?.recordedAudio?.blobURL}
                onLoadedMetadata={(e) => {
                  // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
                  if (e.target.duration === Infinity) {
                    e.target.currentTime = Number.MAX_SAFE_INTEGER;
                    e.target.ontimeupdate = () => {
                      e.target.ontimeupdate = null;
                      e.target.currentTime = 0;
                    };
                  }
                }}
              />
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className="modalBtnCancel"
          onClick={_closeModal}
          aria-label="Cancel and close the modal"
          disabled={recordedFile.isAudioRecording}
        >
          Cancel
        </Button>
        {!isUploadAudio && (
          <Button
            className="modalBtnSave"
            onClick={_saveSoundRecord}
            disabled={
              !recordedFile.recordedAudio || recordedFile.isAudioRecording
            }
            aria-label="Save the recorded audio"
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SoundRecordUploadModal;
