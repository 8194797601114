import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Input, Table } from "reactstrap";
import { useSelector } from "react-redux";
import AddMemberModal from "../components/modals/AddMemberModal";
import {
  showToast,
  capitalizeEveryFirstLetter,
  getFeatureStatus,
  formatDateAndTime,
  formatDate,
  isPermissionToAccess,
  errorHandler,
  getFullName,
} from "../helper-methods";
import { getAllTeamMembers, editTeamMember } from "../http-calls/index";
import { useHistory } from "react-router-dom";
import SkeletonLoading from "../components/SkeletonLoading";

const TeamMembers = () => {
  const history = useHistory();
  const userData = useSelector((state) => state?.userData);

  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState({
    isOpen: false,
    data: null,
  });

  const _getAllTeamMembers = async () => {
    try {
      setIsLoading(true);
      const res = await getAllTeamMembers();
      setMembers(res?.members?.length ? res.members : []);
      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  const _toggleAddMemberModal = (isOpen = false, data = null) => {
    setAddMemberModal({ isOpen, data });
  };

  // calculate how many permission out of total accessible permission are set
  const _getPermissionCount = (permissionSettings) => {
    if (!permissionSettings) return "N/A";

    const isLiveEventActive = getFeatureStatus("event");
    const isPayPerViewActive = getFeatureStatus("ppv");
    const isShoutoutActive = getFeatureStatus("shoutout");
    const isVaultActive = getFeatureStatus("vault");
    const isStoreActive = getFeatureStatus("store");

    const filterPermissionSettings = Object.keys(permissionSettings).filter(
      (each) => {
        if (each === "vault" && !isVaultActive) return null;
        if (each === "liveEvent" && !isLiveEventActive) return null;
        if (each === "PPV" && !isPayPerViewActive) return null;
        if (each === "shoutOuts" && !isShoutoutActive) return null;
        if (each === "store" && !isStoreActive) return null;
        return each;
      }
    );

    const totalPermission = filterPermissionSettings
      .map((key) => Object.keys(permissionSettings[key]))
      .reduce((acc, curr) => acc.concat(curr)).length;

    const givenPermission = filterPermissionSettings
      .map((key) => Object.values(permissionSettings[key]))
      .reduce((acc, curr) => acc.concat(curr))
      .filter((obj) => obj).length;

    return givenPermission + " / " + totalPermission;
  };

  // change member status to active or inactive
  const _changeMemberStatus = async (value, memberId) => {
    try {
      setStatusChangeLoading(true);
      const findMember = members.find((e) => e._id === memberId);
      if (!findMember) {
        setStatusChangeLoading(false);
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }

      let payload = {
        isActive: value,
      };

      const res = await editTeamMember(memberId, payload);
      findMember.isActive = res.assistant.isActive;
      setMembers(members);
      showToast("Status changed successfully", "success");
      setStatusChangeLoading(false);
    } catch (error) {
      setStatusChangeLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!isPermissionToAccess("myTeam", "canViewMembers")) {
      showToast("Unauthorised", "error");
      history.push("/my-profile");
    }

    _getAllTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      data-test="team-members-page"
      className="customPgHeight animated fadeIn"
    >
      <Container className="noPadding">
        <Row className="noMargin bg-white pb-4">
          <Col xs={12} className="noPadding">
            <div className="pgTitle customMarginMob">
              <h2>Team Members</h2>

              {isPermissionToAccess("myTeam", "canAddNewMembers") ? (
                <Button
                  data-test="add-member-button"
                  className="themeBtn addBtn"
                  onClick={() => _toggleAddMemberModal(true)}
                >
                  Add Member
                </Button>
              ) : null}
            </div>

            <div className="referralHistoryTable teamMembersTable">
              <Table data-test="team-members-table" responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Last Login</th>
                    <th>Added</th>
                    <th>Permission</th>
                    {isPermissionToAccess("myTeam", "canUpdateMembers") ? (
                      <>
                        <th>Status</th>
                        {!userData?.user?.isScheduleForAccountDelete && (
                          <th>Action</th>
                        )}
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {members.length ? (
                    members.map((member, index) => (
                      <tr key={index}>
                        <td>
                          {capitalizeEveryFirstLetter(getFullName(member.name))}
                        </td>
                        <td>{member?.email ? member.email : "N/A"}</td>
                        <td>
                          {member?.phone && member?.phoneCountry
                            ? member?.phoneCountry + member?.phone
                            : member?.phone}
                        </td>
                        <td>
                          {member?.lastLogin
                            ? formatDateAndTime(member?.lastLogin)
                            : "N/A"}
                        </td>
                        <td>
                          {member?.createdAt
                            ? formatDate(member?.createdAt)
                            : "N/A"}{" "}
                          By{" "}
                          {member?._createdBy?.name?.full ||
                          member?._createdBy?.name?.first
                            ? capitalizeEveryFirstLetter(
                                getFullName(member?._createdBy?.name)
                              )
                            : "N/A"}
                        </td>
                        <td>
                          {_getPermissionCount(member.permissionSettings)}
                        </td>
                        {isPermissionToAccess("myTeam", "canUpdateMembers") ? (
                          <>
                            <td>
                              <Input
                                type="select"
                                name="select"
                                style={{ width: 100 }}
                                disabled={statusChangeLoading}
                                onChange={(e) =>
                                  _changeMemberStatus(
                                    e.target.value,
                                    member?._id
                                  )
                                }
                                value={member?.isActive}
                              >
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                              </Input>
                            </td>
                            {!userData?.user?.isScheduleForAccountDelete && (
                              <td>
                                <Button
                                  color="link"
                                  className="text-danger px-1"
                                  onClick={() =>
                                    _toggleAddMemberModal(true, member)
                                  }
                                >
                                  Edit
                                </Button>
                              </td>
                            )}
                          </>
                        ) : null}
                      </tr>
                    ))
                  ) : isLoading ? (
                    <SkeletonLoading type={"table"} rows={1} col={8} />
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No Member Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <AddMemberModal
              data-test="add-member-modal"
              isOpen={addMemberModal.isOpen}
              member={addMemberModal.data}
              toggle={() => _toggleAddMemberModal()}
              updateTeamMembersTable={() => _getAllTeamMembers()}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TeamMembers;
