import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ButtonLoading from "../ButtonLoading";
import { addFanToGroups, addTags } from "../../http-calls";
import { errorHandler, showToast } from "../../helper-methods";
import { getAndUpdateTags } from "../../redux/actions";
import { useDispatch } from "react-redux";

const BroadcastTagModal = ({
  isOpen,
  data = [],
  toggle,
  onSucess,
  selectedFans = [],
  onCreateTag,
}) => {
  const dispatch = useDispatch();

  const [tagName, setTagName] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const _resetState = () => {
    setTagName("");
    setErrors("");
  };

  const _closeModal = () => {
    _resetState();
    toggle();
  };

  const _validateTagName = () => {
    if (tagName?.trim()?.length) {
      if (data?.includes(tagName?.toLowerCase()?.trim())) {
        setErrors("Tag name already exists");
        return false;
      } else {
        setErrors("");
        return true;
      }
    } else {
      setErrors("Required");
      return false;
    }
  };

  const _onSubmit = async () => {
    try {
      setLoading(true);
      const isValid = _validateTagName();
      if (!isValid) return;

      // create tag and assign to fan
      const groupRes = await addTags({ groupNames: [tagName?.trim()] });
      const groupId = groupRes?.groups?.[0]?._id;

      await addFanToGroups({
        fanIds: selectedFans?.map((each) => each?._fan?._id),
        groupIds: groupRes?.groups?.map((each) => each?.id),
      });

      await getAndUpdateTags()(dispatch);

      showToast("Tag created for selected Susbcribers", "success");
      onCreateTag(groupId, tagName);
      _closeModal();
    } catch (error) {
      errorHandler(error);
      _closeModal();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal-dialog-centered modal-dialog-scrollable"
        toggle={() => _closeModal()}
      >
        <ModalHeader toggle={() => _closeModal()}>
          Create Tag for selected subscribers
        </ModalHeader>
        <ModalBody>
          <FormGroup className="customCreateSelect">
            <Input
              type="text"
              placeholder="Enter Tag Name"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              onBlur={() => _validateTagName()}
            />
          </FormGroup>
          {errors ? <div className="form-error">{errors}</div> : <></>}
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnCancel" onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={() => _onSubmit()}
            disabled={loading || !tagName?.length ? true : false}
          >
            {loading ? <ButtonLoading /> : null}
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BroadcastTagModal;
