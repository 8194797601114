import { createSlice } from "@reduxjs/toolkit";
import { clearAllSubscribers, getAndUpdateSubscribers } from "../actions";

const initialState = {
  subscribers: [],
};

const subscribersSlice = createSlice({
  name: "subscribers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearAllSubscribers, (state, action) => {
        state.subscribers = [];
      })

      .addCase(getAndUpdateSubscribers.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateSubscribers.fulfilled, (state, action) => {
        state.subscribers = action.payload;
        state.loading = false;
      });
  },
});

export const subscribersReducer = subscribersSlice.reducer;
