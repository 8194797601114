import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { errorHandler, getFullName } from "../../helper-methods";

const PollVotesDetailsModal = ({ isOpen, data, optionsList = [], toggle }) => {
  const _closeModal = () => {
    toggle();
  };

  const _getPollOptionText = (optionId) => {
    const option = optionsList?.find((each) => each?._id === optionId);

    if (option) {
      return option?.text;
    }
    return "";
  };

  useEffect(() => {
    if (!optionsList?.length) {
      errorHandler();
      _closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
      <ModalHeader>Poll Votes Details</ModalHeader>

      <ModalBody>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Vote</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((eachOpinion) => (
              <tr key={eachOpinion?._id}>
                <td>{getFullName(eachOpinion?._from?.name)}</td>
                <td>{_getPollOptionText(eachOpinion?.option)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>

      <ModalFooter>
        <Button onClick={() => _closeModal()}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default PollVotesDetailsModal;
