import React from "react";

const StoryPreviewComponent = ({ story, toggleViewStoryModal }) => {
  return (
    <div onClick={() => toggleViewStoryModal(story)}>
      <div className="storyImgWrap">
        <img
          alt="Story Img"
          src={
            story?.previewImageUrl ||
            story?.thumbnail ||
            "/assets/img/default_user_icon.svg"
          }
        />
      </div>
      <p>{story?.text || ""}</p>
    </div>
  );
};

export default StoryPreviewComponent;
