import React, { Fragment, useState } from "react";
import {
  Col,
  Card,
  Popover,
  PopoverBody,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import {
  formatCurrencyValue,
  formatDateAndTime,
  getFullName,
  hideDescription,
} from "../helper-methods";
import MultiMediaModal from "./modals/MultiMediaModal";

const BroadcastMessageStatMobileView = ({
  data,
  getMediaPreview,
  deleteMassMessageAlert,
}) => {
  const [isAllUsersPopOverVisible, setIsAllUsersPopOverVisible] =
    useState(false);
  const [isPurchasedUsersPopOverVisible, setIsPurchasedUsersPopOverVisible] =
    useState(false);
  const [multiMediaModal, setMultiMediaModal] = useState({
    isOpen: false,
    contents: null,
    activeIndex: null,
  });

  const _toggleMultiMediaModal = (
    isOpen = false,
    contents = null,
    activeIndex = 0
  ) => {
    setMultiMediaModal({
      isOpen,
      contents,
      activeIndex,
    });
  };

  const _toggleAllUsersPopOver = (isOpen = false) => {
    if (isOpen !== isAllUsersPopOverVisible) {
      setIsAllUsersPopOverVisible(isOpen);
    } else {
      setIsAllUsersPopOverVisible(false);
    }
  };

  const _togglePurchasedUsersPopOver = (isOpen = false) => {
    if (isOpen !== isPurchasedUsersPopOverVisible) {
      setIsPurchasedUsersPopOverVisible(isOpen);
    } else {
      setIsPurchasedUsersPopOverVisible(false);
    }
  };

  return (
    <>
      <Col sm={6} lg={4} className="customColMassMsg">
        <Card className="tableData-Card">
          <CardHeader>
            <div>
              <p>{"Date & Time"}</p>
              {formatDateAndTime(data?.createdAt)}
            </div>

            <Button
              title="Delete"
              color="link"
              className="deleteButton"
              onClick={() => deleteMassMessageAlert(data)}
            >
              <i className="fa fa-trash-o" />
            </Button>
          </CardHeader>

          <CardBody>
            <div className="broadcastMsgStats">
              <div>
                <p>Sent</p>
                {/* a popover with the list of names is to be shown under "Sent & Purchased" Field */}
                <span
                  className="cursorPointer themeColor"
                  id={`Popover_mobileview_isAllUsersPopOverVisible_${data?._id}`}
                  onClick={() =>
                    data?._userList?.length
                      ? _toggleAllUsersPopOver(data._id)
                      : {}
                  }
                >
                  {data?._messagesCount || 0}
                </span>
                {data?._userList?.length ? (
                  <Popover
                    trigger="legacy"
                    placement="bottom"
                    isOpen={isAllUsersPopOverVisible === data._id}
                    target={`Popover_mobileview_isAllUsersPopOverVisible_${data._id}`}
                    toggle={() => _toggleAllUsersPopOver()}
                    onClick={() => _toggleAllUsersPopOver()}
                  >
                    <PopoverBody>
                      <div className="listInPopover">
                        {data._userList.map((user, userIndex) => (
                          <p key={userIndex}>{getFullName(user?._to?.name)}</p>
                        ))}
                      </div>
                    </PopoverBody>
                  </Popover>
                ) : null}
              </div>
              <div>
                <p>Purchased</p>
                {/* a popover with the list of names is to be shown under "Sent & Purchased" Field */}
                <span
                  className="cursorPointer themeColor"
                  id={`Popover_mobileview_isPurchasedUsersPopOverVisible_${data._id}`}
                  onClick={() =>
                    data?._purchased?.length
                      ? _togglePurchasedUsersPopOver(data._id)
                      : {}
                  }
                >
                  {data?._purchased?.length || 0}
                </span>
                {data?._userList?.length ? (
                  <Popover
                    trigger="legacy"
                    placement="bottom"
                    isOpen={isPurchasedUsersPopOverVisible === data._id}
                    target={`Popover_mobileview_isPurchasedUsersPopOverVisible_${data._id}`}
                    toggle={() => _togglePurchasedUsersPopOver()}
                    onClick={() => _togglePurchasedUsersPopOver()}
                  >
                    <PopoverBody>
                      <div className="listInPopover">
                        {data._purchased.map((user, userIndex) => (
                          <p key={userIndex}>{getFullName(user?._to?.name)}</p>
                        ))}
                      </div>
                    </PopoverBody>
                  </Popover>
                ) : null}
              </div>
              <div>
                <p>Earned</p>
                <span>
                  {data?._messages?.content?.[0]?.amount &&
                  data._purchased?.length
                    ? formatCurrencyValue(
                        data?._messages?.content?.[0]?.amount *
                          data._purchased?.length
                      )
                    : formatCurrencyValue(0)}
                </span>
              </div>
            </div>

            <div className="broadcastMsgStats">
              <div>
                <p>Price</p>

                <span>
                  {formatCurrencyValue(
                    data?._messages?.content?.[0]?.amount || 0
                  )}
                </span>
              </div>

              <div>
                <p>Text</p>
                {data?._messages?.text?.trim()
                  ? hideDescription({
                      id: `mass_${data?._id}`,
                      description: data?._messages?.text,
                      charCount: 10,
                    })
                  : "N/A"}
              </div>

              <div>
                <p style={{ textAlign: "right" }}>Attachment</p>
                {data?._messages?.content?.length ? (
                  <div
                    onClick={() =>
                      _toggleMultiMediaModal(true, data?._messages?.content)
                    }
                    style={{ minWidth: 110 }}
                    className="d-flex flex-column align-items-end"
                  >
                    <span style={{ marginBottom: 3 }}>
                      {`${data?._messages?.content?.length} Media ${
                        data?._messages?.content?.length > 1 ? "Files" : "File"
                      }`}
                    </span>
                    {getMediaPreview(data)}
                  </div>
                ) : (
                  <span>No Media</span>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <MultiMediaModal
        isOpen={multiMediaModal?.isOpen}
        contents={multiMediaModal?.contents}
        activeIndex={multiMediaModal.activeIndex}
        toggle={() => _toggleMultiMediaModal()}
      />
    </>
  );
};

export default BroadcastMessageStatMobileView;
