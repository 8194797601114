import Hls from "hls.js";
import { useEffect, useState, useRef } from "react";
import { bitrateConfig } from "../../../../config/helper-config";

const useHls = (videoElement, hlsLink = {}, playerState) => {
  const resolutions = hlsLink ? Object.keys(hlsLink) : [];

  // states
  // const [isHlsSupported, setIsHlsSupported] = useState(true);
  const [currentResolution, setCurrentResolution] = useState(resolutions?.[0]);
  const [isAutoSwitchResolution, setIsAutoSwitchResolution] = useState(false);
  const [prevTime, setPrevTime] = useState(0);

  const hlsInstanceRef = useRef({ current: null });

  // function to handle resolution change
  const _onChangeResolution = (e) => {
    try {
      setPrevTime(videoElement.current.currentTime);
      if (e.target.value === "auto") {
        setIsAutoSwitchResolution(true);
        setCurrentResolution("720p");
      } else {
        setIsAutoSwitchResolution(false);
        setCurrentResolution(e.target.value);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // function to play the video after resolution change
  const _playVideo = () => {
    try {
      if (
        videoElement?.current?.paused &&
        document?.visibilityState &&
        playerState?.isPlaying
      ) {
        if (videoElement?.current?.paused) {
          videoElement?.current?.play({ overrideNative: true });
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // function to seek video to previous time after resolution change
  const _seekToTime = (timeInSeconds) => {
    try {
      videoElement.current.currentTime = timeInSeconds;
      _playVideo();
    } catch (error) {
      console.log({ error }, "");
    }
  };

  useEffect(() => {
    if (!videoElement?.current) return;
    let intervalId;

    const _setupHls = () => {
      try {
        if (!hlsLink?.[currentResolution]) {
          console.log({ hlsLink, currentResolution });
          return;
        }

        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(hlsLink[currentResolution]);
          hls.attachMedia(videoElement.current);
          hlsInstanceRef.current = hls;
          videoElement.current.playbackRate = playerState?.speed || 1;

          if (isAutoSwitchResolution) {
            // logic for auto adjusting resolution based on internet speed
            intervalId = setInterval(() => {
              const connection =
                navigator.connection ||
                navigator.mozConnection ||
                navigator.webkitConnection;

              if (videoElement.current.ended || videoElement.current.paused) {
                // Video has ended or paused, clear the interval
                clearInterval(intervalId);
                return;
              }

              if (connection && connection.downlink) {
                const estimatedBandwidth = connection.downlink * 1024 * 1024; // Convert to bits per second

                let closestBandwidthDiff = Number.MAX_VALUE;
                let newResolution = currentResolution;

                // loop through all the streams and find the stream whose bitrate is closest to current system bitrate, and then update the stream
                for (const resolution in hlsLink) {
                  const bandwidth = bitrateConfig[resolution];
                  const bandwidthDiff = Math.abs(
                    bandwidth - estimatedBandwidth
                  );
                  if (bandwidthDiff < closestBandwidthDiff) {
                    closestBandwidthDiff = bandwidthDiff;
                    newResolution = resolution;
                  }
                }

                if (newResolution !== currentResolution) {
                  setPrevTime(videoElement.current.currentTime);
                  setCurrentResolution(newResolution);
                  hls.currentLevel = hls.levels.findIndex(
                    (level) => level.height === newResolution
                  );
                }
              }
            }, 3500); // Adjust the interval based on your preference
          }
        } else {
          // setIsHlsSupported(false);
          if (
            videoElement?.current?.currentSrc !== hlsLink[currentResolution]
          ) {
            videoElement.current.src = hlsLink[currentResolution];
            videoElement.current.playbackRate = playerState?.speed || 1;
          }
        }
      } catch (error) {
        console.log({ error });
      }
    };

    try {
      _setupHls();
      if (prevTime) _seekToTime(prevTime);
    } catch (error) {
      console.log({ error });
    }

    return () => {
      try {
        clearInterval(intervalId);
        if (hlsInstanceRef?.current?.destroy) {
          hlsInstanceRef.current.destroy();
        }
      } catch (error) {
        console.log({ error });
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentResolution,
    isAutoSwitchResolution,
    prevTime,
    hlsLink,
    videoElement,
  ]);

  return {
    // isHlsSupported,
    resolutions,
    currentResolution,
    onChangeResolution: _onChangeResolution,
  };
};

export default useHls;
