import { TextareaAutosize } from "@material-ui/core";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Button,
  Label,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
} from "reactstrap";
import { autoHeightStyleChat } from "../../assets/styles/js";
import {
  capitalize,
  containsRestrictedWords,
  errorHandler,
  formatCurrencyValue,
  formatDecimalNumber,
  generateCalenderDateForChat,
  handleBrokenImageURL,
  getFullName,
  isFromSafari,
  isPermissionToAccess,
  removeLastWord,
  showToast,
  uploadFileOnServer,
  getLowResolutionLink,
} from "../../helper-methods";
import {
  getAllGroupMessages,
  saveContents,
  sendGroupMessage,
} from "../../http-calls";
import {
  updateSelectedGroupThread,
  updateSelectedGroupThreadKey,
} from "../../redux/actions";
import ViewChatMediaModal from "../modals/ViewChatMediaModal";
import MessageItem from "./MessageItem";
import cuid from "cuid";
import MediaLibraryModal from "../modals/MediaLibraryModal";
import FullScreenVideoRecorder from "../FullScreenVideoRecorder";
import SoundRecordUploadModal from "../modals/SoundRecordUploadModal";
import ChatPriceSetterModal from "../modals/ChatPriceSetterModal";
import SkeletonLoading from "../SkeletonLoading";
import ErrorBoundary from "../ErrorBoundary";
import LOAD_ICON from "../../assets/img/load_icon.png";
import TextDetectModal from "../modals/TextDetectModal";
import moment from "moment";
import EmojiPickerReact, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import EditGroupModal from "../modals/EditGroupModal";
import { DEFAULT_GROUP_PICTURE } from "../../config";

const GroupConversationCard = ({ screenWidth, isShow = false }) => {
  const dispatch = useDispatch();

  const observer = useRef();
  const chatWindowRef = useRef();
  const prevScrollHeight = useRef();

  const userData = useSelector((state) => state?.userData);
  const { selectedGroupThread } = useSelector((state) => state?.chatData || {});

  const [groupMessages, setGroupMessages] = useState([]);
  const [groupMessagesCount, setGroupMessagesCount] = useState(0);
  const [messagesPayload, setMessagesPayload] = useState({
    skip: 0,
    limit: 20,
  });

  const [loadingState, setLoadingState] = useState({
    messages: false,
  });

  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [sendMessage, setSendMessage] = useState({
    text: "",
    images: {},
    videos: {},
    audios: {},
    amount: 0,
  });

  const [mediaLibraryModal, setMediaLibraryModal] = useState({
    isOpen: false,
    data: null,
  });
  const [chatPriceSetterModal, setChatPriceSetterModal] = useState({
    isOpen: false,
  });
  const [isOpenEditGroupModal, setIsOpenEditGroupModal] = useState({
    isOpen: false,
  });
  const [viewChatMediaModal, setViewChatMediaModal] = useState({
    isOpen: false,
  });
  const [fullScreenVideoRecorder, setFullScreenVideoRecorder] = useState({
    isOpen: false,
  });
  const [soundRecordUploadModal, setSoundRecordUploadModal] = useState({
    isOpen: false,
  });
  const [textDetectModal, setTextDetectModal] = useState({
    isOpen: false,
  });

  const mediaPreviews = useMemo(() => {
    const { images, videos, audios, amount } = sendMessage;
    const preview = [];
    // Iterate through images
    for (let imageId in images) {
      const image = images[imageId];
      preview.push({
        ...image,
        fileId: imageId,
        fileType: "image",
        category: "images",
      });
    }
    // Iterate through videos
    for (let videoId in videos) {
      const video = videos[videoId];
      preview.push({
        ...video,
        fileId: videoId,
        fileType: "video",
        category: "videos",
      });
    }
    // Iterate through audio
    for (let audioId in audios) {
      const audio = audios[audioId];
      preview.push({
        ...audio,
        fileId: audioId,
        fileType: "audio",
        category: "audios",
      });
    }
    if (amount > 0) {
      preview.push({
        amount,
        fileType: "priceTag",
      });
    }
    return preview;
  }, [sendMessage]);

  const fileCount = useMemo(
    () =>
      sendMessage.amount ? mediaPreviews?.length - 1 : mediaPreviews?.length,
    [sendMessage, mediaPreviews]
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _toggleTextDetectModal = (isOpen = false) => {
    setTextDetectModal({ isOpen });
  };

  const _scrollToBottomInChatWindow = (isHalf = false) => {
    if (!chatWindowRef?.current?.scrollHeight) return;

    if (isHalf) {
      setTimeout(() => {
        chatWindowRef.current.scrollTop =
          chatWindowRef.current.scrollHeight - prevScrollHeight.current;

        prevScrollHeight.current = chatWindowRef.current.scrollHeight;
      }, 0);
      return;
    }

    setTimeout(() => {
      prevScrollHeight.current = chatWindowRef.current.scrollHeight;
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }, 0);
  };

  const _sendMessageError = (message) => {
    try {
      if (!message) return;

      setGroupMessages((prev) => {
        const findMessage = prev.find(
          (each) => each.broadCastId === message.broadCastId
        );
        if (findMessage) {
          findMessage.isError = true;
        }
        return prev;
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _markMessageAsSent = (message) => {
    try {
      setGroupMessages((prev) => {
        const findMessage = prev.find(
          (each) => each.broadCastId === message.broadCastId
        );
        if (findMessage) {
          findMessage.isUploading = false;
        }
        return prev;
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _publishMessageOnChannel = async (message) => {
    try {
      if (!selectedGroupThread?._id) {
        _sendMessageError(message);
        return;
      }

      _manageLoadingState("sendGroupMessage", true);

      const payload = {
        message,
        _group: selectedGroupThread?._id,
      };

      await sendGroupMessage(payload);

      _markMessageAsSent(message);

      setTimeout(() => {
        dispatch(
          updateSelectedGroupThreadKey({
            thread: selectedGroupThread,
            key: "lastMessage",
            value: message,
          })
        );
      }, 0);
    } catch (error) {
      _sendMessageError(message);
      errorHandler(error);
    } finally {
      _manageLoadingState("sendGroupMessage", false);
    }
  };

  const _appendAndPublishNewMessage = (message) => {
    if (message.hasMedia) {
      const contentsObj = Object.keys(message.media).map((e) => {
        return {
          _id: message.media[e]._id,
          contentType: message.media[e].contentType,
          url: message.media[e].url,
          hlsLink: message.media[e].hls,
          thumbnail: message.media[e].thumbnail,
        };
      });

      message["content"] = contentsObj.map((e) => {
        return {
          _contentId: {
            _id: e._id,
            url: e.url,
            hlsLink: e.hls,
            thumbnail: e.thumbnail,
            contentType: e.contentType,
            aspectRatio: e.aspectRatio,
          },
          _id: e._id,
          mediaType: e.contentType,
          // thumbnail: e.thumbnail,
          isPublic: message.isPublic,
          amount: message.amount,
        };
      });
    }

    _scrollToBottomInChatWindow();

    _publishMessageOnChannel(message);
  };

  const _sendNewMessage = async () => {
    try {
      if (!selectedGroupThread?._members?.length) {
        showToast(
          "This tag has no recipients. Add members to send the message.",
          "error"
        );
        return;
      }
      if (loadingState.messages) {
        return;
      }

      const { images, videos, audios, amount, text } = sendMessage;

      _toggleIsShowEmoji();

      let messageText = text?.trim().length ? text.trim() : null;

      const broadCastId = cuid();

      const messagesToBeSent = {};

      // Prepare outbox queue
      // Iterate through images
      // Iterate through images videos audios
      if (
        Object.keys(images).length ||
        Object.keys(videos).length ||
        Object.keys(audios).length
      ) {
        messagesToBeSent[broadCastId] = {
          // _from: selectedGroupThread?.influencer?._id,
          // _to: selectedGroupThread?.fan?._id,
          when: new Date(),
          isPublic: amount && amount > 0 ? false : true,
          amount: amount && amount > 0 ? amount : 0,
          isUploading: true,
          media: {},
          hasMedia: true,
          broadCastId,
          isLocalMessage: true,
          isMyMessage: true,
          time: moment().format("h:mm A"),
        };

        messagesToBeSent[broadCastId]["media"] = {
          ...images,
          ...videos,
          ...audios,
        };
        if (messageText) {
          messagesToBeSent[broadCastId]["text"] = messageText;
          messageText = null;
        }
      }

      if (
        Object.keys(messagesToBeSent)?.length === 0 &&
        messageText &&
        amount &&
        amount > 0
      ) {
        showToast(
          "Text messages cannot be set as paid messages. You must either upload media or remove the payment request.",
          "error"
        );
        return;
      }

      if (Object.keys(messagesToBeSent)?.length === 0 && messageText) {
        messagesToBeSent[broadCastId] = {
          // _from: selectedGroupThread?.influencer?._id,
          // _to: selectedGroupThread?.fan?._id,
          when: new Date(),
          isUploading: true,
          hasMedia: false,
          broadCastId,
          isLocalMessage: true,
          isMyMessage: true,
          time: moment().format("h:mm A"),
        };
        if (messageText) {
          messagesToBeSent[broadCastId]["text"] = messageText;
          messageText = null;
        }
      }

      if (Object.keys(messagesToBeSent)?.length) {
        messagesToBeSent[broadCastId]["_group"] = selectedGroupThread?._id;

        setGroupMessages((prev) => {
          prev.push(messagesToBeSent[broadCastId]);
          return prev;
        });

        // Append as local messages in thread
        _appendAndPublishNewMessage(messagesToBeSent[broadCastId]);

        //   Clear new message
        setSendMessage({
          text: "",
          images: {},
          videos: {},
          audios: {},
          amount: 0,
        });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _onMessageTextInputKeyUp = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value.trim().length &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      // inputRef.current.blur();
      _sendNewMessage();
    }
  };

  const _onMessageTextInputChange = (newText, isEmoji) => {
    const newSendMessage = { ...sendMessage };

    if (containsRestrictedWords(newText)) {
      _toggleTextDetectModal(true);
      newText = removeLastWord(newText);
    }

    if (isEmoji) {
      newSendMessage.text = newSendMessage.text + newText;
    } else {
      newSendMessage.text = newText;
    }

    setSendMessage(newSendMessage);
  };

  const _toggleIsShowEmoji = (isShow = false) => {
    setIsShowEmoji(isShow);
  };

  const _onEmojiClick = (event, emojiObject) => {
    _onMessageTextInputChange(emojiObject.emoji, true);
  };

  const _removeMedia = (mediaFile) => {
    const newSendMessage = { ...sendMessage };
    if (mediaFile?.fileType === "priceTag") {
      newSendMessage.amount = 0;
    } else {
      delete newSendMessage[mediaFile.category][mediaFile.fileId];
    }
    setSendMessage(newSendMessage);
  };

  const _renderMediaPreview = useCallback((mediaFile) => {
    const _handleBrokenImageURL = handleBrokenImageURL();

    switch (mediaFile?.fileType) {
      case "image": {
        return (
          <>
            <img
              src={mediaFile?.previewBlob || mediaFile?.url}
              alt="Uploaded Img"
              loading="lazy"
              onError={(e) =>
                _handleBrokenImageURL({
                  event: e,
                  url: mediaFile.url,
                  fallbackImg: LOAD_ICON,
                })
              }
            />
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "video": {
        return (
          <>
            <video controlsList="nodownload" disablePictureInPicture>
              <source src={mediaFile?.previewBlob || mediaFile?.url} />
              Your browser does not support the video tag.
            </video>
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "audio": {
        return (
          <>
            <img
              src="/assets/img/microphone.png"
              alt="Microphone"
              style={{ height: 28, width: "unset" }}
              loading="lazy"
            />
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "priceTag": {
        return (
          <div>
            {mediaFile?.amount ? formatCurrencyValue(mediaFile.amount) : "-"}
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _closeMessagesCard = () => {
    dispatch(updateSelectedGroupThread(null));
  };

  const _toggleChatPriceSetterModal = (isOpen = false) => {
    setChatPriceSetterModal({ isOpen });
  };

  const _onAmountUpdate = (amount) => {
    const newSendMessage = { ...sendMessage };

    newSendMessage.amount = amount ? formatDecimalNumber(+amount) : 0;

    setSendMessage(newSendMessage);

    _scrollToBottomInChatWindow();
  };

  const _toggleMediaLibraryModal = (isOpen = false, data = null) => {
    setMediaLibraryModal({ isOpen, data });
  };

  const _toggleSoundRecordUploadModal = (isOpen = false) => {
    setSoundRecordUploadModal({ isOpen });
  };

  const _toggleFullScreenVideoRecorder = (isOpen = false) => {
    setFullScreenVideoRecorder({ isOpen });
  };

  const _onRecordingComplete = async (blobObject) => {
    try {
      _toggleFullScreenVideoRecorder();

      if (!blobObject) return;

      const res = await uploadFileOnServer([
        {
          uploadData: blobObject,
          previewBlob: blobObject,
          type: "video",
        },
      ]);

      if (!res?.length) {
        return;
      }

      const { contents } = await saveContents({
        contents: [{ contentType: res[0].contentType, url: res[0].url }],
      });

      if (!contents?.length) {
        return;
      }

      const newSendMessage = { ...sendMessage };

      const videoId = cuid();

      newSendMessage.videos[videoId] = {
        blobURL: blobObject.blobURL,
        type: blobObject.type,
        contentType: contents[0].contentType,
        url: contents[0].url,
        hlsLink: contents[0].hls,
        thumbnail: contents[0].thumbnail,
        _id: contents[0]._id,
      };

      setSendMessage(newSendMessage);

      _scrollToBottomInChatWindow();
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onAudioRecordingStop = async (blobObject) => {
    try {
      _toggleSoundRecordUploadModal();

      if (!blobObject?.blob) return;

      const res = await uploadFileOnServer([
        {
          uploadData: blobObject.blob,
          previewBlob: blobObject.blobURL,
          type: "audio",
        },
      ]);

      if (!res?.length) {
        return;
      }

      const { contents } = await saveContents({
        contents: [{ contentType: res[0].contentType, url: res[0].url }],
      });

      if (!contents?.length) {
        return;
      }

      const newSendMessage = { ...sendMessage };

      const audioId = cuid();

      newSendMessage.audios[audioId] = {
        blobURL: blobObject.blobURL,
        type: blobObject.type,
        contentType: contents[0].contentType,
        url: contents[0].url,
        hlsLink: contents[0].hls,
        thumbnail: contents[0].thumbnail,
        _id: contents[0]._id,
      };

      setSendMessage(newSendMessage);

      _scrollToBottomInChatWindow();
    } catch (error) {
      errorHandler(error);
    }
  };

  const _toggleEditGroupModal = (isOpen = false) => {
    setIsOpenEditGroupModal({ isOpen });
  };

  const _toggleViewChatMediaModal = (isOpen = false) => {
    setViewChatMediaModal({ isOpen });
  };

  const _formatMessageContent = async (contents) => {
    if (!contents?.length) {
      return;
    }

    const newSendMessage = { ...sendMessage };
    const currentTimeStamp = +new Date();

    contents.forEach((each, index) => {
      if (each.contentType.indexOf("image") > -1) {
        const imageId = currentTimeStamp + "" + index;
        newSendMessage.images[imageId] = each;
      } else if (each.contentType.indexOf("video") > -1) {
        const videoId = currentTimeStamp + "" + index;
        newSendMessage.videos[videoId] = each;
      } else if (each.contentType.indexOf("audio") > -1) {
        const audioId = currentTimeStamp + "" + index;
        newSendMessage.audios[audioId] = each;
      } else {
        alert("Only Image, Video and Audio are allowed");
      }
    });

    setSendMessage(newSendMessage);

    _scrollToBottomInChatWindow();
  };

  const _getAndUpdateMessages = async (payload, isResetScroll = false) => {
    try {
      _manageLoadingState("messages", true);

      if (isResetScroll) {
        _scrollToBottomInChatWindow();
      }

      const res = await getAllGroupMessages(selectedGroupThread?._id, payload);

      const messages = res?.group?._messages?.length
        ? res?.group?._messages?.map((each) => ({
            ...each,
            time: moment(each.timestamp).format("h:mm A"),
            isMyMessage: true,
            hasMedia: each.content?.length ? true : false,
            isPublic: each?.content?.[0]?.amount > 0 ? false : true,
          }))
        : [];

      // messages.sort((a, b) =>
      //   new Date(a.timestamp) > new Date(b.timestamp) ? 1 : -1
      // );

      messages.reverse();

      setGroupMessages((prev) => {
        if (payload.skip !== 0) {
          return messages?.length ? messages.concat(prev) : prev;
        }
        return messages || [];
      });
      setGroupMessagesCount(res?.totalCount || 0);

      _scrollToBottomInChatWindow(!isResetScroll);

      setTimeout(() => {
        _manageLoadingState("messages", false);
      }, 0);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("messages", false);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.messages) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          groupMessages?.length < groupMessagesCount
        ) {
          const newMessagesPayload = { ...messagesPayload };
          newMessagesPayload.skip = groupMessages?.length || 0;
          setMessagesPayload(newMessagesPayload);
          _getAndUpdateMessages(newMessagesPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.messages, groupMessages, groupMessagesCount]
  );

  const _initialiseNewChat = () => {
    _toggleIsShowEmoji();

    setSendMessage({
      text: "",
      images: {},
      videos: {},
      audios: {},
      amount: 0,
    });

    const newMessagesPayload = { ...messagesPayload };
    newMessagesPayload.skip = 0;
    setMessagesPayload(newMessagesPayload);
    _getAndUpdateMessages(newMessagesPayload, true);
  };

  const _onDropFile = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _toggleMediaLibraryModal(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (selectedGroupThread?._id) {
      setGroupMessages([]);
      _initialiseNewChat();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupThread?._id]);

  return (
    <>
      <div
        className={`conversationWeb ${
          screenWidth < 768 && !selectedGroupThread ? "d-none" : ""
        } ${isShow === false ? "d-none" : ""}`}
      >
        {selectedGroupThread ? (
          <Fragment>
            <div>
              <div className="addMessageWrap">
                <div className="receiverInfo-Chat">
                  <div className="d-flex align-items-center">
                    <Button
                      className="customBackBtn mr-1"
                      onClick={() => _closeMessagesCard()}
                    >
                      <i className="fa fa-chevron-left" />
                    </Button>

                    <img
                      src={
                        getLowResolutionLink(
                          selectedGroupThread?.profilePicture
                        ) || DEFAULT_GROUP_PICTURE
                      }
                      onError={(e) => (e.target.src = DEFAULT_GROUP_PICTURE)}
                      alt="Profile Img"
                      className="profileMsgPg"
                      loading="lazy"
                    />

                    <div className="receiverName-Chat">
                      <div className="d-flex">
                        <h4>{capitalize(selectedGroupThread?.name)} </h4>
                        {selectedGroupThread?._members?.length ? (
                          <>
                            <div className="tagsListConversation">
                              <div>
                                {getFullName(
                                  selectedGroupThread?._members?.[0]?.name
                                )}
                              </div>
                              {/* if tags is more than 1, then show like this */}
                              {selectedGroupThread?._members?.length >= 2 ? (
                                <>
                                  <span id="tagsList">
                                    +{selectedGroupThread?._members?.length - 1}
                                  </span>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="tagsList"
                                    className="tagsListTooltip"
                                  >
                                    <ListGroup>
                                      {selectedGroupThread?._members
                                        ?.slice(1)
                                        ?.map((each) => (
                                          <ListGroupItem
                                            key={`message_tags_list_${each._id}`}
                                          >
                                            {getFullName(each.name)}
                                          </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                  </UncontrolledTooltip>
                                </>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-center"
                    style={{ flexShrink: 0 }}
                  >
                    <Button
                      color="link"
                      className="seeMedia"
                      onClick={() => _toggleEditGroupModal(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      color="link"
                      className="seeMedia"
                      onClick={() => _toggleViewChatMediaModal(true)}
                    >
                      Media
                    </Button>
                  </div>
                </div>

                <div className="conversationDetails" ref={chatWindowRef}>
                  {/* chat timeline */}

                  {groupMessages?.length ? (
                    groupMessages.map((message, messageIndex) => (
                      <Fragment
                        key={`conversation_message_item_${messageIndex}`}
                      >
                        <ErrorBoundary>
                          <MessageItem
                            {...(messageIndex === 0 ? { lastElementRef } : {})}
                            messageData={message}
                            loading={loadingState.messages}
                            prevDateHeading={generateCalenderDateForChat(
                              groupMessages?.[messageIndex - 1]?.timestamp
                            )}
                            currDateHeading={generateCalenderDateForChat(
                              message?.timestamp
                            )}
                            startCall={() => {}}
                          />
                        </ErrorBoundary>
                      </Fragment>
                    ))
                  ) : loadingState?.messages ? (
                    <SkeletonLoading type={"conversation"} count={2} />
                  ) : (
                    <div />
                  )}
                </div>

                {isPermissionToAccess("messaging", "canSendMessaging") ? (
                  <div
                    id="sendMessageContainer"
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => _onDropFile(e)}
                  >
                    {/* chatHistory */}
                    <div className="typeMsgWrap">
                      {userData.user?.isScheduleForAccountDelete === true ||
                      groupMessages?.isBlocked === true ||
                      groupMessages?.isCountryBlocked === true ||
                      groupMessages?.influencer?.isActive === false ||
                      groupMessages?.influencer?.isScheduleForAccountDelete ===
                        true ? (
                        <div className="text-center w-100">
                          <p>
                            {loadingState?.messages
                              ? "Loading..."
                              : "You can not send messages"}
                          </p>
                        </div>
                      ) : (
                        <>
                          {mediaPreviews.length ? (
                            <div className="msgPreviewWrap mb-2 mt-0">
                              {mediaPreviews.map((media, mediaIndex) => (
                                <div className="msgPreview" key={mediaIndex}>
                                  {_renderMediaPreview(media)}
                                </div>
                              ))}
                            </div>
                          ) : null}

                          {isShowEmoji ? (
                            <EmojiPickerReact
                              onEmojiClick={_onEmojiClick}
                              skinTone={SKIN_TONE_NEUTRAL}
                            />
                          ) : null}

                          <div className="position-relative">
                            <TextareaAutosize
                              style={autoHeightStyleChat}
                              placeholder="Type a message..."
                              value={sendMessage.text}
                              onChange={(e) =>
                                _onMessageTextInputChange(e.target.value)
                              }
                              onKeyUp={(e) => _onMessageTextInputKeyUp(e)}
                            />

                            <Button
                              color="link"
                              className="emojiBtnMsg"
                              onClick={() =>
                                _toggleIsShowEmoji(isShowEmoji ? false : true)
                              }
                            >
                              <i className="fa fa-smile-o" />
                            </Button>

                            <Button
                              disabled={
                                fileCount || sendMessage.text?.trim().length
                                  ? false
                                  : true
                              }
                              className="sendMsg"
                              onClick={_sendNewMessage}
                            >
                              {loadingState.messages ? (
                                <i className="fa fa-spinner fa-spin mr-1" />
                              ) : (
                                <img
                                  src="/assets/img/send.png"
                                  alt="Send"
                                  loading="lazy"
                                />
                              )}
                            </Button>
                          </div>

                          <div className="createPostBtnsWrapper mt-0 mb-1">
                            <div className="createPostBtnsWrap">
                              <div className="d-flex">
                                <Label className="btn mediaBtn">
                                  <img
                                    src="/assets/img/img-gallery-active.png"
                                    alt="Img"
                                    onClick={() =>
                                      _toggleMediaLibraryModal(true)
                                    }
                                    loading="lazy"
                                  />
                                </Label>

                                {/* on clicking the below btn, an audio recorder feature should be shown, for this a modal has been added  */}
                                {!isFromSafari() ? (
                                  <Button
                                    className="mediaBtn"
                                    onClick={() =>
                                      _toggleSoundRecordUploadModal(true)
                                    }
                                  >
                                    <img
                                      src="/assets/img/microphone-feed.png"
                                      alt="Microphone"
                                      className="audioIcon"
                                      loading="lazy"
                                    />
                                  </Button>
                                ) : null}

                                {/* on clicking the below btn, an video recorder feature should be shown.  */}
                                <Button
                                  className="mediaBtn"
                                  onClick={() =>
                                    _toggleFullScreenVideoRecorder(true)
                                  }
                                >
                                  <img
                                    src="/assets/img/video-gallery-active.png"
                                    alt="Video"
                                    className="videoIcon"
                                    loading="lazy"
                                  />
                                </Button>
                                {/* the below btn will show 2 modals, "Message Price" & "Enter Tip Amount" */}
                                <Button
                                  className="mediaBtn ml-1"
                                  onClick={() =>
                                    _toggleChatPriceSetterModal(true)
                                  }
                                  disabled={
                                    userData?.user?.hasOwnProperty(
                                      "paymentEnabled"
                                    ) && !userData?.user?.paymentEnabled
                                  }
                                >
                                  {/* hide this img for subscriber/ show this icon for creator. When this icon is there show "Message Price" modal */}
                                  <img
                                    src="/assets/img/price.png"
                                    alt="Price"
                                    className="priceIcon"
                                    style={{ height: 23, marginTop: 0 }}
                                    loading="lazy"
                                  />
                                </Button>
                              </div>

                              {fileCount > 0 ? (
                                <div
                                  className="filesSelected-CreatePost my-0"
                                  style={{ fontWeight: "500" }}
                                >
                                  {fileCount} {fileCount > 1 ? "files" : "file"}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <FullScreenVideoRecorder
              isOpen={fullScreenVideoRecorder.isOpen}
              toggle={() => _toggleFullScreenVideoRecorder()}
              // onStartRecording={_onStartRecording}
              onRecordingComplete={(blobObject) =>
                _onRecordingComplete(blobObject)
              }
            />

            <ChatPriceSetterModal
              isOpen={chatPriceSetterModal.isOpen}
              toggle={() => _toggleChatPriceSetterModal()}
              onSave={(amount) => _onAmountUpdate(amount)}
              amount={sendMessage?.amount || ""}
            />

            <SoundRecordUploadModal
              isOpen={soundRecordUploadModal.isOpen}
              toggle={() => _toggleSoundRecordUploadModal()}
              onSave={(blobObject) => _onAudioRecordingStop(blobObject)}
              isHideUploadAudioFile={true}
            />

            {isOpenEditGroupModal?.isOpen ? (
              <EditGroupModal
                isOpen={isOpenEditGroupModal.isOpen}
                toggle={() => _toggleEditGroupModal()}
                members={selectedGroupThread?._members}
                groupName={selectedGroupThread?.name}
                groupId={selectedGroupThread?._id}
                profilePic={selectedGroupThread?.profilePicture}
              />
            ) : null}

            <MediaLibraryModal
              isOpen={mediaLibraryModal.isOpen}
              dropFileData={mediaLibraryModal?.data}
              toggle={() => _toggleMediaLibraryModal()}
              uploadContents={(contents) => _formatMessageContent(contents)}
              isMultipleImages={true}
              isMultipleVideos={true}
              isMultipleAudios={true}
              isMultipleUpload={true}
              isAudioUpload={true}
              isVideoUpload={true}
              isImageUpload={true}
              shouldUploadInModal={true}
            />
          </Fragment>
        ) : (
          <div className="selectChatUserWeb">
            <i className="fa fa-users" />

            <p>Select a group</p>
          </div>
        )}
      </div>

      <ViewChatMediaModal
        isOpen={viewChatMediaModal.isOpen}
        toggle={() => _toggleViewChatMediaModal()}
        threadId={selectedGroupThread?._id}
        groupId={selectedGroupThread?._id}
      />

      <TextDetectModal
        isOpen={textDetectModal.isOpen}
        toggle={() => _toggleTextDetectModal()}
      />
    </>
  );
};

export default GroupConversationCard;
