import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Row, Col, Table } from "reactstrap";
import { getPPV } from "../http-calls";
import {
  errorHandler,
  formatCurrencyValue,
  getPostedDateValue,
  getPostedTimeValue,
  getFullName,
  isSubscriptionPriceExist,
} from "../helper-methods";
import { useSelector } from "react-redux";
import { DEFAULT_COVER_PICTURE } from "../config";
import VisibilitySensor from "react-visibility-sensor";
import ConfigureString from "../components/ConfigureString";
import { useHistory, useParams } from "react-router-dom";
import { useMemo } from "react";
import CustomLoader from "../components/custom/CustomLoader";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import AudioPlayer from "../components/custom/AudioPlayer";
import CustomVideoPlayer from "../components/custom/CustomVideoPlayer";
import SkeletonLoading from "../components/SkeletonLoading";

const PayPerViewDetailsPage = () => {
  const history = useHistory();

  const params = useParams();

  const userData = useSelector((state) => state?.userData);

  const previewVideoTimerRef = useRef({ current: null });

  const [ppvData, setPpvData] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [isVideoDisplay, setIsVideoDisplay] = useState(false);
  const [isPlayVideoPreview, setIsPlayVideoPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const multiTierSubscription = useMemo(() => {
    return userData?.user?.multiTierSubscription;
  }, [userData]);

  const _showVideo = () => {
    setIsVideoDisplay(true);
  };

  const _startStopPPVPreviewVideo = (isPlayVideoPreview = false) => {
    if (ppvData?.previewVideoUrl || ppvData?.previewHls) {
      setIsPlayVideoPreview(isPlayVideoPreview);
    }
  };

  const _onChangeVisibility = (isVisible) => {
    if (previewVideoTimerRef?.current)
      clearTimeout(previewVideoTimerRef.current);
    if (ppvData?.previewVideoUrl || ppvData?.previewHls) {
      if (isVisible) {
        previewVideoTimerRef.current = setTimeout(() => {
          _startStopPPVPreviewVideo(true);
        }, 4000);
      } else {
        _startStopPPVPreviewVideo(false);
      }
    }
  };

  const _renderPrice = () => {
    if (ppvData && !multiTierSubscription && ppvData.price) {
      return (
        <div className="eventPrice">
          {ppvData.price ? (
            <div>
              Price: <span>{formatCurrencyValue(ppvData?.price)}</span>
            </div>
          ) : null}
        </div>
      );
    }

    if (
      ppvData &&
      ppvData.price &&
      !ppvData.plusPrice &&
      !ppvData.premiumPrice
    ) {
      return (
        <div className="eventPrice">
          {ppvData.price ? (
            <div>
              Price: <span>{formatCurrencyValue(ppvData.price)}</span>
            </div>
          ) : null}
        </div>
      );
    }

    if (
      ppvData &&
      (ppvData.price || ppvData.plusPrice || ppvData.premiumPrice)
    ) {
      return (
        <div className="eventPrice">
          {isSubscriptionPriceExist("premium") && (
            <div>
              <i className="fa fa-star" style={{ color: "#FFC107" }} /> Premium:{" "}
              <span>
                {ppvData.premiumPrice
                  ? formatCurrencyValue(ppvData.premiumPrice)
                  : "FREE"}
              </span>
            </div>
          )}
          {isSubscriptionPriceExist("plus") && (
            <div>
              <i className="fa fa-star" style={{ color: "#2196F3" }} /> Plus:{" "}
              <span>
                {ppvData.plusPrice
                  ? formatCurrencyValue(ppvData.plusPrice)
                  : "FREE"}
              </span>
            </div>
          )}
          <div>
            <i className="fa fa-star" style={{ color: "#828282" }} /> Basic:{" "}
            <span>
              {ppvData.price ? formatCurrencyValue(ppvData.price) : "FREE"}
            </span>
          </div>
        </div>
      );
    }
    return "FREE";
  };

  const _getPPVDetails = async () => {
    try {
      setLoading(true);

      const res = await getPPV(params?.id);

      setPpvData(res?.ppv);

      setTransactions(res?.transactions?.length ? res.transactions : []);

      setLoading(false);
    } catch (error) {
      history.push("/ppv");
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    _getPPVDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin mb-2">
          <Col sm={12} md={11} lg={10} className="noPadding">
            <div className="pgTitle justify-content-start customMarginMob">
              <Button
                className="customBackBtn"
                onClick={() => history.push("/ppv")}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              <h2>
                <ConfigureString keyString="ppv Details" />
              </h2>
              {loading ? <CustomLoader className="mx-2" /> : null}
            </div>

            {ppvData ? (
              <>
                <div className="ppvPreviewStatsWrap">
                  <div className="mediaVideoWrap-Feed">
                    {!isVideoDisplay ? (
                      <div>
                        <VisibilitySensor
                          onChange={(isVisible) =>
                            _onChangeVisibility(isVisible)
                          }
                        >
                          {(ppvData?.previewVideoUrl || ppvData?.previewHls) &&
                          isPlayVideoPreview ? (
                            <>
                              {/* custom video player support hls */}
                              <CustomVideoPlayer
                                // className={`${getAspectRatioClass(
                                //   ppvData?.previewVideoUrlAspectRatio
                                // )}`}
                                onEnded={() => _startStopPPVPreviewVideo()}
                                src={ppvData?.previewVideoUrl}
                                hlsLink={ppvData?.previewHls}
                                duration={ppvData?.previewVideoUrlDuration}
                                autoPlay
                                muted
                              />
                              <div className="videoPreviewTag">
                                Video Preview
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="shadowOverlayImg" />
                              <img
                                className="mediaPhoto-Feed"
                                src={
                                  ppvData?.coverImage || DEFAULT_COVER_PICTURE
                                }
                                alt="PPV Cover Img"
                                loading="lazy"
                              />
                            </>
                          )}
                        </VisibilitySensor>

                        <Button
                          className="videoPlayBtn"
                          onClick={() => _showVideo()}
                        >
                          <i className="fa fa-play" />
                        </Button>
                      </div>
                    ) : ppvData.contentType === "audio" ? (
                      <AudioPlayer url={ppvData.videoUrl} autoPlay />
                    ) : (
                      <>
                        {/* custom video player support hls */}
                        <CustomVideoPlayer
                          // className={`${getAspectRatioClass(
                          //   ppvData?.aspectRatio
                          // )}`}
                          src={ppvData?.videoUrl}
                          hlsLink={ppvData?.hls}
                          thumbnail={ppvData?.coverImage}
                          feedId={ppvData?._id}
                          duration={ppvData?.duration}
                          pictureInPictureMode
                          fullScreenMode
                          autoPlay
                          controls
                        />
                      </>
                    )}
                  </div>

                  <div className="ppvDetailsWrap">
                    <h5 className="ppvVideoName">{ppvData?.title}</h5>

                    <div className="purchaseNViewsWrap">
                      <div className="mr-auto">
                        <span>Price</span>
                        {/* For ppv Price */}
                        <div
                          className="eventPriceWrap"
                          style={{ marginTop: 2 }}
                        >
                          {_renderPrice()}
                        </div>
                      </div>

                      <div>
                        <span>{ppvData?.viewCount > 1 ? "Views" : "View"}</span>

                        <p>{ppvData?.viewCount}</p>
                      </div>
                    </div>

                    {ppvData?.description?.length ? (
                      <ReadMoreReadLess
                        className="readMore"
                        text={ppvData?.description}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <SkeletonLoading type="box" count={1} height={400} />
            )}

            <div className="ppvPreviewStatsWrap my-4 my-sm-3">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Subscriber</th>
                    <th>Purchase</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.length ? (
                    transactions?.map((transaction, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex justify-content-start align-items-center">
                            {transaction?.tier?.length ? (
                              <img
                                src={`/assets/img/${transaction.tier}-tier.png`}
                                alt="Tier"
                                className="tier-icon"
                                loading="lazy"
                              />
                            ) : null}

                            <span>{getFullName(transaction?._from?.name)}</span>
                          </div>
                        </td>
                        <td>
                          {transaction?.amount
                            ? formatCurrencyValue(transaction.amount)
                            : "N/A"}
                        </td>
                        <td>
                          {transaction?.time
                            ? `${getPostedDateValue(
                                transaction.time
                              )}, ${getPostedTimeValue(transaction.time)}`
                            : "N/A"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <div className="noContentFound my-0">
                          {loading ? (
                            <CustomLoader />
                          ) : (
                            "There is no fan to display."
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PayPerViewDetailsPage;
