import React, { useEffect, useRef, useState } from "react";
import { Collapse, Button } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../config/index";
// import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import MentionUserInput from "./MentionUserInput";
import Comments from "../containers/Comments";
import { useSelector } from "react-redux";
import SkeletonLoading from "./SkeletonLoading";
import { getLowResolutionLink } from "../helper-methods";

const PostCommentSection = ({
  isOpen,
  feed,
  feedIndex,
  comments,
  onComment,
  onLikeComment,
  postComment,
  getComments,
  isCommentFetching,
  type,
  canAddDeleteComment,
  isCommentsLikeLoading,
}) => {
  const user = useSelector((state) => state?.userData?.user);

  const [comment, setComment] = useState("");

  const _updateComment = (value, isEmoji = false) => {
    if (isEmoji) {
      setComment((prev) => prev + value);
    } else {
      setComment(value);
    }
  };

  const _clearComment = () => {
    setComment("");
  };

  // const _onEnterPressed = (event) => {
  //   const code = event.keyCode || event.which;

  //   if (code === 13 && !event.shiftKey && !event.ctrlKey) {
  //     if (event) event.preventDefault();

  //     if (comment?.trim()?.length) {
  //       const newComment = comment.replace(/\n/g, "<br/>");
  //       postComment(newComment.trim());
  //       _clearComment();
  //     }
  //   }
  // };

  const _postComment = () => {
    if (comment?.trim()?.length) {
      // const newComment = comment.replace(/\n/g, "<br/>");
      postComment(comment.trim());
      _clearComment();
    }
  };

  // const _onEmojiClick = (event, emojiObject) => {
  //   _updateComment(emojiObject.emoji, true);
  // };

  // _emojiSectionToggle = () => {
  //   setIsEmojiSectionShown(prev => !prev);
  // };
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (!textAreaRef?.current) {
      return;
    }

    setTimeout(() => {
      textAreaRef?.current?.focus();
    }, 0);
  }, [isOpen]);

  return (
    <>
      {/* comment section/accordion/collapse */}
      <Collapse isOpen={isOpen} className="commentSection">
        {/* textarea for comment */}
        {canAddDeleteComment
          ? user?.settings?.enableComments && (
              <div
                className="d-flex mt-2 position-relative"
                style={{ marginBottom: 10 }}
              >
                <img
                  src={
                    getLowResolutionLink(user?.profilePicture) ||
                    DEFAULT_PROFILE_PICTURE
                  }
                  onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                  alt="avatar"
                  className="userImg-Comment"
                  loading="lazy"
                />

                <MentionUserInput
                  textAreaRef={textAreaRef}
                  isShowEmoji={false}
                  placeholder="Add Comment..."
                  maxTextLength={200}
                  // rows={1}
                  value={comment}
                  onChange={(value) => _updateComment(value)}
                  // onKeyPress={(e) => _onEnterPressed(e)}
                  className="customPostCommentWrap"
                />

                {/* <Button
                  color="link"
                  className="emojiBtn"
                  onClick={_emojiSectionToggle}
                >
                  <i className="fa fa-smile-o" />
                </Button> */}

                <Button
                  className="sendMsg"
                  disabled={!comment.trim().length}
                  onClick={() => _postComment()}
                >
                  <img src="/assets/img/send.png" alt="Send" loading="lazy" />
                </Button>
              </div>
            )
          : null}

        {/* {isEmojiSectionShown ? (
          <Picker
            onEmojiClick={_emojiSectionToggle}
            skinTone={SKIN_TONE_NEUTRAL}
          />
        ) : null} */}

        {/* comments list */}
        {comments?.length ? (
          comments.map((comment, index) => (
            <React.Fragment key={`comment_${index}`}>
              <Comments
                comment={comment}
                isSubComment={false}
                getComments={() => getComments()}
                likeComment={(id, isLiked) => onLikeComment(id, isLiked)}
                isCommentsLikeLoading={isCommentsLikeLoading}
                feedIndex={feedIndex}
                onComment={(index, isAdded) => onComment(index, isAdded)}
                type={type || "post"}
                feed={feed}
                canAddDeleteComment={canAddDeleteComment}
              />
            </React.Fragment>
          ))
        ) : isCommentFetching ? (
          <SkeletonLoading type={"comments"} count={2} />
        ) : (
          <div className="text-center">
            <span>No comments yet</span>
          </div>
        )}
      </Collapse>
    </>
  );
};

export default PostCommentSection;
