import React, { useMemo, useState } from "react";
import { capitalize } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { CURRENCY_SYMBOL } from "../../config";
import {
  errorHandler,
  formatDate,
  isPermissionToAccess,
  showToast,
} from "../../helper-methods";
import { updateOffer } from "../../http-calls";
import CustomSvgIcons from "../CustomSvgIcons";
import AddEditOfferModal from "../modals/AddEditOfferModal";
import CustomPrompt from "../modals/CustomPrompt";
import SkeletonLoading from "../SkeletonLoading";
import CustomTooltip from "../custom/CustomTooltip";

const SettingsPromoOffers = ({
  bundles,
  offers,
  setOffers,
  getAllOffers,
  loading,
}) => {
  const influencer = useSelector((state) => state?.userData?.user);

  const [offerModal, setOfferModal] = useState({
    isOpen: false,
    data: null,
  });
  const [promptModal, setPromptModal] = useState({
    isOpen: false,
    data: "",
  });

  const canUpdateSubscriptionSettings = useMemo(() => {
    return isPermissionToAccess(
      "profileAndSettings",
      "canUpdateSubscriptionSettings",
      true
    );
  }, []);

  const _toggleOfferModal = (isOpen = false, data = {}) => {
    setOfferModal({ isOpen, data });
  };

  const _togglePromptModal = (isOpen = false, data = null) => {
    setPromptModal({ isOpen, data });
  };

  const _toggleOfferStatus = async (data) => {
    try {
      const payload = {
        isActive: !data?.isActive,
      };

      const newOffers = [...offers];
      const findOffer = newOffers.find((each) => each?._id === data?._id);
      if (findOffer) {
        findOffer.isActive = payload.isActive;
        setOffers(newOffers);
      }

      await updateOffer(data._id, payload);

      getAllOffers();

      showToast("Promo Offer has been updated", "success");
    } catch (error) {
      errorHandler(error);
      getAllOffers();
    }
  };

  const _deleteOffer = async (data) => {
    try {
      _togglePromptModal();

      const payload = {
        isDeleted: true,
      };

      const newOffers = [...offers];
      const findOfferIndex = newOffers.findIndex(
        (each) => each?._id === data?._id
      );
      if (findOfferIndex >= 0) {
        newOffers.splice(findOfferIndex, 1);
        setOffers(newOffers);
      }

      await updateOffer(data._id, payload);

      getAllOffers();

      showToast("Promo Offer has been deleted", "success");

      _togglePromptModal();
    } catch (error) {
      errorHandler(error);
      getAllOffers();
    }
  };

  return (
    <>
      <div
        className="header_Settings"
        style={{ marginTop: 35, marginBottom: 15 }}
      >
        <span className="d-flex">
          <h4>Promo Offers</h4>

          <sup id={`promo-offer-tooltip`} className="infoIcon">
            <i className="fa fa-info-circle" />
          </sup>
          <CustomTooltip
            text="These offers are supposed to be more affordable and available for a certain time period or sales count"
            target={`promo-offer-tooltip`}
            placement="right"
          />
        </span>

        {canUpdateSubscriptionSettings ? (
          <Button
            className="themeBtn addBtn"
            onClick={() => _toggleOfferModal(true)}
          >
            Add
          </Button>
        ) : null}
      </div>

      <Table responsive className="subscriptionTable">
        <thead>
          <tr>
            {influencer?.multiTierSubscription && <th>Tier</th>}
            <th>Start & End Date</th>
            <th>Subscription</th>
            <th>No. of Sales</th>
            <th>Cost</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {offers?.length ? (
            offers.map((each, index) => (
              <tr key={index}>
                {influencer?.multiTierSubscription && (
                  <td>{capitalize(each.tier) || ""}</td>
                )}
                {each.start && each.end ? (
                  <td>
                    <span>{formatDate(each.start)}</span>
                    {" - "}
                    <span>{formatDate(each.end)}</span>
                  </td>
                ) : (
                  <td>{formatDate(each.start)}</td>
                )}

                <td>
                  {each?.subscriptionPeriod
                    ? `${each?.subscriptionPeriod / 30} ${
                        each?.subscriptionPeriod / 30 > 1 ? "Months" : "Month"
                      }`
                    : "N/A"}
                </td>
                <td>{each.salesLimit ? each.salesLimit : 0}</td>
                <td>{each.price ? `${CURRENCY_SYMBOL}${each.price}` : 0}</td>
                <td>
                  <div className="d-flex">
                    <Button
                      color="link"
                      size="sm"
                      disabled={!canUpdateSubscriptionSettings}
                      onClick={() => _toggleOfferModal(true, each)}
                    >
                      <CustomSvgIcons type="edit" />
                    </Button>
                    <Button
                      color="link"
                      size="sm"
                      disabled={!canUpdateSubscriptionSettings}
                      onClick={() => _togglePromptModal(true, each)}
                    >
                      <CustomSvgIcons type="trash" />
                    </Button>
                    <Button
                      color="link"
                      size="sm"
                      disabled={!canUpdateSubscriptionSettings}
                      onClick={() => _toggleOfferStatus(each)}
                    >
                      {each.isActive ? (
                        <CustomSvgIcons type="pause" />
                      ) : (
                        <i className="fa fa-play" />
                      )}
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          ) : loading ? (
            <SkeletonLoading type={"table"} rows={1} col={6} />
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No Promo Offer Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <AddEditOfferModal
        multiTierSubscription={influencer?.multiTierSubscription}
        isOpen={offerModal.isOpen}
        offerData={offerModal.data}
        offers={offers}
        bundles={bundles}
        toggle={() => _toggleOfferModal()}
        resetTableData={() => getAllOffers()}
      />

      <CustomPrompt
        isOpen={promptModal?.isOpen}
        metadata={promptModal?.data}
        message="Are you sure want to delete?"
        successButtonText={"Yes"}
        closeButtonText={"No"}
        onSuccess={(data) => _deleteOffer(data)}
        onDismiss={() => _togglePromptModal()}
      />
    </>
  );
};

export default SettingsPromoOffers;
