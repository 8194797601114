import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ReactInstaStories from "react-insta-stories";
import Swal from "sweetalert2";
import {
  errorHandler,
  showToast,
} from "../../helper-methods";
import colors from "../../assets/styles/scss/style.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { Button } from "reactstrap";
import ViewLikePreviewModal from "./ViewLikePreviewModal";
import { getAndUpdateChatThreads } from "../../redux/actions";

const customStyles = {
  content: {
    inset: "50% 0 0 50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 450,
    height: "100dvh",
    overflow: "hidden",
  },
};



const STORY_TIMEOUT = 15000;

const ViewStoryModal = ({ isOpen, data = [], toggle, deleteStory }) => {
  const profileData = useSelector((state) => state?.userData?.user);
  const [showModal, setShowModal] = useState(isOpen);
  const [modal, setModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch()

  const viewToggle = () => {
    setModal(!modal);

  };

  const _getAndUpdateChatThreads = async (payload) => {
    try {

      await getAndUpdateChatThreads(payload)(dispatch);

    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getAndUpdateChatThreads({ limit: 1000, skip: 0 })
  }, [])

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const _closeModal = () => {
    setShowModal(false);
    toggle();
  };

  const _deleteStoryAlert = (story) => {
    if (!story?._id && !story?.id) {
      showToast("Story Not found.", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this story?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors?.themeColor,
      cancelButtonColor: "#ccc",
      confirmButtonText: "Yes, Delete it!",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        await deleteStory(story);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Optional: Handle post-deletion actions here
      }
    });
  };

  const profile = {
    id: data[0]?._createdBy?._id || "",
    profilePicture: profileData?.profilePicture || DEFAULT_PROFILE_PICTURE,
    username: profileData?.name?.full || "Unknown User",
  };

  const stories = data?.map((item) => ({
    url: item?.type === "video" ? item?.storyVideoUrl : item?.previewImageUrl,
    type: item?.type === "video" ? "video" : "image",
    duration:
      item?.type === "video" ? item?.duration || STORY_TIMEOUT : STORY_TIMEOUT,
    createdAt: moment(item?.createdAt).calendar(),
    text: item?.text || "",
    like: item?.likedBy || [],
  }));

  const handleStoryChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={_closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="storyContainer">
        <ReactInstaStories
          stories={stories}
          width="100%"
          height="100dvh"
          loop
          preloadCount={stories.length}
          keyboardNavigation={true}
          storyContainerStyles={{
            background: "rgba(0, 0, 0, 0.9)",
          }}
          progressContainerStyles={{
            padding: "11px 0 0 0",
          }}
          progressWrapperStyles={{ background: "#666" }}
          progressStyles={{ background: "#bbb" }}
          storyStyles={{ width: "100vw" }}
          onAllStoriesEnd={() => _closeModal()}
          currentIndex={currentIndex} // Set the current story index
          onStoryChange={handleStoryChange}
        />
        <div className="storyProfile">
          <img
            src={profile.profilePicture}
            alt={profile.username}
            className="storyProfileUser"
          />
          <div className="flex-grow-1">
            <h5>{profile.username}</h5>
            <p>{stories[0]?.createdAt || ""}</p>
          </div>


          <div className="flex-shrink-0 ml-2 mr-1">
            {data.map((story, index) => (
              <Button
                key={index}
                color="link"
                className="delStory"
                onClick={() => _deleteStoryAlert(story)}
                aria-label={`Delete story ${index + 1}`}
              >
                <i className="fa fa-trash-o" />
              </Button>
            ))}

            <Button
              onClick={_closeModal}
              className="closeModalFeed"
              aria-label="Close"
            >
              <i className="fa fa-close modalFeed_closeIcon" />
            </Button>
          </div>
        </div>

        <ViewLikePreviewModal
          modal={modal}
          viewToggle={viewToggle}
          stories={stories}
          currentIndex={currentIndex}
          likedBy={stories[0]?.like}
        />

        {stories[0]?.text && (
          <div className="storyContent">
            <h5>{stories[0].text}</h5>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewStoryModal;
