import React, { Component } from "react";
import { Button, Label, UncontrolledTooltip } from "reactstrap";
import {
  deepClone,
  convertDate,
  formatCurrencyValue,
  handleBrokenImageURL,
  showToast,
} from "../helper-methods";
import MentionUserInput from "./MentionUserInput";
import CustomLoader from "./custom/CustomLoader";
import MediaLibraryModal from "./modals/MediaLibraryModal";
import IMAGE_ICON from "../assets/img/image_icon.svg";
import CustomTooltip from "./custom/CustomTooltip";

class CreatePost extends Component {
  state = {
    uploadImages: [],
    textContent: "",
    isTyped: false,
    isMediaLibraryModalOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.isTyped) {
      return {
        textContent:
          props.editedFeed && props.editedFeed.text
            ? props.editedFeed.text
            : "",
      };
    }
    return null;
  }

  _clearContent = () => {
    this.setState({ textContent: "" });
  };

  _initializeIsTyped = () => {
    this.setState({ isTyped: false });
  };

  _uploadFiles = (filesList) => {
    if (filesList?.length) {
      this.props.uploadFile(filesList);
    }

    this.setState({ formfieldChanged: true });
  };

  _updateFieldValue = (textContent = "") => {
    this.setState({
      isTyped: true,
      textContent,
      formfieldChanged: true,
    });
  };

  _getFileNumber = () => {
    const { selectedMedias, addFeedPreviewModal, selectedAudio } = this.props;

    let text = "";

    if (selectedMedias?.length) {
      let fileCount = selectedMedias.length;

      if (
        addFeedPreviewModal?.previewImageUrl?.previewBlob ||
        addFeedPreviewModal?.previewImageUrl?.uploadUrl
      )
        fileCount++;

      if (
        addFeedPreviewModal?.previewVideoUrl?.previewBlob ||
        addFeedPreviewModal?.previewVideoUrl?.uploadUrl
      )
        fileCount++;

      text =
        fileCount > 1 ? fileCount + " files added" : fileCount + " file added";
    } else if (selectedAudio) {
      text = "1 file added";
    }

    return text;
  };

  _onLoadedMetadata = (target, index) => {
    const { selectedMedias } = this.props;
    selectedMedias[index]["duration"] = target.duration;
  };

  _editContentRender = (media, index) => {
    media = media.hasOwnProperty("_content") ? media._content : media;
    if (
      (media.hasOwnProperty("contentType") &&
        media["contentType"] === "image") ||
      (media.type && media["type"].split("/")[0] === "image")
    ) {
      return (
        <div className="msgPreview" key={index}>
          <img
            key={index}
            src={
              media.hasOwnProperty("url") ? media["url"] : media["previewBlob"]
            }
            alt="Preview"
            loading="lazy"
          />
          <Button
            onClick={() => this.props.deleteContent(index)}
            className="deletePreview"
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      );
    } else if (
      (media.hasOwnProperty("contentType") &&
        media["contentType"] === "video") ||
      (media.type && media["type"].split("/")[0] === "video")
    ) {
      return (
        <div className="msgPreview" key={index}>
          <video
            key={index}
            src={
              media.hasOwnProperty("url") ? media["url"] : media["previewBlob"]
            }
            onLoadedMetadata={(e) => this._onLoadedMetadata(e.target, index)}
            controlsList="nodownload"
          />
          <Button
            onClick={() => this.props.deleteContent(index)}
            className="deletePreview"
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      );
    } else if (
      (media.hasOwnProperty("contentType") &&
        media["contentType"] === "audio") ||
      (media.type && media["type"].split("/")[0] === "audio")
    ) {
      return (
        <div className="msgPreview" key={index}>
          <img
            src="/assets/img/microphone.png"
            alt="Microphone"
            style={{ height: 28, width: "unset" }}
            loading="lazy"
          />
          <Button
            onClick={() => this.props.deleteContent(0)}
            className="deletePreview"
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      );
    }
    return <div key={index} />;
  };

  _onSubmit = () => {
    const { textContent } = deepClone(this.state);

    this.props.onPost(textContent);
    this.setState({ formfieldChanged: false });
  };

  _parseForTextField = (content) => {
    if (content.length) {
      return content.replace(/\r?<br\/>/g, "\n");
    }
    return "";
  };

  _showPreviewVideoBtn = () => {
    const { selectedMedias } = this.props;

    if (selectedMedias && selectedMedias.length) {
      let isVideoFileExist = selectedMedias.some(
        (media) =>
          (media &&
            media.type &&
            media.type.split("/") &&
            media.type.split("/")[0] &&
            media.type.split("/")[0] === "video") ||
          (media.contentType && media.contentType === "video")
      );

      if (isVideoFileExist) return true;
    }

    return false;
    // return true;
    // return false;
  };

  _isDisabledInWelcomeMessage = () => {
    const { isWelcomeMessage, selectedAudio, selectedMedias } = this.props;
    const { textContent, formfieldChanged } = this.state;

    return (
      isWelcomeMessage &&
      ((!selectedMedias?.length && !selectedAudio?.blobURL && !textContent) ||
        (!formfieldChanged && !selectedAudio?.blobURL))
    );
  };

  _toggleMediaLibraryModal = (
    isMediaLibraryModalOpen = false,
    isMediaLibraryModalData = null
  ) => {
    this.setState({
      isMediaLibraryModalOpen,
      isMediaLibraryModalData,
    });
  };

  _toggleAddPreviewModal = () => {
    const { postCost } = this.props;
    if (postCost) {
      this.props.toggleAddFeedPreviewModal(true);
    } else {
      showToast("Please add a price tag to upload video preview.");
    }
  };

  _handleBrokenImageURL = handleBrokenImageURL();

  _onDropFile = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      this._toggleMediaLibraryModal(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  render() {
    const { textContent, isMediaLibraryModalOpen, isMediaLibraryModalData } =
      this.state;

    const {
      userData,
      isForWelcomeMessage,
      isPollDisplay,
      isPreviewAdded,
      isScheduleDateDisplay,
      isExpiryDateDisplay,
      isCostDisplay,
      isShowGoalBtn,
      isShowNFTBtn,
      addFeedPreviewModal,
      editMode,
    } = this.props;

    return (
      <div className="createPostWrap" onDrop={(e) => this._onDropFile(e)}>
        {/* disabled (don't remove it) */}
        {/* <div className="position-relative">
          <Input
            type="textarea"
            value={this._parseForTextField(textContent)}
            onChange={(e) => this._updateFieldValue(e.target.value)}
            className="typeMessage"
            rows="6"
            placeholder={`What's on your mind, ${
              userData?.user?.name?.first
                ? `${userData.user.name.first.trim()}?`
                : userData?.user?.username
                ? `@${userData?.user?.username}?`
                : "?"
            }`}
            onFocus={() => this._onAboutFieldFocus(true)}
            onBlur={() => this._onAboutFieldFocus()}
          />
          <Button
            className="emojiBtnCreatePost"
            onClick={this._emojiSectionToggle}
          >
            <i className="fa fa-smile-o" />
          </Button>
        </div>
        {isIntroductionFieldFocus || isEmojiSectionShown ? (
          <div className="text-right" style={{ color: "#828282" }}>
            {200 - textContent?.length} left
          </div>
        ) : null}
        {isEmojiSectionShown ? (
          <Picker
            onEmojiClick={this.onEmojiClick}
            skinTone={SKIN_TONE_NEUTRAL}
          />
        ) : null} */}

        <div className="position-relative">
          <MentionUserInput
            isShowEmoji={true}
            placeholder={`What's on your mind, ${userData?.user?.name?.first
                ? `${userData.user.name.first.trim()}?`
                : userData?.user?.username
                  ? `@${userData?.user?.username}?`
                  : "?"
              }`}
            maxTextLength={1500}
            rows={5}
            value={this._parseForTextField(textContent)}
            onChange={(value) => this._updateFieldValue(value)}
          />
        </div>

        <div className="createPostBtnsWrapper">
          <div className="createPostBtnsWrap">
            <div className="d-flex">
              {/* on clicking the below btn, it opens the system/device gallery for image / video upload */}
              <Label className="btn mediaBtn" style={{ marginRight: 5 }}>
                {/* <input
                  type="file"
                  value=""
                  accept="image/*,video/*,.heic, .heif"
                  multiple={
                    !this.props.hasOwnProperty("isMultipleUpload") ||
                    (this.props.hasOwnProperty("isMultipleUpload") &&
                      this.props.isMultipleUpload)
                  }
                  onChange={this._readFile}
                  style={{ display: "none" }}
                  disabled={this.props.isPreparingPreview}
                /> */}
                <Button
                  className="d-none"
                  onClick={() => this._toggleMediaLibraryModal(true)}
                  disabled={
                    !isForWelcomeMessage &&
                    (this.props.editMode || this.props.postInProgress)
                  }
                />
                <img
                  src="/assets/img/icon-plus.png"
                  alt="Img"
                  style={{ height: 22 }}
                  loading="lazy"
                  id={`UploadFiles_${this.props.componentKey || ""}`}
                />
              </Label>
              <UncontrolledTooltip
                placement="bottom"
                target={`UploadFiles_${this.props.componentKey || ""}`}
                className="createPostTooltip"
              >
                Upload Files
              </UncontrolledTooltip>

              {/* className="videoIcon" */}

              {/* on clicking the below btn, an audio recorder feature should be shown, for this a modal has been added  */}
              <Button
                className="mediaBtn"
                id={`UploadAudio_${this.props.componentKey || ""}`}
                onClick={() => this.props.onClick(0)}
                style={{ marginRight: 5 }}
                disabled={
                  !isForWelcomeMessage &&
                  (this.props.editMode || this.props.postInProgress)
                }
              >
                <img
                  src="/assets/img/microphone-feed.png"
                  alt="Microphone"
                  className="audioIcon"
                  loading="lazy"
                />
              </Button>
              <UncontrolledTooltip
                placement="bottom"
                target={`UploadAudio_${this.props.componentKey || ""}`}
              >
                Upload Audio
              </UncontrolledTooltip>

              {/* on clicking the below btn, a modal opens to add poll options  */}
              {isPollDisplay ? (
                <>
                  <Button
                    className="mediaBtn"
                    id="CreatePoll"
                    onClick={() => this.props.onClick(2, textContent, true)}
                    disabled={this.props.editMode || this.props.postInProgress}
                  >
                    <img src="/assets/img/poll.png" alt="Poll" loading="lazy" />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="CreatePoll"
                    className="createPostTooltip"
                  >
                    Create Poll
                  </UncontrolledTooltip>
                </>
              ) : null}

              {/* on clicking the below btn, a modal opens asking for the expiration period for the poll  */}
              {isExpiryDateDisplay ? (
                <>
                  <Button
                    className="mediaBtn"
                    id="ExpirationPeriod"
                    onClick={() => this.props.onClick(1)}
                    disabled={this.props.postInProgress}
                  >
                    <img
                      src="/assets/img/clock.png"
                      alt="Clock"
                      loading="lazy"
                    />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="ExpirationPeriod"
                    className="createPostTooltip"
                  >
                    Set Expiration Period
                  </UncontrolledTooltip>
                </>
              ) : null}

              {/* on clicking the below btn, a calendar should open/show for scheduling the new post.  */}
              {isScheduleDateDisplay ? (
                <>
                  <Button
                    className="mediaBtn"
                    id="SchedulePost"
                    disabled={
                      this.props.editedFeed &&
                      this.props.editedFeed.postType !==
                      "scheduled-temporary" &&
                      this.props.editedFeed.postType !== "scheduled"
                    }
                    onClick={() => this.props.onClick(4)}
                  >
                    <img
                      src="/assets/img/calendar-feed.png"
                      alt="Calendar"
                      loading="lazy"
                    />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="SchedulePost"
                    className="createPostTooltip"
                  >
                    Schedule Post
                  </UncontrolledTooltip>
                </>
              ) : null}

              {/* on clicking the below btn, a modal opens asking for "Post Price" */}
              {isCostDisplay ? (
                <>
                  <Button
                    className="mediaBtn"
                    id={`AddPrice_${this.props.componentKey}`}
                    disabled={
                      (userData.user &&
                        userData.user.hasOwnProperty("paymentEnabled") &&
                        !userData.user.paymentEnabled) ||
                      (this.props.editedFeed &&
                        this.props.editedFeed.postType !==
                        "scheduled-temporary" &&
                        this.props.editedFeed.postType !== "scheduled") ||
                      (!this.props.isImage && !this.props.isSound)
                    }
                    onClick={() => this.props.onClick(3)}
                  >
                    <img
                      src="/assets/img/price.png"
                      alt="Price"
                      className="priceIcon"
                      loading="lazy"
                    />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`AddPrice_${this.props.componentKey}`}
                    className="createPostTooltip"
                  >
                    Add Price
                  </UncontrolledTooltip>
                </>
              ) : null}

              {/* clicking the below btn will open a modal where the creator will set his/her goal */}
              {isShowGoalBtn ? (
                <>
                  <Button
                    className="mediaBtn"
                    id="SetGoal"
                    disabled={
                      (userData.user &&
                        userData.user.hasOwnProperty("paymentEnabled") &&
                        !userData.user.paymentEnabled) ||
                      (!this.props.isImage && !this.props.isSound)
                    }
                    onClick={() => this.props.onClick(5)}
                  >
                    <img
                      src="/assets/img/set-goal.png"
                      alt="Goal"
                      loading="lazy"
                    />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="SetGoal"
                    className="createPostTooltip"
                  >
                    Set A Goal
                  </UncontrolledTooltip>
                </>
              ) : null}

              {/* clicking the below btn will open a modal where the creator will create a nft collection */}
              {isShowNFTBtn ? (
                <>
                  <Button
                    className="mediaBtn"
                    id="CreateNFTCollection"
                    onClick={() => this.props.onClick(6)}
                  >
                    <img
                      src="/assets/img/nft.png"
                      alt="NFT"
                      style={{ height: 23 }}
                      loading="lazy"
                    />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="CreateNFTCollection"
                    className="createPostTooltip"
                  >
                    Create NFT Collection
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>

            {this.props.isShowPreviewBtn && this._showPreviewVideoBtn() ? (
              <>
                <Button
                  color="link"
                  className="addPreviewVideo"
                  onClick={() => this.props.toggleAddFeedPreviewModal(true)}
                >
                  {editMode ? (isPreviewAdded || addFeedPreviewModal?.previewImageUrl?.previewBlob ? "Update" : "Add") : null} Preview
                </Button>
                <sup
                  id="feedpage_add_preview"
                  className="infoIcon"
                  style={{ top: 0 }}
                >
                  <i className="fa fa-info-circle" />
                </sup>
                <CustomTooltip
                  text={`Your fans will only see a preview within paid content. Therefore, we recommend adding a preview exclusively in the paid posts.`}
                  target="feedpage_add_preview"
                />
              </>
            ) : null}

            {this.props.isShowCancelBtn && editMode ? (
              <Button
                color="link"
                className="addPreviewVideo textWarning ml-2"
                onClick={() => {
                  this._clearContent();
                  this.props.cancelEditFeed();
                }}
              >
                Cancel
              </Button>
            ) : null}
          </div>

          {/* the below btn should be disabled by default   */}
          {this.props.isUpdateBtn ? (
            <Button
              className="themeBtn addBtn"
              onClick={this._onSubmit}
              disabled={
                (!this.props.editMode &&
                  !this.props.isPostButtonEnabled &&
                  !textContent.length) ||
                (this.props.editMode &&
                  !this.props.isPoll &&
                  this._getFileNumber() === null &&
                  !textContent.length) ||
                this._isDisabledInWelcomeMessage()
              }
            >
              {this.props.updateBtnText && this.props.updateBtnText.length
                ? this.props.updateBtnText
                : "Update"}
            </Button>
          ) : (
            <Button
              className="sendMsgBtn"
              onClick={this._onSubmit}
              disabled={
                (!this.props.editMode &&
                  !this.props.isPostButtonEnabled &&
                  !textContent?.trim().length) ||
                (this.props.editMode &&
                  !this.props.isPoll &&
                  !this._getFileNumber() &&
                  !textContent.length) ||
                this.props.postInProgress
              }
            >
              {this.props.postInProgress ? (
                <CustomLoader />
              ) : (
                <img
                  src="/assets/img/send-post.png"
                  alt="Send"
                  loading="lazy"
                />
              )}
            </Button>
          )}
        </div>

        {/* Media Uploaded Preview */}
        {this.props.editMode ? (
          <div className="msgPreviewWrap">
            {this.props.selectedMedias && this.props.selectedMedias.length
              ? this.props.selectedMedias.map((media, index) =>
                this._editContentRender(media, index)
              )
              : null}
            {this.props.selectedAudio ? (
              <div className="msgPreview">
                <img
                  src="/assets/img/microphone.png"
                  alt="Microphone"
                  style={{ height: 28, width: "unset" }}
                  loading="lazy"
                />
                <Button
                  onClick={() => this.props.deleteContent(0)}
                  className="deletePreview"
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {addFeedPreviewModal?.previewImageUrl?.previewBlob ||
              addFeedPreviewModal?.previewImageUrl?.uploadUrl ? (
              <div className="msgPreview">
                <img
                  src={
                    addFeedPreviewModal?.previewImageUrl?.previewBlob ||
                    addFeedPreviewModal?.previewImageUrl?.uploadUrl
                  }
                  alt="preview"
                  loading="lazy"
                />
                <Button
                  onClick={() => this.props.resetPreview("previewImageUrl")}
                  className="deletePreview"
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {addFeedPreviewModal?.previewVideoUrl?.previewBlob ||
              addFeedPreviewModal?.previewVideoUrl?.uploadUrl ? (
              <div className="msgPreview">
                <video
                  src={
                    addFeedPreviewModal?.previewVideoUrl?.previewBlob ||
                    addFeedPreviewModal?.previewVideoUrl?.uploadUrl
                  }
                  alt="preview"
                />
                <Button
                  onClick={() => this.props.resetPreview("previewVideoUrl")}
                  className="deletePreview"
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {this.props.isFreeContentForAll ? (
              <div className="msgPreview">
                <span title="Basic Price">Free For All</span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.resetFreeContentForAll()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {this.props.postCost ? (
              <div className="msgPreview">
                <span title="Basic Price">
                  {formatCurrencyValue(this.props.postCost)}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.deleteCost("postPrice")}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
            {/* plusPostPrice */}
            {this.props.plusPostPrice && this.props.plusPostPrice !== "0" ? (
              <div className="msgPreview">
                <span title="Plus Price">
                  {formatCurrencyValue(this.props.plusPostPrice)}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.deleteCost("plusPostPrice")}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
            {/* premiumPostPrice */}
            {this.props.premiumPostPrice &&
              this.props.premiumPostPrice !== "0" ? (
              <div className="msgPreview">
                <span title="Premium Price">
                  {formatCurrencyValue(this.props.premiumPostPrice)}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.deleteCost("premiumPostPrice")}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {/* Goal preview */}
            {this.props.goalAmount ? (
              <div className="msgPreview">
                <span title="Goal Price">
                  {formatCurrencyValue(this.props.goalAmount)}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.saveGoalPrice()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {/* nft preview */}
            {this.props.nftObject ? (
              <div className="msgPreview">
                <span title="Goal Price">
                  {this.props.nftObject.bitCoinType}{" "}
                  {this.props.nftObject.bitCoinCount}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.saveNftObject()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="msgPreviewWrap">
            {this.props.selectedMedias && this.props.selectedMedias.length
              ? this.props.selectedMedias.map((media, index) =>
                media?.contentType === "image" ||
                  media?.type === "image" ||
                  media?.contentType === "audio" ||
                  media?.type === "audio" ? (
                  <div className="msgPreview" key={index}>
                    {media?.contentType === "audio" ||
                      media?.type === "audio" ? (
                      <>
                        <img
                          src="/assets/img/microphone.png"
                          alt="Microphone"
                          style={{ height: 28, width: "unset" }}
                          loading="lazy"
                        />
                        <audio
                          key={index}
                          src={media["previewBlob"]}
                          onLoadedMetadata={(e) =>
                            this._onLoadedMetadata(e.target, index)
                          }
                        />
                      </>
                    ) : (
                      <img
                        key={index}
                        src={media?.url || media["previewBlob"]}
                        alt="preview"
                        loading="lazy"
                        onError={(e) =>
                          this._handleBrokenImageURL({
                            event: e,
                            url: media?.url,
                            fallbackImg: IMAGE_ICON,
                          })
                        }
                      />
                    )}
                    <Button
                      onClick={() => this.props.deleteContent(index)}
                      className="deletePreview"
                    >
                      <i className="fa fa-times" />
                    </Button>
                  </div>
                ) : (
                  <div className="msgPreview" key={index}>
                    <video
                      key={index}
                      src={media?.url || media["previewBlob"]}
                      onLoadedMetadata={(e) =>
                        this._onLoadedMetadata(e.target, index)
                      }
                    />
                    <Button
                      onClick={() => this.props.deleteContent(index)}
                      className="deletePreview"
                    >
                      <i className="fa fa-times" />
                    </Button>
                  </div>
                )
              )
              : null}
            {this.props.selectedAudio !== null ? (
              <div className="msgPreview">
                <img
                  src="/assets/img/microphone.png"
                  alt="Microphone"
                  style={{ height: 28, width: "unset" }}
                  loading="lazy"
                />
                <Button
                  onClick={() => this.props.deleteContent(0)}
                  className="deletePreview"
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {addFeedPreviewModal?.previewImageUrl?.previewBlob ||
              addFeedPreviewModal?.previewImageUrl?.uploadUrl ? (
              <div className="msgPreview">
                <img
                  src={
                    addFeedPreviewModal?.previewImageUrl?.previewBlob ||
                    addFeedPreviewModal?.previewImageUrl?.uploadUrl
                  }
                  alt="preview"
                  loading="lazy"
                />
                <Button
                  onClick={() => this.props.resetPreview("previewImageUrl")}
                  className="deletePreview"
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {addFeedPreviewModal?.previewVideoUrl?.previewBlob ||
              addFeedPreviewModal?.previewVideoUrl?.uploadUrl ? (
              <div className="msgPreview">
                <video
                  src={
                    addFeedPreviewModal?.previewVideoUrl?.previewBlob ||
                    addFeedPreviewModal?.previewVideoUrl?.uploadUrl
                  }
                  alt="preview"
                />
                <Button
                  onClick={() => this.props.resetPreview("previewVideoUrl")}
                  className="deletePreview"
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {this.props.isFreeContentForAll ? (
              <div className="msgPreview">
                <span title="Basic Price">Free For All</span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.resetFreeContentForAll()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {this.props.postCost ? (
              <div className="msgPreview">
                <span title="Basic Price">
                  {formatCurrencyValue(this.props.postCost)}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.deleteCost("postPrice")}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
            {/* plusPostPrice */}

            {this.props.plusPostPrice && this.props.plusPostPrice !== "0" ? (
              <div className="msgPreview">
                <span title="Plus Price">
                  {formatCurrencyValue(this.props.plusPostPrice)}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.deleteCost("plusPostPrice")}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
            {/* premiumPostPrice */}
            {this.props.premiumPostPrice &&
              this.props.premiumPostPrice !== "0" ? (
              <div className="msgPreview">
                <span title="Premium Price">
                  {formatCurrencyValue(this.props.premiumPostPrice)}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.deleteCost("premiumPostPrice")}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {/* goal preview */}
            {this.props.goalAmount ? (
              <div className="msgPreview">
                <span title="Goal Price">
                  {formatCurrencyValue(this.props.goalAmount)}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.saveGoalPrice()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}

            {/* nft preview */}
            {this.props.nftObject ? (
              <div className="msgPreview">
                <span title="Goal Price">
                  {this.props.nftObject.bitCoinType}{" "}
                  {this.props.nftObject.bitCoinCount}
                </span>
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  onClick={() => this.props.saveNftObject()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
          </div>
        )}

        {/* the below div is shown only when the user uploads something, telling him/her the no. of files that has been uploaded  */}
        {!this.props.hideFileNumber && this._getFileNumber() ? (
          <div className="filesSelected-CreatePost">
            {this._getFileNumber()}
          </div>
        ) : null}

        {/* Media Uploaded Preview */}
        {this.props.editMode ? (
          <div className="d-flex flex-column">
            {(!this.props.editedFeed &&
              this.props.startDate &&
              this.props.startDate !== "Invalid date") ||
              (this.props.editedFeed &&
                (this.props.editedFeed.postType === "scheduled-temporary" ||
                  this.props.editedFeed.postType === "scheduled") &&
                this.props.startDate &&
                this.props.startDate !== "Invalid date") ? (
              <div className="d-inline-block" style={{ marginBottom: 10 }}>
                Schedule at {convertDate(this.props.startDate)}
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  style={{
                    position: "unset",
                    verticalAlign: 1,
                    marginLeft: 15,
                  }}
                  onClick={() => this.props.removeScheduleDate()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
            {this.props.endDate && this.props.endDate !== "Invalid date" ? (
              <div className="d-inline-block" style={{ marginBottom: 10 }}>
                Expires on {convertDate(this.props.endDate)}
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  style={{
                    position: "unset",
                    verticalAlign: 1,
                    marginLeft: 15,
                  }}
                  onClick={() => this.props.removeExpireDate()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="d-flex flex-column">
            {(!this.props.editedFeed &&
              this.props.startDate &&
              this.props.startDate !== "Invalid date") ||
              (this.props.editedFeed &&
                (this.props.editedFeed.postType === "scheduled-temporary" ||
                  this.props.editedFeed.postType === "scheduled") &&
                this.props.startDate &&
                this.props.startDate !== "Invalid date") ? (
              <div className="d-inline-block" style={{ marginBottom: 10 }}>
                Schedule at {convertDate(this.props.startDate)}
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  style={{
                    position: "unset",
                    verticalAlign: 1,
                    marginLeft: 15,
                  }}
                  onClick={() => this.props.removeScheduleDate()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
            {this.props.endDate && this.props.endDate !== "Invalid date" ? (
              <div className="d-inline-block" style={{ marginBottom: 10 }}>
                Expires on {this.props.endDate}
                {/* delete btn */}
                <Button
                  className="deletePreview"
                  style={{
                    position: "unset",
                    verticalAlign: 1,
                    marginLeft: 15,
                  }}
                  onClick={() => this.props.removeExpireDate()}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            ) : null}
          </div>
        )}

        <MediaLibraryModal
          isOpen={isMediaLibraryModalOpen}
          dropFileData={isMediaLibraryModalData}
          toggle={() => this._toggleMediaLibraryModal()}
          isWelcomeMessage={this.props.isWelcomeMessage}
          isMultipleUpload={this.props.isMultipleUpload ?? true}
          isMultipleImages={this.props.isMultipleImages ?? true}
          isMultipleVideos={this.props.isMultipleVideos ?? true}
          isMultipleAudios={this.props.isMultipleAudios ?? true}
          isAudioUpload={this.props.isAudioUpload ?? true}
          isVideoUpload={this.props.isVideoUpload ?? true}
          isImageUpload={true}
          isUploadLimit={this.props.isUploadLimit}
          imageLimit={this.props.imageLimit}
          videoLimit={this.props.videoLimit}
          audioLimit={this.props.audioLimit}
          uploadedFiles={this.props.selectedMedias}
          shouldUploadInModal={this.props.shouldUploadInModal}
          uploadContents={(contents) => this._uploadFiles(contents)}
        />
      </div>
    );
  }
}

export default CreatePost;
