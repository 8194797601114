import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Button,
} from "reactstrap";
import Modal from "react-modal";
import { getSrcUrl, imageLoadFallBack } from "../../helper-methods";
import ErrorBoundary from "../ErrorBoundary";
import AudioPlayer from "../custom/AudioPlayer";
import { useMemo } from "react";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
};

const MultiMediaModal = ({
  isOpen,
  contents,
  activeIndex: contentIndex,
  toggle,
  isHideTotalLength,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const filteredContents = useMemo(() => {
    if (!contents?.length) return [];

    return contents.map((each, index) => ({
      ...each,
      id: `MultiMediaModal_${each._id}_${index}`,
    }));
  }, [contents]);

  useEffect(() => {
    if (isOpen && (+contentIndex || +contentIndex === 0)) {
      setActiveIndex(+contentIndex);
    }
  }, [isOpen, contentIndex]);

  // when click on next arrow button
  const _next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === filteredContents?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    _pauseAllMediaWhenToggleIsOpen();
  };

  // when click on previous arrow button
  const _previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? filteredContents.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    _pauseAllMediaWhenToggleIsOpen();
  };

  // when click on carousel indicator
  const _goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
    _pauseAllMediaWhenToggleIsOpen();
  };

  const _setAnimating = (value) => {
    setAnimating(value);
  };

  const _onExit = () => {};

  const _onEnter = () => {};

  const _pauseAllMediaWhenToggleIsOpen = () => {
    const carousel = document.getElementsByClassName("multiMediaCarousel")[0];
    const mediaElements = carousel.getElementsByTagName("video");
    const audioElements = carousel.getElementsByTagName("audio");

    // Pause all videos
    for (let i = 0; i < mediaElements.length; i++) {
      const video = mediaElements[i];
      if (!video.paused) {
        video.pause();
      }
    }

    // Pause all audios
    for (let i = 0; i < audioElements.length; i++) {
      const audio = audioElements[i];
      if (!audio.paused) {
        audio.pause();
      }
    }
  };

  const _getActiveContentItem = (item) => {
    switch (item?.mediaType || item?.contentType) {
      case "image": {
        return (
          <img
            className="mediaPhoto-Feed"
            src={getSrcUrl(item)}
            onError={(e) => (e.target.src = imageLoadFallBack(item))}
            alt="mediaFeed"
            loading="lazy"
          />
        );
      }
      case "video": {
        return (
          <ErrorBoundary>
            {/* custom video player support hls */}
            <CustomVideoPlayer
              src={item?.url || item?._content?.url || item?._contentId?.url}
              hlsLink={
                item?.hls || item?._content?.hls || item?._contentId?.hls
              }
              thumbnail={
                item?.thumbnail ||
                item?._content?.thumbnail ||
                item?._contentId?.thumbnail
              }
              duration={
                item?.duration ||
                item?._content?.duration ||
                item?._contentId?.duration
              }
              controls
            />
          </ErrorBoundary>
        );
      }
      case "audio": {
        return (
          <ErrorBoundary>
            {/* custom video player support hls */}
            <AudioPlayer
              className="w-100 h-100 d-flex justify-content-center align-items-center bg-white rounded"
              url={getSrcUrl(item)}
            />
          </ErrorBoundary>
        );
      }
      case "doc": {
        return (
          <iframe
            title="media_pdf_doc"
            className="pdfInModal"
            src={getSrcUrl(item) + "#toolbar=0"}
          />
        );
      }
      default: {
        return <div>Content Not found!</div>;
      }
    }
  };

  // slides of carousel
  const slides = filteredContents?.length
    ? filteredContents.map((item, index) => {
        return (
          <CarouselItem
            onExiting={() => _setAnimating(true)}
            onExited={() => _setAnimating(false)}
            onExit={(e) => _onExit(e, item)}
            onEnter={(e) => _onEnter(e, item)}
            key={`${"feed_viewer_carousel"}__${index}`}
          >
            {_getActiveContentItem(item, index)}
          </CarouselItem>
        );
      })
    : null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={customStyles}
      ariaHideApp={false}
      className="multipleMediaModal hideCarouselIndicator"
    >
      <Button onClick={toggle} className="closeModalFeed">
        <i className="fa fa-close modalFeed_closeIcon" />
      </Button>

      {filteredContents?.length ? (
        <>
          {/* show active content page number / total content - ( 1 / 8 )*/}
          <div className="mediaCountCarousel">
            {activeIndex + 1}
            {!isHideTotalLength ? `/${filteredContents?.length}` : null}
          </div>

          <Carousel
            className="multiMediaCarousel"
            activeIndex={activeIndex}
            next={() => _next()}
            previous={() => _previous()}
            ride="carousel"
            autoPlay={false}
            interval={false}
          >
            <CarouselIndicators
              items={filteredContents}
              activeIndex={activeIndex}
              onClickHandler={(index) => _goToIndex(index)}
            />

            {slides}

            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={() => _previous()}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={() => _next()}
            />
          </Carousel>
        </>
      ) : (
        <div className="noContentFound">No Content found</div>
      )}
    </Modal>
  );
};

export default MultiMediaModal;
