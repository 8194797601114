import React, { useEffect, useState } from "react";
import { Button, UncontrolledCollapse } from "reactstrap";
import { fetchEvent, getLiveEventStreamingData } from "../http-calls";
import TextareaAutosize from "react-textarea-autosize";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import {
  capitalize,
  showToast,
  formatCurrencyValue,
  formatTimeFromNow,
  errorHandler,
  isPermissionToAccess,
  getLowResolutionLink,
  getFullName,
} from "../helper-methods";
import "moment/min/locales";
import { RegexConfig } from "../config/RegexConfig";
import cuid from "cuid";
import { newSocket } from "../socket-io";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { autoHeightStyleChat } from "../assets/styles/js";
import SkeletonLoading from "../components/SkeletonLoading";
import { HostStreaming, CoHostStreaming } from "../components/LiveStreaming";
import ErrorBoundary from "../components/ErrorBoundary";

const LiveEventStreamingPage = () => {
  const history = useHistory();

  const params = useParams();

  const userData = useSelector((state) => state?.userData);

  const [eventData, setEventData] = useState(null);
  const [eventStreamingData, setEventStreamingData] = useState(null);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [receiveNewMessage, setReceiveNewMessage] = useState(null);

  const _getLiveEventStreamingData = async () => {
    try {
      const res = await getLiveEventStreamingData(params?.id);

      /**
       *  eventStreamingData = {
       *    appId
       *    roomId
       *    token
       *    userName
       *    userRole
       *  }
       */
      setEventStreamingData(res?.response);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _fetchEvent = async () => {
    try {
      const res = await fetchEvent(params?.id);

      setEventData(res.event);
      setComments(res.liveComments);

      _subscribeToThreadChannel(res.event);

      _getLiveEventStreamingData();
    } catch (error) {
      errorHandler(error);
    }
  };

  const _postComment = (newComment = "") => {
    let message = {
      _from: userData?.user?.id,
      _event: eventData?._id,
      tempCommentId: cuid(),
      _formData: {
        profilePicture:
          userData?.user?.profilePicture || DEFAULT_PROFILE_PICTURE,
        name: `@${userData?.user?.username}`,
      },
      text: newComment.length ? newComment : comment.length ? comment : "",
      when: new Date(),
      isTip: false,
    };

    setComment("");

    _publishMessageOnChannel(message);
  };

  const _appendReceiveMessage = (newMessage) => {
    const newComments = [...comments];
    newComments.unshift(newMessage);
    setComments(newComments);
  };

  useEffect(() => {
    if (receiveNewMessage) {
      _appendReceiveMessage(receiveNewMessage);
      setReceiveNewMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveNewMessage]);

  const _subscribeToThreadChannel = (eventData) => {
    try {
      if (eventData?._id) {
        const params = { room: eventData._id };
        newSocket.emit("subscribe", params, function (res) {
          console.log("subscribed>>", res);
          if (res.error) {
            errorHandler(res);
          }
        });

        newSocket.on("neweventcomment", (res) => {
          console.log("receive>>", res);
          if (res.error) {
            errorHandler(res);
          } else {
            setReceiveNewMessage(res);
          }
        });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _publishMessageOnChannel = (comment) => {
    try {
      newSocket.emit("neweventcomment", comment, (res) => {
        console.log("send>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _unsubscribeToThreadChannel = () => {
    try {
      if (eventData?._id) {
        const params = { room: eventData._id };
        // remove all callback of neweventcomment event
        newSocket.removeAllListeners("neweventcomment");
        // unsubscribe event - pause callback
        newSocket.emit("unsubscribe", params, function (res) {
          console.log("unsubscribed>>", res);
          if (res.error) {
            console.log("error>>", res.error);
          }
        });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _updateComment = (value, isEmoji = false) => {
    if (isEmoji) {
      setComment((prev) => prev + value);
    } else {
      setComment(value);
    }
  };

  const _onEnterPressed = (e) => {
    const code = e.keyCode || e.which;

    if (code === 13 && !e.shiftKey && !e.ctrlKey) {
      if (e) e.preventDefault();

      if (comment?.trim()?.length) {
        _postComment();
      }
    }
  };

  const _renderComment = (comment, isTip = false) => {
    let parsedComment = "";

    if (comment.includes("STICKER") && comment.includes("PRICE") && isTip) {
      try {
        const sticker = JSON.parse(comment);
        if (RegexConfig.url.test(sticker?.STICKER)) {
          return (
            <div>
              <pre>
                <img
                  src={sticker?.STICKER}
                  alt="Sticker"
                  className="stickerLiveStreaming"
                  loading="lazy"
                />
              </pre>
              {sticker?.PRICE
                ? `${formatCurrencyValue(sticker?.PRICE)} Paid`
                : null}
            </div>
          );
        }
      } catch (error) {}
    }

    switch (comment) {
      case "LIKE_ICON":
        parsedComment = (
          <pre>
            <img
              src={"/assets/img/like-icon.png"}
              alt="Emoji"
              className="emojiImgAsComment-liveStreaming"
              loading="lazy"
            />
          </pre>
        );
        break;
      case "HUG_ICON":
        parsedComment = (
          <pre>
            <img
              src={"/assets/img/hug-icon.png"}
              alt="Emoji"
              className="emojiImgAsComment-liveStreaming"
              loading="lazy"
            />
          </pre>
        );
        break;
      case "KISS_ICON":
        parsedComment = (
          <pre>
            <img
              src={"/assets/img/kiss-icon.png"}
              alt="Emoji"
              className="emojiImgAsComment-liveStreaming"
              loading="lazy"
            />
          </pre>
        );
        break;
      case "HEART_ICON":
        parsedComment = (
          <pre>
            <img
              src={"/assets/img/heart-icon.png"}
              alt="Emoji"
              className="emojiImgAsComment-liveStreaming"
              loading="lazy"
            />
          </pre>
        );
        break;

      default:
        parsedComment = comment;
        break;
    }
    return parsedComment;
  };

  useEffect(() => {
    if (!isPermissionToAccess("liveEvent", "canStartLiveEvent")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    _fetchEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    return () => {
      _unsubscribeToThreadChannel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <div className="liveEventStreamingWrap">
          {/* video streaming here */}
          {eventStreamingData ? (
            <ErrorBoundary>
              {eventStreamingData?.userRole === "Host" ? (
                <HostStreaming eventStreamingData={eventStreamingData} />
              ) : (
                <CoHostStreaming eventStreamingData={eventStreamingData} />
              )}
            </ErrorBoundary>
          ) : (
            <div className="myCallContainer">
              <SkeletonLoading
                type="box"
                count={1}
                height={"100%"}
                width={"100%"}
                borderRadius={0}
              />
            </div>
          )}

          {/* comment section */}
          <UncontrolledCollapse
            toggler="#toggler"
            className="liveStreamCommentWrap"
          >
            <div className="commentSectionWrap-liveStream">
              {eventData ? (
                <>
                  <div className="liveStreamBrief">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getLowResolutionLink(
                            userData?.user?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile"
                        className="userImg"
                        loading="lazy"
                      />

                      <p className="creatorName-liveStream">
                        <span>{getFullName(userData?.user?.name)}</span> is live
                        now
                      </p>
                    </div>

                    {/* live event name */}
                    <div className="liveStreamName">
                      {eventData?.name || "N/A"}
                    </div>
                  </div>

                  <div className="commentSection comment-LiveStreamEvent">
                    {comments && comments.length ? (
                      React.Children.toArray(
                        comments.map((comment) => (
                          <>
                            <div className="d-flex" style={{ marginTop: 12 }}>
                              <img
                                src={
                                  getLowResolutionLink(
                                    comment?._formData?.profilePicture
                                  ) || DEFAULT_PROFILE_PICTURE
                                }
                                onError={(e) =>
                                  (e.target.src = DEFAULT_PROFILE_PICTURE)
                                }
                                alt="Profile"
                                className="userImg-Comment"
                                loading="lazy"
                              />
                              <div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ marginBottom: 2 }}
                                >
                                  <p className="userName-Comment text-white">
                                    {capitalize(comment?._formData?.name)}
                                  </p>
                                  <div className="commentTime text-white">
                                    {formatTimeFromNow(comment?.when)}
                                  </div>
                                </div>
                                <div className="comments-Post">
                                  {_renderComment(
                                    comment?.text,
                                    comment?.isTip
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      )
                    ) : (
                      <p className="noComments">No comments yet</p>
                    )}
                  </div>

                  <div className="commentArea-liveStream">
                    <div className="d-flex position-relative">
                      <img
                        src={
                          getLowResolutionLink(
                            userData?.user?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile"
                        className="userImg-Comment"
                        loading="lazy"
                      />
                      <TextareaAutosize
                        minRows="1"
                        style={autoHeightStyleChat}
                        placeholder="Write a comment..."
                        onChange={(e) => _updateComment(e.target.value)}
                        onKeyPress={(ev) => _onEnterPressed(ev)}
                        value={comment}
                      />

                      <Button
                        className="sendMsg"
                        style={{ bottom: 7 }}
                        disabled={!comment.trim().length}
                        onClick={() => _postComment()}
                      >
                        <img
                          src="/assets/img/send.png"
                          alt="Send"
                          loading="lazy"
                        />
                      </Button>
                    </div>
                    <div className="liveStreamingBtnOptions">
                      <Button
                        color="link"
                        onClick={() => _postComment("LIKE_ICON")}
                      >
                        <img
                          src={"/assets/img/like-icon.png"}
                          alt="Emoji"
                          loading="lazy"
                        />
                      </Button>
                      <Button
                        color="link"
                        onClick={() => _postComment("HEART_ICON")}
                      >
                        <img
                          src={"/assets/img/heart-icon.png"}
                          alt="Emoji"
                          loading="lazy"
                        />
                      </Button>
                      <Button
                        color="link"
                        onClick={() => _postComment("HUG_ICON")}
                      >
                        <img
                          src={"/assets/img/hug-icon.png"}
                          alt="Emoji"
                          loading="lazy"
                        />
                      </Button>
                      <Button
                        color="link"
                        onClick={() => _postComment("KISS_ICON")}
                      >
                        <img
                          src={"/assets/img/kiss-icon.png"}
                          alt="Emoji"
                          loading="lazy"
                        />
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <SkeletonLoading type="liveStreamComment" />
              )}
            </div>
          </UncontrolledCollapse>
          {/* comment section toggle button */}
          <Button id="toggler" className="liveStreamCommentToggle">
            <i className="fa fa-comment-o" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default LiveEventStreamingPage;
