import React, { useState } from "react";
import MultiMediaModal from "../modals/MultiMediaModal";
import { Button } from "reactstrap";
import {
  capitalize,
  formatCurrencyValue,
  getFullName,
} from "../../helper-methods";
import { DEFAULT_COVER_PICTURE } from "../../config";

const ChatMultiFile = ({ message, fan }) => {
  const [multiMediaModal, setMultiMediaModal] = useState({
    isOpen: false,
    contents: null,
    activeIndex: null,
  });

  const _pauseAllMediaWhenToggleIsOpen = () => {
    try {
      const videos = document.querySelectorAll("video");
      const audios = document.querySelectorAll("audio");

      videos.forEach((each) => {
        if (each?.pause) {
          each.pause();
        }
      });
      audios.forEach((each) => {
        if (each?.pause) {
          each.pause();
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const _toggleMultiMediaModal = (
    isOpen = false,
    contents = null,
    activeIndex = 0
  ) => {
    if (isOpen) {
      _pauseAllMediaWhenToggleIsOpen();
    }

    setMultiMediaModal({
      isOpen,
      contents,
      activeIndex,
    });
  };

  const _getActiveContentItem = (item, message, index) => {
    switch (item?.mediaType) {
      case "image": {
        return (
          <div
            key={item._id || index}
            className="groupMediaWrap"
            onClick={() =>
              _toggleMultiMediaModal(true, message?.content, index)
            }
          >
            <img
              src={item._contentId?.url}
              className="groupMedia"
              alt="Img"
              loading="lazy"
            />
          </div>
        );
      }
      case "video": {
        return (
          <div
            key={item._id || index}
            className="groupMediaWrap"
            onClick={() =>
              _toggleMultiMediaModal(true, message?.content, index)
            }
          >
            <img
              src={item?._contentId?.thumbnail || DEFAULT_COVER_PICTURE}
              onError={(e) => (e.target.value = DEFAULT_COVER_PICTURE)}
              className="groupMedia"
              alt="Img"
              loading="lazy"
            />

            <div className="shadowOverlayImg">
              <Button className="videoPlayBtn">
                <i className="fa fa-play" />
              </Button>
            </div>
          </div>
        );
      }
      case "audio": {
        return (
          <div
            key={item._id || index}
            className="groupMediaWrap"
            onClick={() =>
              _toggleMultiMediaModal(true, message?.content, index)
            }
          >
            <img src="/assets/img/mic.png" alt="Microphone" loading="lazy" />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  const _renderAlbumPreview = () => {
    let contents = null;
    let albumPreview = null;
    let seeMoreLength = null;
    let seeMore = false;

    contents = message.content;

    if (contents && contents.length) {
      if (contents.length > 4) {
        seeMoreLength = contents.length - 4;
        contents = contents.slice(0, 4);
        seeMore = true;
      }
      albumPreview = (
        <div className="d-flex flex-wrap position-relative">
          {contents.map((content, index) =>
            _getActiveContentItem(content, message, index)
          )}
          {seeMore ? (
            <div
              className="seeMoreMedia cursorPointer"
              onClick={() => _toggleMultiMediaModal(true, message?.content, 3)}
            >
              <Button color="link">+{seeMoreLength}</Button>
            </div>
          ) : null}
        </div>
      );
    }

    return albumPreview;
  };

  return (
    <>
      <div>
        <div className="mediaPhotoWrap">
          {_renderAlbumPreview()}

          {!message.isPublic ? (
            <div className="msgPrice">
              {message?.content?.[0]?.amount
                ? formatCurrencyValue(message.content[0].amount)
                : message.amount
                ? formatCurrencyValue(message.amount)
                : ""}
            </div>
          ) : null}
        </div>

        <MultiMediaModal
          isOpen={multiMediaModal.isOpen}
          contents={multiMediaModal.contents}
          activeIndex={multiMediaModal.activeIndex}
          toggle={() => _toggleMultiMediaModal()}
        />
      </div>

      {message?.hasPurchased ? (
        <div className="paidMessageText">
          {fan?.name?.full || fan?.name?.first
            ? capitalize(getFullName(fan.name))
            : "Fan"}{" "}
          Paid{" "}
          {message?.content?.[0]?.amount
            ? formatCurrencyValue(message.content[0].amount)
            : message.amount
            ? formatCurrencyValue(message.amount)
            : ""}{" "}
          for this message
        </div>
      ) : null}
    </>
  );
};

export default ChatMultiFile;
