import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllStories } from "../../http-calls";

const deleteStoryFromRedux = createAction("deleteStoryFromRedux");
const clearAllStories = createAction("clearAllStories");
const updateStories = createAction("updateStories");

const getAndUpdateStories = createAsyncThunk(
  "stories/getAndUpdateStories",
  async (payload, thunkAPI) => {
    const res = await getAllStories();
    return res?.stories;
  }
);

export {
  clearAllStories,
  deleteStoryFromRedux,
  getAndUpdateStories,
  updateStories,
};
