import React from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const AddFeedPreviewModal = ({
  isOpen,
  previewImageUrl,
  previewVideoUrl,
  toggle,
  editMode,
  onChangePreview,
  onCropPreview,
  resetPreview,
}) => {
  const _closeModal = () => {
    toggle();
  };

  const _resetPreview = () => {
    resetPreview();
  };

  const _handleFileChange = (e) => {
    onChangePreview(e);
  };

  // Allow cropping image preview only while uploading a new one
  const canCropPreview = !!previewImageUrl?.uploadData;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {editMode ? "Update" : "Add"} Preview Media
      </ModalHeader>

      <ModalBody>
        <>
          <div>
            {previewImageUrl?.previewBlob || previewImageUrl?.uploadUrl ? (
              <img
                width="100%"
                src={previewImageUrl?.previewBlob || previewImageUrl?.uploadUrl}
                alt="preview"
                loading="lazy"
                className="previewImgInModal"
              />
            ) : previewVideoUrl?.previewBlob || previewVideoUrl?.uploadUrl ? (
              <video
                width="100%"
                src={previewVideoUrl?.previewBlob || previewVideoUrl?.uploadUrl}
                controls
                controlsList="nodownload"
                disablePictureInPicture
              />
            ) : (
              <Label className="themeBtn addBtn d-block mx-auto">
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*, video/*"
                  onChange={(e) => _handleFileChange(e)}
                />
                Upload Preview Media
              </Label>
            )}
          </div>

          {previewImageUrl?.previewBlob ||
          previewImageUrl?.uploadUrl ||
          previewVideoUrl?.previewBlob ||
          previewVideoUrl?.uploadUrl ? (
            <>
              <Label className="themeBtn addBtn d-block mx-auto mt-3">
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*, video/*"
                  onChange={(e) => _handleFileChange(e)}
                />
                Change Preview Media
              </Label>

              {canCropPreview ? (
                <Button
                  color="link"
                  className="addPreviewVideo d-block mt-2 mx-auto"
                  onClick={() => onCropPreview(previewImageUrl?.uploadData)}
                >
                  Crop Preview
                </Button>
              ) : null}
            </>
          ) : null}
        </>
      </ModalBody>

      <ModalFooter>
        {previewImageUrl?.previewBlob ||
        previewImageUrl?.uploadUrl ||
        previewVideoUrl?.previewBlob ||
        previewVideoUrl?.uploadUrl ? (
          <Button className="modalBtnCancel" onClick={() => _resetPreview()}>
            Reset
          </Button>
        ) : null}

        <Button className="modalBtnSave" onClick={() => _closeModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddFeedPreviewModal;
