import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import {
  deepClone,
  formatCurrencyValue,
  formatDateAndTime,
} from "../../helper-methods/index";
import { saveLocalFilters } from "../../redux/actions";
import { useDispatch } from "react-redux";

const StatementStatsTableModal = ({
  isOpen,
  data,
  toggle,
  modalHeader,
  seeMore,
}) => {
  const _closeModal = () => {
    toggle();
  };

  const _seeMore = () => {
    _closeModal();
    seeMore();
  };

  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    filterType: "checkbox", // 'payperview', 'shoutout', 'liveEvent', 'vault', 'post', 'subscription', 'message', 'referrals', 'tips'
    startDate: null,
    endDate: null,
    checkbox: {
      paymentType: [],
    },
    dropdown: {
      paymentType: "",
      paymentTypeId: "",
    },
  });
  useEffect(() => {
    if (modalHeader) {
      const paymentTypeMap = {
        "Vault": "vault",
        "Pay Per View": "payperview",
        "Live Event": "liveEvent",
        "Shout Out" : "shoutout",
        "Post" : "post",
        "Subscription" : "subscription",
        "Message" : "message",
        "Referrals" : "referrals",
        "Tips" : "tips",
        "Qwerty" : "tips",
      };
  
      const paymentType = paymentTypeMap[modalHeader];
      
      if (paymentType) {
        setFilters((prev) => ({
          ...prev,
          checkbox: { paymentType: [paymentType] },
        }));
      }
    }
  }, [modalHeader]);
  dispatch(
    saveLocalFilters({ key: "earningsTable", value: deepClone(filters) })
  );


  return (
    <Modal
      isOpen={isOpen}
      toggle={_closeModal}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={_closeModal}>{modalHeader}</ModalHeader>

      <ModalBody className="px-1 px-md-3">
        <Table responsive>
          <thead>
            <tr>
              <th>{modalHeader} Name</th>
              <th>Earnings</th>
              <th>Referral Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              React.Children.toArray(
                data.map((each) => {
                  return (
                    <tr>
                      <td>
                        {each?.details?.name?.first ||
                          each?.details?.name ||
                          each?.details?.title}
                      </td>
                      <td>{formatCurrencyValue(each.earning || 0)}</td>
                      <td>
                        {formatDateAndTime(each?.details?.createdAt) || "N/A"}
                      </td>
                    </tr>
                  );
                })
              )
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  No earnings yet
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ModalBody>

      <ModalFooter>
        <Button className="modalBtnSave" onClick={() => _seeMore()}>
          See More
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StatementStatsTableModal;
